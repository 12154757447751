/*
    Created on : 31-ago-2020, 10.04.40
    Author     : veronica
*/


/* ==========================================================================
   #Global Owl Slider - begin
   ========================================================================== */
.owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev, .owl-carousel button.owl-dot {
  outline: none;
}

.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.owl-theme .owl-nav [class*='owl-'] {
  color: #FFF;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: $color_celeste;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}

.owl-theme .owl-nav [class*='owl-']:hover {
  background: $txt_blu;
  color: #FFF;
  text-decoration: none;
}

.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 60px;
}

.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  *display: inline;
}

.owl-theme .owl-dots .owl-dot span {
  width: 12px;
  height: 12px;
  margin: 5px 7px;
  background: $color_celeste;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px;
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: $txt_blu;
}
.sfondo-blu {
  .owl-theme .owl-dots .owl-dot span {
    background: #5b6a7a;
  }
  .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: $sfondo_cta;
  }
}
.sfondo-grigio {
  .owl-theme .owl-dots .owl-dot span {
    background: #fff;
  }
  .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: $txt_blu;
  }
}

.sfondo-bianco {
  .owl-theme .owl-dots .owl-dot span {
    background: $color_grigio_scuro;
  }
  .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: $txt_blu;
  }
}
// NAVFLOAT
.owl-carousel.owl-theme-navfloat .owl-nav {
  position: absolute;
  top: 50%;
  width: 100%;
  font-size: 30px;
  -webkit-tap-highlight-color: transparent;
}

.owl-carousel.owl-theme-navfloat .owl-nav [class*='owl-'] {
  margin: 0;
  position: absolute;
  padding: 0;
}

.owl-carousel.owl-theme-navfloat .owl-nav [class*='owl-']:hover {
  color: $txt_blu;
  text-decoration: none;
}

.owl-carousel.owl-theme-navfloat .owl-nav .owl-prev {
  left: 0;
  margin-left: 15px;
}

.owl-carousel.owl-theme-navfloat .owl-nav .owl-next {
  right: 0;
  margin-right: 15px;
}

.owl-carousel.owl-theme-navfloat .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}

// DARK

.owl-theme-neg .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.owl-theme-neg .owl-nav [class*='owl-'] {
  color: #FFF;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: $color_testo_neg;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}

.owl-theme-neg .owl-nav [class*='owl-']:hover {
  background: #fff;
  color: #FFF;
  text-decoration: none;
}

.owl-theme-neg .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}

.owl-theme-neg .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}

.owl-theme-neg .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.owl-theme-neg .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  *display: inline;
}

.owl-theme-neg .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #fff;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px;
}

.owl-theme-neg .owl-dots .owl-dot.active span, .owl-theme-neg .owl-dots .owl-dot:hover span {
  background: $txt_blu;
}


// BOTH

.owl-theme.owlsingleboth {
  .owl-nav {
    width: 100%;
    margin: 0;
    position: absolute;
    top: calc((100% - 90px) / 2);

    button.owl-prev, button.owl-next {
      position: absolute;
      color: $txt_blu;
      font-size: 50px;

      &:hover {
        background-color: transparent;
        color: $color_celeste;
      }
    }

    button.owl-prev {
      left: -60px;
    }

    button.owl-next {
      right: -60px;
    }
  }

  .owl-dots {
    margin-top: 20px;
  }
}

/* ==========================================================================
   #Global Owl Slider - end
   ========================================================================== */

/*+++++++++++++++++++++
CUSTOMIZZAZIONE JCF
+++++++++++++++++++++++*/
.jcf-select {
  border: 0;
  border-bottom: 1px solid $color_grigio_bordo;
  margin: 0;
  min-width: 100%;
  height: 35px;
  padding: 5px 10px 5px 0px;

  &:before {
    content: "";
    position: absolute;
    top: 15px;
    right: 11px;
    border-style: solid;
    border-width: 6px 5px;
    border-color: $color_grigio_bordo transparent transparent;
  }

  .jcf-select-opener {
    width: 35px;
    background: none;
  }
}

.jcf-select .jcf-select-text {
  line-height: 1;
  margin: 5px 35px 5px 0; //12px 35px 5px 0;
}

.jcf-focus {
  border-color: $color_grigio_bordo !important;
}

.jcf-file {
  width: 100%;
  margin: 0;
  margin-bottom: 20px;
  margin-top: 5px;

  .jcf-fake-input {
    width: 80%;
    padding: 6px 8px;
    border: 1px solid $color_grigio_bordo;
    height: 46px;
  }

  .jcf-upload-button {
    padding: 6px 0;
    width: 20%;
    border: 1px solid $color_grigio_bordo;
    height: 46px;
    text-align: center;
    font-size: 13px;
  }
}

.jcf-list, .jcf-button, .jcf-file {
  background: transparent;
}

.jcf-list-box {
  border: 1px solid $color_grigio_bordo;
  margin-left: 0;
  min-width: 100%;
}


#content-marker {
  text-align: left;

  img {
    padding-bottom: 10px;
  }
}

/* -------------------------------------------------- */
/*	WPML
/* -------------------------------------------------- */

.wpml-ls-legacy-list-vertical a {
  padding-left: 0;
}

.wpml-ls-native {
  display: inline-block;
  position: relative
}

.wpml-ls-native:before {
  content: '';
  display: block;
  position: absolute;
  top: 100%;
  left: 50%;
  -webkit-transition: left .2s cubic-bezier(0.4, 0, 0.2, 1), width .2s cubic-bezier(0.4, 0, 0.2, 1);
  transition: left .2s cubic-bezier(0.4, 0, 0.2, 1), width .2s cubic-bezier(0.4, 0, 0.2, 1);
  width: 0;
  height: 2px;
  margin-top: 2px;
  background: $txt_blu
}

.wpml-ls-link:hover .wpml-ls-native, .wpml-ls-link:focus .wpml-ls-native {
}

.wpml-ls-link:hover .wpml-ls-native:before, .wpml-ls-link:focus .wpml-ls-native:before {
  left: 0;
  width: 100%
}

/* -------------------------------------------------- */
/*	INPUT
/* -------------------------------------------------- */


.wpcf7-form, .search-form, .classic-form, .inline-form, .comment-form {
  /*    input[type="submit"] {
          @include button_style(transparent, $txt_blu, $txt_blu, #fff, $txt_blu, $txt_blu);
          &:disabled {
              @include button_style(transparent, $txt_blu, $txt_blu, #fff, $txt_blu, $txt_blu);
          }
      }
      button[type="submit"] {
          @include button_style(transparent, $txt_blu, $txt_blu, #fff, $txt_blu, $txt_blu);
          &:disabled {
              @include button_style(transparent, $txt_blu, $txt_blu, #fff, $txt_blu, $txt_blu);
          }
      }*/
  input[type="text"], input[type="email"], input[type="confirm_email"], input[type="url"], input[type="password"], input[type="search"], input[type="number"], input[type="tel"], input[type="range"],
  input[type="date"], input[type="month"], input[type="week"], input[type="time"], input[type="datetime"], input[type="datetime-local"], input[type="color"], input[type="file"], textarea {
    background-color: transparent;
    border-radius: 25px;
    color: $color_testo;
    padding: 8px 15px;
    width: 100%;
    font-size: 88%;
    line-height: 1;
    border: 2px solid $txt_blu;
    outline: none;
    margin: 0;
  }

  input[type="date"] {
    font-size: 82%; //15px;
  }

  select {
    width: 100%;
    border-radius: 25px;
    color: $color_testo;
    padding: 9px 15px;
    line-height: 1;
    border: 2px solid $txt_blu;
    outline: none;
    margin: 0;
  }

  label {
    width: 100%;
    font-weight: 400;
    margin-top: 15px;
    margin-bottom: 3px;
    padding: 0;
    font-size: 18px;
    letter-spacing: 0;
    color: $txt_blu;
    line-height: 1.5em;
  }

  .select2-container--default .select2-selection--single {
    border-radius: 0;
    border: 1px solid $color_grigio_bordo;
  }

  .select2-container .select2-selection--single {
    height: 42px;
  }

  .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 37px;
  }

  .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 40px;
  }

  .select2-results {
    font-size: 18px;
  }

  .privacy {
    font-size: 60%;
    text-transform: inherit;

    span.wpcf7-list-item {
      margin: 0;
    }
  }

  .obb {
    font-weight: 500;
    font-size: 65%;
  }

  div.wpcf7-validation-errors {
    display: block;
    border: 0;
    color: $color_rosso;
  }

  div.wpcf7-mail-sent-ok {
    border: 0;
    color: $color_verde;
    text-align: center;
  }

  div.wpcf7-response-output {
    margin: 1em 0.5em 1em;
  }

  .ajax-loader {
    float: right;
  }

  span.wpcf7-not-valid-tip {
    font-size: 90%;
    //text-transform: uppercase;
    //color: $color_testo;
    padding-top: 5px;
  }

  .wpcf7-list-item-label {
    font-size: 76%;
  }

  span.wpcf7-list-item {
    margin: 0;
  }

  .select2-container--default .select2-selection--single {
    border-radius: 0;
  }

  .select2-container .select2-selection--single {
    height: 35px;
  }

  .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #000;
    line-height: 35px;
  }

  .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 35px;
  }

  .select2-container--default .select2-selection--single {
    background-color: #fff;
    border: 0;
    border-bottom: 1px solid $color_grigio_bordo;
    border-radius: 0;
    outline: none;
  }
}

.inline-form {
  input[type="text"], input[type="email"], input[type="confirm_email"], input[type="url"], input[type="password"], input[type="search"], input[type="number"], input[type="tel"], input[type="range"],
  input[type="date"], input[type="month"], input[type="week"], input[type="time"], input[type="datetime"], input[type="datetime-local"], input[type="color"], input[type="file"], textarea, select {
    width: auto;
  }
}

/* -------------------------------------------------- */
/*	SLICK
/* -------------------------------------------------- */

.slick-dots {
  //margin-left: -15px;
  bottom: -40px;
}

.slick-dots li button:before {
  color: $color_blu;
  content: '\f111';
  font-family: "Font Awesome 5 Pro";
  font-size: 12px;
  opacity: 1;
  font-style: normal;
  font-weight: 400;
}

.slick-dots li.slick-active button:before {
  opacity: 1;
  color: $color_blu;
  content: '\f111';
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 900;
}
.slick-next {
  right: -60px;
}
.slick-prev {
  left: -60px;
}
.slick-next, .slick-prev {
  width: 50px;
  height: 50px;
}
.slick-next:before, .slick-prev:before{
  color: $color_blu;
  content: '\f111';
  font-family: "Font Awesome 5 Pro";
  opacity: 1;
  font-size: 50px;
}
.slick-prev:before {
  content: '\f053';
}
.slick-next:before {
  content: '\f054';
}
.sfondo-bianco {
  .slick-dots li button:before {
    color: $color_grigio_scuro;
  }
  .slick-dots li.slick-active button:before {
    color: $txt_blu;
  }
}
/* -------------------------------------------------- */
/*	PAGE NAVI
/* -------------------------------------------------- */

.wp-pagenavi {
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  font-size: 120%;

  a, span {
    border: 0;
  }

  .pages {
    display: none;
  }

  .current {
    color: $color_blu;
  }
}

/* -------------------------------------------------- */
/*	Cookiebot
/* -------------------------------------------------- */
.CookieDeclarationType {
  border: 0 !important;
  padding: 0 !important;
}

.page-id-1562 #primary.site-main.legal .mod {
  display: none;
}

/* -------------------------------------------------- */
/*	SWIPER SLIDER
/* -------------------------------------------------- */
.swiper-pagination-bullet {
  width: 12px;
  height: 12px;
}
.sfondo-blu {
  .swiper-pagination-bullet {
    opacity: 1;
    background: #5b6a7a;
  }
  .swiper-pagination-bullet-active {
    background: $sfondo_cta;
  }
}
.sfondo-grigio {
  .swiper-pagination-bullet {
    opacity: 1;
    background: #fff;
  }
  .swiper-pagination-bullet-active {
    background: $txt_blu;
  }
}
