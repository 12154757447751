/*
    Created on : 31-ago-2020, 10.04.40
    Author     : veronica
*/

html {
  font-size: 100%;
  scroll-behavior: smooth;
}

body {
  font-family: $font_base;
  font-weight: 450;
  background-color: $sfondo_base;
  color: $color_testo;
  font-size: $size_base;
  line-height: 1.3;
  text-align: inherit;

  @include a_style($color_link, $color_link_over);
  @include a_style_link($txt_blu, $txt_blu);
  @include a_style_link_prev($txt_blu, $txt_blu);
  a.link.toggle-down {
    &:after {
      content: "\f063";
    }
  }
  a.link.toggle-down.active {
    &:after {
      content: "\f062";
    }
  }
  a.link.play {
    &:after {
      content: "\f144";
    }
  }

  a.pulsante, .wp-block-button__link, .wp-block-file__button, .wp-block-file .wp-block-file__button, submit, input[type="submit"] {
    @include button_style($txt_blu, transparent, #fff, $txt_blu, $txt_blu, $txt_blu);
  }

  a.pulsante.green {
    @include button_style($color_verde_scuro, transparent, #fff, $color_verde_scuro, $color_verde_scuro, $color_verde_scuro);
  }

  a.pulsante.viola {
    @include button_style($color_viola_ms, transparent, #fff, $color_viola_ms, $color_viola_ms, $color_viola_ms);
  }

  a.pulsante.viola-negativo {
    @include button_style(transparent, $color_viola_ms, $color_viola_ms, #fff, $color_viola_ms, $color_viola_ms);
  }

  .gruppo-pulsanti {
    a.pulsante, .wp-block-button__link, submit, input[type="submit"] {
      @include button_style(transparent, $txt_blu, $txt_blu, #fff, $txt_blu, $txt_blu);
    }
  }
  .audio_wrapper {
    @include button_style($txt_blu, transparent, #fff, $txt_blu, $txt_blu, $txt_blu);
    padding: 19px 10px;
    div.essential_audio > div:nth-child(1) div, div.essential_audio > div:nth-child(1) div.play {
      background-color: $txt_blu !important;
      border: 1px solid #fff;
    }
    div.essential_audio > div:nth-child(2) div {
      background-color: $sfondo_celeste;
    }
    div.essential_audio > div:nth-child(2):after {
      background-color: #ededed;
    }
    &:hover {
      div.essential_audio > div:nth-child(1) div, div.essential_audio > div:nth-child(1) div.play {
        border: 1px solid $txt_blu;
      }
      div.essential_audio > div:nth-child(2) div {
        background-color: #666;
      }
      div.essential_audio > div:nth-child(2):after {
        background-color: #000;
      }

    }
  }
}

body.noscroll {
  overflow: hidden;
}

p, .testo, .contenuto {
  line-height: 1.3;

  blockquote {
    font-style: italic;

    p {
      margin-bottom: 7px;
    }

    cite {
      font-size: 85%;
    }
  }
}

h1, h2, h3, h4, h5, .titolo, .titolo1, .titolo2, .titolo3, .titolo4, .titolo5 {
  font-family: $font_titoli;
  letter-spacing: -1px;
  padding: 0 0 10px 0;
  margin: 0;
  outline: none;
  color: $txt_blu;
  @include a_style($txt_blu, $color_link_over);

  a {
    &:hover {
      text-decoration: none;
    }
  }
}

h3, h4, h5, .titolo3, .titolo4, .titolo5 {
  letter-spacing: -0.5px;
}

.enfasi {
  font-family: $font_titoli_book;
  color: $txt_blu;
  font-size: 120% !important;
}

.subhead {
  font-family: $font_titoli_book;
  letter-spacing: -1px;
  font-size: 32px;
  line-height: 42px;
  color: $txt_blu;
}

@include titolize($size_h1, $size_h2, $size_h3, $size_h4, $size_h5, $size_h6);
@include spaziatorize($spaziatore);
@include curva_style(300px);

.titolo2.big {
  font-size: 55px;
  line-height: 56px;
}

.azzera {
  margin: 0 !important;
  padding: 0 !important;
}

.upper {
  text-transform: uppercase;
}

.maiuscoletto {
  text-transform: lowercase;
  font-variant: small-caps;
}

.w100 {
  width: 100% !important;
}

.h100 {
  height: 100%;
}

.no-padding {
  padding: 0;
}

.img-responsive {
  display: inline-block;
}

.relative {
  position: relative;
}

.duecol {
  columns: 2;
}

.lista_due_colonne {
  columns: 2;
  list-style: none;
  margin: 40px 0;
  padding: 0;

  li {
    font-weight: bold;
    font-size: 15px;
    letter-spacing: 1px;
    padding: 5px 0;
  }
}

/* DISTANZIO DI 20PX */

.spazio {
  padding-top: 20px;
  padding-bottom: 20px;
}

.spazio-t {
  padding-top: 20px;
}

.spazio-b {
  padding-bottom: 20px;
}

.spazio-r {
  padding-right: 20px;
}

.spazio-l {
  padding-left: 20px;
}

.bianco {
  color: #fff;
}

.blu {
  color: $txt_blu;
}

.celeste {
  color: $sfondo_cta !important;
}

.giallo {
  color: $color_giallo;
}

.rosso {
  color: $color_rosso !important;
  @include a_style($color_rosso, $color_blu);
}

.nero {
  color: #000;
}

.sfondo-grigio {
  background-color: $sfondo_grigio;
  color: $color_testo;
}

.sfondo-bianco {
  @include sfondo-bianco();
}

.sfondo-celeste {
  @include sfondo-celeste();
}

.sfondo-cta {
  @include sfondo-cta();
}

.sfondo-blu {
  background-color: $color_blu;
  color: $color_testo_neg;

  h1, h2, h3, h4, h5, h6, .titolo, .subhead, .titolo1, .titolo2, .titolo3, .titolo4 {
    color: #fff;
  }

  @include a_style($color_link, #ffffff);
  @include a_style_link(#ffffff, #ffffff);

  a.pulsante, .wp-block-button__link, submit, input[type="submit"] {
    @include button_style(transparent, #fff, #fff, $txt_blu, #fff, #fff);
  }
}

.sfondo-verde {
  @include sfondo-cta();
  background-color: $color_verde;
}

.sfondo-giallo {
  @include sfondo-cta();
  background-color: $color_giallo;
}

.sfondo-nero {
  @include sfondo-nero();
}

.sfondo-viola {
  @include sfondo-viola();
}

.page {
  margin: 0;
}

.audio_component {
  position: relative;
  height: 45px;
  .link-audio-player {
    position: absolute;
    top: 0;
    height: 45px;
    display: flex;
    align-items: center;
  }
  .audio_wrapper {
    visibility: hidden;
    position: absolute;
    top:0;
  }
}

.page-load-status {
  display: none; /* hidden by default */
  padding-top: 20px;
  text-align: center;
  color: #777;
}

#centrami {
  width: 304px;
  margin: 0 auto;
  padding-bottom: 30px;
}


.riduco {
  max-width: 1541px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.arrotonda {
  border-radius: 25px;
}

.hidden {
  visibility: hidden;
}

.check-ok {
  color: $color_verde;
}

.check-ko {
  color: $color_ko;
}

.audio_wrapper {

}

.modal {
  z-index: 3000;
}

.modal-content {
  border: 0;
  border-radius: 25px;
}

.modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}

.modal-footer {
  border-top: 0;
}

#myModalPeople {
  .modal-header .close {
    color: #fff;
    text-shadow: none;
    @include a_style(#fff, #fff);

    &:hover {
      color: #fff;
    }
  }
}

#myModalPopup {
  .modal-header {
    .close {
      text-shadow: none;
      color: #fff;
      opacity: 1;
      margin: -1rem -2rem -0.5rem auto;
      outline: none;
    }
  }

  .modal-content {
    background: transparent;
    border-radius: 0;
  }

  .modal-body {
    padding: 0;

    .inner {
      padding: 40px;
    }
  }
}


.form_required {
  border: 2px solid $color_rosso !important;
  //background-color: rgba(255,255,0,1) !important;
}

.wrapper-activate {
  iframe {
    width: 100%;
    //min-height: 820px;
    border: 0;

  }
}

/* -------------------------------------------------- */
/*	 OVERRIDE MENU
/* -------------------------------------------------- */

body #page.site.submenu {
  margin-top: 0 !important;

  #masthead.site-header .menu__header {
    position: relative;
  }
}

/* -------------------------------------------------- */
/*	BLOCCHI COMUNI
/* -------------------------------------------------- */

#primary.site-main {

  #myModalInt {
    .modal-header, .modal-footer, .modal-body {
      position: relative;
    }

    .cerchiotop {
      background-color: #fff;
      border-radius: 25px; //50vh;
    }

    .barra-dx {
      .pulsante {
        width: 234px;
      }

      .approfondimento ul {
        list-style: none;
        margin: 0;
        padding: 0;
      }
    }

    .modal-footer .barra-dx {
      top: -4px;
      right: 21px;
    }

    .apps .griglia .item {
      max-width: none;
      flex-grow: 0;
      flex-basis: 100%;

      .cerchio {
        width: 100%;
        max-width: 100%;
        justify-content: center;
        cursor: auto;
      }
    }
  }

  @media (max-width: 992px) {
    #myModalInt .modal-lg, #myModalInt .modal-xl {
      max-width: 90vw !important;

      .apps .griglia .item {

        flex-grow: 0;
        flex-basis: 33%;

        .cerchio {
          width: 100%;
          max-width: 97%;
        }
      }
    }
  }
  @media (max-width: 576px) {
    #myModalInt .modal-dialog {
      max-width: 90vw;
    }
  }


  .form_newsletter_inline-form {
    .nlinput {
      max-width: 300px;
    }
  }

  .intro.solotxt {
    background-image: url('../img/top-solotxt.svg');
    background-size: cover;
    background-position: center top;
  }

  .intro.solotxt.txtleft {
    background-position: center right;
  }


  .img-sinistra-bottom {
    position: absolute;
    left: 5%;
    bottom: 5%;
    width: auto;
    z-index: 100;
  }

  .img-destra-top {
    position: absolute;
    right: 5%;
    top: 5%;
    width: auto;
    z-index: 100;
  }

  .firstcontent {
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .fancybox-video {
    background: transparent;
    border: 0;
    display: block;
    margin: 0;
    overflow: hidden;
    padding: 0;
    width: 100%;
    height: calc(100% * 9 / 16);
  }

  .fancybox-slide--video .fancybox-content {
    background: transparent;
  }

  #jolly {
    position: relative;

    .testo {
      padding: 50px 0;
    }

    .immagine {
      max-width: 674px;
      position: absolute;
      bottom: 0;
      width: 40%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

  }

  #jolly.imgdx {
    .immagine {
      right: 10%;
    }

    .forma {
      border-radius: 180px 0 180px 180px;
    }
  }

  #jolly.imgsx {
    .immagine {
      left: 10%;
    }

    .forma {
      border-radius: 0 180px 180px 180px;
    }
  }

  #block-intro {
    position: relative;
    background-image: url('../img/fascia-integration.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .imgxl {
      position: absolute;
      top: 12%;
      left: 52%;
      max-width: 647px;
      height: auto;
      padding-right: 10px;
    }

    .testo {
      padding: 30px 0;
    }

    .img {
      max-width: 647px;
      height: auto;
    }
  }

  #block-suite {
    //@include a_style($txt_blu, $txt_blu);

    .item {
      border-right: 1px solid #fff;

      &:first-of-type {
        border-left: 1px solid #fff;
      }

      .inner {
        padding: 40px;

        .content {
          min-height: 440px;
          width: 100%;
          position: relative;

          .logo {
            position: absolute;
            width: 100%;
            right: 0;
            bottom: 0;

            img {
              max-width: 150px;
              height: auto;
            }
          }
        }

        .puntatore {
          display: none;
        }

      }
    }
  }

  .double-cta {
    .item {
      border-right: 2px solid $sfondo_grigio;

      &:first-of-type {
        border-left: 2px solid $sfondo_grigio;
      }

      .inner {
        padding: 40px;

        .content {
          width: 100%;
          position: relative;

          .sottotitolo {
            padding-bottom: 40px;
          }

          .logo {
            padding-bottom: 40px;

            img {
              max-width: 150px;
              height: auto;
            }
          }

        }
      }
    }
  }

  #block-suite-new {
    .item {
      border-right: 1px solid #fff;

      &:first-of-type {
        border-left: 1px solid #fff;
      }

      .inner {
        .content {
          min-height: 440px;
        }
      }
    }
  }

  #block-clienti {
    .owl-carousel {
      padding-top: 50px;
    }

    .img-cli {
      /*            width: 116px;
                  height: auto;
                  margin: 0 auto;*/
    }
  }

  #block-contatori {
    .wrapper {
      align-items: center;
      justify-content: center;
      display: flex;

      .item {
        background-color: rgba(204, 212, 226, 0.6);
        text-align: center;
        padding: 50px 100px;
        border-radius: 100px;
        margin-left: -30px;

        .titolo {
          font-size: $size_h1;
          font-weight: bold;
        }

        .sottotitolo {
          font-size: 166%;
        }

        &:first-of-type {
          border-radius: 0 100px 100px 0;
          flex-grow: 1;
          padding-left: 0;
          padding-right: 50px;
          margin-left: 0;

          .titolo, .sottotitolo {
            color: transparent;
          }
        }

        &:last-of-type {
          border-radius: 100px 0 0 100px;
          flex-grow: 1;
          padding-right: 0;
          padding-left: 50px;

          .titolo, .sottotitolo {
            color: transparent;
          }
        }
      }

      .item.pieno {
        width: auto;
        max-width: 328px;
      }
    }
  }

  .calltoaction {
    @include sfondo-cta();
    position: relative;
    background-color: $sfondo_cta;

    .cerchio {
      background-image: url('../img/pattern-2.png');
      border-radius: 50%;
      width: 170px;
      height: 170px;
      position: absolute;
      left: 40%;
      top: 20px;
      opacity: 0.3;
    }

    .immagine {
      position: absolute;
      left: 0;
      top: 0;
      width: 45%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 0 400px 0 0;

      .velina {
        background-color: $sfondo_header;
        opacity: 0.7;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 0 400px 0 0;
      }

      .plus {
        position: absolute;
        left: 5%;
        bottom: 0;
        width: 50%;
        height: auto;
      }
    }

    @include input_style(#fff, #fff, $color_testo, $color_testo, $sfondo_header, $sfondo_header);

    .titolo2 {
      color: #fff;
    }

    .titolo5 {
      padding-bottom: 50px;
    }
  }


  .help-block {
    .mySwiperThumbs {
      //overflow: visible;

      .swiper-slide {
        //width: max-content !important;
        cursor: pointer;
        margin-bottom: 30px;

        .titolotab {
          display: inline-block;
          padding-bottom: 20px;
          display: inline-block;
          //width: max-content !important;
          position: relative;
          border-bottom: 4px solid transparent;
          transition-property: border-bottom-color;
          transition-duration: 1s;
        }
      }

      .swiper-slide.swiper-slide-thumb-active .titolotab {
        font-weight: bold;
      }
    }

    .lista-blocchi-help {
      .item {
        -webkit-box-shadow: 0px 20px 42px -45px rgba(0, 34, 90, 0.77);
        -moz-box-shadow: 0px 20px 42px -45px rgba(0, 34, 90, 0.77);
        box-shadow: 0px 20px 42px -45px rgba(0, 34, 90, 0.77);
        @include sfondo-bianco;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 25px;
        padding: 50px;
        margin-bottom: 10px;
        margin-top: 8px;

        .testoinner {
          padding-bottom: 1rem;
        }
      }
    }
  }

  .help-block.sfondo-blu {
    .mySwiperThumbs {
      .swiper-slide .titolotab {
        color: #fff;
      }

      .swiper-slide.swiper-slide-thumb-active .titolotab {
        border-bottom-color: #fff;
      }
    }
  }

  .help-block.sfondo-grigio {
    .mySwiperThumbs {
      .swiper-slide .titolotab {
        color: $txt_blu;
      }

      .swiper-slide.swiper-slide-thumb-active .titolotab {
        border-bottom-color: $txt_blu;
      }
    }
  }

  .help-block.sfondo-cta {
    .titolo1 {
      color: $txt_blu;
    }

    .mySwiperThumbs {
      .swiper-slide .titolotab {
        color: $txt_blu;
      }

      .swiper-slide.swiper-slide-thumb-active .titolotab {
        border-bottom-color: $txt_blu;
      }
    }
  }

  @media (min-width: 992px) {
    .help-block .mySwiperThumbs .swiper-slide {
      width: max-content !important;
    }
  }

  .lista-blocchi {
    background-color: $sfondo_grigio;

    .item {
      background: #fff;
      border-radius: 25px;
      padding: 40px;
      margin-bottom: 10px;
      margin-top: 8px;
      transition: transform 0.5s;

      img {
        width: auto;
        height: 74px;
        margin-bottom: 20px;
      }

      .testoinner {
        padding-bottom: 1rem;
      }

      &:hover {
        -webkit-transform: translateY(-8px);
        -ms-transform: translateY(-8px);
        transform: translateY(-8px);

        -webkit-box-shadow: 0px 20px 85px -45px rgba(0, 34, 90, 0.77);
        -moz-box-shadow: 0px 20px 85px -45px rgba(0, 34, 90, 0.77);
        box-shadow: 0px 20px 85px -45px rgba(0, 34, 90, 0.77);
        transition: all .2s ease-in-out;
      }
    }

    .item.sfondo-blu {
      background-color: $sfondo_blu;
    }

    .item.sfondo-cta {
      background-color: $sfondo_cta;
    }

    .item.sfondo-grigio {
      background-color: $sfondo_grigio;
    }

    .item.sfondo-nero {
      background-color: $color_nero;
    }

    .item.sfondo-viola {
      background-color: $color_viola_ms;
    }


    .owl-carousel .owl-stage {
      padding-left: 0 !important;

      .owl-item.active .item {
        -webkit-box-shadow: 11px 15px 15px -15px rgba(0, 34, 90, 0.77);
        -moz-box-shadow: 11px 15px 15px -15px rgba(0, 34, 90, 0.77);
        box-shadow: 11px 15px 15px -15px rgba(0, 34, 90, 0.77);
        transition: all .2s ease-in-out;
      }
    }


  }

  .lista-blocchi.col2 {
    .item img {
      height: 172px;
      margin-bottom: 40px;
    }
  }

  #blocco-silos {
    background-color: $sfondo_grigio;

    .item {
      h3 {
        padding: 20px 0;
        border-bottom: 1px solid $color_grigio_linea;
      }

      h4 {
        padding: 20px 0;
        border-bottom: 1px solid $color_grigio_linea;
      }

      .testoinner {
        padding: 20px 0;
        font-size: 22px;
        color: $txt_blu;
      }

      .points {
        margin: 0 -40px -40px -40px;
        border-top: 4px solid $sfondo_grigio;
        padding: 40px;

        ul {
          //list-style: none;
          /*                    margin: 0;
                              padding: 0;*/
          li {
            font-size: 22px;
            padding: 10px 0;
            color: $txt_blu;
            border-bottom: 1px solid $color_grigio_linea;

            &:last-of-type {
              border-bottom: 0;
            }
          }
        }
      }
    }

    .item.bestseller {
      -webkit-box-shadow: 0px 20px 85px -45px rgba(0, 34, 90, 0.77);
      -moz-box-shadow: 0px 20px 85px -45px rgba(0, 34, 90, 0.77);
      box-shadow: 0px 20px 85px -45px rgba(0, 34, 90, 0.77);
      transition: all .2s ease-in-out;
    }

    .item.bestseller {
      position: relative;
      padding-top: 140px;
      padding-bottom: 140px;

      .best {
        color: #fff;
        position: absolute;
        top: 0;
        right: 0;
        width: 40%;
        min-width: 130px;
        min-height: 130px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        border-top-right-radius: 25px;
        border-bottom-left-radius: 25px;
        text-align: center;

      }
    }
  }

  #blocco-silos.call {
    background-color: $sfondo_grigio;
    background-image: url('../img/help/4-elementi-help-center-chiamaci.svg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    .item {
      padding: 40px 20px;

      img {
        width: 100%;
        height: auto;
      }

      .days {
        padding: 20px 0;
        border-bottom: 1px solid $color_grigio_linea;
      }

      .schedule {
        padding: 20px 0;
        border-bottom: 1px solid $color_grigio_linea;
      }

      .points {
        margin: 0 -20px -20px;
        padding: 20px;
      }

      .points .el {
        padding: 10px 0;

      }
    }
  }

  #assistenza {
    background-image: url('../img/prodotto/8-imagiclecare.svg');
    background-size: cover;
    background-position: center;

    .blocco {
      border-bottom: 1px solid $color_grigio_bordo;

      &:last-of-type {
        border-bottom: 0;
      }
    }
  }

  .prod-cta {
    @include sfondo-cta();

    .item {
      border-left: 1px solid #fff;
      margin-top: 50px;
      margin-bottom: 50px;

      &:last-of-type {
        border-right: 1px solid #fff;
      }
    }
  }

  .top-header.titolosx {
    background-position: bottom right;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: flex-end;
  }

  .top-header.solotxt {
    background-image: url('../img/top-solotxt.svg');
    background-size: cover;
    background-position: center top;
  }

  .top-header.solotxt.txtleft {
    background-position: center right;
  }

  .top-header.bigimg {
    position: relative;
    background-color: $sfondo_grigio;

    h1 {
      color: #fff;
    }

    .ombra {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 17, 57, .2);
      border-bottom-left-radius: 10vh;
      border-bottom-right-radius: 10vh;
    }

    .immagine {
      position: relative;

      img {
        border-bottom-left-radius: 10vh;
        border-bottom-right-radius: 10vh;
      }
    }

    .contenuto {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
    }
  }


  .intro-titolosx {
    background-color: $sfondo_cta;
    background-image: url('../img/help/0-elementi-help-center-banner-HC.svg');
    background-position: bottom right;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 80px;

    .contenuto {
      padding-top: 20px;
      padding-bottom: 170px;
    }
  }

  .intro-titolo-center {
    position: relative;
    background-color: $sfondo_grigio;

    h1 {
      color: #fff;
    }

    .ombra {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 17, 57, .2);
      border-bottom-left-radius: 10vh;
      border-bottom-right-radius: 10vh;
    }

    .immagine {
      position: relative;

      img {
        border-bottom-left-radius: 10vh;
        border-bottom-right-radius: 10vh;
      }
    }

    .contenuto {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
    }
  }

  #blog {
    background-image: url('../img/prodotto/11-dal-blog.svg');
    background-size: cover;
    background-position: center;

    .pattern-news {
      padding-top: 60px;
      padding-bottom: 50px;

      .griglia.classico {
        height: 310px;
      }
    }
  }

  #block-languages {
    background-color: $sfondo_grigio;
    background-image: url('../img/sette-lingue.svg');
    background-size: contain;
    background-position: bottom right;
    background-repeat: no-repeat;

    .container-fluid {
      max-width: 2300px !important;
    }

    .lista {
      display: flex;
      flex-wrap: wrap;

      .el {
        flex-basis: 176px;

        img {
          height: 176px;
          width: 176px;
        }
      }
    }
  }

  .roadmap {
    position: relative;

    .grafica {
      text-align: right;
    }

    .contenuto {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }

  #review {
    .sfondo {
      background-color: $sfondo_blu;
      height: 100%;
      width: 66%;
      position: absolute;
      right: 0;
      top: 0;
      border-top-left-radius: 50vh;
      border-bottom-left-radius: 50vh;
    }

    .sfondo-rtl {
      background-color: $sfondo_blu;
      height: 100%;
      width: 66%;
      position: absolute;
      left: 0;
      top: 0;
      border-top-right-radius: 50vh;
      border-bottom-right-radius: 50vh;
    }

    .top-review {
      background-image: url('../img/customers/5-elementi-customers-sempre.svg');
      background-size: contain;
      background-position: bottom left;
      background-repeat: no-repeat;

      .img {
        max-height: 400px;
      }
    }

    .lista-review {
      position: relative;
      color: #fff;

      .container {
        z-index: 100;
        position: relative;
      }

      .citazione {
        font-family: $font_titoli;
        font-size: 32px;
      }

      .name {
        font-size: 18;
        font-weight: bold;
        padding-top: 30px;
      }

      .role {
        font-size: 18;
      }
    }
  }

  .people-img {
    max-width: 282px;
  }

  .lista-people {
    .inner {
      padding-top: 20px;

      .name {
        font-weight: 600;
        font-size: 24px;
        letter-spacing: -1px;
        padding-bottom: 7px;
      }

      .role {
        font-size: 18px;
        padding-bottom: 10px;
      }

      .area {
        font-size: 22px;
      }
    }
  }

  .people-item, .owl-carousel .owl-item .people-item {
    text-align: center;

    .people-img {
      max-width: 282px;
      height: auto;
      display: inline-block;
    }
  }

  .block-cta-round {
    background-color: #fff;
    background-image: url('../img/help/3-elementi-help-center-ticket.svg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    .immagine {
      padding-top: 20px;
      position: relative;

      .img-grafico {
        background-color: $sfondo_cta;
        width: 90%;
        height: 100%;
        border-radius: 0 100vh 100vh 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }

      img {
        z-index: 100;
        max-height: 470px;
        position: relative;
        width: auto;
      }
    }
  }

  .block-cta-round.dx {
    .immagine {
      .img-grafico {
        border-radius: 100vh 0 0 100vh;
        left: auto;
        right: 0;
      }
    }
  }

  #myModalPeople {
    .modal-content {
      @include sfondo-blu();
      background-color: #003F85;
      color: #fff;
      border-radius: 0;

      .modal-header {
        border-bottom: 0;
      }

      .modal-footer {
        border-top: 0;
      }
    }
  }

  .modal-backdrop {
    background-color: $txt_blu;
  }


  #storie {
    background-image: url('../img/prodotto/6-storie-di-aperto.svg');
    background-size: cover;
    background-position: center;
    background-color: $sfondo_grigio;

    .lista {
      .item {
        background-color: #fff;
        cursor: pointer;

        img {
          border-bottom-left-radius: 200px;
          width: 90%;
          float: right;
          border-top-right-radius: 25px;
        }

        h3, h4 {
          padding: 12px 21px 10px 10%;
        }

      }
    }
  }

  #storie .approfondimento, #myModalStorie {
    padding-top: 40px;
    display: none;

    .item {
      padding: 50px;
      background-color: #fff;

      .sinistra {
        .introimg {
          display: none;
          border-bottom-left-radius: 200px;
          width: 90%;
          float: right;
          border-top-right-radius: 25px;
          width: 45%;
        }

        height: 100%;
        color: $txt_blu;
        @include a_style($txt_blu, $txt_blu);
        border-right: 1px solid $color_grigio_bordo;
        font-size: 22px;
        font-weight: 400;

        ul {
          margin: 0;
          padding: 0;
          list-style: none;

          li {
            padding-bottom: 0;
            line-height: 1;
          }
        }

        .logo {
          max-width: 125px;
          height: auto;
          padding-bottom: 20px;
        }

        .settore, .website, .piattaforma {
          font-size: 18px;

        }

      }

      .contenuto {
        height: 100%;

        .testo {
          font-size: 18px;
          padding-left: 20px;
        }

        .imgbottom {
          display: none;
          padding-top: 20px;
        }
      }
    }
  }


  .apps {
    .griglia {
      display: flex;
      flex-wrap: wrap;

      .item {
        display: flex;
        flex-grow: 1;
        flex-basis: 212px;
        margin-bottom: 18px;
        max-width: 234px;
        justify-content: center;

        .cerchio {
          cursor: pointer;
          display: flex;
          align-items: center;
          width: 212px;
          height: 80px;
          max-width: 212px;
          max-height: 80px;
          border-radius: 25px;
          background-color: #fff;
          padding: 10px;
          border: 1px solid $sfondo_grigio;
          transform: translateY(0);
          transition: transform 0.5s, box-shadow .2s ease-in-out;
          //transition: all .2s ease-in-out;
          .icona {
            width: 60px;
            height: 60px;
            margin-right: 20px;
          }

          .nomeapp {
            font-size: 18px;
            font-weight: 300;
            padding: 0;
            margin: 0;
          }

          &:hover {
            -webkit-transform: translateY(-5px);
            -ms-transform: translateY(-5px);
            transform: translateY(-5px);
            -webkit-box-shadow: 0 15px 35px -15px rgba(0, 34, 90, 0.77);
            -moz-box-shadow: 0 15px 35px -15px rgba(0, 34, 90, 0.77);
            box-shadow: 0 15px 35px -15px rgba(0, 34, 90, 0.77);
            //transition: all .2s ease-in-out;
          }
        }

        .cerchio.dis {
          opacity: 0.65;
        }

      }
    }
  }

  #points.rowpoints {
    background-image: url('../img/elementi-points-3500.png');
    background-size: contain;
    background-position: top;
    background-repeat: repeat-y;

    .container {
      border-bottom: 1px solid $color_grigio_linea;

      .immagine {
        max-width: 316px;
        height: auto;
      }

      &:last-of-type {
        border-bottom: none;
      }
    }

    .container.no-border {
      border-bottom: 0;
    }

  }

  .integrazioni {
    background-color: $sfondo_grigio_scuro;
    background-image: url('../img/prodotto/4-integrata-con.svg');
    background-size: cover;
    background-position: center;

    .griglia {
      display: grid;
      grid-gap: 45px;
      grid-template-columns: repeat(5, 1fr);

      .item {
        justify-self: center;
        display: flex;

        .cerchio {
          //display: flex;
          align-items: center;
          width: 176px;
          height: 176px;
          max-width: 176px;
          max-height: 176px;
          border-radius: 25px; //50%;
          background-color: #fff;

          img.open-integration, img.open-integration-modal {
            cursor: pointer;
          }

          &:hover {
            -webkit-box-shadow: 0px 20px 85px -45px rgba(0, 34, 90, 0.77);
            -moz-box-shadow: 0px 20px 85px -45px rgba(0, 34, 90, 0.77);
            box-shadow: 0px 20px 85px -45px rgba(0, 34, 90, 0.77);
            transition: all .2s ease-in-out;
          }

          .discover {
            text-align: center;
            font-size: 14px;
            font-weight: bold;
            margin-top: -27px;
            display: block;
          }
        }

        .cerchio.dis {
          background-color: #E9EDF3;
        }

      }
    }
  }

  #block-cloud {
    background-color: #fff;

    .immagine {
      position: relative;

      .img-grafico {
        background-color: $sfondo_cta;
        width: 90%;
        height: 100%;
        border-radius: 0 100vh 100vh 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }

      img {
        z-index: 100;
        width: 630px;
        max-width: 80%;
        position: relative;
        padding: 100px 0;
      }
    }

    .inner .content {
      max-width: 580px;
    }
  }

  #blocco-ricerca-kb {
    background-color: $sfondo_grigio;
    background-image: url('../img/help/2-elementi-help-center-cosa-stai.svg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    .search-field {
      background: #fff;
    }
  }
}


/***** Use Case *******/


#shr_canvas2 {
  display: none;
}

#usecase {
  background: #e9edf3;
}

.item.arrotonda ul {
  padding: 0;
  margin-bottom: 10px;
}

.nascondiblock_usecase {
  display: none !important;
}

.nascondiblock_usecaseSol {
  display: none;
}

.nascondiblock_usecaseSearch {
  display: none;
}

.arrotonda.usecase {
  min-height: auto !important;
  height: fit-content;

  h4 {
    padding-bottom: 0px !important;
  }

  img {
    max-width: 120px;
    margin-bottom: 20px;
  }

  li {
    color: #5c6b7a !important;
  }
}


.arrotonda.usecase ul, .cpt_usecase-template-default .content-landing .blocco-s ul {
  margin-left: 0 !important;
  padding-left: 20px !important;
  margin-bottom: 15px;
}


.arrotonda.usecase li::marker, #tab-usecase .arrotonda li::marker, .cpt_usecase-template-default .content-landing .blocco-s ul li::marker {
  color: #5c6b7a !important;
}

.content_box_use_case .pattern-news {
  padding-bottom: 0px !important;
}

.content_box_use_case .pattern-news .griglia {
  height: auto !important;
  padding-bottom: 80px !important;
}

#tab-usecase .arrotonda {
  padding: 28px !important;
}

@media (max-width: 767px) {
  .arrotonda.usecase {
    padding: 20px;
  }

  .usecaseform .col-12 {
    margin-bottom: 5px;
  }
}

/** AREE COMUNI **/

#primary.site-main {

  #faq {
    background-color: $sfondo_grigio;
    background-image: url('../img/prodotto/14-faq.svg');
    background-size: 100%;
    background-position: top;
    background-repeat: no-repeat;

    .lista {
      .item {
        background: #fff;
        padding: 20px 30px;
        margin-bottom: 10px;
        border-radius: 35px;

        .domanda {
          padding-bottom: 0;
          font-weight: bold;
          color: $txt_blu;
          display: grid;
          grid-template-columns: 90% 10%;
          align-items: center;

          &:after {
            display: flex;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            font-family: "Font Awesome 5 Pro";
            font-weight: 300;
            align-self: flex-start;
            justify-content: end;
          }
        }

        .domanda[aria-expanded='false']::after {
          content: "\f067";
        }

        .domanda[aria-expanded='true']::after {
          content: "\f068";
        }

        .risposta {
          padding-top: 15px;
        }
      }
    }
  }


  .approfondimento-inc {
    background-image: url("../img/packages/02-sfondo@2x.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    .item {
      padding: 40px;
    }
    .lista .lista-item {
      border-right: 3px solid $sfondo_grigio_scuro;
      padding-left: 40px;
      padding-right: 40px;
      &:first-child {
        padding-left: 20px;
      }
      &:last-child {
        border-right: 0;
        padding-right: 20px;
      }
      img {
        height: 74px;
        margin-bottom: 20px;
        width: auto;
      }

    }
  }
}
