/*
    Created on : 25-mar-2021, 9.47.15
    Author     : veronica
*/

/** OVERRIDE MENU **/
body.single-cpt_prodotti #page.site,
body.page-template-page-offerte #page.site,
body.page-template-page-offerte-child #page.site,
#page.site:has(main#primary.no-top) {
    margin-top: 0!important;
    #masthead.site-header .menu__header {
        position: relative;
    }
}

/* -------------------------------------------------- */
/*	PAGE PRODOTTO
/* -------------------------------------------------- */

#primary.site-main.prodotto {

    #menu-prod {
        transition: all .35s ease-in-out;
        background: transparent;
        padding: 10px;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        .icona {
            width: 52px;
            height: 52px;
        }
        .goToOverview {
            display: inline-flex;
            margin-bottom: 0;
            padding-bottom: 0;
        }
        .logoprodhide, .menumobileprod, #prodList {
            opacity: 0;
        }
        .list-group {
            border: 0;
            .list-group-item {
                border: 0;
                background: none;
                color:$txt_blu;
                font-size: 18px;
                font-weight: 400;
                padding-right: 20px;
                white-space: nowrap;
                &:hover {
                    color: $txt_blu;
                    font-weight: bold;
                    text-decoration: none;
                }
            }
            .list-group-item.active {
                //border: 0;
                background: none;
                font-weight: bold;
            }
            a.pulsante {
                white-space: nowrap;
                padding: 6px 15px;
                font-size: 16px;
            }
        }

    }
    #menu-prod.compresso {
        .goToOverview {
            opacity: 0;
        }
    }
    #menu-prod.mostra {
        background: #fff;
        #prodList, .goToOverview {
            opacity: 1;
        }
    }

    .tab-content {
        margin-top: -83px;
    }

    #intro {
        position: relative;
        /*        background-image: url('../img/prodotto/prod_bkg_lg.svg');
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center top;*/
        .el-intro {
            padding-top: 190px;
            padding-bottom: 160px;
        }
        .el-grafico img {
            /*            -webkit-box-shadow: 0px 20px 85px -45px rgba(0,34,90,0.77);
                        -moz-box-shadow: 0px 20px 85px -45px rgba(0,34,90,0.77);
                        box-shadow: 0px 20px 85px -45px rgba(0,34,90,0.77);
                        transition: all .2s ease-in-out;*/
        }
        .logo-grafico {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center top;
        }
        .subhead {
            color: $txt_blu;
            padding-bottom: 50px;
        }
        .embed-video {
            border: 30px solid $txt_blu;
            border-radius: 30px;
        }

        .play-video {
            cursor: pointer;
            position: absolute;
            width: 100%;
            left: 0;
            top: 45%;
            text-align: center;
            font-size: 90px;
            @include a_style(#004182, #ef5a26);
            .play-video-tag {
                color: #004182;
                cursor: pointer;
                &:hover {
                    color: #ef5a26;
                }
            }
        }
    }
    #punti {
        background-image: url('../img/prodotto/3-la-migliore-acc.svg');
        background-size: cover;
        background-position: center;
    }
    #integrata {
        background-color: $sfondo_grigio_scuro;
        background-image: url('../img/prodotto/4-integrata-con.svg');
        background-size: cover;
        background-position: center;
        .griglia {
            display: grid;
            grid-gap: 45px;
            grid-template-columns: repeat(5,1fr);
            .item {
                justify-self: center;
                display: flex;
                .cerchio {
                    display: flex;
                    align-items: center;
                    width: 176px;
                    height: 176px;
                    max-width: 176px;
                    max-height: 176px;
                    border-radius: 50%;
                    background-color: #fff;
                }
                .cerchio.dis {
                    background-color: #E9EDF3;
                }

            }
        }
    }

    #dettagli {
        background-image: url('../img/prodotto/5-dettagli-che-fanno.svg');
        background-size: cover;
        background-position: center;
        background-color: $color_blu;
        .main-lite {
            .row {
                margin-bottom: 50px;
                position: relative;
            }
            .contenuto {
                min-height: 600px;
                background: #fff;
                border-radius: 0 30px 30px 0;
                padding: 50px 40px;
                display: flex;
                align-items: center;
                h2, h3 {
                    color: $txt_blu;
                }
                .testo {
                    padding-top: 10px;
                    font-size: 26px;
                }
                .scopri {
                    padding-top: 30px;
                    .scopri-link {
                        color: $txt_blu;
                        cursor: pointer;
                        font-weight: 800;
                    }
                }
            }
            .contenuto.revert {
                border-radius: 30px 0 0 30px;
            }
            .grafica {
                background-position: center;
                background-size: cover;

                min-height: 600px;
                border-radius: 30px 0 0 30px;

                .video-wrapper {
                    position: relative;
                    height: 100%;
                    width: 100%;
                    overflow: hidden;
                    opacity: 1;
                    transition: opacity .2s;
                    video {
                        position: absolute;
                        top: 0;
                        height: 100%;
                        width: 100%;
                        border-radius: 30px 0 0 30px;
                    }
                    .playback-control-container {
                        position: absolute;
                        left: 0;
                        right: 0;
                        margin: auto;
                        width: 100%;
                        text-align: center;
                        z-index: 3;
                        bottom: 50px;

                        .playback-control {
                            opacity: 0;
                            -webkit-transition: opacity .3s;
                            transition: opacity .3s
                        }

                        .playback-control:focus {
                            opacity: 1
                        }



                        .playback-control {
                            display: block;
                            position: relative;
                            z-index: 2;
                            background-repeat: no-repeat;
                            margin: auto;
                            display: inline-block;
                            font-style: normal;
                            font-variant: normal;
                            text-rendering: auto;
                            -webkit-font-smoothing: antialiased;
                            background: transparent;
                            border: 0;
                            outline: none;
                        }


                        .playback-control.is-paused {
                            width: 36px;
                            height: 36px;
                            background-size: 36px 36px;
                            background-repeat: no-repeat;
                            background-image: url("../img/feature/play.png")
                        }

                        .playback-control.is-playing {
                            width: 36px;
                            height: 36px;
                            background-size: 36px 36px;
                            background-repeat: no-repeat;
                            background-image: url("../img/feature/stop.png")
                        }
                    }
                    &:hover {
                        .playback-control {
                            opacity: 1
                        }
                    }
                }
                .video-wrapper.nascondi {
                    opacity: 0;
                }


            }
            .grafica.revert {
                border-radius: 0 30px 30px 0;

                .video-wrapper video {
                    border-radius: 0 30px 30px 0;
                }
                .more {
                    border-radius: 0 30px 30px 0;
                }
            }
            /*            .more {
                            display: none;
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background: #fff;
                            align-items: center;
                            border-radius: 30px;
                            .item {
                                max-height: 100%;
                                justify-content: center;
                                display: flex;
                                img {
                                    max-height: 510px;
                                    width: auto;
                                }
                            }

                            .chiudi {
                                position: absolute;
                                right: 30px;
                                top: 30px;
                                font-size: 24px;
                                z-index: 100;
                            }
                        }
                        .more.mostra {
                            display: flex;
                        }*/

            .modal-content {
                border: 0;
                .modal-header {
                    border: 0;
                    padding-left: 30px;
                    padding-right: 30px;
                }
                .modal-body {
                    border: 0;
                    padding-left: 30px;
                    padding-right: 30px;
                }
            }

        }


    }

    #storie {
        background-image: url('../img/prodotto/6-storie-di-aperto.svg');
        background-size: cover;
        background-position: center;
        background-color: $sfondo_grigio;
        .lista {
            .item {
                background-color: #fff;
                cursor:pointer;
                img {
                    border-bottom-left-radius: 200px;
                    width: 90%;
                    float: right;
                    border-top-right-radius: 25px;
                }
                h3, h4 {
                    padding: 12px 21px 10px 10%;
                }

            }
        }
        .approfondimento {
            padding-top:40px;
            display: none;
            .item {
                padding: 50px;
                background-color: #fff;
                .sinistra {
                    .introimg {
                        display: none;
                        border-bottom-left-radius: 200px;
                        width: 90%;
                        float: right;
                        border-top-right-radius: 25px;
                        width: 45%;
                    }
                    height: 100%;
                    color: $txt_blu;
                    @include a_style($txt_blu,$txt_blu);
                    border-right: 1px solid $color_grigio_bordo;
                    font-size: 22px;
                    font-weight: 400;
                    ul {
                        margin: 0;
                        padding: 0;
                        list-style: none;
                        li {
                            padding-bottom: 0;
                            line-height: 1;
                        }
                    }
                    .logo {
                        max-width: 125px;
                        height: auto;
                        padding-bottom: 20px;
                    }
                    .settore, .website, .piattaforma {
                        font-size: 18px;

                    }

                }
                .contenuto {
                    height: 100%;
                    .testo {
                        font-size: 18px;
                        padding-left: 20px;
                    }
                    .imgbottom {
                        display: none;
                        padding-top: 20px;
                    }
                }
            }
        }
    }


    #prodotti {
        background-image: url('../img/prodotto/7-ottieni-il-massimo.svg');
        background-size: cover;
        background-position: center;
    }

    #assistenza {
        background-image: url('../img/prodotto/8-imagiclecare.svg');
        background-size: cover;
        background-position: center;
    }

    #risorse {
        background-image: url('../img/prodotto/9-risorse.svg');
        background-size: cover;
        background-position: center;
        .pattern-news {
            padding-bottom: 50px;
        }
    }

    #tech {
        background-color: $sfondo_grigio;
        background-image: url('../img/prodotto/10-caratteristiche-architettura.svg');
        //background-repeat: no-repeat;
        background-size: cover;
        padding-top: 180px;
        background-position: center top;
        .card .intestazione a:before {
            padding-right: 30px;
            display: inline-block;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            font-family: Font Awesome\ 5 Pro;
            font-weight: 400;
        }
        .card .intestazione a[aria-expanded=false]:before {
            content: "\f067";
        }
        .card .intestazione a[aria-expanded=true]:before {
            content: "\f068";
        }
        table th, table td {
            padding-right: 30px;
            &:last-of-type {
                padding-right: 0;
            }
        }


    }
    #tab-resources {
        padding-top: 83px;
    }

    #blog {
        background-image: url('../img/prodotto/11-dal-blog.svg');
        background-size: cover;
        background-position: center;
        .pattern-news {
            padding-top: 60px;
            padding-bottom: 50px;
            .griglia.classico {
                height: 310px;
            }
        }
    }
    #webinar {
        background-color: $sfondo_grigio;
        background-image: url('../img/prodotto/12-webinar.svg');
        background-size: cover;
        background-position: center;
        .pattern-news {
            background-image: none;
            padding-bottom: 50px;
        }
    }
    #whitepaper {
        background-color: $sfondo_grigio;
        background-image: url('../img/prodotto/13-white-paper-rev1.svg');
        background-size: cover;
        background-position: center;
        .pattern-news {
            background-image: none;
            padding-bottom: 50px;
        }
    }
    #faq {
        background-color: $sfondo_grigio;
        background-image: url('../img/prodotto/14-faq.svg');
        background-size: 100%;
        background-position: top;
        background-repeat: no-repeat;
        .lista {
            .item {
                background: #fff;
                padding: 20px 30px;
                margin-bottom: 10px;
                border-radius: 35px;
                .domanda {
                    padding-bottom: 0;
                    font-weight: bold;
                    color: $txt_blu;
                    display: grid;
                    grid-template-columns: 90% 10%;
                    align-items: center;
                    &:after {
                        display: flex;
                        text-rendering: auto;
                        -webkit-font-smoothing: antialiased;
                        font-family: "Font Awesome 5 Pro";
                        font-weight: 300;
                        align-self: flex-start;
                        justify-content: end;
                    }
                }
                .domanda[aria-expanded='false']::after {
                    content: "\f067";
                }
                .domanda[aria-expanded='true']::after {
                    content: "\f068";
                }
                .risposta {
                    padding-top: 15px;
                }
            }
        }
    }

    .punti-elenco {
        .inner {
            background: #fff;
            padding: 50px;
            .testo {
                h3 {
                    font-size: 24px;
                    font-weight: 600;
                    padding-bottom: 30px;
                }
            }
            .lista {
                padding-top: 40px;
                .item {
                    border-top: 1px solid #ccc;
                    padding: 15px 0;
                    .testo-lista {
                        p {
                            padding-bottom: 0;
                        }

                    }
                }

            }
            .galleria {
                padding-top: 40px;
            }
        }
    }



}

/*
    MOBILE
*/


@media (max-width: 2200px) {

}
@media (max-width: 1800px) {

    #primary.site-main.prodotto #intro .logo-grafico {
        background-size: 110%;
    }
    #primary.site-main.prodotto #punti {
        background-position: left;
    }

}

@media (max-width: 1750px) {

}

@media (max-width: 1650px) {
    #primary.site-main.prodotto #intro .logo-grafico {
        background-size: 120%;
    }
}

@media (max-width: 1500px) {

    #primary.site-main.prodotto #intro .logo-grafico {
        background-size: 130%;
    }
}

@media (max-width: 1400px) {

}

@media (max-width: 1350px) {

    #primary.site-main.prodotto #intro .logo-grafico {
        background-size: 140%;
    }
}

@media (max-width: 1250px) {

}

@media (max-width: 1199px) {
    // lg

    #primary.site-main.prodotto {

        #intro .el-intro {
            padding-top: 140px;
            padding-bottom: 70px;
        }
        #integrata .griglia .item .cerchio {
            width: 170px;
            height: 170px;
        }
    }
    #primary.site-main.prodotto #dettagli .main-lite {
        .contenuto,.grafica {
            min-height: 504px;
        }
    }
    #primary.site-main.prodotto #integrata .griglia {
        grid-gap: 30px 0;
    }
}

@media (max-width: 1150px) {
    #primary.site-main.prodotto {
        #intro .logo-grafico {
            background-size: 170%;
        }
    }
}

@media (max-width: 992px) {
    // lg - fine
}

@media (max-width: 991px) {
    //md
    #primary.site-main.prodotto {

        #menu-prod {
            background-color: #fff;
            .submenuprod {
                display: none;
                .list-group-horizontal {
                    flex-direction: column;
                }
                .list-group {
                    padding-top: 10px;
                    padding-bottom: 10px;
                    .list-group-item {
                        padding: 10px 0 10px 0;
                        border-bottom: 1px solid #ededed;
                        &:nth-of-type(3){
                            border-bottom: 0;
                        }
                    }
                    a.pulsante {
                        margin-top: 30px;
                    }
                }

            }
            .menumobileprod {
                opacity: 1;
                cursor: pointer;
                color: $txt_blu;
                transform: none;
                transition: transform 0.5s ease-in-out;
            }

        }
        #menu-prod.open {
            .menumobileprod {
                transform: rotate(180deg);
            }
            .submenuprod {
                #prodList {
                    opacity: 1;
                }
            }
        }
        #menu-prod.mostra {
            background: #fff;
            .menumobileprod {
                display: block;
            }
            .menumobileprod, #prodList {
                opacity: 1;
            }
        }

        .tab-content {
            margin-top: 0;
        }
        #intro {
            .logo-grafico {
                background-size: contain;
            }
            .play-video {
                top: 40%;
                font-size: 70px;
            }
        }
        #integrata .griglia {
            grid-gap: 20px 0;
            .item .cerchio {
                width: 130px;
                height: 130px;
            }
        }
        #tech {
            padding-top: 100px;
        }
        #tab-resources {
            padding-top:0;
        }

        #dettagli .main-lite {
            .contenuto, .contenuto.revert {
                border-radius: 0 0 30px 30px;
                min-height: 480px;
            }
            .grafica, .grafica.revert {
                border-radius: 30px 30px 0 0;
                min-height: 480px;
                .video-wrapper video {
                    border-radius: 30px 30px 0 0;
                }
            }
        }
    }
}

@media (max-width: 950px) {

}

@media (max-width: 895px) {

}

@media (max-width: 768px) {
    //md-fine

    // prodotto
    #primary.site-main.prodotto #menu-prod {
        .goToOverview {
            font-size: 20px;
        }
        .icona {
            width: 42px;
            height: 42px;
        }
        .list-group .list-group-item {
            padding-left: 0;
            padding-right: 15px;
        }
        .list-group a.pulsante {
            min-width: auto;
        }
    }
    #primary.site-main.prodotto #intro .el-intro {
        padding-top: 120px;
        padding-bottom: 50px;
    }

}

@media (max-width: 767px) {
    //sm
    #primary.site-main.prodotto {
        #intro {
            .el-intro {
                padding-top: 290px;
                padding-bottom: 50px;
            }
            .logo-grafico {
                /*            background-image: url('../img/prodotto/prod_bkg_sm.svg'); */
                background-size: cover;
            }
        }
        #menu-prod {
            padding: 10px 0;
        }

        #integrata .griglia {
            grid-gap: 18px;
            grid-template-columns: repeat(4,1fr);
            .item .cerchio {
                width: 116px;
                height: 100%;
            }
        }


    }

    #primary.site-main.prodotto #dettagli .main-lite {
        .contenuto, .contenuto.revert {
            min-height: 428px;
        }
        .grafica, .grafica.revert {
            min-height: 428px;
        }
    }
    #primary.site-main.prodotto {
        #risorse .pattern-news, #blog .pattern-news, #webinar .pattern-news,  #whitepaper .pattern-news {
            padding-top: 30px;
            padding-bottom: 20px;
        }
    }
}

@media (max-width: 650px) {

}

@media (max-width: 575px) {
    // xs
    #primary.site-main.prodotto {
        .tab-content {
            margin-top: 0;
        }
    }

    #primary.site-main.prodotto #tab-resources {
        padding-top: 0;
    }
    #primary.site-main.prodotto #tech {
        padding-top: 60px;
    }

    #primary.site-main.prodotto #dettagli .main-lite {
        .contenuto, .contenuto.revert {
            min-height: 442px;
        }
        .grafica, .grafica.revert {
            min-height: 442px;
        }
    }
    #primary.site-main.prodotto #integrata .griglia {
        //grid-gap: 10px;
        grid-template-columns: repeat(3,1fr);
    }
}

@media (max-width: 480px) {
    #primary.site-main.prodotto #dettagli .main-lite {
        .contenuto, .contenuto.revert {
            min-height: 362px;
        }
        .grafica, .grafica.revert {
            min-height: 362px;
        }
    }
    #primary.site-main.prodotto #intro .play-video {
        top: 32%;
        font-size: 60px;
    }
    #primary.site-main.prodotto #intro .logo-grafico {
        background-position: 0 -15px;
    }
    #primary.site-main.prodotto #intro .el-intro {
        padding-top: 200px;
        padding-bottom: 10px;
    }
}

@media (max-width: 420px) {
    #primary.site-main.prodotto #dettagli .main-lite .grafica, #primary.site-main.prodotto #dettagli .main-lite .grafica.revert {
        min-height: 322px;
    }
}

@media (max-width: 375px) {
    //iphone 6/7/8
    #primary.site-main.prodotto #dettagli .main-lite .grafica, #primary.site-main.prodotto #dettagli .main-lite .grafica.revert {
        min-height: 285px;
    }
    #primary.site-main.prodotto #integrata .griglia {
        grid-gap: 10px;
        grid-template-columns: repeat(2,1fr);
    }
}

@media (max-width: 320px) {

}

@media (max-width: 300px) {

}
