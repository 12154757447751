/*
    Created on : 31-ago-2020, 10.04.40
    Author     : veronica
*/

.navbar-reserved {
  padding: 24px 1rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 1000;

  .navbar-toggler {
    padding: 0;
    border: 0;
    color: $txt_blu;
    outline: none;
  }

  submit {
    @include button_style($txt_blu, transparent, #fff, $txt_blu, $txt_blu, $txt_blu);
    padding: 6px 15px;
    font-size: 16px;
    font-weight: 600;
  }

  a {
    font-size: 16px;
    color: $txt_blu;
    text-decoration: none;
    font-weight: 600;

    &:focus {
      outline: 0;
    }

    &:visited {
      color: $txt_blu;
    }

    &:hover {
      color: $txt_blu;
      text-decoration: none;
      font-weight: 600;
    }
  }

  a {
    display: inline-block;
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 100%;
      left: 50%;
      -webkit-transition: left .2s cubic-bezier(0.4, 0, 0.2, 1), width .2s cubic-bezier(0.4, 0, 0.2, 1);
      transition: left .2s cubic-bezier(0.4, 0, 0.2, 1), width .2s cubic-bezier(0.4, 0, 0.2, 1);
      width: 0;
      height: 2px;
      margin-top: 2px;
      background: $txt_blu;
    }

    &:hover {
      &:before {
        left: 0;
        width: 100%
      }
    }
  }

  ul {
    margin: 0;
  }

  li {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 0;
  }

}

#masthead.site-header {
  .logo {
    width: 142px;
  }

  .iniziali {
    border-radius: 50%;
    background-color: #fff;
    color: $txt_blu;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    align-content: center;
    text-transform: uppercase;
    justify-content: center;
  }

  .menu__dimmed {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #00173c;
    opacity: 0;
    z-index: 2000;
    -webkit-transition: opacity .6s cubic-bezier(0.4, 0, 0.2, 1) .2s;
    transition: opacity .6s cubic-bezier(0.4, 0, 0.2, 1) .2s;
  }

  .menu__dimmed.open {
    display: block;
    opacity: .68;
    -webkit-transition: opacity .6s cubic-bezier(0.4, 0, 0.2, 1);
    transition: opacity .6s cubic-bezier(0.4, 0, 0.2, 1);
  }

  .menu__header {
    z-index: 2100;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;

    .svg-blu {
      display: inline-block;
      width: auto;
      height: 18px;
    }

    .svg path {
      fill: #fff;
    }

    .svg-blu path {
      fill: $txt_blu;
    }

    .menu__top {
      z-index: 2100;
      background: $sfondo_header;
      color: #fff;
      position: relative;
      padding: 0 40px;

      .nav-link.mgc__depth1-link {
        font-weight: 600;
        font-size: 16px;
        color: $txt_blu;

        &:hover {
          font-weight: 700;
          //font-size: 100%;
        }
      }

      a {
        font-weight: 600;
        font-size: 16px;
        color: $txt_blu;
        @include trans-fx;
        text-decoration: none;

        &:focus {
          outline: 0;
        }

        &:visited {
          color: $txt_blu;
        }

        &:hover {
          color: $txt_blu;
          text-decoration: none;
          font-weight: 700;
          //font-size: 100%;
        }
      }

      a.pulsante {
        @include button_style($txt_blu, transparent, #fff, $txt_blu, $txt_blu, $txt_blu);
        padding: 6px 15px;
        font-size: 16px;
      }

      ul {
        margin-left: 0;
      }

      ul li {
        padding: 20px 0;
        margin: 0;
        border-bottom: 3px solid transparent;
      }

      /*            ul li.active {
                      border-bottom: 3px solid $txt_blu;
                      font-weight: bold;
                      transition: border 2s;
                  }*/
      ul li.menu-pulsante {
        padding: 0;
        margin-left: 20px;
      }

      .mgc__depth1-link-text {
        display: inline-block;
        position: relative
      }

      .mgc__depth1-link-text:before {
        content: '';
        display: block;
        position: absolute;
        top: 100%;
        left: 50%;
        -webkit-transition: left .2s cubic-bezier(0.4, 0, 0.2, 1), width .2s cubic-bezier(0.4, 0, 0.2, 1);
        transition: left .2s cubic-bezier(0.4, 0, 0.2, 1), width .2s cubic-bezier(0.4, 0, 0.2, 1);
        width: 0;
        height: 3px;
        margin-top: 28px;
        background: $txt_blu
      }

      .mgc__depth1-link:hover .mgc__depth1-link-text, .mgc__depth1-link:focus .mgc__depth1-link-text {
        font-weight: bold;
      }

      .mgc__depth1-link:hover .mgc__depth1-link-text:before, .mgc__depth1-link:focus .mgc__depth1-link-text:before, ul li.active .mgc__depth1-link .mgc__depth1-link-text:before {
        left: 0;
        width: 100%
      }

      ul.nav.dx .nav-item .nav-link {
        padding: 0;
        padding-left: 20px;
        font-size: 20px;
      }

      .header-nav-mobile__menu-button-wrapper {
        width: 20px;
        height: 15px;
        padding: 0;
        position: relative;
        cursor: pointer;
        border: 0;
        background: none;
        outline: none;

        .header-nav-mobile__menu-line {
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
          width: 100%;
          height: 2px;
          position: absolute;
          left: 0;
          top: 7.5px;
          -webkit-transform-origin: center center;
          -ms-transform-origin: center center;
          transform-origin: center center;
          -webkit-transition: -webkit-transform 0.3s, background 0.3s;
          -webkit-transition: transform 0.3s, background 0.3s;
          transition: transform 0.3s, background 0.3s;
          //background-image: url(/_next/static/images/menu_line-18cdb2e….svg);
          background-color: $txt_blu;
        }

        .header-nav-mobile__menu-line--top {
          -webkit-transform: translateY(-7.5px);
          -ms-transform: translateY(-7.5px);
          transform: translateY(-7.5px);
        }

        .header-nav-mobile__menu-line--middle {
          -webkit-transform: translateY(0);
          -ms-transform: translateY(0);
          transform: translateY();
        }

        .header-nav-mobile__menu-line--bottom {
          -webkit-transform: translateY(7.5px);
          -ms-transform: translateY(7.5px);
          transform: translateY(7.5px);
        }

      }

      #menu-mobile.active {
        .header-nav-mobile__menu-button-wrapper {
          .header-nav-mobile__menu-line--top {
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }

          .header-nav-mobile__menu-line--middle {
            display: none;
          }

          .header-nav-mobile__menu-line--bottom {
            -webkit-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
          }
        }
      }

    }

    #menul1imagicle.menuclose {
      margin-right: -5000px;
      transition: 1s;

    }

    #menul1imagicle {
      transition: 1s;
      margin-right: 0px;
    }

    .menu__bottom {
      position: absolute;
      top: 79px;
      width: 100%;
      overflow: hidden;
      z-index: 2100;
      background: #fff;
      color: $txt_blu;
      height: 0;
      -webkit-transition-property: height;
      transition-property: height;
      -webkit-transition-delay: .1s;
      transition-delay: .1s;

      h2, .menu-title {
        font-weight: bold;
        font-size: 121%;
        padding-top: 0;
      }

      .submenu {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        visibility: hidden;
        opacity: 0;
        padding: 40px 0;
        overflow: hidden;
        transition: visibility 0s, opacity 2s linear;
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
        -webkit-transition: opacity .4s cubic-bezier(0.4, 0, 0.2, 1) .1s, -webkit-transform .5s cubic-bezier(0.4, 0, 0.2, 1) .1s;
        transition: opacity .4s cubic-bezier(0.4, 0, 0.2, 1) .1s, -webkit-transform .5s cubic-bezier(0.4, 0, 0.2, 1) .1s;
        transition: transform .5s cubic-bezier(0.4, 0, 0.2, 1) .1s, opacity .4s cubic-bezier(0.4, 0, 0.2, 1) .1s;
        transition: transform .5s cubic-bezier(0.4, 0, 0.2, 1) .1s, opacity .4s cubic-bezier(0.4, 0, 0.2, 1) .1s, -webkit-transform .5s cubic-bezier(0.4, 0, 0.2, 1) .1s;

        .menuinner {
          padding-left: 15px;
          border-right: 1px solid $sfondo_grigio;
        }

        ul, ul.nav {
          margin-left: 0;
          list-style: none;

          li {
            padding-bottom: 13px;
            margin-bottom: 0px;
            //display:inline-block;
            a {
              //font-size: 110%;
              color: $txt_blu;
              //@include trans-fx(600ms);

              text-decoration: none;
              font-weight: 400;

              &:focus {
                outline: 0;
              }

              &:visited {
                color: $txt_blu;
              }

              &:hover {
                color: $txt_blu;
                text-decoration: none;
                font-weight: 400;
              }
            }

            .mgc__depth3-link-text {
              display: inline-block;
              position: relative
            }

            .mgc__depth3-link-text:before {
              content: '';
              display: block;
              position: absolute;
              top: 100%;
              left: 50%;
              -webkit-transition: left .2s cubic-bezier(0.4, 0, 0.2, 1), width .2s cubic-bezier(0.4, 0, 0.2, 1);
              transition: left .2s cubic-bezier(0.4, 0, 0.2, 1), width .2s cubic-bezier(0.4, 0, 0.2, 1);
              width: 0;
              height: 2px;
              margin-top: 2px;
              background: $txt_blu
            }

            .mgc__depth3-link:hover .mgc__depth3-link-text, .mgc__depth3-link:focus .mgc__depth3-link-text {
            }

            .mgc__depth3-link:hover .mgc__depth3-link-text:before, .mgc__depth3-link:focus .mgc__depth3-link-text:before {
              left: 0;
              width: 100%
            }

          }

          li.spazio-top {
            padding-top: 10px;
          }
        }

        .article {
          cursor: pointer;

          .tipo {
            padding-top: 15px;
            font-weight: bold;
            z-index: 300;
            position: relative;
          }

          .titolo {
            font-size: 122%;
            padding-top: 5px;
          }

        }
      }

      .submenu.menusx {
        .col-titolo {
          text-align: right;
          padding-right: 30px;
        }

        .col-menu {
          padding-left: 60px;
        }

        .col-article {
          padding-left: 60px;
        }
      }

      .submenu.menudx {
        .col-titolo {
          text-align: right;
          padding-right: 40px;
        }

        .col-menu {
          padding-left: 40px;
        }

        .col-article {
          padding-right: 60px;
        }

        .wpml-ls-statics-shortcode_actions {
          margin-left: 0;
        }
      }

      .submenu-search {
        .search-field {
          border-radius: 0;
          border-width: 0 0 2px 0;
          padding-left: 0;
          font-size: 26px;
          color: $txt_blu;
          width: 100%;
        }

        .search-submit {
          display: none;
        }

        ::-webkit-input-placeholder {
          /* WebKit browsers */
          color: transparent;
        }

        :-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: transparent;
        }

        ::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: transparent;
        }

        :-ms-input-placeholder {
          /* Internet Explorer 10+ */
          color: transparent;
        }

        input::placeholder {
          color: transparent;
        }

      }

      .submenu-login {
        h2, .menu-title {
          padding-bottom: 10px;
        }

        .fa-ul {
          padding-left: 30px;
          margin-bottom: 5px;

          li {
            padding-bottom: 5px !important;
          }
        }

        submit {
          margin-top: 20px;
        }

        a.forgot {
          font-size: 85%;
        }

        a.pulsante, submit {
          @include button_style($txt_blu, transparent, #fff, $txt_blu, $txt_blu, $txt_blu);
          padding: 6px 15px;
          font-size: 16px;
        }

        a.pulsante.negativo {
          @include button_style(transparent, $txt_blu, $txt_blu, #fff, $txt_blu, $txt_blu);
          padding: 6px 15px;
          font-size: 16px;
        }
      }

      .bordo {
        border-right: 2px solid #ededed;
      }

      .bordo-sx {
        border-left: 2px solid #ededed;
      }

      // override per mobile
      .submenu.submenu-mobile {
        height: calc(100vh - 80px);
        overflow: scroll;

        ul.mm-depth1 {
          margin-left: 60px;

          li {
            padding-bottom: 0;
          }
        }

        .article {
          padding: 40px 0;
        }

        .mm-depth1 {
          width: 100%;
          -webkit-transition: opacity .2s cubic-bezier(0.4, 0, 0.2, 1);
          transition: opacity .2s cubic-bezier(0.4, 0, 0.2, 1);
          display: table;
          line-height: 1.33;
          float: left;

          .mm-depth1-menu {
            display: table;
            width: 80%;
            height: auto;
            vertical-align: middle;
            margin-bottom: 0;
            max-width: 300px;
          }

          .mm-depth1-link {
            display: table-cell;
            /*                    left: 0;
                                width: 100%;*/
            padding: 0;
            vertical-align: middle;
            opacity: 1;
            -webkit-transform: translateX(0);
            transform: translateX(0);
            -webkit-transition: opacity .3s cubic-bezier(0.4, 0, 0.2, 1) .2s, -webkit-transform .5s cubic-bezier(0.6, 0, 0.4, 1);
            transition: opacity .3s cubic-bezier(0.4, 0, 0.2, 1) .2s, -webkit-transform .5s cubic-bezier(0.6, 0, 0.4, 1);
            transition: transform .5s cubic-bezier(0.6, 0, 0.4, 1), opacity .3s cubic-bezier(0.4, 0, 0.2, 1) .2s;
            transition: transform .5s cubic-bezier(0.6, 0, 0.4, 1), opacity .3s cubic-bezier(0.4, 0, 0.2, 1) .2s, -webkit-transform .5s cubic-bezier(0.6, 0, 0.4, 1);
            overflow: hidden;
            position: relative;
            padding-right: 40px;
            padding-bottom: 15px;
            font-weight: 600;

            .icon--next {
              display: block;
              position: absolute;
              right: 0;
              top: 0;
              padding: 10px 0;
            }
          }

          .mm-depth1-link-text {
            display: inline-block;
            position: relative
          }

          .mm-depth1-link-text:before {
            content: '';
            display: block;
            position: absolute;
            top: 100%;
            left: 50%;
            -webkit-transition: left .2s cubic-bezier(0.4, 0, 0.2, 1), width .2s cubic-bezier(0.4, 0, 0.2, 1);
            transition: left .2s cubic-bezier(0.4, 0, 0.2, 1), width .2s cubic-bezier(0.4, 0, 0.2, 1);
            width: 0;
            height: 2px;
            margin-top: 2px;
            background: $txt_blu
          }

          .mm-depth1-link:hover .mm-depth1-link-text, .mm-depth1-link:focus .mm-depth1-link-text {
            font-weight: bold;
          }

          .mm-depth1-link:hover .mm-depth1-link-text:before, .mm-depth1-link:focus .mm-depth1-link-text:before {
            left: 0;
            width: 100%
          }

          a.pulsante {
            @include button_style($txt_blu, transparent, #fff, $txt_blu, $txt_blu, $txt_blu);
            padding: 6px 15px;
            font-size: 16px;
            margin-top: 30px;
            width: 100%;
          }
        }

        .mm-depth2-wrap {
          display: none;
          overflow: hidden;
          position: fixed;
          left: auto;
          right: 0;
          top: 79px;
          bottom: 0;
          width: 100%;
          background: #fff;
          z-index: 2200;
          -webkit-transform: translateX(360px);
          transform: translateX(360px);
          -webkit-transition: -webkit-transform .3s cubic-bezier(0.6, 0, 0.4, 1) .05s;
          transition: -webkit-transform .3s cubic-bezier(0.6, 0, 0.4, 1) .05s;
          transition: transform .3s cubic-bezier(0.6, 0, 0.4, 1) .05s;
          transition: transform .3s cubic-bezier(0.6, 0, 0.4, 1) .05s, -webkit-transform .3s cubic-bezier(0.6, 0, 0.4, 1) .05s;

          .mm-menu-close {
            position: absolute;
            top: 20px;
            right: 60px;
            width: 40px;
            height: 40px;
            opacity: 0;
            z-index: 2300;
            -webkit-transition: opacity .2s cubic-bezier(0.4, 0, 0.2, 1);
            transition: opacity .2s cubic-bezier(0.4, 0, 0.2, 1);
          }

          .mm-depth2-inner {
            visibility: visible;
            overflow: hidden;
            overflow-y: auto;
            padding-top: 56px;
            height: calc(100vh - 150px);
            /*                        position: absolute;
                                    left: 0;
                                    top: 56px;
                                    bottom: 0;
                                    width: 100%;
                                    height: auto;*/
            -webkit-transition-property: scrollTop;
            transition-property: scrollTop;
            padding: 0 69px;

            ul.mm-depth2 li {
              padding-bottom: 10px;
              margin-bottom: 0;
            }

            .mm-depth2-inner-sub {
              width: 25%;
              float: left;
              padding-left: 1em;
              padding-right: 1em;

              &:nth-child(1) {
                padding-left: 0;
                border-left-width: 0;
              }
            }

            .article {
              width: 40%;
              clear: both;
              padding-right: 1em;
            }
          }

          .mm-depth2-back-wrap {
            padding-top: 40px;
            padding-bottom: 20px;
            padding-left: 42px;
            width: 25%;
            /*                        -webkit-transform: translateX(60px);
                                    transform: translateX(60px);
                                    height: 57px;
                                    display: block;
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    height: 56px;
                                    width: 100%;*/
            //padding-right: 76px;
            background: #fff;
            z-index: 2100;

            .mm-depth2-back {
              font-weight: bold;
            }

            /*                        .mm-depth2-back {
                                        display: block;
                                        position: absolute;
                                        left: 60px;
                                        top: 20px;
                                        width: 40px;
                                        height: 40px;
                                    }*/
          }

        }

        .mm-depth2-wrap.open {
          -webkit-transform: translateX(0);
          transform: translateX(0);
          -webkit-transition: -webkit-transform .3s cubic-bezier(0.6, 0, 0.4, 1);
          transition: -webkit-transform .3s cubic-bezier(0.6, 0, 0.4, 1);
          transition: transform .3s cubic-bezier(0.6, 0, 0.4, 1);
          transition: transform .3s cubic-bezier(0.6, 0, 0.4, 1), -webkit-transform .3s cubic-bezier(0.6, 0, 0.4, 1);

          .mm-depth2-back-wrap {
            visibility: visible;
            opacity: 1;
            -webkit-transform: translateX(0);
            transform: translateX(0);
            -webkit-transition: opacity .5s cubic-bezier(0.4, 0, 0.2, 1) .2s, -webkit-transform .5s cubic-bezier(0.4, 0, 0.2, 1);
            transition: opacity .5s cubic-bezier(0.4, 0, 0.2, 1) .2s, -webkit-transform .5s cubic-bezier(0.4, 0, 0.2, 1);
            transition: transform .5s cubic-bezier(0.4, 0, 0.2, 1), opacity .5s cubic-bezier(0.4, 0, 0.2, 1) .2s;
            transition: transform .5s cubic-bezier(0.4, 0, 0.2, 1), opacity .5s cubic-bezier(0.4, 0, 0.2, 1) .2s, -webkit-transform .5s cubic-bezier(0.4, 0, 0.2, 1);
          }

          .mm-depth2-wrap.open .mm-depth2-inner {
            visibility: visible;
          }

          .mm-menu-close {
            visibility: visible;
            opacity: 1;
          }
        }

        .submenu-search {
          h2, .menu-title {
            padding-bottom: 0;
          }

          .search-field {
            font-size: 20px;
          }
        }

      }
    }

    .menu__bottom.active {
      //transition: height 0.25s ease-in;
      transition-duration: 0.5s;
      height: 550px;

      .submenu.open {
        visibility: visible;
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
      }
    }
  }
}

.article.download, .article.webinar {
  .data {
    font-weight: bold;
    font-size: 26px;
  }

  .image {
    width: 314px;
    height: 210px;
    background-image: url('../img/pattern-webinar.svg');
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
    padding-right: 20px;
    font-weight: 300;
    background-size: 1000px;
    position: relative;

    .date {
      font-family: $font_titoli;
      display: contents;
      font-weight: bold;
      font-size: 26px;
    }

    .time {
      font-family: 'Gotham Rounded Book';
      display: contents;
      font-size: 32px;
      text-transform: uppercase;
      letter-spacing: -2px;
    }

    .lang {
      display: block;
      position: absolute;
      right: 30px;
      top: 20px;
      background: $sfondo_grigio;
      border: 4px solid #fff;
      border-radius: 20px;
      width: 70px;
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      height: 30px;
    }

    .action {
      display: block;
      position: absolute;
      bottom: 0;
      background: $txt_blu;
      color: #fff;
      border-radius: 50px;
      height: 50px;
      font-family: $font_titoli;
      font-weight: bold;
      font-size: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 33px;
      border-top-right-radius: 0;
      left: auto;
      right: 0;
      width: 80%;

      .ico-webinar {
        width: 50px;
        height: auto;
        position: absolute;
        left: -4px;
        bottom: 0;
        font-size: 60px;

      }
    }
  }

}

.article.download .image {
  background-position: center;
  background-size: contain;
}

.item.webinar.live {
  .image {
    background-image: none;
  }
}

#masthead.site-header .menu__header .menu__bottom .submenu {
  .sub-menu-inner {
    padding-left: 10px;

    .titolo2 {
      padding-bottom: 15px;
    }

    li.menu-item {
      padding-bottom: 8px;
    }

    a.mgc__depth3-link {
      width: 100%;
      padding: 5px;
      border-radius: 5px;
      position: relative;
      display: block;

      &:after {
        display: inline-flex;
        justify-content: end;
        align-items: center;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        font-family: Font Awesome\ 5 Pro;
        font-weight: 400;
        padding-right: 10px;
        position: absolute;
        right: 0;
      }
    }

    a.mgc__depth3-link[aria-expanded=false] {
      background-color: transparent;

      &:after {
        content: none;
      }
    }

    a.mgc__depth3-link[aria-expanded=true] {
      background-color: #E9EDF3;

      &:after {
        content: "\f105";
      }
    }
  }

  .sub-sub-menu-inner {
    //padding-top: 40px;
  }

  .sub-sub-menu-mobile {
    ul {
      padding-bottom: 10px;

      li {
        border-bottom: 1px solid #E9EDF3;

      }
    }
  }
}

#masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile .mm-depth2-wrap .mm-depth2-inner .mm-depth2-inner-sub.sub-menu-inner {
  min-width: 50%;

  a.mgc__depth3-link[aria-expanded=true] {
    &:after {
      content: "\f107";
    }
  }
}

@media (max-width: 2200px) {

}

@media (max-width: 1800px) {

}

@media (max-width: 1750px) {

}

@media (max-width: 1650px) {

}

@media (max-width: 1500px) {

}

@media (max-width: 1400px) {

}

@media (max-width: 1350px) {

}

@media (max-width: 1250px) {
  #masthead.site-header .menu__header .menu__top {
    padding: 0 10px;
  }

}

@media (max-width: 1199px) {
  // lg
  #masthead.site-header .menu__header {
    .menu__top {
      padding: 22px 40px;

      ul li {
        padding: 0;
      }

      a {
        font-size: 20px;
      }
    }

    .menu__bottom .submenu.menudx .col-menu {
      padding-right: 40px;
    }
  }
  #masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile .mm-depth2-wrap {
    top: 0;
  }
  #masthead.site-header .menu__header .menu__bottom.active {
    height: calc(100vh - 80px);
  }
}

@media (max-width: 1150px) {

}

@media (max-width: 992px) {
  // lg - fine
}

@media (max-width: 991px) {
  //md

  #masthead.site-header .menu__header {
    .menu__bottom {
      .submenu ul, .submenu ul.nav {
        margin-left: 0;
      }

      .submenu.menusx {
        .col-article, .col-menu {
          padding-left: 0;
        }

        .article {
          margin-left: 30px;
        }
      }

      .submenu.menudx {
        .col-article {
          padding-right: 30px;
        }

        .col-menu {
          padding-right: 9px;
          padding-left: 30px;
        }
      }

      .submenu.menudx.submenu-search .blocco {
        padding-top: 80px;
        padding-bottom: 80px;

        h2, .menu-title {
          padding-bottom: 0;
        }
      }

      .submenu.menudx .wpml-ls-statics-shortcode_actions {
        margin-left: 0;
      }
    }
  }

  .navbar-reserved {
    a {
      font-size: 110%;
    }

    ul {
      li {
        padding-left: 0;
        padding-bottom: 10px;

        a {
          font-size: 110%;
        }

      }
    }

    #navbarNav {
      border-top: 2px solid #ededed;
      padding-top: 20px;
      margin-top: 10px;
    }

    form {
      border-top: 2px solid #ededed;
      padding-top: 20px;
      padding-bottom: 20px;
      margin-top: 10px;
    }
  }
}

@media (max-width: 950px) {

}

@media (max-width: 895px) {

}

@media (max-width: 768px) {
  //md-fine
  #masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile .mm-depth2-wrap .mm-depth2-inner {
    .mm-depth2-inner-sub {
      h2, .menu-title {
        padding-top: 20px;
        border-top: 2px solid #ededed;
        border-bottom: 2px solid #ededed;
      }

      width: 100%;
      height: auto !important;
      border-left-width: 0;

      &:nth-child(1) {
        padding-left: 1em;
        border-left-width: 0;
      }

    }

    .article {
      display: none;
    }
  }
  #masthead.site-header .menu__header .menu__bottom .submenu ul.nav {
    margin-top: 10px;
  }
  #masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile .mm-depth2-wrap .mm-depth2-back-wrap {
    width: 100%;
  }


}

@media (max-width: 767px) {
  //sm
  #masthead.site-header .menu__header {
    .menu__bottom {
      .submenu.menudx {
        .col-article {
          padding: 0 20px;
        }

        .col-menu {
          padding-right: 20px;
          padding-left: 20px;
        }
      }

      .submenu.menudx.submenu-search .blocco {
        padding-top: 50px;
        padding-bottom: 50px;
      }
    }
  }
  #masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile .mm-depth2-wrap .mm-depth2-back-wrap {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

@media (max-width: 650px) {

}

@media (max-width: 575px) {
  // xs

  #masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile .mm-depth2-wrap .mm-depth2-back-wrap .mm-depth2-back {
    left: 30px;
  }
  #masthead.site-header .menu__header {
    .menu__top {
      padding: 22px 0;
    }

    .menu__bottom {
      .submenu {
        min-height: auto;
        padding-top: 0px;
        padding-bottom: 0;
      }

      .submenu.submenu-lang, .submenu.submenu-login {
        padding-top: 30px;
      }

      .bordo-sx {
        border-left: 0;
      }

      .submenu.submenu-mobile {
        ul.mm-depth1 {

          margin-left: 0;

          .mm-depth1-menu {
            margin-top: 15px;
            margin-bottom: 15px;
            border-bottom: 2px solid #ededed;
            width: 100%;
            max-width: 100%;

            &:last-of-type {
              border-bottom: 0;
            }
          }
        }

        .mm-depth2-wrap {
          .mm-depth2-inner {
            padding: 0 15px;

            .mm-depth2-inner-sub {
              padding-left: 0;
            }

            .mm-depth2-inner-sub:first-child {
              padding-left: 0;
            }
          }

          .mm-depth2-back-wrap {
            padding-top: 25px;
            padding-left: 10px;
          }

        }

      }
    }
  }
}

@media (max-width: 480px) {
  #masthead.site-header {
    .logo {
      width: 140px;
    }

    .menu__header {
      .menu__top {
        padding: 12px 0;
      }

      .menu__bottom {
        top: 58px;

        .submenu.submenu-mobile {
          height: calc(100vh - 58px);
          overflow: scroll;
        }
      }
    }
  }
}

@media (max-width: 430px) {

}

@media (max-width: 380px) {

}

@media (max-width: 320px) {

}

@media (max-width: 300px) {

}
