/*
    Created on : 31-ago-2020, 10.04.40
    Author     : veronica
*/
/*@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');
$font_base: 'Source Sans Pro', sans-serif;*/

//font
$font_base: 'Source Sans Variable', sans-serif;

$font_titoli: 'Gotham Rounded';
$font_titoli_book: 'Gotham Rounded Book';

// size
$size_base: 18px;
$size_h1: 64px; //360%; //64px;
$size_h2: 44px; //245%; //44px;
$size_h3: 32px; //178%; //32px;
$size_h4: 26px; //145%; //26px;
$size_h5: 24px; //134%; //24px;
$size_h6: 22px; //22px;
$spaziatore: 120px;

// variabili
$txt_blu: #00225A;

$sfondo_base: #fff;
$sfondo_blu: #004182;
$sfondo_celeste: #85E2FF;
$sfondo_grigio: #E9EDF3;
$sfondo_grigio_scuro: #D5DCE8;
$sfondo_header: #00C0F0;
$sfondo_cta: #00C0F0;

$color_nero : #191919;
$color_blu: #004182;
$color_celeste: #008CFF;
$color_rosso: #F05032;
$color_giallo: #FBB03B;
$color_verde: #16DB7D;
$color_verde_scuro : #078855;
$color_ko: #FC2759;
$color_viola_ms: #5D5BD4;

$color_menu: $txt_blu;
$color_testo: #5C6B7A;
$color_testo_neg: #B8D1F5;
$color_testo_blog: #4b5563;

$color_titoli: $txt_blu;
$color_link: #429AFF;
$color_link_over: #429AFF;

$color_grigio_bordo: #ccc;
$color_grigio_testi: #999;
$color_grigio_scuro: rgb(204,212,226);
$color_grigio_linea: #c6cbd1;
