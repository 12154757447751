/*
    Created on : 25-mar-2021, 9.47.15
    Author     : veronica
*/
/** OVERRIDE MENU **/
body.single-cpt_suite #page.site, body.page-template-page-platform #page.site{
    margin-top: 0!important;
    #masthead.site-header .menu__header {
        position: relative;
    }
}

/* -------------------------------------------------- */
/*	PAGE PRODOTTO
/* -------------------------------------------------- */

#primary.site-main.prodotto.suite {

    .top {
        background-position: center;
        background-color: $sfondo_grigio;
        background-size: contain;
        background-repeat: no-repeat;
        width: 100%;
        height: calc(100vh - 68px);
        position: relative;
        overflow: hidden;
        /* Style the video: 100% width and height to cover the entire window */

        .myVideo {
            object-fit: cover;
            width: 100vw;
            height: 100vh;
            position: relative;
            right: 0;
            bottom: 0;
            /*            min-width: 100%;
                        min-height: 100%;*/
        }

        /* Add some content at the bottom of the video/page */

        .content {
            position: absolute;
            top: 120px;
            width: 100%;
            height: auto;
            display: flex;
            //align-items: center;
            justify-content: center;
            /*            img {
                            opacity: 0.5;
                        }*/
            h1 {
                -webkit-animation-delay: 10s;
                animation-delay: 10s;
                -webkit-animation-delay: calc(var(--animate-delay)*10);
                animation-delay: calc(var(--animate-delay)*10);
            }

            .subhead {
                padding-bottom: 50px;
                -webkit-animation-delay: 13s;
                animation-delay: 13s;
                -webkit-animation-delay: calc(var(--animate-delay)*13);
                animation-delay: calc(var(--animate-delay)*13);
            }
            .azioni {
                -webkit-animation-delay: 13.5s;
                animation-delay: 13.5s;
                -webkit-animation-delay: calc(var(--animate-delay)*13.5);
                animation-delay: calc(var(--animate-delay)*13.5);
            }
            .play-video {
                cursor: pointer;
                position: absolute;
                width: 100%;
                left: 0;
                top: 45%;
                text-align: center;
                @include a_style(#004182, #ef5a26);
                .play-video-tag {
                    color: #004182;
                    cursor: pointer;
                    &:hover {
                        color: #ef5a26;
                    }
                }
            }
            .fancybox-video {
                background: transparent;
                border: 0;
                display: block;
                margin: 0;
                overflow: hidden;
                padding: 0;
                width: 100%;
                height: calc(100% * 9/16);
            }
            .fancybox-slide--video .fancybox-content {
                background: transparent;
            }
        }
        .hero__scroll {
            position: absolute;
            bottom: 30px;
            width: 200px;
            margin: auto;
            display: block;
            cursor: pointer;
            padding-bottom: 40px;
            left: 0;
            right: 0;
            text-transform: uppercase;
            -webkit-animation-delay: 5s;
            animation-delay: 5s;
            font-size: 60px;
            text-align: center;
            -webkit-animation-delay: calc(var(--animate-delay)*5);
            animation-delay: calc(var(--animate-delay)*5);
            .chevron {
                margin-top: 20px;
                display: block;
                /*                -webkit-animation: pulse 2s infinite;
                                animation: pulse 2s infinite;*/
                color: $txt_blu;
            }
        }


    }

    #intro {
        position: relative;
        /*        background-image: url('../img/prodotto/prod_bkg_lg.svg');
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center top;*/
        .el-intro {
            padding-top: 190px;
            padding-bottom: 160px;
        }
        .logo-grafico {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center top;
        }
        .subhead {
            color: $txt_blu;
            padding-bottom: 50px;
        }
        .embed-video {
            border: 30px solid $txt_blu;
            border-radius: 30px;
        }

        .play-video {
            cursor: pointer;
            position: absolute;
            width: 100%;
            left: 0;
            top: 45%;
            text-align: center;
            font-size: 90px;
            @include a_style(#004182, #ef5a26);
            .play-video-tag {
                color: #004182;
                cursor: pointer;
                &:hover {
                    color: #ef5a26;
                }
            }
        }
    }
    /*
        display: flex;
    flex-grow: 1;
    flex-basis: 280px;
    margin-bottom: 18px;
    max-width: 280px;
    */
    .apps {
        .griglia {
            display: flex;
            flex-wrap: wrap;
            .item {
                display: flex;
                flex-grow:1;
                flex-basis: 212px;
                margin-bottom: 18px;
                max-width: 234px;


                .cerchio {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    width: 220px;
                    height: 80px;
                    max-width: 220px;
                    max-height: 80px;
                    border-radius: 25px;
                    background-color: #fff;
                    padding: 10px;
                    border: 1px solid $sfondo_grigio;
                    transform: translateY(0);
                    transition: transform 0.5s, box-shadow .2s ease-in-out;
                    //transition: all .2s ease-in-out;
                    .icona {
                        width: 60px;
                        height: 60px;
                        margin-right: 12px;
                    }
                    .nomeapp {
                        font-size: 18px;
                        font-weight: 300;
                        padding: 0;
                        margin: 0;
                    }
                    &:hover {
                        -webkit-transform: translateY(-5px);
                        -ms-transform: translateY(-5px);
                        transform: translateY(-5px);
                        -webkit-box-shadow: 0 15px 35px -15px rgba(0,34,90,0.77);
                        -moz-box-shadow: 0 15px 35px -15px rgba(0,34,90,0.77);
                        box-shadow: 0 15px 35px -15px rgba(0,34,90,0.77);
                        //transition: all .2s ease-in-out;
                    }
                }
                .cerchio.dis {
                    opacity: 0.65;
                }

            }
        }
    }
    #needs {
        background-color: $sfondo_grigio;
        background-image: url('../img/prodotto/14-faq.svg');
        background-size: cover;
        background-position: top;
        background-repeat: no-repeat;
        .apps {
            padding-top: 40px;
        }
        .lista-needs {
            .item-need {
                background: #fff;
                padding: 20px 30px;
                margin-bottom: 10px;
                border-radius: 35px;
                -webkit-box-shadow: 0px 20px 85px -45px rgba(0,34,90,0.77);
                -moz-box-shadow: 0px 20px 85px -45px rgba(0,34,90,0.77);
                box-shadow: 0px 20px 85px -45px rgba(0,34,90,0.77);
                transition: all .2s ease-in-out;
                .domanda {
                    padding-bottom: 0;
                    font-weight: bold;
                    color: $txt_blu;
                    &:after {
                        display: inline-block;
                        text-rendering: auto;
                        -webkit-font-smoothing: antialiased;
                        font-family: "Font Awesome 5 Pro";
                        font-weight: 400;
                        float: right;
                    }

                }
                .domanda[aria-expanded='false']::after {
                    content: "\f107";
                }
                .domanda[aria-expanded='true']::after {
                    content: "\f106";
                }
                .risposta {
                    padding-top: 40px;
                }
            }
        }
    }

    #infofaq {
        background-image: url('../img/suite/faq-elementi-grafici.svg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        .item {
            padding: 40px;
            background: $sfondo-grigio;
            color: $txt_blu;
            .numero {
                font-family: $font_titoli;
            }
            .domanda {
            }
            .risposta {
                font-size: 22px;
            }
            .info {
                color: $sfondo_cta;
                font-size: 40px;
            }
        }

        .el-grafico {
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 1;
        }

    }

    #differenze {
        .tabella {
            padding: 70px 50px;
            .row {
                border-bottom: 1px solid $color_grigio_linea;
            }
            background: #fff;
            .intestazione, .issue, .cloud, .other {
                padding: 10px 0;
            }
            .intestazione {
                color: $txt_blu;
                font-family: $font_titoli;
                font-size: 24px;
            }
            .cloud {
                background-color: rgba(233,237,243,0.2);
                border-left: 1px solid $sfondo_grigio;
                border-right: 1px solid $sfondo_grigio;
            }
            .ok {
                color: #16DB7D;
            }
            .ko {
                color: #FC2759;
            }
        }
    }
}

/*
    MOBILE
*/


@media (max-width: 2200px) {

}
@media (max-width: 1800px) {



}

@media (max-width: 1750px) {

}

@media (max-width: 1650px) {

}

@media (max-width: 1500px) {


}

@media (max-width: 1400px) {

}

@media (max-width: 1350px) {


}

@media (max-width: 1250px) {

}

@media (max-width: 1199px) {
    // lg

    #primary.site-main.prodotto.suite .apps .griglia .item .cerchio {
        width: 95%;
        max-width: unset;
    }
}

@media (max-width: 1150px) {

}

@media (max-width: 992px) {
    // lg - fine
}

@media (max-width: 991px) {
    //md
    #primary.site-main.prodotto.suite .top {
        height: calc(100vh - 142px);
    }
    #primary.site-main.prodotto.suite .top .content {
        top: 60px;
    }
    #primary.site-main.prodotto.suite #differenze .tabella {
        padding: 50px;
        .cloud {
            background: none;
            border: none;
            padding-left: 10px;
        }
        .issue {
            padding-bottom: 0;
            padding-left: 10px;
        }
        .cloud, .other {
            .subtitle {
                font-weight: bold;
            }
        }
        .row {
            &:nth-child(odd) {
                background-color: rgba(233,237,243,0.2);

            }
        }
    }
}

@media (max-width: 950px) {

}

@media (max-width: 895px) {

}

@media (max-width: 768px) {
    //md-fine

    #primary.site-main.prodotto.suite .top .hero__scroll {
        bottom: 0;
        padding-bottom: 40px;
    }
}

@media (max-width: 767px) {
    //sm
    #primary.site-main.prodotto.suite .apps .griglia .item {
        flex-basis: 50%;
        max-width: 50%;
        justify-content: center;
    }
    #primary.site-main.prodotto.suite #differenze .tabella {
        padding: 30px;
    }
}

@media (max-width: 650px) {

}

@media (max-width: 575px) {
    // xs
    #primary.site-main.prodotto.suite .top {
        height: calc(100vh - 135px);
    }
    #primary.site-main.prodotto.suite .top .hero__scroll {
        bottom: 0;
        padding-bottom: 20px;
    }
    #primary.site-main.prodotto.suite #differenze .tabella {
        padding: 20px;
        .other {
            padding-left: 10px;
            padding-top: 0;
        }
    }
}

@media (max-width: 480px) {
    #primary.site-main.prodotto.suite .top {
        height: calc(100vh - 113px);
    }
    #primary.site-main.prodotto.suite .apps .griglia .item {
        flex-basis: 100%;
        max-width: 100%;
    }
}

@media (max-width: 420px) {

}

@media (max-width: 375px) {
    //iphone 6/7/8

}

@media (max-width: 320px) {

}

@media (max-width: 300px) {

}
