/* 
    Created on : 31-ago-2020, 17.52.38
    Author     : veronica
*/

@font-face {
    font-family: 'Gotham Rounded';
    src: url('../fonts/Gotham/GothamRounded-Medium.eot');
    src: url('../fonts/Gotham/GothamRounded-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gotham/GothamRounded-Medium.woff2') format('woff2'),
        url('../fonts/Gotham/GothamRounded-Medium.woff') format('woff'),
        url('../fonts/Gotham/GothamRounded-Medium.ttf') format('truetype'),
        url('../fonts/Gotham/GothamRounded-Medium.svg#GothamRounded-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Rounded Book';
    src: url('../fonts/Gotham/GothamRounded-Book.eot');
    src: url('../fonts/Gotham/GothamRounded-Book.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gotham/GothamRounded-Book.woff2') format('woff2'),
        url('../fonts/Gotham/GothamRounded-Book.woff') format('woff'),
        url('../fonts/Gotham/GothamRounded-Book.ttf') format('truetype'),
        url('../fonts/Gotham/GothamRounded-Book.svg#GothamRounded-Book') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/*@font-face {
    src: url('../fonts/SourceSansVariable/SourceSansVariable-Roman.otf.woff2?v3.006') format('woff2-variations');
    font-family:'Source Sans Variable';
    font-weight: 200 900;
    font-style: normal;
    font-display: swap;
}
@font-face {
    src: url('../fonts/SourceSansVariable/SourceSansVariable-Italic.otf.woff2?v3.006') format('woff2-variations');
    font-family:'Source Sans Variable';
    font-weight: 200 900;
    font-style: italic;
    font-display: swap;
}*/


@font-face{
    font-family: 'Source Sans Variable';
    font-weight: 200 900;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
    src: url('../fonts/SourceSansVariable/SourceSansVariable-Roman.otf.woff2') format('woff2-variations'),
         url('../fonts/source-sans-release/WOFF2/VAR/SourceSans3VF-Roman.ttf.woff2') format('woff2'),
         url('../fonts/source-sans-release/WOFF/VAR/SourceSans3VF-Roman.ttf.woff') format('woff'),
         url('../fonts/source-sans-release/VAR/SourceSans3VF-Roman.ttf') format('truetype');
}

@font-face{
    font-family: 'Source Sans Variable';
    font-weight: 200 900;
    font-style: italic;
    font-stretch: normal;
    font-display: swap;
    src: url('../fonts/SourceSansVariable/SourceSansVariable-Italic.otf.woff2') format('woff2-variations'),
         url('../fonts/source-sans-release/WOFF2/VAR/SourceSans3VF-Italic.ttf.woff2') format('woff2'),
         url('../fonts/source-sans-release/WOFF/VAR/SourceSans3VF-Italic.ttf.woff') format('woff'),
         url('../fonts/source-sans-release/VAR/SourceSans3VF-Italic.ttf') format('truetype');
}