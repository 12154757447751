/*
    Created on : 31-ago-2020, 10.04.40
    Author     : veronica
*/

#page.site {
  margin-top: 79px;
  //transition: margin-top 0.3s ease-in-out;
}

/* -------------------------------------------------- */
/*	FOOTER
/* -------------------------------------------------- */

#colophon.site-footer {
  color: #fff;
  padding: 50px 0;
  font-size: 15px;

  .logoimagicle {
    font-family: $font_titoli;
    font-size: 20px;
    font-weight: bold;
    padding-top: 10px;
    display: block;
    border-bottom: 1px solid #fff;
    padding-bottom: 30px;

    small {
      font-family: $font_base;
      font-size: 15px;
      font-weight: normal;
      padding-left: 10px;

    }
  }

  .logozucchetti {
    width: 180px;
  }

  .titoletto {
    font-weight: bold;
    font-size: 16px;
    padding-bottom: 10px;
    padding-top: 30px;
  }

  ul {
    list-style: none;
    margin: 10px 0 0 0;
    padding: 0;

    li {
      padding-bottom: 5px;
    }
  }

  .newsletter {
    .col-email, .col-submit {
      width: 100% !important;
    }

    .col-email {
      padding-bottom: 10px;
    }

    padding-top: 30px;

    input[type="email"] {
      background-color: #fff;
      color: $color_testo;
      border: 1px solid #fff;
    }
  }

  .social {
    padding-top: 10px;
    font-size: 150%;

    a {
      padding-right: 5px;

      &:last-of-type {
        padding-right: 0;
      }
    }
  }

  .contatti {
    .titoletto {
      margin-top: 30px;
      padding-top: 30px;
      border-top: 1px solid #fff;
      padding-bottom: 20px;
    }

    .zona {
      font-weight: bold;
      font-size: 16px;
      padding-bottom: 10px;
    }

    .telefono {
      font-weight: 400;
      font-size: 15px;
      padding-bottom: 10px;
    }

    .orario {
      font-weight: 400;
      font-size: 15px;
    }
  }

  .copy {
    border-top: 1px solid #fff;
    margin-top: 30px;
    padding-top: 30px;
  }

  @include a_style(#fff, $color_link_over);
}

/* -------------------------------------------------- */
/*	PAGE HOME
/* -------------------------------------------------- */

#primary.site-main.home {
  .supertop {
    background-image: url('../img/sfondo-home-head.svg');
    background-position: center 120px;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .supertop-text {
    background-image: url('../img/home-top-titolo-desk.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .supertop-app {
    background-image: url('../img/sfondo-home-c-4.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  /*    #top-home {
          .scorri {
              padding-top: 10px;
              color: $txt_blu;
          }
      }*/
  #top-video {
    position: relative;
    padding-top: 60px;
    display: none;
    opacity: 0;
    transition: opacity 2s;

    .close-video {
      position: absolute;
      right: -20px;
      top: 0;
      width: auto;

      .close-video-tag {
        color: #004182;
        cursor: pointer;

        &:hover {
          color: $txt_blu;
        }
      }
    }

  }

  #top-video.mostra {
    display: block;
  }

  .top {
    background-position: center;
    background-color: $sfondo_grigio;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    //height: calc(100vh - 68px);
    position: relative;
    overflow: hidden;
    /* Style the video: 100% width and height to cover the entire window */

    .myVideo {
      object-fit: contain;
      width: 100vw;
      /*height: 100vh;*/
      position: relative;
      right: 0;
      bottom: 0;
      /*            min-width: 100%;
                  min-height: 100%;*/
    }
  }

  #top-home {
    display: block;

    .subhead {
      padding-bottom: 50px;
    }

    .play-video {
      cursor: pointer;
      position: absolute;
      width: 100%;
      left: 0;
      top: 45%;
      text-align: center;
      @include a_style(#004182, #ef5a26);

      .play-video-tag {
        color: #004182;
        cursor: pointer;

        &:hover {
          color: #ef5a26;
        }
      }
    }

    .fancybox-video {
      background: transparent;
      border: 0;
      display: block;
      margin: 0;
      overflow: hidden;
      padding: 0;
      width: 100%;
      height: calc(100% * 9 / 16);
    }

    .fancybox-slide--video .fancybox-content {
      background: transparent;
    }

  }

  #top-home.nascondi {
    display: none;
    transition: all 2s linear;
  }

  #prodotti {
    position: relative;
    background-color: transparent;

    /*        background-image: url("../img/linguetta-dx.svg");
            background-repeat: no-repeat;
            background-position: right bottom;
            background-size: 300px;*/
  }

  #prodotti.lista-blocchi .item .testoinner {
    padding-bottom: 3rem;
    min-height: 123px;
  }

  #prodotti.pacchetti.lista-blocchi {
    .item {
      border-radius: 50px;
      padding: 0;

      .inner {
        padding: 40px;
        font-size: 20px;

        .apps a:visited {
          color: $txt_blu;
        }

        .apps a:hover {
          color: $color_link;
        }
      }

      img {
        height: 117px;
      }
    }

    .banda {
      height: 2.5em;
      border-bottom-left-radius: 50px;
      border-bottom-right-radius: 50px;
    }
  }

  #news-home {
    position: relative;
    padding-top: 90px;
    padding-bottom: 0;
    background-repeat: no-repeat;
    background-position: top;
    //background-size: 300px;
    background-image: url('../img/sfondo-news.svg');

    .curva {
      position: absolute;
      left: 0;
      top: 0;
      width: 300px;
    }

    /*        .tondo {
                position: absolute;
                background: $color_giallo;
                width: 85px;
                height: 85px;
                border-radius: 50%;
                right: -30px;
                top: -30px;
            }*/
    .cerchio {
      position: absolute;
      bottom: calc(-170px / 2);
      left: 10%;
      width: 170px;
      height: auto;

      .over {
        position: absolute;
        width: 100%;
        height: calc(50% - 1px);
        top: 0;
        left: 0;
        background: $sfondo_blu;
        opacity: 0.8;
      }
    }
  }

  #chi-siamo {
    position: relative;
    padding: 8% 0;
    background-repeat: no-repeat;
    background-position: top right;
    background-image: url('../img/fascia-we-are-imagicle-all.svg');

    .testo {
      padding: 50px 0;
    }

    .immagine {
      max-width: 674px;
      position: absolute;
      right: 10%;
      bottom: 0;
      border-radius: 20%;
      width: 40%;
    }

    .img-round {
      border-radius: 90px;
    }
  }
}

/* -------------------------------------------------- */
/*	PAGE WEBINAR
/* -------------------------------------------------- */

#primary.site-main.webinar {
  .top-header.titolosx {
    background-image: url('../img/webinar/1-banner-webinar.svg');
  }

  .pattern-news {
    background-image: none;
  }

  .input-search-wrapper {
    position: relative;

    input {
      background: #fff;
      padding-right: 30px;
    }

    &:before {
      content: "\f002";
      font-family: "Font Awesome 5 Pro";
      font-style: normal;
      font-weight: normal;
      text-decoration: inherit;
      color: $txt_blu;
      font-size: 18px;
      padding-right: 15px;
      position: absolute;
      top: 12px;
      right: 0;
    }
  }

  .ico-webinar {
    width: 50px;
    height: auto;

  }

  #blocco-ricerca {
    background-image: url('../img/webinar/2-search-webinar.svg');
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;

    .search-field {
      background: #fff;
    }
  }

  #blocco-risultato-ricerca {
    .pattern-news {
      padding-bottom: 30px;
    }
  }

  #blocco-silos {
    background-color: $sfondo_grigio;
    background-image: url('../img/webinar/3-diventa-esperto-webinar.svg');
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
  }

  #blocco-evidenza {
    background-image: url('../img/webinar/4-da-non-perdere-webinar.svg');
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
  }

  #blocco-live-demand {
    background-image: url('../img/webinar/5-live-on-demand-webinar.svg');
    background-size: contain;
    background-position: top center;
    //background-repeat: no-repeat;

  }
}

/* -------------------------------------------------- */
/*	PAGE PRICING
/* -------------------------------------------------- */

#primary.site-main.pricing {
  .sezione-inner {
    background-color: $sfondo_grigio;
    background-image: url('../img/pricing/scegli-cio-pricing.svg');
    //background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
  }

  #partner {
    background-image: url('../img/pricing/partner-pricing.svg');
    //background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
  }

  #cisco {
    background-image: url('../img/pricing/Cisco-GPL.svg');
    //background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;

    img.spazio {
      max-width: 360px;
      max-height: 360px;
    }
  }

  #blocco-silos {
    background-color: transparent;

    .item.bestseller {
      position: relative;
      padding-top: 140px;
      padding-bottom: 140px;

      .best {
        color: #fff;
        position: absolute;
        top: 0;
        right: 0;
        width: 40%;
        min-width: 130px;
        min-height: 130px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        border-top-right-radius: 25px;
        border-bottom-left-radius: 25px;
        text-align: center;

      }
    }
  }

}

/* -------------------------------------------------- */
/*	PAGE HELP CENTER
/* -------------------------------------------------- */

#primary.site-main.help-center {
  .top-header.titolosx {
    background-image: url('../img/help/0-elementi-help-center-banner-HC.svg');
  }

  #points {
    background-image: url('../img/help/1-elementi-help-center-per-cosa.svg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .lista-people {
    background-image: url('../img/help/6-elementi-help-center-siamo-qua.svg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

  }

  #block-intro .imgxl {
    top: auto;
    bottom: 0;
  }
}

/* -------------------------------------------------- */
/*	PAGE ADV SERVICES
/* -------------------------------------------------- */

#primary.site-main.advanced-services {
  .top-header.titolosx {
    background-image: url('../img/advanced/0-elementi-advanced-services-banner.svg');
  }

  #highlights {
    background-image: url('../img/advanced/1-elementi-advanced-services-points.svg');
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .block-cta-file {
    background-color: $sfondo_grigio;
    background-image: url('../img/advanced/5-elementi-advanced-services-engagement.svg');
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .lista-people {
    background-image: url('../img/help/6-elementi-help-center-siamo-qua.svg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

  }
}

/* -------------------------------------------------- */
/*	PAGE PEOPLE
/* -------------------------------------------------- */

#primary.site-main.people {

  .top-header {
    .el-grafico {
      background-image: url('../img/people/0-elementi-people-banner.svg');
      background-size: cover;
      background-position: top right;
      background-repeat: no-repeat;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: -10%;
    }
  }

  #colonnasx {
    background-image: url('../img/people/1-elementi-people-our-values.svg');
    background-size: cover;
    background-position: top center;
    //background-repeat: no-repeat;
    #points {
      background: none;
    }

    .lista-blocchi .item img {
      height: auto;
      padding-bottom: 20px;
      max-height: 245px;
    }
  }


  .block-list {
    background-image: url('../img/people/3-elementi-people-togheter.svg');
    background-size: cover;
    background-position: left;

    .lista-blocchi {
      background-color: transparent;

      .item {
        padding: 20px;
        cursor: pointer;

        .team-name {
          font-weight: bold;
          font-size: 18px;
        }

        .team-people {
          font-family: $font_titoli;
          font-weight: 600;
          font-size: 24px;

          span {
            font-size: 120px;
          }
        }

        .team-position {
          font-family: $font_titoli;
          font-size: 24px;
          font-weight: 600;
          color: $sfondo_cta;
          padding-bottom: 10px;

          span {
            font-size: 40px;
          }

        }

      }

      .item.freccia {
        background-image: url('../img/freccia-people.svg');
        background-position: calc(100% - 20px) 20px;
        background-size: 48px;
        background-repeat: no-repeat;
      }

    }

    .lista-blocchi .item.total {
      background-color: $sfondo_cta;
      cursor: auto;

      .team-position {
        color: #fff;
      }
    }
  }

  .people-item, .owl-carousel .owl-item .people-item {
    cursor: pointer;
  }

  .extendex-list {
    background-image: url('../img/people/4-elementi-people-advanced.svg');
    background-size: contain;
    background-position: top center;
  }

  #support {
    background-image: url('../img/people/5-elementi-people-imagicle-support.svg');
    background-size: contain;
    background-position: top center;
  }

  .blocco-cta-round {
    background-image: url('../img/people/6-elementi-people-growing.svg');
  }

}

/* -------------------------------------------------- */
/*	PAGE SUPPORT
/* -------------------------------------------------- */

#primary.site-main.support {
  .top-header.titolosx {
    background-image: url('../img/support/0-elementi-support-banner-support.svg');
  }

  #highlights {
    background-image: url('../img/support/1-elementi-support-banner-cura.svg');
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
  }

  #blocco-silos {
    background-color: transparent;
  }

  .details {
    background-image: url('../img/support/4-elementi-support-banner-imagiclecare.svg');
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;

    #table-details {
      display: none;
    }
  }

  .roadmap {
    background-image: url('../img/support/5-elementi-support-banner-stagione.svg');
    background-size: cover;
    background-position: left top;
    background-repeat: no-repeat;
  }
}

/* -------------------------------------------------- */
/*	PAGE DOWNLOAD
/* -------------------------------------------------- */

#primary.site-main.download {
  .top-header.titolosx {
    background-image: url('../img/download/01-download-header-sfondo.svg');
    background-position: left;
  }

  #highlights {
    background-image: url('../img/download/02-download-points.svg');
    background-size: contain;
    background-position: center top;
    background-repeat: no-repeat;
    background-color: $sfondo_grigio;
  }

  .double-cta {
    background-color: $sfondo_grigio_scuro;
  }
}

/* -------------------------------------------------- */
/*	PAGE ROADMAP
/* -------------------------------------------------- */

#primary.site-main.roadmap {
  .top-header.titolosx {
    background-image: url('../img/roadmap/01-roadmap-header-sfondo.svg');
    background-position: left;
  }

  .firstcontent {
    background-image: url('../img/roadmap/02-intro.svg');
  }

  .block-cta-round.sx {
    background-color: $sfondo_grigio;
    background-image: none;

    .immagine {
      padding: 0;

      .img-grafico {
        display: none;
      }
    }
  }

  #story {
    background-color: $sfondo_grigio;
    background-image: url('../img/roadmap/04-roadmap.svg');
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;

    .apps {
      padding-top: 40px;
    }

    .lista-needs {
      .item-need {
        background: #fff;
        padding: 20px 30px;
        margin-bottom: 10px;
        border-radius: 35px;
        -webkit-box-shadow: 0px 20px 85px -45px rgba(0, 34, 90, 0.77);
        -moz-box-shadow: 0px 20px 85px -45px rgba(0, 34, 90, 0.77);
        box-shadow: 0px 20px 85px -45px rgba(0, 34, 90, 0.77);
        transition: all .2s ease-in-out;

        .domanda {
          padding-bottom: 0;
          font-weight: bold;
          color: $txt_blu;

          &:after {
            display: inline-block;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            font-family: "Font Awesome 5 Pro";
            font-weight: 400;
            float: right;
          }

        }

        .domanda[aria-expanded='false']::after {
          content: "\f107";
        }

        .domanda[aria-expanded='true']::after {
          content: "\f106";
        }

        .risposta {
          .riga {
            border-bottom: 1px solid $color_grigio_linea;
          }

          padding-top: 40px;

          .el {
            padding: 10px 10px 10px 0;
          }

          .product {
            font-size: 22px;
          }

          .feature {
            font-size: 18;
            padding: 10px 10px 10px 0;

            strong, b, span {
              color: $txt_blu;
              font-size: 22px;
              font-weight: 400;
            }
          }

          .for {
            font-size: 18;
            font-weight: 500;

            ul {
              list-style: none;
              padding: 0;
              margin: 0;
            }

            .cisco {
              color: $txt_blu;
            }

            .teams {
              color: $color_celeste;
            }

            .cross {
              color: $color_rosso;
            }
          }
        }
      }
    }
  }
}

/* -------------------------------------------------- */
/*	PAGE SECURITY
/* -------------------------------------------------- */

#primary.site-main.security {
  .top-header.titolosx {
    background-image: url('../img/security/01-header-sfondo.svg');
    background-position: left;
  }

  .firstcontent {
    background-image: url('../img/roadmap/02-intro.svg');
  }
}

/* -------------------------------------------------- */
/*	PAGE SMART WORKING
/* -------------------------------------------------- */

#primary.site-main.smartworking {

  .top-header.titolosx {
    background-image: url('../img/smartworking/01-working-header.svg');
    background-position: left;
  }

  .firstcontent {
    background-image: url('../img/smartworking/02-working-thenew.svg');
  }

  #prodotti {
    background-image: url('../img/smartworking/03-working-working.svg');
    background-position: center bottom;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: $sfondo_grigio_scuro;
  }

  .lista-blocchi {
    background-color: none;
  }

  #environment {
    background-image: url('../img/smartworking/05-working-build.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: $sfondo_grigio;
  }

  #points.rowpoints {
    background-image: url('../img/smartworking/06-working-why.svg');
    background-color: $sfondo_grigio_scuro !important;
    background-repeat: no-repeat;
  }
}

/* -------------------------------------------------- */
/*	PAGE LEGAL COMPLIANCE
/* -------------------------------------------------- */

#primary.site-main.legal-compliance {
  .top-header.titolosx {
    background-image: url('../img/legalcompliance/01-compliance-header.svg');
    background-position: left;
  }

  #needs {
    .parte-top {
      background-image: url('../img/legalcompliance/02-compliance-certifications.svg');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      background-color: $sfondo_grigio;
    }

    .apps {
      padding-top: 40px;
      clear: both;
    }

    .item-need {
      background: #fff;
      padding: 40px;
      margin-bottom: 10px;
      border-radius: 35px;
      -webkit-box-shadow: 0px 20px 85px -45px rgba(0, 34, 90, 0.77);
      -moz-box-shadow: 0px 20px 85px -45px rgba(0, 34, 90, 0.77);
      box-shadow: 0px 20px 85px -45px rgba(0, 34, 90, 0.77);
      transition: all .2s ease-in-out;
    }

  }

  #points {
    background-image: url('../img/legalcompliance/03-compliance-thesolution.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: $sfondo_grigio;
  }
}

/* -------------------------------------------------- */
/*	PAGE CUSTOMER
/* -------------------------------------------------- */

#primary.site-main.customer {
  .header_review {
    background-color: $sfondo_cta;
    background-image: url('../img/customers/0-elementi-customers-banner.svg');
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;

    .owl-dots {
      padding-top: 20px;
      text-align: left;
      //margin-bottom: 50px;
    }
  }

  .wrapper-contatori {
    background-image: url('../img/customers/1-elementi-customers-almost.svg');
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    background-color: $sfondo_grigio;
  }

  #block-contatori {
    margin-top: 0;

    .wrapper .item.pieno {
      width: auto;
      max-width: 450px;
    }
  }

  #block-clienti {
    h2 {
      display: none;
    }

    .owl-carousel {
      padding-top: 0;
    }
  }

  #teatri {
    padding-top: 0;

    .apps {
      padding-top: 40px;
    }

    .lista-needs {
      .item-need {
        background: #fff;
        padding: 20px 30px;
        margin-bottom: 10px;
        border-radius: 35px;
        -webkit-box-shadow: 0px 20px 85px -45px rgba(0, 34, 90, 0.77);
        -moz-box-shadow: 0px 20px 85px -45px rgba(0, 34, 90, 0.77);
        box-shadow: 0px 20px 85px -45px rgba(0, 34, 90, 0.77);
        transition: all .2s ease-in-out;

        .domanda {
          padding-bottom: 0;
          font-weight: bold;
          color: $txt_blu;

          &:after {
            display: inline-block;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            font-family: "Font Awesome 5 Pro";
            font-weight: 400;
            float: right;
          }

        }

        .domanda[aria-expanded='false']::after {
          content: "\f107";
        }

        .domanda[aria-expanded='true']::after {
          content: "\f106";
        }

        .risposta {
          padding-top: 40px;
          background-repeat: no-repeat;
          background-position: left;
          background-size: contain;
          min-height: 600px;

          ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
              font-size: 21px;
            }
          }

          ul.dividi {
            columns: 2;
          }

          .storia-item {
            cursor: pointer;

            &:hover {
              color: $txt_blu;
            }
          }
        }
      }
    }
  }

  #storie {
    background-image: url('../img/customers/3-elementi-customers-storie.svg');
    background-size: cover;
    background-position: center;
    background-color: $sfondo_grigio;

    .lista {
      .item {
        background-color: #fff;
        margin-bottom: 18px;
        cursor: pointer;

        img {
          border-bottom-left-radius: 200px;
          width: 90%;
          float: right;
          border-top-right-radius: 25px;
        }

        h3, h4 {
          padding: 12px 21px 10px 10%;
        }

      }
    }

    .approfondimento {
      padding-top: 40px;
      display: none;

      .item {
        padding: 0 20px;
        background-color: #fff;

        .sinistra {
          .introimg {
            display: none;
            border-bottom-left-radius: 200px;
            width: 90%;
            float: right;
            border-top-right-radius: 25px;
            width: 45%;
          }

          height: auto;
          color: $txt_blu;
          @include a_style($txt_blu, $txt_blu);
          font-size: 22px;
          font-weight: 400;

          ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
              padding-bottom: 0;
              line-height: 1;
            }
          }

          .logo {
            max-width: 125px;
            height: auto;
            padding-bottom: 20px;
          }

          .settore, .website, .piattaforma {
            font-size: 18px;

          }

        }

        .contenuto {
          height: auto;
          //border-left: 1px solid $color_grigio_bordo;
          .testo {
            font-size: 18px;
            padding-left: 20px;
          }

          .imgbottom {
            display: none;
            padding-top: 20px;
          }
        }
      }
    }
  }

  #points {
    background-image: url('../img/customers/7-elementi-customers-the-solution.svg');
    background-size: cover;
    background-position: left;
  }

}

/* -------------------------------------------------- */
/*	PAGE ABOUT
/* -------------------------------------------------- */

#primary.site-main.about {
  .top-header {
    .el-grafico {
      background-image: url('../img/about/0-elementi-about-banner.svg');
      background-size: cover;
      background-position: top right;
      background-repeat: no-repeat;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }

  .silos-wrapper {
    background-color: $sfondo_grigio-scuro;
  }

  #mission {
    background-image: url('../img/about/2-elementi-about-mission.svg');
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
  }

  #blocco-silos {

    .item {
      padding: 40px 20px;

      img {
        width: 100%;
        height: auto;
      }

      .points {
        margin: 0 -20px -20px;
        padding: 20px;
      }

      .days {
        font-size: 86px;
        padding-top: 20px;
        line-height: 1;
        font-family: $font_titoli;
      }

      .testoinner {
        padding-top: 0;
        font-family: $font_titoli;
      }

      .points .el {
        padding: 10px 0;
        color: $sfondo_cta;
      }
    }

    .parte-top {
      padding-bottom: 30px;
      background-color: $sfondo_grigio;
    }

    .parte-bottom {
      padding-top: 30px;
      background-color: $sfondo_grigio-scuro;
      background-image: url('../img/help/4-elementi-help-center-chiamaci.svg');
      background-position: top;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  .prod-cta {
    @include sfondo-grigio();
    background-color: transparent !important;

    h3 {
      color: $txt_blu;
    }
  }

  #timeline {
    background-image: url('../img/about/3-elementi-about-work-togheter.svg');
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;

    .owl-carousel .owl-stage {
      display: flex;
      align-items: flex-end;
    }

    .owl-item {
      .item {
        .image img {
          height: 100%;
          max-height: 200px;
          width: auto;
          padding: 20px 40px;
          display: inline;
          max-width: inherit;
        }

        .testo {
          padding: 0 40px 20px 40px;
          font-family: $font_titoli;
          font-size: 26px;
          color: $txt_blu;
          direction: initial;
        }

        .anno {
          height: 100px;
          align-items: center;
          display: flex;
          background: $sfondo_blu;
          color: $sfondo_cta;
          font-size: 50px;
          font-family: $font_titoli;
          padding: 0 40px;
          line-height: 1;
          justify-content: flex-end;
        }

      }

    }

    .owl-item.center {
      .anno {
        background: $sfondo_cta;
        color: $txt_blu;
        font-size: 64px;
      }
    }

    .past {
      .owl-item {
        &:first-of-type {
          .item .anno {
            border-radius: 0 50vh 50vh 0;
          }
        }

        .item.ultimo {
          .anno {
            border-radius: 50vh 0 0 50vh;
          }
        }

      }
    }

    .future {
      .owl-item {
        .item {
          .anno {
            justify-content: flex-start;
          }
        }

        &:first-of-type {
          .item .anno {
            border-radius: 50vh 0 0 50vh;
          }
        }

        &:last-of-type {
          .item .anno {
            border-radius: 0 50vh 50vh 0;
          }
        }
      }
    }

  }

  #block-intro {
    background-image: url('../img/help/3-elementi-help-center-ticket.svg');

    .imgxl {
      top: auto;
      bottom: 0;
      max-width: 50%;
    }
  }
}

/* -------------------------------------------------- */
/*	PAGE EMBED
/* -------------------------------------------------- */
body.licenseCloud {
  background-color: transparent;
}

#primary.site-main.embed {

  .form-wrapper {
    background-color: #fff;
    -webkit-box-shadow: 0px 20px 85px -45px rgba(0, 34, 90, 0.77);
    -moz-box-shadow: 0px 20px 85px -45px rgba(0, 34, 90, 0.77);
    box-shadow: 0px 20px 85px -45px rgba(0, 34, 90, 0.77);
    transition: all .2s ease-in-out;

    h3.title, h4.title {
      border-bottom: 3px solid #e9edf3;
      padding: 30px;
    }

    .cloudRegister {
      padding: 30px;
    }

    .cloudLicenseInput {
      padding-bottom: 30px;

      input {
        width: 100%;
      }
    }

    #idCaptchaCheck {
      padding-bottom: 30px;
      display: flex;
      justify-content: center;
    }

    .cloudButton {
      padding-bottom: 30px;

      input {
        width: 100%;
      }
    }
  }
}

/* -------------------------------------------------- */
/*	PAGE LANDING
/* -------------------------------------------------- */

#primary.site-main.landing {
  position: relative;

  .top-landing {
    background-color: $sfondo_grigio_scuro;
    background-image: url('../img/0-elementi-grafici-form-banner.svg');
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .content-landing {
    background-color: $sfondo_grigio;
    background-image: url('../img/1-elementi-grafici-form.svg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .form-wrapper {
    height: 990px;
    background-color: #fff;
    transform: translateY(-300px);
    min-height: 900px;
    -webkit-box-shadow: 0px 20px 85px -45px rgba(0, 34, 90, 0.77);
    -moz-box-shadow: 0px 20px 85px -45px rgba(0, 34, 90, 0.77);
    box-shadow: 0px 20px 85px -45px rgba(0, 34, 90, 0.77);
    transition: all .2s ease-in-out;

    iframe {
      height: 100%;
      width: 100%;
      border-radius: 25px;
    }
  }

  .form-wrapper-gated {
    min-height: auto;
    padding: 40px;
    height: auto;
    background-color: #fff;
  }
}

/* -------------------------------------------------- */
/*	PAGE LANDING WEBINAR
/* -------------------------------------------------- */

#primary.site-main.landing.webinar-single {
  .shareaholic-share-buttons-container {
    display: none;
  }

  .lista-people {
    .item {
      padding-top: 20px;
      padding-bottom: 20px;
      margin-top: -1px;
      border-top: 1px solid $color_grigio_linea;
      border-bottom: 1px solid $color_grigio_linea;

      img {
        max-width: 100px;
        margin-right: 10px;
      }
    }

    .inner {
      .name {
        font-size: 20px;
        padding-bottom: 5px;
      }

      .role {
        font-size: 16px;
        padding-bottom: 10px;
      }
    }
  }
}

/* -------------------------------------------------- */
/*	PAGE CONTATTI
/* -------------------------------------------------- */

#primary.site-main.contacts {
  #blocco-silos {
    padding-top: 0;

    .intestazione {
      text-align: left;
    }
  }
}

/* -------------------------------------------------- */
/*	PAGE ACTIVATE
/* -------------------------------------------------- */

#primary.site-main.activate {
  .top-header.titolosx {
    background-image: url('../img/activate/0-elementi-grafici-activate-banner.svg');

  }

  #highlights {
    background-color: $sfondo_grigio;
    background-image: url('../img/activate/1-elementi-grafici-activate-sed-ut.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

/* -------------------------------------------------- */
/*	PAGE KB
/* -------------------------------------------------- */

#primary.site-main.kb {

  #prodotti {

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      padding-top: 30px;

      a {
        //font-weight: 300;
        padding-bottom: 5px;
      }
    }

  }

  #featured {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      columns: 3;

      li {
        padding-bottom: 10px;
      }
    }
  }
}

/* -------------------------------------------------- */
/*	PAGE KB Result
/* -------------------------------------------------- */

#primary.site-main.kb-result {

  //background-color: $sfondo_grigio;
  //background-image: url('../img/sfondo-legal.svg');
  //background-position: center top;
  //background-size: cover;
  //background-repeat: no-repeat;

  iframe {
    width: 100%;
    border: 0;
    background: #fff;
    padding: 0;
    height: calc(100vh - 106px);
  }
}

.otgs-development-site-front-end {
  display: none;
}

/* -------------------------------------------------- */
/*	PAGE LEGAL
/* -------------------------------------------------- */

#primary.site-main.legal {
  background-color: $sfondo_grigio;
  background-image: url('../img/sfondo-legal.svg');
  background-size: contain;
  background-position: top left;
  background-repeat: repeat-y;

  .mod {
    font-size: 15px;
    color: $txt_blu;
    padding-bottom: 10px;
  }

  .sottomenu {
    padding-top: 50px;

    .active {
      font-weight: bold;
      color: $txt_blu;
    }

    @include a_style($txt_blu, $txt_blu);

    li {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .navbar-nav {
      margin-left: 0;
    }

    .navbar-toggler {
      border: 0;
    }

    button.navbar-toggler:focus  {
      outline: none;
    }

    .navbar-brand {
      font-weight: bold;
    }
  }

  .inner {
    padding: 40px;
  }
}

/* -------------------------------------------------- */
/*	PAGE Self-Service Demo
/* -------------------------------------------------- */

#primary.site-main.legal.self-service {
  background-image: url('../img/0-elementi-grafici-demo-banner.svg');
  background-repeat: no-repeat;
}

/* -------------------------------------------------- */
/*	PAGE SINGLE SOLUION
/* -------------------------------------------------- */

#primary.site-main.solution {
  .top-header.titolosx {
    background-image: url('../img/vertical/01-Sfondi-VerticalGovernement-Banner.svg');
    background-position: left;
  }

  .firstcontent {
    background-image: url('../img/vertical/02-Sfondi-VerticalGovernement-intro.svg');
  }

  #vertical {
    .icona {
      text-align: center;
      padding-right: 18px;

      img {
        max-width: 212px;
      }
    }

    .lista-blocchi {
      .titolo1 {
        font-size: 90px;
      }
    }

    .lista-blocchi {
      background: transparent;
    }

    .info-grafica {
      background-image: url('../img/vertical/Sfondo-VerticalGovernment-Infografica.svg');
      background-position: left top;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  #highlights {
    background-image: url('../img/vertical/06-Sfondi-VerticalGovernement-Storie.svg');
    background-position: center top;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .apps .griglia {
    justify-content: center;
  }

  .silos-expand {
    h3 {
      border-bottom: 0;
    }
  }

  #points.rowpoints .container:last-of-type {
    border-bottom: 1px solid #c6cbd1;
  }
}

/* -------------------------------------------------- */
/*	PAGE ALL INTEGRATIONS
/* -------------------------------------------------- */

#primary.site-main.integrations {

  .top-header.titolosx {
    background-image: url('../img/integrations/00-MaterialiGrafici-PaginaIntegrations-Banner.svg');
    background-position: left;
  }

  .firstcontent {
    background-image: url('../img/integrations/01-MaterialiGrafici-PaginaIntegrations-intro.svg');
  }

  .integrazioni {
    background-color: transparent;
    background-image: none;
  }

  .integrations-wrapper {
    background-color: $sfondo_grigio;
    background-image: url('../img/integrations/02-MaterialiGrafici-PaginaIntegrations-Calling.svg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  #points {
    background-image: url('../img/integrations/03-MaterialiGrafici-PaginaIntegrations-API.svg');
    background-position: center top;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

/* -------------------------------------------------- */
/*	PAGE SINGLE INTEGRATION
/* -------------------------------------------------- */

#primary.site-main.integration {
  .top-header.titolosx {
    background-image: url('../img/integrations/00-MaterialiGrafici-PaginaIntegrations-Banner.svg');
    background-position: left;
  }

  .wrapper-intro {
    background-color: $sfondo_grigio;
    background-image: url('../img/integrations/1-single-integrations-intro.svg');
    background-position: center top;
    background-size: contain;
    background-repeat: no-repeat;

  }

  .integrazioni {
    background-image: url('../img/integrations/2-single-integrations-integration.svg');
    background-position: bottom;
  }

  .integrazioni.n2 {
    background-image: url('../img/integrations/4-single-integrations-integ2.svg');
    background-position: top;
  }

  #blocco-silos {
    background-image: url('../img/integrations/5-single-integrations-software.svg');
    background-position: center top;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .software {
    background-color: $sfondo_grigio_scuro;
    background-image: url('../img/integrations/6-single-integrations-activate.svg');
    background-position: center top;
    background-size: contain;
    background-repeat: no-repeat;

    .block-cta-round.dx {
      background-image: none;
      background-color: transparent;

      .immagine .img-grafico {
        background-color: #fff;
      }
    }
  }

  .tabella-wrapper {
    .blocco {
      padding: 30px 0 0 0;
    }

    .tabella {
      padding: 0;
    }
  }

}

/* -------------------------------------------------- */
/*	PAGE AR: SALES TOOLS
/* -------------------------------------------------- */

#primary.site-main.sales-tools, #primary.site-main.reserved-download {
  .primaria img {
    padding-right: 20px;
  }

  .files {
    font-size: 160%;

    i {
      padding: 0 5px;
    }
  }

  .card {
    .intestazione {
      display: inline-flex;
    }

    .intestazione a {
      &:after {
        padding-left: 30px;
        display: inline-block;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        font-family: "Font Awesome 5 Pro";
        font-weight: 400;
        float: right;
      }

    }

    .intestazione a[aria-expanded='false']::after {
      content: "\f107";
    }

    .intestazione a[aria-expanded='true']::after {
      content: "\f106";
    }
  }

}

#primary.site-main.reserved-download {
  .accordion > .card {
    border: 0;
    border: 0;
    margin-bottom: 40px;
    border-radius: 25px !important;
    padding: 40px;
  }

  .card-body {
    padding: 1.25rem 0;
  }


}

/* -------------------------------------------------- */
/*	PAGE AR: VULNERABILITY FIX
/* -------------------------------------------------- */

#primary.site-main.vulnerability-fix {
  .wrapper-fix {
    padding: 40px;

  }

  select {
    color: $txt_blu;
  }
}

/* -------------------------------------------------- */
/*	PAGE SEARCH
/* -------------------------------------------------- */

#primary.site-main.search-result {
  #blocco-ricerca {
    background-color: $sfondo-grigio;
    background-image: url('../img/top-solotxt.svg');
    background-size: cover;
    background-position: center top;

    .search-submit {
      display: none;
    }
  }

  .results {
    background-image: url('../img/blog/single-blog-sfondo.svg');
    background-size: cover;
    background-position: left top;
    background-repeat: no-repeat;

    .result-item {
      h6 {
        font-size: 100%;
        color: $txt-blu;
        padding-bottom: 0;
        margin-bottom: 0;
        margin-top: 20px;
      }

      padding-top: 40px;
      padding-bottom: 40px;
      border-bottom: 1px solid $color_grigio_linea;

      img {
        max-height: 300px;
        width: auto;
      }
    }

    .wp-pagenavi {
      padding-top: 50px;
      padding-bottom: 0;
    }
  }
}

/* -------------------------------------------------- */
/*	PAGE WEBEX
/* -------------------------------------------------- */

#primary.site-main.page-webex {
  .subhead {
    color: $color_nero;
  }

  .rowpoints {
    .testo {
      font-size: 22px;
    }
  }

  .apps {
    .griglia {
      display: flex;
      flex-wrap: wrap;

      .item {
        display: flex;
        flex-grow: 1;
        flex-basis: 212px;
        margin-bottom: 18px;
        max-width: 234px;


        .cerchio {
          cursor: pointer;
          display: flex;
          align-items: center;
          width: 212px;
          height: 80px;
          max-width: 212px;
          max-height: 80px;
          border-radius: 25px;
          background-color: $sfondo_grigio;
          padding: 10px;
          border: 1px solid $sfondo_grigio;
          transform: translateY(0);
          transition: transform 0.5s, box-shadow .2s ease-in-out;
          //transition: all .2s ease-in-out;
          .icona {
            width: 60px;
            height: 60px;
            margin-right: 20px;
          }

          .nomeapp {
            font-size: 18px;
            font-weight: 300;
            padding: 0;
            margin: 0;
          }

          &:hover {
            -webkit-transform: translateY(-5px);
            -ms-transform: translateY(-5px);
            transform: translateY(-5px);
            -webkit-box-shadow: 0 15px 35px -15px rgba(0, 34, 90, 0.77);
            -moz-box-shadow: 0 15px 35px -15px rgba(0, 34, 90, 0.77);
            box-shadow: 0 15px 35px -15px rgba(0, 34, 90, 0.77);
            //transition: all .2s ease-in-out;
          }
        }

        .cerchio.dis {
          opacity: 0.65;
        }

      }
    }
  }

  .cta-webex-inner {
    border-radius: 25px;
    border: 2px solid #818181;
    padding: 0 5%;

    .inner {
      padding: 40px;
    }
  }

  .rowpoints {
    .rowpoints-element {
      border-bottom: 2px solid #818181;

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  .calltoaction {
    background: $color_nero;
    background-image: url("../img/sfondo_cta_nero.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .cerchio {
      display: none;
    }

    .titolo2 {
      color: #fff;
    }

    .titolo5 {
      color: #818181;
    }

    a.pulsante {
      @include button_style(transparent, #fff, #fff, $color_nero, #fff, #fff);
    }

    .immagine {
      background-size: contain;
      border-radius: 0;
    }
  }
}

.page-template-page-reserved-internal .navbar-reserved {
  display: none;
}

/* -------------------------------------------------- */
/*	PAGE MS
/* -------------------------------------------------- */

#primary.site-main.page-ms {
  .subhead {
    color: $color_nero;
  }

  .firstcontent {
    background-color: $color_grigio_scuro;
  }

  .rowpoints {
    .testo {
      font-size: 22px;
    }
  }

  .apps {
    .griglia {
      display: flex;
      flex-wrap: wrap;

      .item {
        display: flex;
        flex-grow: 1;
        flex-basis: 212px;
        margin-bottom: 18px;
        max-width: 234px;


        .cerchio {
          cursor: pointer;
          display: flex;
          align-items: center;
          width: 212px;
          height: 80px;
          max-width: 212px;
          max-height: 80px;
          border-radius: 25px;
          background-color: $sfondo_grigio;
          padding: 10px;
          border: 1px solid $sfondo_grigio;
          transform: translateY(0);
          transition: transform 0.5s, box-shadow .2s ease-in-out;
          //transition: all .2s ease-in-out;
          .icona {
            width: 60px;
            height: 60px;
            margin-right: 20px;
          }

          .nomeapp {
            font-size: 18px;
            font-weight: 300;
            padding: 0;
            margin: 0;
          }

          &:hover {
            -webkit-transform: translateY(-5px);
            -ms-transform: translateY(-5px);
            transform: translateY(-5px);
            -webkit-box-shadow: 0 15px 35px -15px rgba(0, 34, 90, 0.77);
            -moz-box-shadow: 0 15px 35px -15px rgba(0, 34, 90, 0.77);
            box-shadow: 0 15px 35px -15px rgba(0, 34, 90, 0.77);
            //transition: all .2s ease-in-out;
          }
        }

        .cerchio.dis {
          opacity: 0.65;
        }

      }
    }
  }

  .cta-webex-inner {
    border-radius: 25px;
    @include sfondo-viola;
    border: 2px solid $color_viola_ms;
    padding: 0 5%;

    .inner {
      padding: 40px;
    }
  }

  .rowpoints {
    .rowpoints-element {
      border-bottom: 2px solid #ffffff;

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  .calltoaction {
    @include sfondo-viola;
    background-color: #ffffff;
    background-image: url("../img/sfondo_cta_viola.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .cerchio {
      display: none;
    }

    .immagine {
      background-size: contain;
      border-radius: 0;
    }
  }
}

/* -------------------------------------------------- */
/*	PAGE EVENTO
/* -------------------------------------------------- */

#primary.site-main.prodotto.suite.page-evento {
  .apps {
    .griglia {
      display: flex;
      flex-wrap: wrap;

      .item {
        display: flex;
        flex-grow: 1;
        flex-basis: 212px;
        margin-bottom: 18px;
        max-width: 234px;


        .cerchio {
          cursor: pointer;
          display: flex;
          align-items: center;
          width: 212px;
          height: 80px;
          max-width: 212px;
          max-height: 80px;
          border-radius: 25px;
          background-color: $sfondo_grigio;
          padding: 10px;
          border: 1px solid $sfondo_grigio;
          transform: translateY(0);
          transition: transform 0.5s, box-shadow .2s ease-in-out;
          //transition: all .2s ease-in-out;
          .icona {
            width: 60px;
            height: 60px;
            margin-right: 20px;
          }

          .nomeapp {
            font-size: 18px;
            font-weight: 300;
            padding: 0;
            margin: 0;
          }

          &:hover {
            -webkit-transform: translateY(-5px);
            -ms-transform: translateY(-5px);
            transform: translateY(-5px);
            -webkit-box-shadow: 0 15px 35px -15px rgba(0, 34, 90, 0.77);
            -moz-box-shadow: 0 15px 35px -15px rgba(0, 34, 90, 0.77);
            box-shadow: 0 15px 35px -15px rgba(0, 34, 90, 0.77);
            //transition: all .2s ease-in-out;
          }
        }

        .cerchio.dis {
          opacity: 0.65;
        }

      }
    }
  }

}

/* -------------------------------------------------- */
/*	PAGE 404
/* -------------------------------------------------- */

#primary.site-main.pagina404 {
  background-color: $sfondo-grigio;
  background-image: url('../img/404-sfondo.svg');
  background-size: cover;
  background-position: center;

  .search-submit {
    display: none;
  }

  .search-field {
    background-color: #fff;
  }
}

/**** Release ****/


#primary.site-main.blog-single .header-release .blocco-middle {
  position: relative;
  margin-top: calc(25vh - 32px);

}

.cpt_release-template-default .pattern-news {
  padding-bottom: 15px;
}


@media (max-width: 991px) {
  #primary.site-main.blog-single .header-release {
    background-position: 100% 100%;
    background-size: 200%;
    padding-top: 50px;
    min-height: 60vh;
  }

  #primary.site-main.blog-single .header-release .contenuto {
    text-align: center;
  }

  #primary.site-main.blog-single .header-release .dett-cta-gray {
    padding-bottom: 0px !important;
  }

  #primary.site-main.blog-single .header-release .dett-cta-gray .row.justify-content-center {
    margin-bottom: 0px !important;
  }
}

/** LANDING **/
#primary.site-main.landing-2023 {
  .cross-landing {
    position: fixed;
    left: 0;
    top: 140px;
    height: calc(100vh - 200px);
    width: 100%;
    z-index: 1;
  }

  .top-landing {
    background-image: url("https://www.imagicle.com/wp-content/themes/imagicle/assets/img/landing/landing_header.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left;
  }

  .content-landing {
    background-image: url("https://www.imagicle.com/wp-content/themes/imagicle/assets/img/landing/landing_blu.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  #review {
    background-image: url("https://www.imagicle.com/wp-content/themes/imagicle/assets/img/landing/landing_bianco.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .top-review {
      background: none;
    }
  }

  .lista-blocchi {
    background-image: url("https://www.imagicle.com/wp-content/themes/imagicle/assets/img/landing/landing_grigio.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}

#primary.site-main.landing-2023-sticky {
  background-image: url("https://www.imagicle.com/wp-content/themes/imagicle/assets/img/landing/sfondo-sticky.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;

  .landing-header {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 900px;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  .landing-header.form-open {
    height: 990px;

  }

  .landing-header-img {
    height: 100%;
    object-fit: cover;
    position: static;
    top: 0%;
    bottom: auto;
    left: 0%;
    right: auto;
    width: 100%;
  }

  .certificazioni {
    img {
      height: fit-content;
      max-height: 150px;
      width: auto;
    }
  }

  .review {
    color: $txt_blu;

    .citazione {
      font-size: 32px;
      font-family: Gotham Rounded;
    }

    .name {
      font-size: 18px;
      font-weight: 700;
      padding-top: 30px;
    }

    .role {
      font-size: 18px;
    }
  }
}

.landing-form {
  width: 100%;
  max-width: 450px;
  background-color: #fff;
  border-radius: 30px;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  transition: 1s;
  -webkit-box-shadow: 0px 10px 13px -7px #000000, -32px 27px 15px -1px rgba(0, 0, 0, 0);
  box-shadow: 0px 10px 13px -7px #000000, -32px 27px 15px -1px rgba(0, 0, 0, 0);

  @include sfondo-bianco();

  .titolo-form {
    border-bottom: 4px solid $sfondo_grigio;
    padding: 30px 35px 20px 35px;
  }

  .notes {
    font-size: 80%;
    color: $color_grigio_testi;
    padding-top: 20px;
  }


  #demo_form {
    padding: 30px 35px;
  }

  .hidden-input {
    display: none;
  }

  .input, .text, .select, input[type="text"], input[type="email"], select, texarea {
    display: block;
    width: 100%;
    height: 100%;
    outline-offset: 0px;
    background-color: rgba(255, 255, 255, 0);
    border: 2px #000;
    border-radius: 27px;
    outline: 3px #333;
    //margin-bottom: 0;
    padding: 13px 20px 0;
    font-size: 17px;
    z-index: 4;
  }

  .input.select, .select, select {
    width: 90%;
    padding: 5px 20px 0;
  }

  input[type=submit] {
    //padding: 17px 30px;
  }

  .form-field {
    z-index: 1000;
    color: #000;
    padding-bottom: 12px;
    position: relative;
  }

  .form-field:hover {
    z-index: 15;
    position: relative;
  }

  .input-wrapper {
    height: 56px;
    border: 2px solid $txt_blu;
    border-radius: 27px;
    justify-content: start;
    align-items: center;
    display: flex;
    position: relative;
    width: 100%;
  }

  .input-wrapper.textarea {
    height: 100px;
  }

  .checkbox-wrapper {
    display: grid;
    grid-template-columns: 20px 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 5px;
    grid-row-gap: 0px;
    align-items: start;
    color: $txt_blu;
    font-size: 80%;
  }

  .checkbox-label {
    margin-bottom: 0;
  }

  .input-wrapper.textarea {
    height: auto;
    padding-top: 0;
  }

  .input-label {
    z-index: 3;
    color: #737b99;
    margin-bottom: 0;
    font-size: 17px;
    font-weight: 400;
    line-height: 1;
    transition: all .2s;
    position: absolute;
    top: 18px;
    bottom: auto;
    left: 20px;
    right: auto;
    display: block;
  }


  .input-wrapper:focus-within > .input-label, .input-wrapper.has-data-entered > .input-label {
    color: #474f70;
    font-size: 13px;
    top: 9px;
  }

  .input:focus, .select:focus {
    border-color: #3898ec;
    outline: 0;
  }

  textarea.input, textarea.select {
    height: auto;
  }

  .input::-ms-input-placeholder {
    color: rgba(255, 255, 255, 0);
  }

  .input::placeholder {
    color: rgba(255, 255, 255, 0);
  }

  .input.textarea {
    max-height: 400px;
    min-height: 100px;
    margin-bottom: 0;
    padding-top: 23px;
  }

  .high-zindex {
    z-index: 2000 !important;
  }
}

.landing-form.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 110px;
  bottom: 30px;
}

/* -------------------------------------------------- */
/*	PAGE ENGAGE-OFFERTE
/* -------------------------------------------------- */

#primary.site-main.prodotto.engage .tab-content {
  margin-top: 0px;
}

#primary.site-main.prodotto.engage .lista-blocchi {
  background-color: #fff;
}

#primary.site-main.prodotto.engage .firstcontent {
  padding-bottom: 0;
}

#primary.site-main.prodotto.engage .top-header.titolosx.sfondo-grigio {
  background-image: url("../img/engage_banner_sfondo.svg");
}

#primary.site-main.prodotto.engage-child .top-header.titolosx.sfondo-celeste {
  background-image: url("../img/engage_generica_banner_sfondo.svg");
}

#primary.site-main.prodotto.engage .dettagli_cta .row {
  border-bottom: 2px solid #fff;
}

#primary.site-main.prodotto.engage .dettagli-accordion {
  border-bottom: 2px solid #fff;
}

/* -------------------------------------------------- */
/*	PAGE PACKAGE
/* -------------------------------------------------- */

#primary.site-main.sigle-packages {
  .wrapper-intro {
    background-color: $sfondo_grigio_scuro;
    background-image: url("../img/packages/00-sfondo header@2x.png");
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;

    .firstcontent {
      background-color: #fff;

      /*.col {
        text-align: left !important;
      }*/
    }
  }

  .apps .griglia {
    justify-content: center;

    .item .cerchio {
      background-color: $sfondo_grigio;
    }
  }

  .area-prodotti {
    background-image: url("../img/packages/01-sfondo@2x.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  #points {
    background-image: url("../img/packages/03-sfondo@2x.png");
    background-position: center left;
    background-repeat: no-repeat;
    background-size: contain;
  }

}

/* -------------------------------------------------- */
/*	PAGE HIGHLIGHTS
/* -------------------------------------------------- */

#primary.site-main.highlights {
  .apps .griglia {
    .item .cerchio {
      background-color: $sfondo_grigio;
    }
  }

  .pattern-news {
    padding-bottom: 0;

    .griglia {
      max-width: 100%;
    }
  }

  .rowpoints .rowpoints-element {
    border-bottom: 2px solid #fff;
  }

  .rowpoints .rowpoints-element:last-of-type {
    border-bottom: 0;
  }
}
