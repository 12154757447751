/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 7-mag-2021, 10.34.43
    Author     : veronica
*/


.tabella {
  padding: 70px 50px;

  .row {
    border-bottom: 1px solid $color_grigio_linea;
  }

  background: #fff;

  .grouptitle {
    color: $txt_blu;
    padding: 30px 0 10px 0;
    font-size: $size_h6;
    font-weight: 500;
  }

  .cellath {
    padding: 10px 0;
  }

  .td {
    padding: 10px 10px 10px 0;
    //border-left: 1px solid $sfondo_grigio;
    color: $txt_blu;
  }

  .th {
    padding: 10px 10px 10px 0;
    color: $txt_blu;
    font-family: $font_titoli;
    font-size: 24px;
  }

  .evidenza {
    background-color: rgba(233, 237, 243, 0.2);
  }
}
.sfondo-bianco .tabella {
  .row {
    border-bottom: 1px solid #fff;
  }
  background-color: $sfondo_grigio;
  .evidenza {
    background-color: $sfondo_grigio_scuro
  }
}

.tabella.img {
  padding-top: 0;

  .row-img {
    border-bottom: 0 !important;

    .th-img {
      img {
        max-height: 52px;
      }
    }
  }
}

#page.site table, .tablepress {
  border: 0;
  border-top: 1px solid $color_grigio_linea !important;
  width: 100%;

  tr {
    border-bottom: 1px solid $color_grigio_linea !important;
  }

  tr.no-border {
    border-bottom: 0;
  }

  th {
    padding: 10px 10px 10px 0;
    color: $txt_blu;
    font-family: $font_titoli;
    font-size: 24px;
  }

  td {
    padding: 20px 10px 20px 0 !important;

    .titolo {
      padding-bottom: 0;
    }
  }

  .sfondocta {
    background-color: $sfondo_cta;
  }

  .evidenza {
    background-color: rgba(233, 237, 243, 0.2);
  }
}

#page.site table.no-border {
  border-top: 0;
}

.tablepress > :where(thead,tfoot) > * > th {
  padding: 10px 10px 10px 0 !important;
  color: $txt_blu !important;
  font-family: $font_titoli !important;
  font-size: 24px !important;
  background: none !important;
}

#page.site table.highlightc1 {
  td:nth-child(1) {
    background-color: rgba(233, 237, 243, 0.2);
  }
}

#page.site table.highlightc2 {
  td:nth-child(2) {
    background-color: rgba(233, 237, 243, 0.2);
  }
}

#page.site table.highlightc3 {
  td:nth-child(3) {
    background-color: rgba(233, 237, 243, 0.2);
  }
}

#page.site table.highlightc4 {
  td:nth-child(4) {
    background-color: rgba(233, 237, 243, 0.2);
  }
}

#page.site table.highlightc5 {
  td:nth-child(5) {
    background-color: rgba(233, 237, 243, 0.2);
  }
}

#page.site table.highlightc6 {
  td:nth-child(6) {
    background-color: rgba(233, 237, 243, 0.2);
  }
}

#page.site table.centerc1 {
  td:nth-child(1) {
    text-align: center;
  }
}

#page.site table.centerc2 {
  td:nth-child(2) {
    text-align: center;
  }
}

#page.site table.centerc3 {
  td:nth-child(3) {
    text-align: center;
  }
}

#page.site table.centerc4 {
  td:nth-child(4) {
    text-align: center;
  }
}

#page.site table.centerc5 {
  td:nth-child(5) {
    text-align: center;
  }
}

#page.site table.centerc6 {
  td:nth-child(6) {
    text-align: center;
  }
}

#page.site table.compare {
  th {
    text-align: center;
  }
  th:nth-child(1) {
    text-align: left;
  }
  td {
    text-align: center;
  }
  td:nth-child(1) {
    font-weight: bold;
    text-align: left;
  }
}

/*.row-img-wrapper {
    background: #fff;
    border-top-left-radius:25px;
    border-top-right-radius:25px;
    .th-img {
        img {
            max-height: 52px;
        }
        &:last-of-type {
            border-top-right-radius:25px;
        }
    }

}*/
/*
    MOBILE
*/

@media (max-width: 1199px) {
  // lg


}


@media (max-width: 992px) {
  // lg - fine
}

@media (max-width: 991px) {
  //md
  .tabella {
    .evidenza {
      background-color: none;
    }

    .cellath {
      color: $txt_blu;
    }

    .td {
      color: $color_testo;

      .intestazione img {
        width: 30px;
        height: auto;
      }
    }

  }

}


@media (max-width: 768px) {
  //md-fine
}

@media (max-width: 767px) {
  //sm

}

@media (max-width: 575px) {
  // xs
  .tabella {
    .grouptitle {
      &:after {
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        font-family: "Font Awesome 5 Pro";
        font-weight: 300;
        content: "\f107";
        float: right;
        font-size: 28px;
      }
    }

    .grouptitle.open {
      &:after {
        content: "\f106";
      }
    }

    .groupcontent {
      display: none;
    }

    .groupcontent.open {
      display: flex;
    }
  }
}

@media (max-width: 480px) {

}

@media (max-width: 420px) {

}

@media (max-width: 375px) {
  //iphone 6/7/8

}
