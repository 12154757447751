/*!
 * Bootstrap
 */
@import "bootstrap-4.5.2/scss/bootstrap";
@import "bootstrap-4.5.2/scss/bootstrap-grid";
@import "bootstrap-4.5.2/scss/bootstrap-reboot";

/*!
 * Valnan v3.0
 */
@import "valnan/font";
@import "valnan/variables";
@import "valnan/minix";
@import "valnan/common";
@import "valnan/tabella";
@import "valnan/vendor";
@import "valnan/cliente";
@import "valnan/blog";
@import "valnan/news";
@import "valnan/product";
@import "valnan/suite";
@import "valnan/menu";
@import "valnan/mobile";