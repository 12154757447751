/*
    Created on : 31-ago-2020, 10.04.40
    Author     : veronica
*/


@mixin fontize($thefont:$font_base) {
  font-family: $thefont;
}

@mixin titolize($size_h1, $size_h2, $size_h3, $size_h4, $size_h5, $size_h6) {
  h1, .titolo1 {
    font-size: $size_h1;
    font-weight: 500;
    line-height: 1;
    padding-bottom: 15px;
  }

  h2, .titolo2 {
    font-size: $size_h2;
    font-weight: 700;
    line-height: 1.15;
    padding-bottom: 20px;
  }

  h3, .titolo3 {
    font-size: $size_h3;
    font-weight: 700;
    line-height: 1.3;
  }

  h4, .titolo4 {
    font-size: $size_h4;
    font-weight: 700;
    line-height: 1.3;
  }

  h5, .titolo5 {
    font-size: $size_h5;
    font-weight: 500;
    line-height: 1.3;
  }

  h6, .sottotitolo {
    font-size: $size_h6;
    font-weight: 450;
    line-height: 1.3;
  }
  .subhead {
    font-size: $size_h3;
    line-height: 1.3;
  }
}

@mixin spaziatorize($spaziatore) {

  /* INSERISCO IL PADDING COME DA SPAZIATORE */

  .blocco {
    padding-top: $spaziatore;
    padding-bottom: $spaziatore;
  }

  .blocco-top {
    padding-top: $spaziatore;
  }

  .blocco-bottom {
    padding-bottom: $spaziatore;
  }

  /* INSERISCO IL PADDING COME DA SPAZIATORE 2/3 */

  .blocco-m {
    padding-top: calc(#{($spaziatore / 3) * 2});
    padding-bottom: calc(#{($spaziatore / 3) * 2});
  }

  .blocco-m-t {
    padding-top: calc(#{($spaziatore / 3) * 2});
  }

  .blocco-m-b {
    padding-bottom: calc(#{($spaziatore / 3) * 2});
  }

  .blocco-s {
    padding-top: calc(#{$spaziatore / 2});
    padding-bottom: calc(#{$spaziatore / 2});
  }

  .blocco-s-t {
    padding-top: calc(#{$spaziatore / 2});
  }

  .blocco-s-b {
    padding-bottom: calc(#{$spaziatore / 2});
  }

  /* INSERISCO IL MARGINE COME DA SPAZIATORE */

  .margini {
    margin-top: $spaziatore;
    margin-bottom: $spaziatore;
  }

  .margine-top {
    margin-top: $spaziatore;
  }

  .margine-bottom {
    margin-bottom: $spaziatore;
  }

  .margini-m {
    margin-top: calc(#{($spaziatore / 3) * 2});
    margin-bottom: calc(#{($spaziatore / 3) * 2});
  }

  .margine-m-t {
    margin-top: calc(#{($spaziatore / 3) * 2});
  }

  .margine-m-b {
    margin-bottom: calc(#{($spaziatore / 3) * 2});
  }

  .margini-s {
    margin-top: calc(#{$spaziatore / 2});
    margin-bottom: calc(#{$spaziatore / 2});
  }

  .margine-s-t {
    margin-top: calc(#{$spaziatore / 2});
  }

  .margine-s-b {
    margin-bottom: calc(#{$spaziatore / 2});
  }
}

@mixin a_style($the_color:$color_link, $over_color:$color_link_over) {
  a {
    color: $the_color;
    @include trans-fx;
    text-decoration: none;

    &:focus {
      outline: 0;
    }

    &:visited {
      color: $the_color;
    }

    &:hover {
      color: $over_color;
      text-decoration: underline;
    }
  }
}

@mixin a_style_link($the_color:$color_testo, $over_color:$color_rosso) {
  a.link {
    font-weight: 800;
    color: $the_color;
    @include trans-fx;
    text-decoration: none;
    display: inline-block;
    font-size: 88%;
    text-transform: uppercase;

    &:focus {
      outline: 0;
    }

    &:visited {
      color: $the_color;
    }

    &:hover {
      color: $over_color;
      text-decoration: none;

      &:after {
        padding-left: 20px;
      }
    }

    &:after {
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      font-family: "Font Awesome 5 Pro";
      font-weight: 900;
      content: "\f061";
      padding-left: 10px;
      transition: all .4s ease-in-out;

    }
  }
}

@mixin a_style_link_prev($the_color:$color_testo, $over_color:$color_rosso) {
  a.link-prev {
    font-weight: 800;
    color: $the_color;
    @include trans-fx;
    text-decoration: none;
    display: inline-block;
    font-size: 88%;
    text-transform: uppercase;

    &:focus {
      outline: 0;
    }

    &:visited {
      color: $the_color;
    }

    &:hover {
      color: $over_color;
      text-decoration: none;

      &:before {
        padding-right: 20px;
      }
    }

    &:before {
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      font-family: "Font Awesome 5 Pro";
      font-weight: 900;
      content: "\f060";
      padding-right: 10px;
      transition: all .4s ease-in-out;

    }
  }
}

@mixin button_style($background, $background_over, $color, $color_over, $border, $border_over) {
  position: relative;
  border-radius: 25px;
  background: $background;
  color: $color;
  border: 2px solid $border;
  box-shadow: none;
  text-shadow: none;
  text-decoration: none !important;

  vertical-align: middle;
  display: inline-block;

  width: auto;
  margin: 0;
  font-size: 88%;
  font-weight: 700;
  text-transform: uppercase;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: color .4s ease-in-out, background-color .4s ease-in-out, border-color .4s ease-in-out;
  -o-transition: color .4s ease-in-out, background-color .4s ease-in-out, border-color .4s ease-in-out;
  transition: color .4s ease-in-out, background-color .4s ease-in-out, border-color .4s ease-in-out;
  padding: 10px 30px;
  cursor: pointer;
  min-width: 155px;
  text-align: center;
  &:visited {
    color: $color;
  }
  &:hover {
    font-weight: 700;
    background: $background_over;
    color: $color_over;
    border-color: $border_over;
    box-shadow: none;
  }
}

@mixin input_style($background, $background_over, $color, $color_over, $border, $border_over) {
  .wpcf7-form, .search-form, .classic-form {
    input[type="text"], input[type="email"], input[type="confirm_email"], input[type="url"], input[type="password"], input[type="search"], input[type="number"], input[type="tel"], input[type="range"],
    input[type="date"], input[type="month"], input[type="week"], input[type="time"], input[type="datetime"], input[type="datetime-local"], input[type="color"], input[type="file"], textarea {
      background-color: $background;
      color: $color;
      border: 1px solid $border;

      &:focus {
        background-color: $background_over;
        color: $color_over;
        border: 1px solid $border_over;
      }
    }
  }
}

@mixin placeholder($color) {
  input::-webkit-input-placeholder {
    color: $color !important;
  }
  /* Chrome/Opera/Safari */
  input::-moz-placeholder {
    color: $color !important;
    opacity: 1;
  }
  /* Firefox 19+ */
  input:-ms-input-placeholder {
    color: $color !important
  }
  /* IE 10+ */
  input:-moz-placeholder { /* Firefox 18- */
    color: $color !important;
  }
}

@mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@mixin vertical-align-center($position: relative) {
  position: $position;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@mixin remove-vertical-align-center() {
  top: inherit;
  left: inherit;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

@mixin trans-fx($time: 200ms) {
  transition-timing-function: ease-in-out;
  transition-duration: $time;
  -webkit-transition-duration: $time;
  -moz-transition-duration: $time;
  -ms-transition-duration: $time;
  -o-transition-duration: $time;
}


@mixin curva_style($the_size) {
  .curva-tl {
    border-top-left-radius: $the_size;
  }
  .curva-tr {
    border-top-right-radius: $the_size;
  }
  .curva-br {
    border-bottom-right-radius: $the_size;
  }
  .curva-bl {
    border-bottom-left-radius: $the_size;
  }
}


@mixin sfondo-grigio() {
  background-color: $sfondo_grigio;
  color: $color_testo;
  .subhead {
    color: $txt_blu;
  }
}

@mixin sfondo-bianco() {
  background-color: #fff; //$color_celeste;
  color: $color_testo;
  h1, h2, h3, h4, h5, h6, .titolo, .titolo1, .titolo2, .titolo3, .titolo4 {
    color: $txt_blu;
  }
  @include a_style($color_link, $color_link_over);
  @include a_style_link($txt_blu, $txt_blu);
  a.pulsante, .wp-block-button__link, submit, input[type="submit"], input[type="reset"], input[type="button"] {
    @include button_style($txt_blu, transparent, #fff, $txt_blu, $txt_blu, $txt_blu);
  }
  a.pulsante.negativo, input[type="submit"].negativo, input[type="reset"].negativo, input[type="button"].negativo {
    @include button_style(transparent, $txt_blu, $txt_blu, #fff, $txt_blu, $txt_blu);
  }
  .gruppo-pulsanti {
    a.pulsante, .wp-block-button__link, submit, input[type="submit"], input[type="reset"], input[type="button"] {
      @include button_style(transparent, $txt_blu, $txt_blu, #fff, $txt_blu, $txt_blu);
    }
  }
}

@mixin sfondo-celeste() {
  background-color: $sfondo_cta; //$color_celeste;
  color: $txt_blu;
  h1, h2, h3, h4, h5, h6, .titolo, .titolo1, .titolo2, .titolo3, .titolo4 {
    color: $txt_blu;
  }
  @include a_style($txt_blu, $color_testo_neg);
  @include a_style_link($txt_blu, $txt_blu);
  a.pulsante, .wp-block-button__link, submit, input[type="submit"], input[type="reset"], input[type="button"] {
    @include button_style($txt_blu, transparent, #fff, $txt_blu, $txt_blu, $txt_blu);
  }
  a.pulsante.negativo, input[type="submit"].negativo, input[type="reset"].negativo, input[type="button"].negativo {
    @include button_style(transparent, $txt_blu, $txt_blu, #fff, $txt_blu, $txt_blu);
  }
  @include input_style($txt_blu, transparent, #fff, $txt_blu, $txt_blu, $txt_blu);
}

@mixin sfondo-cta() {
  background-color: $sfondo_cta; //$color_celeste;
  color: $txt_blu;
  h1, h2, h3, h4, h5, h6, .titolo, .titolo1, .titolo2, .titolo3, .titolo4 {
    color: #fff;
  }
  @include a_style($color_testo_neg, $color_testo_neg);
  @include a_style_link($txt_blu, $txt_blu);
  a.pulsante, .wp-block-button__link, submit, input[type="submit"], input[type="reset"], input[type="button"] {
    @include button_style($txt_blu, transparent, #fff, $txt_blu, $txt_blu, $txt_blu);
  }
  a.pulsante.negativo, input[type="submit"].negativo, input[type="reset"].negativo, input[type="button"].negativo {
    @include button_style(transparent, $txt_blu, $txt_blu, #fff, $txt_blu, $txt_blu);
  }
  @include input_style(#fff, #fff, $color_testo, $color_testo, $sfondo_cta, $sfondo_cta);
}

@mixin sfondo-blu() {
  background-color: $color_blu;
  color: $color_testo_neg;
  h1, h2, h3, h4, h5, h6, .titolo, .titolo1, .titolo2, .titolo3, .titolo4 {
    color: #fff;
  }
  @include a_style($color_link, #ffffff);
  @include a_style_link(#ffffff, #ffffff);
  a.pulsante, .wp-block-button__link, submit, input[type="submit"], input[type="reset"], input[type="button"] {
    @include button_style(transparent, #fff, #fff, $txt_blu, #fff, #fff);
  }
  a.pulsante.negativo, input[type="submit"].negativo, input[type="reset"].negativo, input[type="button"].negativo {
    @include button_style(#fff, transparent, $txt_blu, #fff, #fff, #fff);
  }
}

@mixin sfondo-nero() {
  background-color: $color_nero;
  color: #818181;
  h1, h2, h3, h4, h5, h6, .titolo, .titolo1, .titolo2, .titolo3, .titolo4, .titolo5 {
    color: #ffffff;
  }
  @include a_style(#ffffff, $color_verde_scuro);
  @include a_style_link(#ffffff, $color_verde_scuro);
  a.pulsante, .wp-block-button__link, submit, input[type="submit"], input[type="reset"], input[type="button"] {
    @include button_style($color_verde_scuro, transparent, #fff, #fff, $color_verde_scuro, #fff);
  }
  a.pulsante.negativo, input[type="submit"].negativo, input[type="reset"].negativo, input[type="button"].negativo {
    @include button_style(transparent, $color_verde_scuro, #fff, #fff, #fff, $color_verde_scuro);
  }
}

@mixin sfondo-viola() {
  background-color: $color_viola_ms;
  color: #ffffff;
  h1, h2, h3, h4, h5, h6, .titolo, .titolo1, .titolo2, .titolo3, .titolo4, .titolo5 {
    color: #ffffff;
  }
  @include a_style(#ffffff, $color_grigio_linea);
  @include a_style_link(#ffffff, $color_grigio_linea);
  a.pulsante, .wp-block-button__link, submit, input[type="submit"], input[type="reset"], input[type="button"] {
    @include button_style(#ffffff, transparent, $color_viola_ms, #fff, #fff, #fff);
  }
  a.pulsante.negativo, input[type="submit"].negativo, input[type="reset"].negativo, input[type="button"].negativo {
    @include button_style($color_viola_ms, #ffffff, #fff, $color_viola_ms, $color_viola_ms, $color_viola_ms);
  }
}
