/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 3-feb-2021, 10.42.11
    Author     : veronica
*/

@mixin item-base() {
  .image {
    width: 63%;
    height: 46%;
    right: 0;
    top: 0;
    border-radius: 0 25px 0px 104px;
  }
}

@mixin item-grande() {
  .image {
    width: 75%;
    height: 64%;
    right: 0;
    top: 0;
    border-radius: 0 25px 0 255px;
  }
}

@mixin item-orizzontale() {
  .testi {
    width: 40%;
  }
  .image {
    width: 50%;
    height: 100%;
    right: 0;
    top: 0;
    border-radius: 175px 25px 25px 0;
  }
  &:hover {
    .testi {
      //transition: all .1s ease-in-out;
      //width: calc(100% - 56px);
      width: 56%;
    }
  }

}

@mixin item-verticale() {
  .image {
    width: 90%;
    height: 44%;
    right: 0;
    top: 20%;
    border-radius: 140px 0 0 140px;
  }
}

@mixin item-minimo() {
  padding: 0;
  min-height: auto !important;
  margin-bottom: 0;
  .image-velina {
    display: none;
  }
  .image {
    width: 100%;
    height: auto;
    min-height: 100px;
    position: relative;
    right: auto;
    top: auto;
    border-radius: 0 25px 0px 104px;
  }
  .tipo {
    padding: 20px 20px 0 20px;
  }
  .data {
    padding: 0 20px;
  }
  .testi {
    position: relative;
    width: 100%;
    bottom: auto;
    padding: 20px;

    .testo {
      opacity: 1;
      transform: none;
      max-height: initial;
    }
  }
  &:hover {
    .image-sfondo {
      background: #00225A;
      opacity: 7%
    }
  }
  &:active {
    transition: all .4s ease-in-out;
    //background-color: $sfondo_celeste;

    .image-velina {
      display: none;
    }

    .image-sfondo {
      background: #00225A;
      opacity: 20%
    }
  }
}

@mixin item-mobile() {
  min-height: 400px;
  padding: 0;
  margin-bottom: 20px;
  .image-sfondo, .image-velina {
    display: none;
  }
  .image {
    width: 100%;
    height: auto;
    min-height: 250px;
    position: relative;
    right: auto;
    top: auto;
    border-radius: 0 25px 0px 104px;
  }
  .tipo {
    padding: 20px 20px 0 20px;
  }
  .data {
    padding: 0 20px;
  }
  .testi {
    position: relative;
    width: 100%;
    bottom: auto;
    padding: 20px;

    .testo {
      opacity: 1;
      transform: none;
      max-height: initial;
    }
  }
  &:hover {
    box-shadow: none;
    transition: none;
    background-color: #fff;

    .testi .testo {
      max-height: none;
    }
  }
}

.pattern-news {
  position: relative;
  padding-top: 50px;
  padding-bottom: 180px;

  .item a {
    color: $txt_blu;

    &:hover {
      text-decoration: none;
    }
  }

  .item {
    padding: 28px;
    height: 100%;
    min-height: 266px;
    background-color: #fff;
    color: $txt_blu;
    overflow: hidden;
    list-style: none;
    cursor: pointer;

    .tipo {
      //text-transform: uppercase;
      font-weight: bold;
      z-index: 300;
      position: relative;
      /*            &:after {
                      content: " ";
                      display: block;
                      width: 50px;
                      height: 2px;
                      background-color: $txt_blu;
                      margin: 8px 0;
                  }*/
      .label {
        display: none
      }

      .subtipo {
        display: block;
      }
    }

    .data {
      position: relative;
      z-index: 300;
    }

    .testi {
      transition: all .1s ease-in-out;
      position: absolute;
      width: calc(100% - 56px);
      bottom: 20px;

      .titolo5 {
        padding-bottom: 0;
        transition: all .4s ease-in-out;
      }

      .testo {
        padding-top: 10px;
        max-height: 0;
        height: 100%;
        margin: 0;
        transition: all .4s ease-in-out;
        transform: translateX(-100%);
        opacity: 0;
      }

      z-index: 300;
    }

    .image {
      background-image: url('../img/pattern-news.png');
      position: absolute;
      z-index: 100;
      background-size: 120%;
    }

    @include item-base();

    .image.custom {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }

    .image-velina, .image-sfondo {
      width: 100%;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 200;
    }

    .image-sfondo {
      z-index: 250;
    }

    &:hover {
      -webkit-box-shadow: 0px 10px 77px 20px rgba(0, 17, 57, 0.5);
      -moz-box-shadow: 0px 10px 77px 20px rgba(0, 17, 57, 0.5);
      box-shadow: 0px 10px 77px 20px rgba(0, 17, 57, 0.5);
      transition: all .4s ease-in-out;
      background-color: $sfondo_celeste;

      .data {
        opacity: 0;
      }

      .testi .titolo5 {
        font-size: 100%;
        display: none;
      }

      .testi .testo {
        max-height: 300px;
        //margin-bottom: .6375em;
        opacity: 1;
        transform: translateX(0);

      }

      .image-velina {
        -webkit-backdrop-filter: saturate(180%) blur(20px);
        backdrop-filter: saturate(180%) blur(20px);
      }

      .image-sfondo {
        background: $sfondo_celeste;
        opacity: 0.4;
      }
    }
  }
}

// WEBINAR
.pattern-news {
  .item.webinar {
    .data {
      font-weight: bold;
      font-size: 26px;
    }

    .image {
      background-image: url('../img/pattern-webinar.svg');
      display: grid;
      text-align: right;
      padding-right: 20px;
      font-weight: 300;
      background-size: 1000px;
      grid-template-rows: 45% 55%;
      grid-gap: 5px;

      .date {
        font-family: $font_titoli;
        display: flex;
        font-weight: bold;
        font-size: 26px;
        justify-content: end;
        align-self: flex-end;
      }

      .time {
        font-family: 'Gotham Rounded Book';
        display: flex;
        font-size: 32px;
        text-transform: uppercase;
        letter-spacing: -2px;
        justify-content: flex-end;
        align-self: flex-start;
      }

      .lang {
        display: block;
        position: absolute;
        right: 25px;
        top: -15px;
        background: $sfondo_grigio;
        border: 4px solid #fff;
        border-radius: 20px;
        width: 70px;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        height: 30px;
      }

      .action {
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        background: $txt_blu;
        color: #fff;
        border-radius: 50px;
        width: 230px;
        height: 50px;
        font-family: $font_titoli;
        font-weight: bold;
        font-size: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 33px;

        .ico-webinar {
          width: 50px;
          height: auto;
          position: absolute;
          left: -4px;
          font-size: 58px;
          top: -0;
        }
      }
    }

    .image.custom {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
  }

  .item.webinar.live {
    .image {
      background-image: none;
    }
  }

  .item.nasconditype {
    display: none;
  }

  .item.nascondilang {
    display: none;
  }

  .item.nascondiprod {
    display: none;
  }
}

// DOWNLOAD
.pattern-news {
  .item.download {
    .image {
      .action {
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        background: $txt_blu;
        color: #fff;
        border-radius: 50px;
        width: 230px;
        height: 50px;
        font-family: $font_titoli;
        font-weight: bold;
        font-size: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 33px;

        .ico-webinar {
          width: 50px;
          height: auto;
          position: absolute;
          left: -4px;
          font-size: 58px;
          top: -0;
        }
      }
    }
  }

}

// GRIGLIA
.pattern-news {
  .griglia, .et_builder_outer_content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 18px;
    max-width: 1140px;
    height: 638px;
    margin: 0;
    padding: 0;

  }

  .griglia.colonne {
    .item {
      &:nth-child(1) {
        // orizzontale
        grid-column: 1;
        grid-row: 1/3;
        @include item-verticale();

      }

      &:nth-child(2) {
        // verticale
        grid-column: 2;
        grid-row: 1/3;
        @include item-verticale();

      }

      &:nth-child(3) {
        // verticale
        grid-column: 3;
        grid-row: 1/3;
        @include item-verticale();

      }

      &:nth-child(4), &:nth-child(5), &:nth-child(6) {
        display: none;
      }
    }
  }

  .griglia.last {
    height: auto;
    grid-template-columns: repeat(1, 1fr);

    .item {
      height: 560px;
      @include item-grande();
    }
  }

  .griglia.blog, .et_builder_outer_content {
    height: auto;

    .item {
      height: 560px;
      @include item-verticale();
    }
  }

  .griglia.libero {
    height: auto;

    .item {
      height: 560px;
      @include item-verticale();
    }
  }

  .griglia.infobox {
    height: auto;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 12px;
    max-width: 400px;

    .item {
      @include item-minimo();
      min-height: auto !important;
      margin-bottom: 0;

      .image {
        min-height: 100px;
      }
    }
  }

  .griglia.misto {
    .item {
      &:nth-child(1) {
        // orizzontale
        grid-column: 1/3;
        grid-row: 1;
        @include item-orizzontale();

        .image {
          .lang {
            top: 20px;
          }

          .action {
            border-top-right-radius: 0;
            left: auto;
            right: 0;
            width: 80%;
          }
        }
      }

      &:nth-child(2) {
        // verticale
        grid-column: 3;
        grid-row: 1/3;
        @include item-verticale();

      }

      &:nth-child(5), &:nth-child(6) {
        display: none;
      }
    }
  }

  .griglia.evidenza {
    .item {
      &:nth-child(1) {
        // quadrato grande
        grid-column: 1/3;
        grid-row: 1/3;
        @include item-grande();


      }

      &:nth-child(2) {
        // verticale
        grid-column: 3;
        grid-row: 1/3;
        @include item-verticale();

      }

      &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6) {
        display: none;
      }
    }
  }

  .griglia.one {
    .item {
      &:nth-child(1) {
        // quadrato grande
        grid-column: 1/4;
        grid-row: 1/3;
        @include item-grande();
      }

      &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6) {
        display: none;
      }
    }
  }
}

.sfondo-grigio .pattern-news {
  .item:hover {
    -webkit-box-shadow: 0px 15px 25px 10px rgba(0, 17, 57, 0.25);
    -moz-box-shadow: 0px 15px 25px 10px rgba(0, 17, 57, 0.25);
    box-shadow: 0px 15px 25px 10px rgba(0, 17, 57, 0.25);
  }
}

/* -------------------------------------------------- */
/*	PAGE WEBEX
/* -------------------------------------------------- */
.news-sfondo-nero {
  .titolo2 {
    color: $color_nero;
  }

  .pattern-news {
    .item {
      @include sfondo-nero();
      background: $color_nero;
      color: #818181;

      h1, h2, h3, h4, h5, h6, .titolo, .titolo1, .titolo2, .titolo3, .titolo4, .titolo5 {
        color: #ffffff;
      }

      @include a_style(#ffffff, #818181);

      &:hover {
        -webkit-box-shadow: 0px 10px 77px 20px rgba(0, 17, 57, 0.5);
        -moz-box-shadow: 0px 10px 77px 20px rgba(0, 17, 57, 0.5);
        box-shadow: 0px 10px 77px 20px rgba(0, 17, 57, 0.5);
        transition: all .4s ease-in-out;
        background-color: $color_nero;
        @include a_style(#818181, #fff);

        .testi .testo {
          max-height: 100px;
          //margin-bottom: .6375em;
          opacity: 1;
          transform: translateX(0);
          color: #fff;
        }

        .image-velina {
          -webkit-backdrop-filter: saturate(180%) blur(20px);
          backdrop-filter: saturate(180%) blur(20px);
        }

        .image-sfondo {
          background: $color_nero;
          opacity: 0.4;
        }
      }

    }
  }
}


/* -------------------------------------------------- */
/*	PAGE MS
/* -------------------------------------------------- */
.news-sfondo-viola {
  .titolo2 {
    color: #000;
  }

  .pattern-news {
    .item {
      @include sfondo-viola();
      background: $color_viola_ms;
      color: #ffffff;

      h1, h2, h3, h4, h5, h6, .titolo, .titolo1, .titolo2, .titolo3, .titolo4, .titolo5 {
        color: #ffffff;
      }

      @include a_style(#ffffff, #ffffff);

      &:hover {
        -webkit-box-shadow: 0px 10px 77px 20px rgba(0, 17, 57, 0.5);
        -moz-box-shadow: 0px 10px 77px 20px rgba(0, 17, 57, 0.5);
        box-shadow: 0px 10px 77px 20px rgba(0, 17, 57, 0.5);
        transition: all .4s ease-in-out;
        background-color: $color_viola_ms;
        @include a_style(#fff, #fff);

        .testi .testo {
          max-height: 100px;
          //margin-bottom: .6375em;
          opacity: 1;
          transform: translateX(0);
          color: #fff;
        }

        .image-velina {
          -webkit-backdrop-filter: saturate(180%) blur(20px);
          backdrop-filter: saturate(180%) blur(20px);
        }

        .image-sfondo {
          background: $color_viola_ms;
          opacity: 0.4;
        }
      }

    }
  }
}

@media (max-width: 1199px) {
  // lg
}

@media (max-width: 992px) {
  // lg - fine
}

@media (max-width: 991px) {
  //md
  .pattern-news {
    padding-bottom: 90px;

    .griglia, .griglia.libero, .griglia.blog, .et_builder_outer_content {
      height: auto !important;
      grid-template-columns: repeat(1, 1fr) !important;

      .item {
        height: auto !important;
        min-height: 330px !important;
        @include item-orizzontale();

        .data {
          padding-bottom: 20px;
        }

        .tipo {
          padding-bottom: 20px;
        }

        .testi {
          position: relative;
          bottom: auto;

          .testo {
            opacity: 1;
            transform: none;
            max-height: initial;

          }
        }
      }
    }

    .griglia.evidenza, .griglia.misto, .griglia.colonne {
      .item {
        &:nth-child(1) {
          grid-column: 1;
          grid-row: 1;
          @include item-orizzontale();

        }

        &:nth-child(2) {
          grid-column: 1;
          grid-row: 2;
          @include item-orizzontale();

        }

        &:nth-child(3) {
          grid-column: 1;
          grid-row: 3;
          @include item-orizzontale();

        }

        &:nth-child(4), &:nth-child(5), &:nth-child(6) {
          display: none;
        }
      }
    }
  }
  .pattern-news .item.webinar .image {
    .lang {
      right: 30px;
      top: 20px;
    }
  }
  .pattern-news .item.webinar .image .action, .pattern-news .item.download .image .action {
    border-top-right-radius: 0;
    left: auto;
    right: 0;
    width: 80%;

    .ico-webinar {
      font-size: 60px;
      top: auto;
      bottom: 0;
    }
  }
  .pattern-news .griglia.infobox .item .image {
    width: 100%;
  }
}

@media (max-width: 950px) {

}

@media (max-width: 895px) {

}

@media (max-width: 768px) {
  //md-fine


}

@media (max-width: 767px) {
  .pattern-news {
    padding-top: 60px;
    padding-bottom: 40px;

    .griglia {
      display: block;
    }

    .item {
      @include item-mobile();
    }

    .griglia, .griglia.libero, .griglia.blog, .et_builder_outer_content {
      .item {
        @include item-mobile();
      }
    }

  }
  .pattern-news .griglia.infobox .item {
    margin-bottom: 12px;
  }

  .pattern-news .item.webinar .image .action, .pattern-news .item.download .image .action {
    border-top-right-radius: 50px;
    width: 50%;
    left: 30px;
    right: auto;
  }
  .sfondo-grigio .pattern-news {
    .item:hover {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
  }
}

@media (max-width: 650px) {

}

@media (max-width: 575px) {
  //xs

}

@media (max-width: 480px) {

  .pattern-news .item {
    min-height: 310px;
  }


}

@media (max-width: 430px) {

}

@media (max-width: 380px) {

}

@media (max-width: 320px) {

}

@media (max-width: 300px) {

}
