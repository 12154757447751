/*
    Created on : 31-ago-2020, 10.04.40
    Author     : veronica
*/

/* ++++++++++++++++  OVERRIDE ++++++++++++++++++++++*/

/***** Remove cookiebot logo *******/

/* Branding on the banner */
a#CybotCookiebotDialogPoweredbyCybot,
div#CybotCookiebotDialogPoweredByText {
  display: none;
}

/* Branding on the widget */
#CookiebotWidget .CookiebotWidget-body .CookiebotWidget-main-logo {
  display: none;
}

/* ++++++++++++++++++++++++++++++++++++++++++++++++*/

@media (max-width: 2200px) {
  #primary.site-main.home .supertop {
    background-size: contain;
  }
}

@media (max-width: 1800px) {

}

@media (max-width: 1750px) {

}

@media (max-width: 1650px) {

}

@media (max-width: 1500px) {
  #primary.site-main.home #chi-siamo .immagine {
    max-width: 674px;
    right: 80px;
    width: 43%;
  }
  #primary.site-main #review .sfondo {
    width: 70%;
  }
}

@media (max-width: 1400px) {

}

@media (max-width: 1350px) {
  #primary.site-main #block-contatori .wrapper .item {
    padding: 50px 75px;

    .titolo {
      font-size: 265%;
    }

    .sottotitolo {
      font-size: 150%;
    }
  }

  @include curva_style(200px);
  #primary.site-main.home #chi-siamo .immagine {
    max-width: 674px;
    position: absolute;
    right: 36px;
    bottom: 0;
    border-radius: 20%;
    width: 46%;
  }

  #primary.site-main #block-languages .lista .el {
    flex-basis: 33%;
  }
  #primary.site-main #block-cloud .immagine img {
    padding: 140px 0;
  }
}

@media (max-width: 1250px) {
  #primary.site-main.home #chi-siamo {
    padding-bottom: 0;
  }

  #primary.site-main .roadmap .grafica {
    //margin-right: -200px;
    .el:nth-child(1) img {
      width: 20%;
      height: auto;
    }

    .el:nth-child(2) img {
      width: 40%;
      height: auto;
    }

    .el:nth-child(3) img {
      width: 50%;
      height: auto;
    }
  }

  #primary.site-main .intro-titolosx .contenuto {
    padding-bottom: 120px;
  }
  #primary.site-main .img-destra-top {
    right: 10px;
    top: auto;
    bottom: 5%;
  }
}

@media (max-width: 1199px) {
  // lg
  .titolo2.big {
    font-size: 50px;
    line-height: 50px;

  }
  .cta-gialla {
    img {
      width: auto;
      height: 430px;
      object-fit: cover;
      object-position: 0;
      padding-left: 0px;
    }
  }
  #primary.site-main {
    #block-intro .imgxl {
      top: 20%;
      left: auto;
      right: 0;
      max-width: 50vw;
    }

    #block-contatori .wrapper .item {
      padding: 50px 65px;

      .titolo {
        font-size: 230%;
      }

      .sottotitolo {
        font-size: 120%;
      }
    }

    .integrazioni .griglia {
      grid-gap: 30px 0;

      .item .cerchio {
        width: 170px;
        height: 170px;
      }
    }
  }
  #primary.site-main.home {

    #prodotti {
      background-size: 70px;
    }

    #chi-siamo {
      background-position: 130% 0;
    }
  }

  #primary.site-main #jolly.imgdx {
    .immagine {
      right: 0;
      width: 50%;
    }

    .forma {
      border-radius: 180px 0 180px 0;
    }
  }
  #primary.site-main #block-languages {
    .lista .el {
      flex-basis: 25%;
    }
  }

  #primary.site-main.kb #featured ul {
    column-count: 2;
  }
  #primary.site-main #myModalInt {
    .duecol, .lista_due_colonne {
      column-count: 1;
    }

    .apps .griglia {
      display: grid;
      grid-gap: 1vh;
      grid-template-columns: repeat(3, 1fr);

      .item {
        flex-basis: 100%;
        margin-bottom: 0;
      }
    }
  }
  #primary.site-main.kb-result iframe {
    height: calc(100vh - 120px);
  }
}

@media (max-width: 1150px) {
  #primary.site-main.home #news-home .tondo {
    right: -10px;
  }
  #primary.site-main {
    .calltoaction .immagine {
      width: 50%;
    }
  }


}

@media (max-width: 992px) {
  // lg - fine
}

@media (max-width: 991px) {
  //md
  $size_h1: 54px;
  $size_h2: 40px;
  $size_h3: 30px;
  $size_h4: 24px;
  $size_h5: 22px;
  $size_h6: 20px;
  @include curva_style(170px);
  @include titolize($size_h1, $size_h2, $size_h3, $size_h4, $size_h5, $size_h6);

  .titolo2.big {
    font-size: 45px;
    line-height: 45px;
  }
  .cta-gialla img {
    height: 380px;
  }

  @include spaziatorize(100px);
  .nav-link {
    padding: 0.5rem 0.6rem;
  }

  .tabella .td {
    border-left: 0;
    border-top: 1px solid #e9edf3;

    .intestazione {
      width: 50%;
      float: left;
      text-align: left;
    }

    .contenuto {
      width: 50%;
      float: right;
      text-align: right;
    }
  }

  #myModalInt {
    .barra-dx {

      padding-top: 0px;
    }

  }

  #primary.site-main {
    .top-header.titolosx {
      background-position: bottom right;
      background-size: 200%;
      padding-top: 50px;

      .contenuto, .immagine {
        text-align: center;
      }
    }

    .intro-titolosx {
      padding-top: 20px;

      .contenuto {
        padding-bottom: 120px;
      }
    }

    #block-intro {
      //@include sfondo-cta();
      //background: $sfondo_cta;
      padding-top: 0;
      background-image: none;

      .blocco {
        //padding-top: 0;
      }

      .imgxl-container {
        padding-top: calc(#{$spaziatore / 2});
        padding-bottom: calc(#{$spaziatore / 2});
        background: $sfondo_cta;
        text-align: center;
        border-bottom-left-radius: 35%;

        .imgxl {
          position: relative;
          left: auto;
          top: auto;
          //width: 80%;
          padding: 0;
          max-width: 70vw;
        }
      }
    }

    #block-suite .item .inner {
      padding: 20px;
    }

    .double-cta {
      padding: 0;

      .item {
        border-right: 0;
        border-bottom: 2px solid $sfondo_grigio;

        .inner {
          padding: 40px 0;

          .content {
            min-height: auto;
          }
        }

        &:first-of-type {
          border-left: 0;
        }

        &:last-of-type {
          border-bottom: 0;
        }

        h3 br {
          display: none;
          padding-left: 5px;
        }
      }
    }

    #block-suite-new {
      .item {
        border-bottom-color: #fff;
        border-right: 0;
      }

      .item:first-of-type {
        border-left: 0;
      }
    }

    #block-contatori .wrapper {
      .item:first-of-type {
        padding-right: 0;
      }

      .item:last-of-type {
        padding-left: 0;
      }
    }

    .calltoaction {
      .titolo5 {
        padding-bottom: 25px;
      }

      submit {
        margin-top: 10px;
        width: 100%;
      }
    }

    .prod-cta {
      .item {
        margin: 0;
        border-left: 0;
        border-bottom: 1px solid #fff;

        &:last-of-type {
          border-right: 0;
          border-bottom: 0;
        }
      }
    }

    .integrazioni .griglia {
      grid-gap: 20px 0;

      .item .cerchio {
        width: 130px;
        height: 130px;
      }
    }
  }

  #primary.site-main.home {
    .supertop-text {
      background-image: url('../img/home-top-titolo-mob.svg');
    }

    #news-home {
      padding-bottom: 90px;

      .cerchio {
        display: none;
      }
    }

    #chi-siamo {
      background-image: none;
      padding-top: 0px;
      margin-top: 0;
      padding-top: 90px;

      .img-round {
        border-radius: 100px;
        margin-bottom: 50px;
      }
    }
  }

  #primary.site-main #jolly.imgdx {
    .immagine {
      right: 0;
      width: 100%;
    }

    .forma {
      border-radius: 180px 0 180px 0;
    }
  }
  // webinar
  #primary.site-main.webinar #blocco-live-demand {
    .live-lang, .live-type, .ondemand-lang, .ondemand-type {
      margin-bottom: 20px;
    }
  }
  #primary.site-main #block-intro .imgxl-container {
    padding-bottom: 0;
    text-align: right;
    padding-right: 0px;
    padding-top: 5px;

    .imgxl {
      width: 70%;
      right: 10px;
    }
  }

  #primary.site-main #review {
    .sfondo {
      width: 100%;
      height: 100%;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    .lista-review .citazione {
      padding-bottom: 20px;
    }
  }

  #primary.site-main .img-sinistra-bottom {
    left: -40px;
    bottom: 20px;
  }
  #primary.site-main .roadmap {
    .contenuto {
      // align-items: flex-start;
      position: relative;
      padding-bottom: 100px;
    }

    .grafica {
      display: none;
    }
  }

  #primary.site-main.landing .form-wrapper {
    transform: translateY(0);
    margin-top: 50px;
    height: 630px;
    min-height: auto;
    //height: auto;
  }

  #primary.site-main.about #block-intro .imgxl-container {
    background: none;
    text-align: center;
    padding-top: 0;
  }
  #primary.site-main #block-cloud .immagine img {
    padding: 80px 0;
  }
  #primary.site-main #myModalInt {
    .apps .griglia {
      grid-template-columns: repeat(2, 1fr);

    }
  }

  #primary.site-main.landing-2023-sticky .landing-header {
    height: 1530px;
    transition: 1s;
    transition-property: height;
  }

  #primary.site-main.landing-2023-sticky .landing-header.form-open {
    height: 1790px;
  }

  #primary.site-main .help-block .lista-blocchi-help .item .img-slider {
    padding: 1rem;
  }
  #primary.site-main .help-block .lista-blocchi-help .item {
    padding: 40px;
  }

  #primary.site-main {
    .approfondimento {
      .item {
        padding: 30px;
      }

      .lista .lista-item {
        padding-left: 30px;
        padding-right: 30px;

        &:first-child {
          padding-left: 10px;
        }

        &:last-child {
          padding-right: 10px;
        }
      }
    }
  }

}

@media (max-width: 950px) {
  #primary.site-main #block-contatori .wrapper .item {
    padding: 40px 55px;

    .titolo {
      font-size: 180%;
    }

    .sottotitolo {
      font-size: 110%;
    }
  }
}

@media (max-width: 895px) {

}

@media (max-width: 768px) {
  //md-fine
  @include curva_style(120px);
  #primary.site-main.home #chi-siamo {
    background-position: 400% 0;
    padding-top: 40px;
  }

  /*    #primary.site-main .roadmap .grafica {
          margin-right: -250px;
      }*/

  #primary.site-main.blog-single .body-blog {
    background-image: none;
  }
}

@media (max-width: 767px) {
  //sm
  $size_h1: 58px;
  $size_h2: 38px;
  $size_h3: 28px;
  $size_h4: 22px;
  $size_h5: 20px;
  $size_h6: 18px;
  @include titolize($size_h1, $size_h2, $size_h3, $size_h4, $size_h5, $size_h6);

  .titolo2.big {
    font-size: 43px;
    line-height: 43px;
  }

  .cta-gialla {
    img {
      height: 377px;
      object-fit: cover;
      object-position: 50px -20px;
      padding-left: 0;
      width: 100%;
    }
  }

  .wpml-ls-legacy-list-vertical a {
    padding-left: 0;
  }
  #colophon.site-footer .newsletter {
    padding-top: 0;
  }
  #primary.site-main .lista-blocchi .item {
    border-radius: 25px;
  }
  #primary.site-main {
    .intro-titolosx .contenuto {
      padding-bottom: 50px;
    }

    #block-suite {
      //border-radius: 0 125px 0 0;
      padding: 0;

      .item {
        border-right: 0;
        border-bottom: 2px solid #fff;

        .inner {
          padding: 40px 0;
          position: relative;

          .content {
            width: 90%;
            min-height: auto;

            h3 {
              padding-bottom: 0;
            }
          }

          .puntatore {
            position: absolute;
            right: 0;
            top: calc(50% - 27px);
            display: block;
            @include a_style($txt_blu, $txt_blu);

          }
        }

        &:first-of-type {
          border-left: 0;
        }

        &:last-of-type {
          border-bottom: 0;
        }

        h3 br {
          display: none;
          padding-left: 5px;
        }
      }
    }

    #block-suite-new .item .inner .content {
      min-height: auto;
    }

    #block-contatori .wrapper {
      flex-wrap: wrap;

      .item:first-of-type, .item:last-of-type {
        display: none;
      }

      .item:nth-child(2), .item:nth-child(3), .item:nth-child(4), .item:nth-child(5) {
        width: auto;
        flex-grow: 1;
        flex-basis: 50%;
        max-width: 400px;
      }

      .item:nth-child(2), .item:nth-child(4) {
        margin-left: 0;
        border-bottom-left-radius: 0px;
        border-top-left-radius: 0px;
      }

      .item:nth-child(3), .item:nth-child(5) {
        margin-right: 0;
        border-bottom-right-radius: 0px;
        border-top-right-radius: 0px;
      }

      .item:nth-child(4), .item:nth-child(5) {
        margin-top: -25px;
      }

    }

    .calltoaction {
      padding-top: 300px;

      .container {
        position: relative;
        z-index: 1000;
      }

      .immagine {
        width: 100%;
        height: 300px;
        border-radius: 0 0 0 300px;
        z-index: 200;
      }

      .cerchio {
        left: auto;
        right: 0;
        height: 100%;
        border-radius: 0;
        top: 0;
        z-index: 1000;
        background-color: transparent;
      }
    }

    .lista-blocchi {
      .container-fluid {
        padding-right: 0;

        .owl-carousel .owl-item img {
          width: 50%;
        }
      }

      .item .pulsante {
        margin-top: 20px;
      }
    }

    #assistenza {
      .inner {
        padding-top: 20px;
      }

      .blocco {
        padding-top: 50px;
        padding-bottom: 50px;
      }
    }

    #storie {
      .lista .item img {
        display: none;
      }

      .approfondimento .item {
        padding: 40px;

        .sinistra {
          border-right: 0;
          border-bottom: 1px solid #ccc;
          height: auto;
          padding-bottom: 20px;

          .introimg {
            display: block;
          }
        }

        .contenuto {
          height: auto;
          padding-top: 20px;

          .testo {
            padding-left: 0;
          }
        }

      }
    }

    .integrazioni .griglia {
      grid-gap: 18px;
      grid-template-columns: repeat(4, 1fr);

      .item .cerchio {
        width: 116px;
        height: 100%;
      }
    }
  }
  #primary.site-main.landing .form-wrapper {
    height: 900px;
  }

  #primary.site-main.home {

    #chi-siamo {
      background-image: none;

      .testo {
        padding-top: 0;
      }

      .img-round {
        border-radius: 72px;
      }
    }

    #news-home {
      background-size: cover;
      background-position: right;
    }

  }

  /*    #primary.site-main .roadmap .grafica  {
          padding-top: 150px;
      }*/

  #primary.site-main.legal {
    .top.blocco {
      padding-bottom: 0;
    }

    .sottomenu {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  #primary.site-main.kb #featured ul {
    column-count: 1;
  }
  #primary.site-main.page-webex .apps .griglia .item {
    max-width: 50%;
  }

  #primary.site-main {
    .approfondimento {
      .item {
        padding: 30px;
      }

      .lista .lista-item {
        padding: 30px 0;
        border-right: 0;
        border-bottom: 3px solid $color_grigio_scuro;

        &:first-child {
          padding-left: 0px;
          padding-top: 0;
        }

        &:last-child {
          padding-right: 0px;
          border-bottom: 0;
          padding-bottom: 0;
        }
      }
    }
  }
}

@media (max-width: 650px) {

}

@media (max-width: 575px) {
  // xs
  $size_h1: 50px;
  $size_h2: 34px;
  $size_h3: 28px;
  $size_h4: 22px;
  $size_h5: 20px;
  $size_h6: 18px;
  @include titolize($size_h1, $size_h2, $size_h3, $size_h4, $size_h5, $size_h6);
  @include spaziatorize(60px);

  .sfondo-giallo {
    .immagine {
      background-repeat: no-repeat;
      background-size: cover;
      height: 40vh;
      width: 100%;
      background-position: left center;
      max-height: 410px;
    }
  }
  #colophon.site-footer .logoimagicle {
    border-bottom: 0;
    padding-bottom: 0;
  }
  .owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: 10px;
  }
  .owl-theme .owl-dots .owl-dot span {
    width: 10px;
    height: 10px;
  }
  .swiper-pagination-bullet {
    height: 10px;
    width: 10px;
  }
  .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
    padding-right: 18px;
    padding-left: 18px;
  }

  a.pulsante {
    width: 100% !important;
    text-align: center;
  }
  #primary.site-main.prodotto {
    #storie {
      .lista {
        display: none;
      }

      .approfondimento {
        display: block !important;
        padding-top: 0;

        .item {
          .sinistra {
            logo {
              float: right;
            }

            .introimg {
              display: none;
            }
          }

          .contenuto {
            .imgbottom {
              display: block;
            }
          }

        }
      }
    }
  }
  #primary.site-main {
    .lista-blocchi {
      padding-bottom: 0;

      .lista {
        margin-left: 18px;
        padding-bottom: 30px;

        .owl-carousel.owl-drag .owl-item {
          margin-bottom: 20px;
        }
      }
    }

    .integrazioni .griglia {
      //grid-gap: 10px;
      grid-template-columns: repeat(3, 1fr);
    }
  }
  #primary.site-main .intro-titolo-center .immagine img {
    border-bottom-left-radius: 5vh;
    border-bottom-right-radius: 5vh;
  }

  #primary.site-main.home {
    .supertop {
      background-image: none; //url('../img/fondo-home-elementi.svg');
    }

    #top-home {

      //height: calc(100vh - 80px);
      position: relative;
      padding-bottom: 10px;

      .subhead {
        padding-bottom: 0;
      }

      .container, .row {
        height: 100%;
      }

      .el-grafico .blocco-s-t {
        padding-bottom: 30px;
      }

      .play-video {

        .play-video-tag {
          font-size: 50px;
        }
      }

    }

    #prodotti.pacchetti.lista-blocchi .item {
      img {
        height: 97px;
      }

      .inner {
        font-size: 18px;
        padding: 25px;
      }
    }

    #news-home {
      padding-top: 60px;
      padding-bottom: 40px;
    }

  }
  #primary.site-main #block-contatori .wrapper .item .titolo {
    font-size: 211%;
  }

  #primary.site-main .block-cta-round .immagine {
    margin-left: -9px;
  }
  #primary.site-main .block-cta-round.dx .immagine {
    margin-right: -9px;
  }

  #primary.site-main .block-cta-round-sx .immagine {
    margin-left: -9px;
  }
  #primary.site-main #myModalInt {
    .apps .griglia {
      grid-template-columns: repeat(1, 1fr);

    }
  }
  #primary.site-main.legal .inner {
    padding: 20px;
  }
  #primary.site-main.landing-2023-sticky .landing-header {
    height: 1030px;
  }
  #primary.site-main.landing-2023-sticky .landing-header.form-open {
    height: 1280px;
  }

  #primary.site-main .help-block .lista-blocchi-help .item {
    padding: 30px;
  }
  #primary.site-main .help-block .lista-blocchi-help .item .img-slider {
    padding: 60px 20px 40px 20px;
  }
}

@media (max-width: 480px) {
  $size_h1: 42px;
  $size_h2: 30px;
  $size_h3: 24px;
  $size_h4: 22px;
  $size_h5: 20px;
  $size_h6: 18px;
  @include titolize($size_h1, $size_h2, $size_h3, $size_h4, $size_h5, $size_h6);

  #primary.site-main.home #top-home {
    padding: 0;
    height: auto;
  }

  #primary.site-main {
    .calltoaction {
      padding-top: 200px;

      .cerchio {
        width: 19%;
      }

      .immagine {
        height: 200px;
      }
    }
  }
  #primary.site-main.home #chi-siamo {
    padding-top: 20px;

    .img-round {
      border-radius: 46px;
    }
  }
  #primary.site-main.page-webex .apps .griglia .item {
    max-width: 100%;
  }
}

@media (max-width: 430px) {

}

@media (max-width: 375px) {
  #primary.site-main .integrazioni .griglia {
    grid-gap: 10px;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 320px) {

}

@media (max-width: 300px) {

}
