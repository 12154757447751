/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 8-lug-2021, 15.30.18
    Author     : veronica
*/

.supertop-blog {
    transition: all .35s ease-in-out;
    background-color: transparent;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    padding: 5px 0;
    h4 {
        display: none;
        padding-bottom: 0;
    }
    .immagine {
        width: 205px;
        height: 29px;
        background-image: url('../img/blog/imagicle-beat-logo-grey.svg');
        background-position: center left;
        background-size: contain;
        background-repeat: no-repeat;
        margin: 15px 0;
    }
    .form_newsletter_menutop {

        display:none;
        .nlinput {
            max-width: 300px;
            padding-top: 6px;
            padding-bottom: 6px;
        }
    }
}

.supertop-blog.mostra {
    background-color: #fff;
    h4, .form_newsletter_menutop {
        display: block;
    }
    .immagine {
        background-image: url('../img/blog/imagicle-beat-logo-grey.svg');
    }
}
/* -------------------------------------------------- */
/*	PAGE ARCHIVE
/* -------------------------------------------------- */
/** OVERRIDE MENU **/
body.archive.category  #page.site {
    margin-top: 0!important;
    #masthead.site-header .menu__header {
        position: relative;
    }
} 

/* -------------------------------------------------- */
/*	PAGE BLOG
/* -------------------------------------------------- */
/** OVERRIDE MENU **/
body.page-template-page-blog #page.site {
    margin-top: 0!important;
    #masthead.site-header .menu__header {
        position: relative;
    }
} 

#primary.site-main.blog {
    .pattern-news .item .tipo {
        .label {
            display: block;
        }
        .subtipo {
            display: none;
        }
    } 
    .wrapper-blog {
        background-image: url('../img/blog/blog-beat-header.svg');
        background-position: left top;
        background-color: $sfondo-grigio;
        background-size: contain;
        background-repeat: no-repeat;
    }
    .top-header.titolosx {
        background-image: none;
        background-color: transparent;
    }


    #blog-field {
        background-color: #fff;
    }
    .pattern-news {
        padding-top: 0;
        padding-bottom: 18px;
    }
    #blocco-risultato-ricerca {
        .pattern-news {
            padding-top: 0;
            padding-bottom: 18px;
        }
    }
    .widget-area {
        input, select {
            max-width: 90%;
        }
        label {
            margin-top: 0;
            color: $txt_blu;
            display: block;
            margin-bottom: .5rem;
        }
        .wrapper, .widget {
            padding-bottom: 25px;

        }
        .widget_search  {
            .widget-title, .search-submit {
                display: none;
            }
            .search-field {
                background: #fff;
            }
        }
        .wrapper-category, .widget_categories {
            .widget-title {
                display: none;
            }
            ul {
                list-style: none;
                margin: 0;
                padding-left: 0;
                li {
                    font-size: 120%;
                    @include a_style($txt_blu, $color_link);
                    padding-bottom: 10px;
                    a {
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
        }
        .widget-title {
            font-size: 32px;
        }

        .wrapper-newsletter {
            padding-top: 20px;

            .col-email, .col-submit {
                width: 100% !important;
            }
            .col-email {
                padding-bottom: 10px;
            }
            input[type="email"] {
                background-color: #fff;
                color: $color_testo;
            }
        }
        .wrapper-social {
            padding-top: 30px;
            a {
                font-size: 150%;
                color: $txt_blu;
                padding-right: 5px;
                &:last-of-type {
                    padding-right: 0;
                }
            }
        }

    }

}

/* -------------------------------------------------- */
/*	PAGE NEWSROOM
/* -------------------------------------------------- */

#primary.site-main.newsroom {
    background-color: $sfondo_grigio;
    background-image: url('../img/pricing/un-offerta-pricing.svg');
    background-size: 120vw;
    background-position: center top;
    background-repeat: no-repeat;

    #newsroom-field {
        background-color: #fff;
    }
    .pattern-news {
        padding-top: 0;
        padding-bottom: 18px;
    }
    #blocco-risultato-ricerca {
        .pattern-news {
            padding-top: 50px;
            padding-bottom: 50px;
        }
    }
    .pattern-news .item .tipo {
        .label {
            display: block;
        }
        .subtipo {
            display: none;
        }
    } 

}

/* -------------------------------------------------- */
/*	SINGLE BLOG
/* -------------------------------------------------- */
/** OVERRIDE MENU **/
body.single-post #page.site {
    margin-top: 0!important;
    #masthead.site-header .menu__header {
        position: relative;
    }
}
#primary.site-main.blog-single {

    .supertop-blog {
        .immagine {
            background-image: url('../img/blog/imagicle-beat-logo-white.svg');
        }
    }
    .supertop-blog.mostra {
        .immagine {
            background-image: url('../img/blog/imagicle-beat-logo-grey.svg');
        }
    }
    .header-blog {
        position: relative;
        //background-color: $color_celeste;
        color: #fff;
        background-image: url('../img/blog/single-top-blog.png');
        background-size: cover;
        background-position: left top;
        background-repeat: no-repeat;
        margin-top: -70px;
        padding-top: 70px;
        .ombra {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0,17,57,.4);
        }
        .titolo5 {
            padding-bottom: 0;
            color: #fff;
        }
        h1 {
            border-bottom: 1px solid #fff;
            color: #fff;
            padding: 40px 0;
        }
        .tags {
            text-transform: uppercase;
            font-size: 18px;
            line-height: 26px;
            padding-top: 10px;
            padding-bottom: 10px;
        }
        .social {
            font-size: 26px;
            a {
                padding-right: 5px;
                border-radius: 3px;
            }

        }
        .author {
            cursor: pointer;
            img {
                width: 50px;
                height: auto;
            }
        }
        @include a_style(#fff, #fff);

    }
    .body-blog {
        background-image: url('../img/blog/single-blog-sfondo.svg');
        background-size: cover;
        background-position: left top;
        background-repeat: repeat-y;
    }
    .content-blog {
        h2 {
            font-size: 50px;
            font-weight: 500;
            line-height: 120%;
            padding: 20px 0 20px 0;
            color: $txt_blu;
        }

        h3 {
            font-size: 32px;
            font-weight: 500;
            line-height: 42px;
            padding: 15px 0 15px 0;
            color: $txt_blu;
        }
        ul, ol {
            margin-left: 0;
            padding-left: 20px;
            li {
                color: $color_testo_blog;
                font-size: 20px;
                margin-bottom: 10px;
                line-height: 30px;
            }
        }
        p {
            color: $color_testo_blog;
            padding: 0;
            margin: 0;
            padding-bottom: 20px;
            font-size: 20px;
            line-height: 30px;
        }
        p.subhead {
            font-size: 32px;
            line-height: 42px;
            color: $txt_blu;
        }
        blockquote {
            color: $color_link;
            font-family: $font_titoli;
            font-size: 32px;
            padding: 60px 0;
            margin: 0 100px 0 100px;
            position: relative;
            p {
                padding-bottom: 0;
                color: $color_link;
                font-family: $font_titoli;
                font-size: 32px;
                line-height: 42px;
            }
            cite {
                font-size: 28px;
            }
            &:before {
                display: block;
                /*                content: "\f10d";
                                text-rendering: auto;
                                -webkit-font-smoothing: antialiased;
                                font-family: "Font Awesome 5 Pro";
                                font-weight: 900;*/
                content: " ";
                background-image: url(../img/virgolette-cit.svg);
                width: 58px;
                height: 41px;
                float: left;
                position: absolute;
                left: -70px;
                top: 50px;
                background-color: white;
                background-position: center;
                background-repeat: no-repeat;
            }
        }
        figure.w100 img {
            width: 100%;
        }
        .sfondo-grigio, .sfondo-celeste {
            padding: 20px;
            margin: 20px 0;
            color: $color_testo_blog;
            ul, ol {
                color: $color_testo_blog;
            }
        }
        .sfondo-blu {
            padding: 20px;
            h1,h2,h3,h4,h5,h6,p {
                color: #fff;
            }
            @include a_style(#fff, #fff);
        }
        .negativo {
            h1,h2,h3,h4,h5,h6,p {
                color: #fff;
            }
            @include a_style(#fff, #fff);
        }
    }
    .other-blog {
        .tags {
            text-transform: uppercase;
            font-size: 18px;
            line-height: 26px;
            padding-bottom: 30px;
            @include a_style($color_testo_blog, $txt_blu);
            a {
                background-color: $sfondo_grigio;
                border-radius: 20vh;
                margin-right: 5px;
                padding: 0px 10px;
            }
        }
        .social {
            font-size: 28px;
            padding-bottom: 30px;
            a {
                padding-right: 5px;
            }
            @include a_style($txt_blu, $color_link_over);
        }
    }
    .comment-blog {
        .comment-form-cookies-consent label {
            width: auto;
        }
        .comments-title {
            display: none;
        }
        .comment-list {
            margin-left: 0;
            list-style: none;
            padding-left: 0;
            border-top: 1px solid $color_grigio_linea;
        }
        .children {
            border-top: 1px solid $color_grigio_linea;
            margin-left: 0;
            list-style: none;
        }
        .comment-body {
            padding-top: 15px;
            padding-bottom: 15px;

            .comment-author {
                .fn {     
                    text-transform: uppercase;
                }
                .avatar,.says {
                    display: none;
                }
            }
            .comment-metadata {
                font-size: 80%;
                @include a_style($txt_blu, $color_rosso);
            }
            .comment-content, .comment-awaiting-moderation {
                p {
                    padding-bottom: 0;
                    margin-bottom: 0;
                }
            }
        }
        .comment-respond {
            padding-top: 40px;
            padding-bottom: 40px;
            .comment-notes {
                font-weight: 300;
            }
            .comment-form-url {
                //display: none;
            }
            .comment-form-comment {
                font-size: 80%;
            }
            #cancel-comment-reply-link {
                float: right;
            }
        }
    }
}

/* -------------------------------------------------- */
/*	SINGLE BLOG OLD
/* -------------------------------------------------- */

/** OVERRIDE MENU **/
body.single-cpt_blog #page.site {
    margin-top: 0!important;
    #masthead.site-header .menu__header {
        position: relative;
    }
}

#primary.site-main.blog-oldsite-single {

    .content-blog {
        font-size: 20px;
        color: $color_testo_blog;
        line-height: 30px;

        .aligncenter {
            display: block !important;
            margin-left: auto!important;
            margin-right: auto!important;
        }

        .et_pb_text_inner div span, .et_pb_text_inner p span {
            font-size: 20px!important;
        }

        .et-l .et_pb_row_1.et_pb_row {
            margin-left: auto!important;
        }

        h1, h1 span, h2, h2 span {
            font-size: 50px!important;
            font-weight: 500!important;
            line-height: 120%!important;
            padding: 20px 0 20px 0!important;
            color: $txt_blu!important;
        }

        h3, h3 span {
            font-size: 32px!important;
            font-weight: 500!important;
            line-height: 42px!important;
            padding: 15px 0 15px 0!important;
            color: $txt_blu!important;
        }
        /*        h6, p.subhead, .subhead {
                    font-size: 32px!important;
                    line-height: 42px!important;
                    color: $txt_blu!important;
                    font-family: $font_titoli!important;
                }*/
        .shareaholic-canvas {
            max-width: 1080px;
            margin: 0 auto;
            padding-bottom: 60px;
            width: 80%;
        }

        /*    #imaAuthorAvatar {
                display: none;
            }*/
        .et-l .et_pb_text_1 h1 {
            font-size: 44px;
            color: $txt_blu;
        }

        /*        .et_pb_section_0 {
                    padding: 4% 0 !important;
                    h1.entry-title, h1, h1 span  {
                        font-size: $size_h1 !important;
                    }
                }*/
        /*    .et_pb_section_1 {
                display: none;
            }*/
        #et-boc .et-l .et_pb_with_background .et_pb_row, #et-boc .et-l .single.et_full_width_page .et_pb_row, #et-boc .et-l .single.et_full_width_portfolio_page .et_pb_row {
            width: 100%;
            max-width: 1140px;
            padding-right: 9px;
            padding-left: 9px;
        }
        #et-boc strong a, #et-boc strong a {
            font-weight: bold!important;
        }
        #et-boc .et-l .et_pb_module blockquote {
            margin: 0 100px;
            padding-left: inherit;
            border-left: 0;
            border-color: #fff;
            font-style: normal;
        }
        #et-boc .et-l .et_pb_section_0.et_pb_section {
            background-color: transparent!important;
        }
    }
}
@media (max-width: 1199px) {
    // lg
    #primary.site-main.blog-oldsite-single .content-blog #et-boc .et-l .et_pb_with_background .et_pb_row, 
    #primary.site-main.blog-oldsite-single .content-blog #et-boc .et-l .single.et_full_width_page .et_pb_row, 
    #primary.site-main.blog-oldsite-single .content-blog #et-boc .et-l .single.et_full_width_portfolio_page .et_pb_row {
        width: 100%;
        max-width: 960px;
    }
}
@media (max-width: 991px) {
    //md
    #primary.site-main.blog-oldsite-single .content-blog #et-boc .et-l .et_pb_with_background .et_pb_row, 
    #primary.site-main.blog-oldsite-single .content-blog #et-boc .et-l .single.et_full_width_page .et_pb_row, 
    #primary.site-main.blog-oldsite-single .content-blog #et-boc .et-l .single.et_full_width_portfolio_page .et_pb_row {
        width: 100%;
        max-width: 720px;
    }
}
@media (max-width: 767px) { 
    //sm
    #primary.site-main.blog-oldsite-single .content-blog #et-boc .et-l .et_pb_with_background .et_pb_row, 
    #primary.site-main.blog-oldsite-single .content-blog #et-boc .et-l .single.et_full_width_page .et_pb_row, 
    #primary.site-main.blog-oldsite-single .content-blog #et-boc .et-l .single.et_full_width_portfolio_page .et_pb_row {
        width: 100%;
        max-width: 540px;
    }
}
@media (max-width: 575px) { 
    // xs
    #primary.site-main.blog-oldsite-single .content-blog #et-boc .et-l .et_pb_with_background .et_pb_row, 
    #primary.site-main.blog-oldsite-single .content-blog #et-boc .et-l .single.et_full_width_page .et_pb_row, 
    #primary.site-main.blog-oldsite-single .content-blog #et-boc .et-l .single.et_full_width_portfolio_page .et_pb_row {
        width: 100%;
        max-width: 100%;
    }
}
