@charset "UTF-8";
/*!
 * Bootstrap
 */
/*!
 * Bootstrap v4.5.2 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 9px;
  padding-left: 9px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -9px;
  margin-left: -9px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 9px;
  padding-left: 9px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }
  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }
  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }
  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }
  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}
.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50%/50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: none;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 9px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -9px;
    margin-left: -9px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 9px;
    margin-bottom: 0;
    margin-left: 9px;
  }
}

.card-group > .card {
  margin-bottom: 9px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item {
  display: flex;
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #0062cc;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}
.alert-primary hr {
  border-top-color: #9fcdff;
}
.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50%/100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
/*!
 * Bootstrap Grid v4.5.2 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 9px;
  padding-left: 9px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -9px;
  margin-left: -9px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 9px;
  padding-left: 9px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }
  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }
  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }
  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }
  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
/*!
 * Bootstrap Reboot v4.5.2 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

/*!
 * Valnan v3.0
 */
/* 
    Created on : 31-ago-2020, 17.52.38
    Author     : veronica
*/
@font-face {
  font-family: "Gotham Rounded";
  src: url("../fonts/Gotham/GothamRounded-Medium.eot");
  src: url("../fonts/Gotham/GothamRounded-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Gotham/GothamRounded-Medium.woff2") format("woff2"), url("../fonts/Gotham/GothamRounded-Medium.woff") format("woff"), url("../fonts/Gotham/GothamRounded-Medium.ttf") format("truetype"), url("../fonts/Gotham/GothamRounded-Medium.svg#GothamRounded-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gotham Rounded Book";
  src: url("../fonts/Gotham/GothamRounded-Book.eot");
  src: url("../fonts/Gotham/GothamRounded-Book.eot?#iefix") format("embedded-opentype"), url("../fonts/Gotham/GothamRounded-Book.woff2") format("woff2"), url("../fonts/Gotham/GothamRounded-Book.woff") format("woff"), url("../fonts/Gotham/GothamRounded-Book.ttf") format("truetype"), url("../fonts/Gotham/GothamRounded-Book.svg#GothamRounded-Book") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
/*@font-face {
    src: url('../fonts/SourceSansVariable/SourceSansVariable-Roman.otf.woff2?v3.006') format('woff2-variations');
    font-family:'Source Sans Variable';
    font-weight: 200 900;
    font-style: normal;
    font-display: swap;
}
@font-face {
    src: url('../fonts/SourceSansVariable/SourceSansVariable-Italic.otf.woff2?v3.006') format('woff2-variations');
    font-family:'Source Sans Variable';
    font-weight: 200 900;
    font-style: italic;
    font-display: swap;
}*/
@font-face {
  font-family: "Source Sans Variable";
  font-weight: 200 900;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: url("../fonts/SourceSansVariable/SourceSansVariable-Roman.otf.woff2") format("woff2-variations"), url("../fonts/source-sans-release/WOFF2/VAR/SourceSans3VF-Roman.ttf.woff2") format("woff2"), url("../fonts/source-sans-release/WOFF/VAR/SourceSans3VF-Roman.ttf.woff") format("woff"), url("../fonts/source-sans-release/VAR/SourceSans3VF-Roman.ttf") format("truetype");
}
@font-face {
  font-family: "Source Sans Variable";
  font-weight: 200 900;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
  src: url("../fonts/SourceSansVariable/SourceSansVariable-Italic.otf.woff2") format("woff2-variations"), url("../fonts/source-sans-release/WOFF2/VAR/SourceSans3VF-Italic.ttf.woff2") format("woff2"), url("../fonts/source-sans-release/WOFF/VAR/SourceSans3VF-Italic.ttf.woff") format("woff"), url("../fonts/source-sans-release/VAR/SourceSans3VF-Italic.ttf") format("truetype");
}
/*
    Created on : 31-ago-2020, 10.04.40
    Author     : veronica
*/
/*@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');
$font_base: 'Source Sans Pro', sans-serif;*/
/*
    Created on : 31-ago-2020, 10.04.40
    Author     : veronica
*/
/*
    Created on : 31-ago-2020, 10.04.40
    Author     : veronica
*/
html {
  font-size: 100%;
  scroll-behavior: smooth;
}

body {
  font-family: "Source Sans Variable", sans-serif;
  font-weight: 450;
  background-color: #fff;
  color: #5C6B7A;
  font-size: 18px;
  line-height: 1.3;
  text-align: inherit;
}
body a {
  color: #429AFF;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
}
body a:focus {
  outline: 0;
}
body a:visited {
  color: #429AFF;
}
body a:hover {
  color: #429AFF;
  text-decoration: underline;
}
body a.link {
  font-weight: 800;
  color: #00225A;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
  display: inline-block;
  font-size: 88%;
  text-transform: uppercase;
}
body a.link:focus {
  outline: 0;
}
body a.link:visited {
  color: #00225A;
}
body a.link:hover {
  color: #00225A;
  text-decoration: none;
}
body a.link:hover:after {
  padding-left: 20px;
}
body a.link:after {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f061";
  padding-left: 10px;
  transition: all 0.4s ease-in-out;
}
body a.link-prev {
  font-weight: 800;
  color: #00225A;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
  display: inline-block;
  font-size: 88%;
  text-transform: uppercase;
}
body a.link-prev:focus {
  outline: 0;
}
body a.link-prev:visited {
  color: #00225A;
}
body a.link-prev:hover {
  color: #00225A;
  text-decoration: none;
}
body a.link-prev:hover:before {
  padding-right: 20px;
}
body a.link-prev:before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f060";
  padding-right: 10px;
  transition: all 0.4s ease-in-out;
}
body a.link.toggle-down:after {
  content: "\f063";
}
body a.link.toggle-down.active:after {
  content: "\f062";
}
body a.link.play:after {
  content: "\f144";
}
body a.pulsante, body .wp-block-button__link, body .wp-block-file__button, body .wp-block-file .wp-block-file__button, body submit, body input[type=submit] {
  position: relative;
  border-radius: 25px;
  background: #00225A;
  color: #fff;
  border: 2px solid #00225A;
  box-shadow: none;
  text-shadow: none;
  text-decoration: none !important;
  vertical-align: middle;
  display: inline-block;
  width: auto;
  margin: 0;
  font-size: 88%;
  font-weight: 700;
  text-transform: uppercase;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  -o-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  padding: 10px 30px;
  cursor: pointer;
  min-width: 155px;
  text-align: center;
}
body a.pulsante:visited, body .wp-block-button__link:visited, body .wp-block-file__button:visited, body .wp-block-file .wp-block-file__button:visited, body submit:visited, body input[type=submit]:visited {
  color: #fff;
}
body a.pulsante:hover, body .wp-block-button__link:hover, body .wp-block-file__button:hover, body .wp-block-file .wp-block-file__button:hover, body submit:hover, body input[type=submit]:hover {
  font-weight: 700;
  background: transparent;
  color: #00225A;
  border-color: #00225A;
  box-shadow: none;
}
body a.pulsante.green {
  position: relative;
  border-radius: 25px;
  background: #078855;
  color: #fff;
  border: 2px solid #078855;
  box-shadow: none;
  text-shadow: none;
  text-decoration: none !important;
  vertical-align: middle;
  display: inline-block;
  width: auto;
  margin: 0;
  font-size: 88%;
  font-weight: 700;
  text-transform: uppercase;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  -o-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  padding: 10px 30px;
  cursor: pointer;
  min-width: 155px;
  text-align: center;
}
body a.pulsante.green:visited {
  color: #fff;
}
body a.pulsante.green:hover {
  font-weight: 700;
  background: transparent;
  color: #078855;
  border-color: #078855;
  box-shadow: none;
}
body a.pulsante.viola {
  position: relative;
  border-radius: 25px;
  background: #5D5BD4;
  color: #fff;
  border: 2px solid #5D5BD4;
  box-shadow: none;
  text-shadow: none;
  text-decoration: none !important;
  vertical-align: middle;
  display: inline-block;
  width: auto;
  margin: 0;
  font-size: 88%;
  font-weight: 700;
  text-transform: uppercase;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  -o-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  padding: 10px 30px;
  cursor: pointer;
  min-width: 155px;
  text-align: center;
}
body a.pulsante.viola:visited {
  color: #fff;
}
body a.pulsante.viola:hover {
  font-weight: 700;
  background: transparent;
  color: #5D5BD4;
  border-color: #5D5BD4;
  box-shadow: none;
}
body a.pulsante.viola-negativo {
  position: relative;
  border-radius: 25px;
  background: transparent;
  color: #5D5BD4;
  border: 2px solid #5D5BD4;
  box-shadow: none;
  text-shadow: none;
  text-decoration: none !important;
  vertical-align: middle;
  display: inline-block;
  width: auto;
  margin: 0;
  font-size: 88%;
  font-weight: 700;
  text-transform: uppercase;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  -o-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  padding: 10px 30px;
  cursor: pointer;
  min-width: 155px;
  text-align: center;
}
body a.pulsante.viola-negativo:visited {
  color: #5D5BD4;
}
body a.pulsante.viola-negativo:hover {
  font-weight: 700;
  background: #5D5BD4;
  color: #fff;
  border-color: #5D5BD4;
  box-shadow: none;
}
body .gruppo-pulsanti a.pulsante, body .gruppo-pulsanti .wp-block-button__link, body .gruppo-pulsanti submit, body .gruppo-pulsanti input[type=submit] {
  position: relative;
  border-radius: 25px;
  background: transparent;
  color: #00225A;
  border: 2px solid #00225A;
  box-shadow: none;
  text-shadow: none;
  text-decoration: none !important;
  vertical-align: middle;
  display: inline-block;
  width: auto;
  margin: 0;
  font-size: 88%;
  font-weight: 700;
  text-transform: uppercase;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  -o-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  padding: 10px 30px;
  cursor: pointer;
  min-width: 155px;
  text-align: center;
}
body .gruppo-pulsanti a.pulsante:visited, body .gruppo-pulsanti .wp-block-button__link:visited, body .gruppo-pulsanti submit:visited, body .gruppo-pulsanti input[type=submit]:visited {
  color: #00225A;
}
body .gruppo-pulsanti a.pulsante:hover, body .gruppo-pulsanti .wp-block-button__link:hover, body .gruppo-pulsanti submit:hover, body .gruppo-pulsanti input[type=submit]:hover {
  font-weight: 700;
  background: #00225A;
  color: #fff;
  border-color: #00225A;
  box-shadow: none;
}
body .audio_wrapper {
  position: relative;
  border-radius: 25px;
  background: #00225A;
  color: #fff;
  border: 2px solid #00225A;
  box-shadow: none;
  text-shadow: none;
  text-decoration: none !important;
  vertical-align: middle;
  display: inline-block;
  width: auto;
  margin: 0;
  font-size: 88%;
  font-weight: 700;
  text-transform: uppercase;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  -o-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  padding: 10px 30px;
  cursor: pointer;
  min-width: 155px;
  text-align: center;
  padding: 19px 10px;
}
body .audio_wrapper:visited {
  color: #fff;
}
body .audio_wrapper:hover {
  font-weight: 700;
  background: transparent;
  color: #00225A;
  border-color: #00225A;
  box-shadow: none;
}
body .audio_wrapper div.essential_audio > div:nth-child(1) div, body .audio_wrapper div.essential_audio > div:nth-child(1) div.play {
  background-color: #00225A !important;
  border: 1px solid #fff;
}
body .audio_wrapper div.essential_audio > div:nth-child(2) div {
  background-color: #85E2FF;
}
body .audio_wrapper div.essential_audio > div:nth-child(2):after {
  background-color: #ededed;
}
body .audio_wrapper:hover div.essential_audio > div:nth-child(1) div, body .audio_wrapper:hover div.essential_audio > div:nth-child(1) div.play {
  border: 1px solid #00225A;
}
body .audio_wrapper:hover div.essential_audio > div:nth-child(2) div {
  background-color: #666;
}
body .audio_wrapper:hover div.essential_audio > div:nth-child(2):after {
  background-color: #000;
}

body.noscroll {
  overflow: hidden;
}

p, .testo, .contenuto {
  line-height: 1.3;
}
p blockquote, .testo blockquote, .contenuto blockquote {
  font-style: italic;
}
p blockquote p, .testo blockquote p, .contenuto blockquote p {
  margin-bottom: 7px;
}
p blockquote cite, .testo blockquote cite, .contenuto blockquote cite {
  font-size: 85%;
}

h1, h2, h3, h4, h5, .titolo, .titolo1, .titolo2, .titolo3, .titolo4, .titolo5 {
  font-family: "Gotham Rounded";
  letter-spacing: -1px;
  padding: 0 0 10px 0;
  margin: 0;
  outline: none;
  color: #00225A;
}
h1 a, h2 a, h3 a, h4 a, h5 a, .titolo a, .titolo1 a, .titolo2 a, .titolo3 a, .titolo4 a, .titolo5 a {
  color: #00225A;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
}
h1 a:focus, h2 a:focus, h3 a:focus, h4 a:focus, h5 a:focus, .titolo a:focus, .titolo1 a:focus, .titolo2 a:focus, .titolo3 a:focus, .titolo4 a:focus, .titolo5 a:focus {
  outline: 0;
}
h1 a:visited, h2 a:visited, h3 a:visited, h4 a:visited, h5 a:visited, .titolo a:visited, .titolo1 a:visited, .titolo2 a:visited, .titolo3 a:visited, .titolo4 a:visited, .titolo5 a:visited {
  color: #00225A;
}
h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, .titolo a:hover, .titolo1 a:hover, .titolo2 a:hover, .titolo3 a:hover, .titolo4 a:hover, .titolo5 a:hover {
  color: #429AFF;
  text-decoration: underline;
}
h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, .titolo a:hover, .titolo1 a:hover, .titolo2 a:hover, .titolo3 a:hover, .titolo4 a:hover, .titolo5 a:hover {
  text-decoration: none;
}

h3, h4, h5, .titolo3, .titolo4, .titolo5 {
  letter-spacing: -0.5px;
}

.enfasi {
  font-family: "Gotham Rounded Book";
  color: #00225A;
  font-size: 120% !important;
}

.subhead {
  font-family: "Gotham Rounded Book";
  letter-spacing: -1px;
  font-size: 32px;
  line-height: 42px;
  color: #00225A;
}

h1, .titolo1 {
  font-size: 64px;
  font-weight: 500;
  line-height: 1;
  padding-bottom: 15px;
}

h2, .titolo2 {
  font-size: 44px;
  font-weight: 700;
  line-height: 1.15;
  padding-bottom: 20px;
}

h3, .titolo3 {
  font-size: 32px;
  font-weight: 700;
  line-height: 1.3;
}

h4, .titolo4 {
  font-size: 26px;
  font-weight: 700;
  line-height: 1.3;
}

h5, .titolo5 {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.3;
}

h6, .sottotitolo {
  font-size: 22px;
  font-weight: 450;
  line-height: 1.3;
}

.subhead {
  font-size: 32px;
  line-height: 1.3;
}

/* INSERISCO IL PADDING COME DA SPAZIATORE */
.blocco {
  padding-top: 120px;
  padding-bottom: 120px;
}

.blocco-top {
  padding-top: 120px;
}

.blocco-bottom {
  padding-bottom: 120px;
}

/* INSERISCO IL PADDING COME DA SPAZIATORE 2/3 */
.blocco-m {
  padding-top: calc(80px);
  padding-bottom: calc(80px);
}

.blocco-m-t {
  padding-top: calc(80px);
}

.blocco-m-b {
  padding-bottom: calc(80px);
}

.blocco-s {
  padding-top: calc(60px);
  padding-bottom: calc(60px);
}

.blocco-s-t {
  padding-top: calc(60px);
}

.blocco-s-b {
  padding-bottom: calc(60px);
}

/* INSERISCO IL MARGINE COME DA SPAZIATORE */
.margini {
  margin-top: 120px;
  margin-bottom: 120px;
}

.margine-top {
  margin-top: 120px;
}

.margine-bottom {
  margin-bottom: 120px;
}

.margini-m {
  margin-top: calc(80px);
  margin-bottom: calc(80px);
}

.margine-m-t {
  margin-top: calc(80px);
}

.margine-m-b {
  margin-bottom: calc(80px);
}

.margini-s {
  margin-top: calc(60px);
  margin-bottom: calc(60px);
}

.margine-s-t {
  margin-top: calc(60px);
}

.margine-s-b {
  margin-bottom: calc(60px);
}

.curva-tl {
  border-top-left-radius: 300px;
}

.curva-tr {
  border-top-right-radius: 300px;
}

.curva-br {
  border-bottom-right-radius: 300px;
}

.curva-bl {
  border-bottom-left-radius: 300px;
}

.titolo2.big {
  font-size: 55px;
  line-height: 56px;
}

.azzera {
  margin: 0 !important;
  padding: 0 !important;
}

.upper {
  text-transform: uppercase;
}

.maiuscoletto {
  text-transform: lowercase;
  font-variant: small-caps;
}

.w100 {
  width: 100% !important;
}

.h100 {
  height: 100%;
}

.no-padding {
  padding: 0;
}

.img-responsive {
  display: inline-block;
}

.relative {
  position: relative;
}

.duecol {
  columns: 2;
}

.lista_due_colonne {
  columns: 2;
  list-style: none;
  margin: 40px 0;
  padding: 0;
}
.lista_due_colonne li {
  font-weight: bold;
  font-size: 15px;
  letter-spacing: 1px;
  padding: 5px 0;
}

/* DISTANZIO DI 20PX */
.spazio {
  padding-top: 20px;
  padding-bottom: 20px;
}

.spazio-t {
  padding-top: 20px;
}

.spazio-b {
  padding-bottom: 20px;
}

.spazio-r {
  padding-right: 20px;
}

.spazio-l {
  padding-left: 20px;
}

.bianco {
  color: #fff;
}

.blu {
  color: #00225A;
}

.celeste {
  color: #00C0F0 !important;
}

.giallo {
  color: #FBB03B;
}

.rosso {
  color: #F05032 !important;
}
.rosso a {
  color: #F05032;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
}
.rosso a:focus {
  outline: 0;
}
.rosso a:visited {
  color: #F05032;
}
.rosso a:hover {
  color: #004182;
  text-decoration: underline;
}

.nero {
  color: #000;
}

.sfondo-grigio {
  background-color: #E9EDF3;
  color: #5C6B7A;
}

.sfondo-bianco {
  background-color: #fff;
  color: #5C6B7A;
}
.sfondo-bianco h1, .sfondo-bianco h2, .sfondo-bianco h3, .sfondo-bianco h4, .sfondo-bianco h5, .sfondo-bianco h6, .sfondo-bianco .titolo, .sfondo-bianco .titolo1, .sfondo-bianco .titolo2, .sfondo-bianco .titolo3, .sfondo-bianco .titolo4 {
  color: #00225A;
}
.sfondo-bianco a {
  color: #429AFF;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
}
.sfondo-bianco a:focus {
  outline: 0;
}
.sfondo-bianco a:visited {
  color: #429AFF;
}
.sfondo-bianco a:hover {
  color: #429AFF;
  text-decoration: underline;
}
.sfondo-bianco a.link {
  font-weight: 800;
  color: #00225A;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
  display: inline-block;
  font-size: 88%;
  text-transform: uppercase;
}
.sfondo-bianco a.link:focus {
  outline: 0;
}
.sfondo-bianco a.link:visited {
  color: #00225A;
}
.sfondo-bianco a.link:hover {
  color: #00225A;
  text-decoration: none;
}
.sfondo-bianco a.link:hover:after {
  padding-left: 20px;
}
.sfondo-bianco a.link:after {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f061";
  padding-left: 10px;
  transition: all 0.4s ease-in-out;
}
.sfondo-bianco a.pulsante, .sfondo-bianco .wp-block-button__link, .sfondo-bianco submit, .sfondo-bianco input[type=submit], .sfondo-bianco input[type=reset], .sfondo-bianco input[type=button] {
  position: relative;
  border-radius: 25px;
  background: #00225A;
  color: #fff;
  border: 2px solid #00225A;
  box-shadow: none;
  text-shadow: none;
  text-decoration: none !important;
  vertical-align: middle;
  display: inline-block;
  width: auto;
  margin: 0;
  font-size: 88%;
  font-weight: 700;
  text-transform: uppercase;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  -o-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  padding: 10px 30px;
  cursor: pointer;
  min-width: 155px;
  text-align: center;
}
.sfondo-bianco a.pulsante:visited, .sfondo-bianco .wp-block-button__link:visited, .sfondo-bianco submit:visited, .sfondo-bianco input[type=submit]:visited, .sfondo-bianco input[type=reset]:visited, .sfondo-bianco input[type=button]:visited {
  color: #fff;
}
.sfondo-bianco a.pulsante:hover, .sfondo-bianco .wp-block-button__link:hover, .sfondo-bianco submit:hover, .sfondo-bianco input[type=submit]:hover, .sfondo-bianco input[type=reset]:hover, .sfondo-bianco input[type=button]:hover {
  font-weight: 700;
  background: transparent;
  color: #00225A;
  border-color: #00225A;
  box-shadow: none;
}
.sfondo-bianco a.pulsante.negativo, .sfondo-bianco input[type=submit].negativo, .sfondo-bianco input[type=reset].negativo, .sfondo-bianco input[type=button].negativo {
  position: relative;
  border-radius: 25px;
  background: transparent;
  color: #00225A;
  border: 2px solid #00225A;
  box-shadow: none;
  text-shadow: none;
  text-decoration: none !important;
  vertical-align: middle;
  display: inline-block;
  width: auto;
  margin: 0;
  font-size: 88%;
  font-weight: 700;
  text-transform: uppercase;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  -o-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  padding: 10px 30px;
  cursor: pointer;
  min-width: 155px;
  text-align: center;
}
.sfondo-bianco a.pulsante.negativo:visited, .sfondo-bianco input[type=submit].negativo:visited, .sfondo-bianco input[type=reset].negativo:visited, .sfondo-bianco input[type=button].negativo:visited {
  color: #00225A;
}
.sfondo-bianco a.pulsante.negativo:hover, .sfondo-bianco input[type=submit].negativo:hover, .sfondo-bianco input[type=reset].negativo:hover, .sfondo-bianco input[type=button].negativo:hover {
  font-weight: 700;
  background: #00225A;
  color: #fff;
  border-color: #00225A;
  box-shadow: none;
}
.sfondo-bianco .gruppo-pulsanti a.pulsante, .sfondo-bianco .gruppo-pulsanti .wp-block-button__link, .sfondo-bianco .gruppo-pulsanti submit, .sfondo-bianco .gruppo-pulsanti input[type=submit], .sfondo-bianco .gruppo-pulsanti input[type=reset], .sfondo-bianco .gruppo-pulsanti input[type=button] {
  position: relative;
  border-radius: 25px;
  background: transparent;
  color: #00225A;
  border: 2px solid #00225A;
  box-shadow: none;
  text-shadow: none;
  text-decoration: none !important;
  vertical-align: middle;
  display: inline-block;
  width: auto;
  margin: 0;
  font-size: 88%;
  font-weight: 700;
  text-transform: uppercase;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  -o-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  padding: 10px 30px;
  cursor: pointer;
  min-width: 155px;
  text-align: center;
}
.sfondo-bianco .gruppo-pulsanti a.pulsante:visited, .sfondo-bianco .gruppo-pulsanti .wp-block-button__link:visited, .sfondo-bianco .gruppo-pulsanti submit:visited, .sfondo-bianco .gruppo-pulsanti input[type=submit]:visited, .sfondo-bianco .gruppo-pulsanti input[type=reset]:visited, .sfondo-bianco .gruppo-pulsanti input[type=button]:visited {
  color: #00225A;
}
.sfondo-bianco .gruppo-pulsanti a.pulsante:hover, .sfondo-bianco .gruppo-pulsanti .wp-block-button__link:hover, .sfondo-bianco .gruppo-pulsanti submit:hover, .sfondo-bianco .gruppo-pulsanti input[type=submit]:hover, .sfondo-bianco .gruppo-pulsanti input[type=reset]:hover, .sfondo-bianco .gruppo-pulsanti input[type=button]:hover {
  font-weight: 700;
  background: #00225A;
  color: #fff;
  border-color: #00225A;
  box-shadow: none;
}

.sfondo-celeste {
  background-color: #00C0F0;
  color: #00225A;
}
.sfondo-celeste h1, .sfondo-celeste h2, .sfondo-celeste h3, .sfondo-celeste h4, .sfondo-celeste h5, .sfondo-celeste h6, .sfondo-celeste .titolo, .sfondo-celeste .titolo1, .sfondo-celeste .titolo2, .sfondo-celeste .titolo3, .sfondo-celeste .titolo4 {
  color: #00225A;
}
.sfondo-celeste a {
  color: #00225A;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
}
.sfondo-celeste a:focus {
  outline: 0;
}
.sfondo-celeste a:visited {
  color: #00225A;
}
.sfondo-celeste a:hover {
  color: #B8D1F5;
  text-decoration: underline;
}
.sfondo-celeste a.link {
  font-weight: 800;
  color: #00225A;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
  display: inline-block;
  font-size: 88%;
  text-transform: uppercase;
}
.sfondo-celeste a.link:focus {
  outline: 0;
}
.sfondo-celeste a.link:visited {
  color: #00225A;
}
.sfondo-celeste a.link:hover {
  color: #00225A;
  text-decoration: none;
}
.sfondo-celeste a.link:hover:after {
  padding-left: 20px;
}
.sfondo-celeste a.link:after {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f061";
  padding-left: 10px;
  transition: all 0.4s ease-in-out;
}
.sfondo-celeste a.pulsante, .sfondo-celeste .wp-block-button__link, .sfondo-celeste submit, .sfondo-celeste input[type=submit], .sfondo-celeste input[type=reset], .sfondo-celeste input[type=button] {
  position: relative;
  border-radius: 25px;
  background: #00225A;
  color: #fff;
  border: 2px solid #00225A;
  box-shadow: none;
  text-shadow: none;
  text-decoration: none !important;
  vertical-align: middle;
  display: inline-block;
  width: auto;
  margin: 0;
  font-size: 88%;
  font-weight: 700;
  text-transform: uppercase;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  -o-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  padding: 10px 30px;
  cursor: pointer;
  min-width: 155px;
  text-align: center;
}
.sfondo-celeste a.pulsante:visited, .sfondo-celeste .wp-block-button__link:visited, .sfondo-celeste submit:visited, .sfondo-celeste input[type=submit]:visited, .sfondo-celeste input[type=reset]:visited, .sfondo-celeste input[type=button]:visited {
  color: #fff;
}
.sfondo-celeste a.pulsante:hover, .sfondo-celeste .wp-block-button__link:hover, .sfondo-celeste submit:hover, .sfondo-celeste input[type=submit]:hover, .sfondo-celeste input[type=reset]:hover, .sfondo-celeste input[type=button]:hover {
  font-weight: 700;
  background: transparent;
  color: #00225A;
  border-color: #00225A;
  box-shadow: none;
}
.sfondo-celeste a.pulsante.negativo, .sfondo-celeste input[type=submit].negativo, .sfondo-celeste input[type=reset].negativo, .sfondo-celeste input[type=button].negativo {
  position: relative;
  border-radius: 25px;
  background: transparent;
  color: #00225A;
  border: 2px solid #00225A;
  box-shadow: none;
  text-shadow: none;
  text-decoration: none !important;
  vertical-align: middle;
  display: inline-block;
  width: auto;
  margin: 0;
  font-size: 88%;
  font-weight: 700;
  text-transform: uppercase;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  -o-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  padding: 10px 30px;
  cursor: pointer;
  min-width: 155px;
  text-align: center;
}
.sfondo-celeste a.pulsante.negativo:visited, .sfondo-celeste input[type=submit].negativo:visited, .sfondo-celeste input[type=reset].negativo:visited, .sfondo-celeste input[type=button].negativo:visited {
  color: #00225A;
}
.sfondo-celeste a.pulsante.negativo:hover, .sfondo-celeste input[type=submit].negativo:hover, .sfondo-celeste input[type=reset].negativo:hover, .sfondo-celeste input[type=button].negativo:hover {
  font-weight: 700;
  background: #00225A;
  color: #fff;
  border-color: #00225A;
  box-shadow: none;
}
.sfondo-celeste .wpcf7-form input[type=text], .sfondo-celeste .wpcf7-form input[type=email], .sfondo-celeste .wpcf7-form input[type=confirm_email], .sfondo-celeste .wpcf7-form input[type=url], .sfondo-celeste .wpcf7-form input[type=password], .sfondo-celeste .wpcf7-form input[type=search], .sfondo-celeste .wpcf7-form input[type=number], .sfondo-celeste .wpcf7-form input[type=tel], .sfondo-celeste .wpcf7-form input[type=range],
.sfondo-celeste .wpcf7-form input[type=date], .sfondo-celeste .wpcf7-form input[type=month], .sfondo-celeste .wpcf7-form input[type=week], .sfondo-celeste .wpcf7-form input[type=time], .sfondo-celeste .wpcf7-form input[type=datetime], .sfondo-celeste .wpcf7-form input[type=datetime-local], .sfondo-celeste .wpcf7-form input[type=color], .sfondo-celeste .wpcf7-form input[type=file], .sfondo-celeste .wpcf7-form textarea, .sfondo-celeste .search-form input[type=text], .sfondo-celeste .search-form input[type=email], .sfondo-celeste .search-form input[type=confirm_email], .sfondo-celeste .search-form input[type=url], .sfondo-celeste .search-form input[type=password], .sfondo-celeste .search-form input[type=search], .sfondo-celeste .search-form input[type=number], .sfondo-celeste .search-form input[type=tel], .sfondo-celeste .search-form input[type=range],
.sfondo-celeste .search-form input[type=date], .sfondo-celeste .search-form input[type=month], .sfondo-celeste .search-form input[type=week], .sfondo-celeste .search-form input[type=time], .sfondo-celeste .search-form input[type=datetime], .sfondo-celeste .search-form input[type=datetime-local], .sfondo-celeste .search-form input[type=color], .sfondo-celeste .search-form input[type=file], .sfondo-celeste .search-form textarea, .sfondo-celeste .classic-form input[type=text], .sfondo-celeste .classic-form input[type=email], .sfondo-celeste .classic-form input[type=confirm_email], .sfondo-celeste .classic-form input[type=url], .sfondo-celeste .classic-form input[type=password], .sfondo-celeste .classic-form input[type=search], .sfondo-celeste .classic-form input[type=number], .sfondo-celeste .classic-form input[type=tel], .sfondo-celeste .classic-form input[type=range],
.sfondo-celeste .classic-form input[type=date], .sfondo-celeste .classic-form input[type=month], .sfondo-celeste .classic-form input[type=week], .sfondo-celeste .classic-form input[type=time], .sfondo-celeste .classic-form input[type=datetime], .sfondo-celeste .classic-form input[type=datetime-local], .sfondo-celeste .classic-form input[type=color], .sfondo-celeste .classic-form input[type=file], .sfondo-celeste .classic-form textarea {
  background-color: #00225A;
  color: #fff;
  border: 1px solid #00225A;
}
.sfondo-celeste .wpcf7-form input[type=text]:focus, .sfondo-celeste .wpcf7-form input[type=email]:focus, .sfondo-celeste .wpcf7-form input[type=confirm_email]:focus, .sfondo-celeste .wpcf7-form input[type=url]:focus, .sfondo-celeste .wpcf7-form input[type=password]:focus, .sfondo-celeste .wpcf7-form input[type=search]:focus, .sfondo-celeste .wpcf7-form input[type=number]:focus, .sfondo-celeste .wpcf7-form input[type=tel]:focus, .sfondo-celeste .wpcf7-form input[type=range]:focus,
.sfondo-celeste .wpcf7-form input[type=date]:focus, .sfondo-celeste .wpcf7-form input[type=month]:focus, .sfondo-celeste .wpcf7-form input[type=week]:focus, .sfondo-celeste .wpcf7-form input[type=time]:focus, .sfondo-celeste .wpcf7-form input[type=datetime]:focus, .sfondo-celeste .wpcf7-form input[type=datetime-local]:focus, .sfondo-celeste .wpcf7-form input[type=color]:focus, .sfondo-celeste .wpcf7-form input[type=file]:focus, .sfondo-celeste .wpcf7-form textarea:focus, .sfondo-celeste .search-form input[type=text]:focus, .sfondo-celeste .search-form input[type=email]:focus, .sfondo-celeste .search-form input[type=confirm_email]:focus, .sfondo-celeste .search-form input[type=url]:focus, .sfondo-celeste .search-form input[type=password]:focus, .sfondo-celeste .search-form input[type=search]:focus, .sfondo-celeste .search-form input[type=number]:focus, .sfondo-celeste .search-form input[type=tel]:focus, .sfondo-celeste .search-form input[type=range]:focus,
.sfondo-celeste .search-form input[type=date]:focus, .sfondo-celeste .search-form input[type=month]:focus, .sfondo-celeste .search-form input[type=week]:focus, .sfondo-celeste .search-form input[type=time]:focus, .sfondo-celeste .search-form input[type=datetime]:focus, .sfondo-celeste .search-form input[type=datetime-local]:focus, .sfondo-celeste .search-form input[type=color]:focus, .sfondo-celeste .search-form input[type=file]:focus, .sfondo-celeste .search-form textarea:focus, .sfondo-celeste .classic-form input[type=text]:focus, .sfondo-celeste .classic-form input[type=email]:focus, .sfondo-celeste .classic-form input[type=confirm_email]:focus, .sfondo-celeste .classic-form input[type=url]:focus, .sfondo-celeste .classic-form input[type=password]:focus, .sfondo-celeste .classic-form input[type=search]:focus, .sfondo-celeste .classic-form input[type=number]:focus, .sfondo-celeste .classic-form input[type=tel]:focus, .sfondo-celeste .classic-form input[type=range]:focus,
.sfondo-celeste .classic-form input[type=date]:focus, .sfondo-celeste .classic-form input[type=month]:focus, .sfondo-celeste .classic-form input[type=week]:focus, .sfondo-celeste .classic-form input[type=time]:focus, .sfondo-celeste .classic-form input[type=datetime]:focus, .sfondo-celeste .classic-form input[type=datetime-local]:focus, .sfondo-celeste .classic-form input[type=color]:focus, .sfondo-celeste .classic-form input[type=file]:focus, .sfondo-celeste .classic-form textarea:focus {
  background-color: transparent;
  color: #00225A;
  border: 1px solid #00225A;
}

.sfondo-cta {
  background-color: #00C0F0;
  color: #00225A;
}
.sfondo-cta h1, .sfondo-cta h2, .sfondo-cta h3, .sfondo-cta h4, .sfondo-cta h5, .sfondo-cta h6, .sfondo-cta .titolo, .sfondo-cta .titolo1, .sfondo-cta .titolo2, .sfondo-cta .titolo3, .sfondo-cta .titolo4 {
  color: #fff;
}
.sfondo-cta a {
  color: #B8D1F5;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
}
.sfondo-cta a:focus {
  outline: 0;
}
.sfondo-cta a:visited {
  color: #B8D1F5;
}
.sfondo-cta a:hover {
  color: #B8D1F5;
  text-decoration: underline;
}
.sfondo-cta a.link {
  font-weight: 800;
  color: #00225A;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
  display: inline-block;
  font-size: 88%;
  text-transform: uppercase;
}
.sfondo-cta a.link:focus {
  outline: 0;
}
.sfondo-cta a.link:visited {
  color: #00225A;
}
.sfondo-cta a.link:hover {
  color: #00225A;
  text-decoration: none;
}
.sfondo-cta a.link:hover:after {
  padding-left: 20px;
}
.sfondo-cta a.link:after {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f061";
  padding-left: 10px;
  transition: all 0.4s ease-in-out;
}
.sfondo-cta a.pulsante, .sfondo-cta .wp-block-button__link, .sfondo-cta submit, .sfondo-cta input[type=submit], .sfondo-cta input[type=reset], .sfondo-cta input[type=button] {
  position: relative;
  border-radius: 25px;
  background: #00225A;
  color: #fff;
  border: 2px solid #00225A;
  box-shadow: none;
  text-shadow: none;
  text-decoration: none !important;
  vertical-align: middle;
  display: inline-block;
  width: auto;
  margin: 0;
  font-size: 88%;
  font-weight: 700;
  text-transform: uppercase;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  -o-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  padding: 10px 30px;
  cursor: pointer;
  min-width: 155px;
  text-align: center;
}
.sfondo-cta a.pulsante:visited, .sfondo-cta .wp-block-button__link:visited, .sfondo-cta submit:visited, .sfondo-cta input[type=submit]:visited, .sfondo-cta input[type=reset]:visited, .sfondo-cta input[type=button]:visited {
  color: #fff;
}
.sfondo-cta a.pulsante:hover, .sfondo-cta .wp-block-button__link:hover, .sfondo-cta submit:hover, .sfondo-cta input[type=submit]:hover, .sfondo-cta input[type=reset]:hover, .sfondo-cta input[type=button]:hover {
  font-weight: 700;
  background: transparent;
  color: #00225A;
  border-color: #00225A;
  box-shadow: none;
}
.sfondo-cta a.pulsante.negativo, .sfondo-cta input[type=submit].negativo, .sfondo-cta input[type=reset].negativo, .sfondo-cta input[type=button].negativo {
  position: relative;
  border-radius: 25px;
  background: transparent;
  color: #00225A;
  border: 2px solid #00225A;
  box-shadow: none;
  text-shadow: none;
  text-decoration: none !important;
  vertical-align: middle;
  display: inline-block;
  width: auto;
  margin: 0;
  font-size: 88%;
  font-weight: 700;
  text-transform: uppercase;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  -o-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  padding: 10px 30px;
  cursor: pointer;
  min-width: 155px;
  text-align: center;
}
.sfondo-cta a.pulsante.negativo:visited, .sfondo-cta input[type=submit].negativo:visited, .sfondo-cta input[type=reset].negativo:visited, .sfondo-cta input[type=button].negativo:visited {
  color: #00225A;
}
.sfondo-cta a.pulsante.negativo:hover, .sfondo-cta input[type=submit].negativo:hover, .sfondo-cta input[type=reset].negativo:hover, .sfondo-cta input[type=button].negativo:hover {
  font-weight: 700;
  background: #00225A;
  color: #fff;
  border-color: #00225A;
  box-shadow: none;
}
.sfondo-cta .wpcf7-form input[type=text], .sfondo-cta .wpcf7-form input[type=email], .sfondo-cta .wpcf7-form input[type=confirm_email], .sfondo-cta .wpcf7-form input[type=url], .sfondo-cta .wpcf7-form input[type=password], .sfondo-cta .wpcf7-form input[type=search], .sfondo-cta .wpcf7-form input[type=number], .sfondo-cta .wpcf7-form input[type=tel], .sfondo-cta .wpcf7-form input[type=range],
.sfondo-cta .wpcf7-form input[type=date], .sfondo-cta .wpcf7-form input[type=month], .sfondo-cta .wpcf7-form input[type=week], .sfondo-cta .wpcf7-form input[type=time], .sfondo-cta .wpcf7-form input[type=datetime], .sfondo-cta .wpcf7-form input[type=datetime-local], .sfondo-cta .wpcf7-form input[type=color], .sfondo-cta .wpcf7-form input[type=file], .sfondo-cta .wpcf7-form textarea, .sfondo-cta .search-form input[type=text], .sfondo-cta .search-form input[type=email], .sfondo-cta .search-form input[type=confirm_email], .sfondo-cta .search-form input[type=url], .sfondo-cta .search-form input[type=password], .sfondo-cta .search-form input[type=search], .sfondo-cta .search-form input[type=number], .sfondo-cta .search-form input[type=tel], .sfondo-cta .search-form input[type=range],
.sfondo-cta .search-form input[type=date], .sfondo-cta .search-form input[type=month], .sfondo-cta .search-form input[type=week], .sfondo-cta .search-form input[type=time], .sfondo-cta .search-form input[type=datetime], .sfondo-cta .search-form input[type=datetime-local], .sfondo-cta .search-form input[type=color], .sfondo-cta .search-form input[type=file], .sfondo-cta .search-form textarea, .sfondo-cta .classic-form input[type=text], .sfondo-cta .classic-form input[type=email], .sfondo-cta .classic-form input[type=confirm_email], .sfondo-cta .classic-form input[type=url], .sfondo-cta .classic-form input[type=password], .sfondo-cta .classic-form input[type=search], .sfondo-cta .classic-form input[type=number], .sfondo-cta .classic-form input[type=tel], .sfondo-cta .classic-form input[type=range],
.sfondo-cta .classic-form input[type=date], .sfondo-cta .classic-form input[type=month], .sfondo-cta .classic-form input[type=week], .sfondo-cta .classic-form input[type=time], .sfondo-cta .classic-form input[type=datetime], .sfondo-cta .classic-form input[type=datetime-local], .sfondo-cta .classic-form input[type=color], .sfondo-cta .classic-form input[type=file], .sfondo-cta .classic-form textarea {
  background-color: #fff;
  color: #5C6B7A;
  border: 1px solid #00C0F0;
}
.sfondo-cta .wpcf7-form input[type=text]:focus, .sfondo-cta .wpcf7-form input[type=email]:focus, .sfondo-cta .wpcf7-form input[type=confirm_email]:focus, .sfondo-cta .wpcf7-form input[type=url]:focus, .sfondo-cta .wpcf7-form input[type=password]:focus, .sfondo-cta .wpcf7-form input[type=search]:focus, .sfondo-cta .wpcf7-form input[type=number]:focus, .sfondo-cta .wpcf7-form input[type=tel]:focus, .sfondo-cta .wpcf7-form input[type=range]:focus,
.sfondo-cta .wpcf7-form input[type=date]:focus, .sfondo-cta .wpcf7-form input[type=month]:focus, .sfondo-cta .wpcf7-form input[type=week]:focus, .sfondo-cta .wpcf7-form input[type=time]:focus, .sfondo-cta .wpcf7-form input[type=datetime]:focus, .sfondo-cta .wpcf7-form input[type=datetime-local]:focus, .sfondo-cta .wpcf7-form input[type=color]:focus, .sfondo-cta .wpcf7-form input[type=file]:focus, .sfondo-cta .wpcf7-form textarea:focus, .sfondo-cta .search-form input[type=text]:focus, .sfondo-cta .search-form input[type=email]:focus, .sfondo-cta .search-form input[type=confirm_email]:focus, .sfondo-cta .search-form input[type=url]:focus, .sfondo-cta .search-form input[type=password]:focus, .sfondo-cta .search-form input[type=search]:focus, .sfondo-cta .search-form input[type=number]:focus, .sfondo-cta .search-form input[type=tel]:focus, .sfondo-cta .search-form input[type=range]:focus,
.sfondo-cta .search-form input[type=date]:focus, .sfondo-cta .search-form input[type=month]:focus, .sfondo-cta .search-form input[type=week]:focus, .sfondo-cta .search-form input[type=time]:focus, .sfondo-cta .search-form input[type=datetime]:focus, .sfondo-cta .search-form input[type=datetime-local]:focus, .sfondo-cta .search-form input[type=color]:focus, .sfondo-cta .search-form input[type=file]:focus, .sfondo-cta .search-form textarea:focus, .sfondo-cta .classic-form input[type=text]:focus, .sfondo-cta .classic-form input[type=email]:focus, .sfondo-cta .classic-form input[type=confirm_email]:focus, .sfondo-cta .classic-form input[type=url]:focus, .sfondo-cta .classic-form input[type=password]:focus, .sfondo-cta .classic-form input[type=search]:focus, .sfondo-cta .classic-form input[type=number]:focus, .sfondo-cta .classic-form input[type=tel]:focus, .sfondo-cta .classic-form input[type=range]:focus,
.sfondo-cta .classic-form input[type=date]:focus, .sfondo-cta .classic-form input[type=month]:focus, .sfondo-cta .classic-form input[type=week]:focus, .sfondo-cta .classic-form input[type=time]:focus, .sfondo-cta .classic-form input[type=datetime]:focus, .sfondo-cta .classic-form input[type=datetime-local]:focus, .sfondo-cta .classic-form input[type=color]:focus, .sfondo-cta .classic-form input[type=file]:focus, .sfondo-cta .classic-form textarea:focus {
  background-color: #fff;
  color: #5C6B7A;
  border: 1px solid #00C0F0;
}

.sfondo-blu {
  background-color: #004182;
  color: #B8D1F5;
}
.sfondo-blu h1, .sfondo-blu h2, .sfondo-blu h3, .sfondo-blu h4, .sfondo-blu h5, .sfondo-blu h6, .sfondo-blu .titolo, .sfondo-blu .subhead, .sfondo-blu .titolo1, .sfondo-blu .titolo2, .sfondo-blu .titolo3, .sfondo-blu .titolo4 {
  color: #fff;
}
.sfondo-blu a {
  color: #429AFF;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
}
.sfondo-blu a:focus {
  outline: 0;
}
.sfondo-blu a:visited {
  color: #429AFF;
}
.sfondo-blu a:hover {
  color: #ffffff;
  text-decoration: underline;
}
.sfondo-blu a.link {
  font-weight: 800;
  color: #ffffff;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
  display: inline-block;
  font-size: 88%;
  text-transform: uppercase;
}
.sfondo-blu a.link:focus {
  outline: 0;
}
.sfondo-blu a.link:visited {
  color: #ffffff;
}
.sfondo-blu a.link:hover {
  color: #ffffff;
  text-decoration: none;
}
.sfondo-blu a.link:hover:after {
  padding-left: 20px;
}
.sfondo-blu a.link:after {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f061";
  padding-left: 10px;
  transition: all 0.4s ease-in-out;
}
.sfondo-blu a.pulsante, .sfondo-blu .wp-block-button__link, .sfondo-blu submit, .sfondo-blu input[type=submit] {
  position: relative;
  border-radius: 25px;
  background: transparent;
  color: #fff;
  border: 2px solid #fff;
  box-shadow: none;
  text-shadow: none;
  text-decoration: none !important;
  vertical-align: middle;
  display: inline-block;
  width: auto;
  margin: 0;
  font-size: 88%;
  font-weight: 700;
  text-transform: uppercase;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  -o-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  padding: 10px 30px;
  cursor: pointer;
  min-width: 155px;
  text-align: center;
}
.sfondo-blu a.pulsante:visited, .sfondo-blu .wp-block-button__link:visited, .sfondo-blu submit:visited, .sfondo-blu input[type=submit]:visited {
  color: #fff;
}
.sfondo-blu a.pulsante:hover, .sfondo-blu .wp-block-button__link:hover, .sfondo-blu submit:hover, .sfondo-blu input[type=submit]:hover {
  font-weight: 700;
  background: #fff;
  color: #00225A;
  border-color: #fff;
  box-shadow: none;
}

.sfondo-verde {
  background-color: #00C0F0;
  color: #00225A;
  background-color: #16DB7D;
}
.sfondo-verde h1, .sfondo-verde h2, .sfondo-verde h3, .sfondo-verde h4, .sfondo-verde h5, .sfondo-verde h6, .sfondo-verde .titolo, .sfondo-verde .titolo1, .sfondo-verde .titolo2, .sfondo-verde .titolo3, .sfondo-verde .titolo4 {
  color: #fff;
}
.sfondo-verde a {
  color: #B8D1F5;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
}
.sfondo-verde a:focus {
  outline: 0;
}
.sfondo-verde a:visited {
  color: #B8D1F5;
}
.sfondo-verde a:hover {
  color: #B8D1F5;
  text-decoration: underline;
}
.sfondo-verde a.link {
  font-weight: 800;
  color: #00225A;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
  display: inline-block;
  font-size: 88%;
  text-transform: uppercase;
}
.sfondo-verde a.link:focus {
  outline: 0;
}
.sfondo-verde a.link:visited {
  color: #00225A;
}
.sfondo-verde a.link:hover {
  color: #00225A;
  text-decoration: none;
}
.sfondo-verde a.link:hover:after {
  padding-left: 20px;
}
.sfondo-verde a.link:after {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f061";
  padding-left: 10px;
  transition: all 0.4s ease-in-out;
}
.sfondo-verde a.pulsante, .sfondo-verde .wp-block-button__link, .sfondo-verde submit, .sfondo-verde input[type=submit], .sfondo-verde input[type=reset], .sfondo-verde input[type=button] {
  position: relative;
  border-radius: 25px;
  background: #00225A;
  color: #fff;
  border: 2px solid #00225A;
  box-shadow: none;
  text-shadow: none;
  text-decoration: none !important;
  vertical-align: middle;
  display: inline-block;
  width: auto;
  margin: 0;
  font-size: 88%;
  font-weight: 700;
  text-transform: uppercase;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  -o-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  padding: 10px 30px;
  cursor: pointer;
  min-width: 155px;
  text-align: center;
}
.sfondo-verde a.pulsante:visited, .sfondo-verde .wp-block-button__link:visited, .sfondo-verde submit:visited, .sfondo-verde input[type=submit]:visited, .sfondo-verde input[type=reset]:visited, .sfondo-verde input[type=button]:visited {
  color: #fff;
}
.sfondo-verde a.pulsante:hover, .sfondo-verde .wp-block-button__link:hover, .sfondo-verde submit:hover, .sfondo-verde input[type=submit]:hover, .sfondo-verde input[type=reset]:hover, .sfondo-verde input[type=button]:hover {
  font-weight: 700;
  background: transparent;
  color: #00225A;
  border-color: #00225A;
  box-shadow: none;
}
.sfondo-verde a.pulsante.negativo, .sfondo-verde input[type=submit].negativo, .sfondo-verde input[type=reset].negativo, .sfondo-verde input[type=button].negativo {
  position: relative;
  border-radius: 25px;
  background: transparent;
  color: #00225A;
  border: 2px solid #00225A;
  box-shadow: none;
  text-shadow: none;
  text-decoration: none !important;
  vertical-align: middle;
  display: inline-block;
  width: auto;
  margin: 0;
  font-size: 88%;
  font-weight: 700;
  text-transform: uppercase;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  -o-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  padding: 10px 30px;
  cursor: pointer;
  min-width: 155px;
  text-align: center;
}
.sfondo-verde a.pulsante.negativo:visited, .sfondo-verde input[type=submit].negativo:visited, .sfondo-verde input[type=reset].negativo:visited, .sfondo-verde input[type=button].negativo:visited {
  color: #00225A;
}
.sfondo-verde a.pulsante.negativo:hover, .sfondo-verde input[type=submit].negativo:hover, .sfondo-verde input[type=reset].negativo:hover, .sfondo-verde input[type=button].negativo:hover {
  font-weight: 700;
  background: #00225A;
  color: #fff;
  border-color: #00225A;
  box-shadow: none;
}
.sfondo-verde .wpcf7-form input[type=text], .sfondo-verde .wpcf7-form input[type=email], .sfondo-verde .wpcf7-form input[type=confirm_email], .sfondo-verde .wpcf7-form input[type=url], .sfondo-verde .wpcf7-form input[type=password], .sfondo-verde .wpcf7-form input[type=search], .sfondo-verde .wpcf7-form input[type=number], .sfondo-verde .wpcf7-form input[type=tel], .sfondo-verde .wpcf7-form input[type=range],
.sfondo-verde .wpcf7-form input[type=date], .sfondo-verde .wpcf7-form input[type=month], .sfondo-verde .wpcf7-form input[type=week], .sfondo-verde .wpcf7-form input[type=time], .sfondo-verde .wpcf7-form input[type=datetime], .sfondo-verde .wpcf7-form input[type=datetime-local], .sfondo-verde .wpcf7-form input[type=color], .sfondo-verde .wpcf7-form input[type=file], .sfondo-verde .wpcf7-form textarea, .sfondo-verde .search-form input[type=text], .sfondo-verde .search-form input[type=email], .sfondo-verde .search-form input[type=confirm_email], .sfondo-verde .search-form input[type=url], .sfondo-verde .search-form input[type=password], .sfondo-verde .search-form input[type=search], .sfondo-verde .search-form input[type=number], .sfondo-verde .search-form input[type=tel], .sfondo-verde .search-form input[type=range],
.sfondo-verde .search-form input[type=date], .sfondo-verde .search-form input[type=month], .sfondo-verde .search-form input[type=week], .sfondo-verde .search-form input[type=time], .sfondo-verde .search-form input[type=datetime], .sfondo-verde .search-form input[type=datetime-local], .sfondo-verde .search-form input[type=color], .sfondo-verde .search-form input[type=file], .sfondo-verde .search-form textarea, .sfondo-verde .classic-form input[type=text], .sfondo-verde .classic-form input[type=email], .sfondo-verde .classic-form input[type=confirm_email], .sfondo-verde .classic-form input[type=url], .sfondo-verde .classic-form input[type=password], .sfondo-verde .classic-form input[type=search], .sfondo-verde .classic-form input[type=number], .sfondo-verde .classic-form input[type=tel], .sfondo-verde .classic-form input[type=range],
.sfondo-verde .classic-form input[type=date], .sfondo-verde .classic-form input[type=month], .sfondo-verde .classic-form input[type=week], .sfondo-verde .classic-form input[type=time], .sfondo-verde .classic-form input[type=datetime], .sfondo-verde .classic-form input[type=datetime-local], .sfondo-verde .classic-form input[type=color], .sfondo-verde .classic-form input[type=file], .sfondo-verde .classic-form textarea {
  background-color: #fff;
  color: #5C6B7A;
  border: 1px solid #00C0F0;
}
.sfondo-verde .wpcf7-form input[type=text]:focus, .sfondo-verde .wpcf7-form input[type=email]:focus, .sfondo-verde .wpcf7-form input[type=confirm_email]:focus, .sfondo-verde .wpcf7-form input[type=url]:focus, .sfondo-verde .wpcf7-form input[type=password]:focus, .sfondo-verde .wpcf7-form input[type=search]:focus, .sfondo-verde .wpcf7-form input[type=number]:focus, .sfondo-verde .wpcf7-form input[type=tel]:focus, .sfondo-verde .wpcf7-form input[type=range]:focus,
.sfondo-verde .wpcf7-form input[type=date]:focus, .sfondo-verde .wpcf7-form input[type=month]:focus, .sfondo-verde .wpcf7-form input[type=week]:focus, .sfondo-verde .wpcf7-form input[type=time]:focus, .sfondo-verde .wpcf7-form input[type=datetime]:focus, .sfondo-verde .wpcf7-form input[type=datetime-local]:focus, .sfondo-verde .wpcf7-form input[type=color]:focus, .sfondo-verde .wpcf7-form input[type=file]:focus, .sfondo-verde .wpcf7-form textarea:focus, .sfondo-verde .search-form input[type=text]:focus, .sfondo-verde .search-form input[type=email]:focus, .sfondo-verde .search-form input[type=confirm_email]:focus, .sfondo-verde .search-form input[type=url]:focus, .sfondo-verde .search-form input[type=password]:focus, .sfondo-verde .search-form input[type=search]:focus, .sfondo-verde .search-form input[type=number]:focus, .sfondo-verde .search-form input[type=tel]:focus, .sfondo-verde .search-form input[type=range]:focus,
.sfondo-verde .search-form input[type=date]:focus, .sfondo-verde .search-form input[type=month]:focus, .sfondo-verde .search-form input[type=week]:focus, .sfondo-verde .search-form input[type=time]:focus, .sfondo-verde .search-form input[type=datetime]:focus, .sfondo-verde .search-form input[type=datetime-local]:focus, .sfondo-verde .search-form input[type=color]:focus, .sfondo-verde .search-form input[type=file]:focus, .sfondo-verde .search-form textarea:focus, .sfondo-verde .classic-form input[type=text]:focus, .sfondo-verde .classic-form input[type=email]:focus, .sfondo-verde .classic-form input[type=confirm_email]:focus, .sfondo-verde .classic-form input[type=url]:focus, .sfondo-verde .classic-form input[type=password]:focus, .sfondo-verde .classic-form input[type=search]:focus, .sfondo-verde .classic-form input[type=number]:focus, .sfondo-verde .classic-form input[type=tel]:focus, .sfondo-verde .classic-form input[type=range]:focus,
.sfondo-verde .classic-form input[type=date]:focus, .sfondo-verde .classic-form input[type=month]:focus, .sfondo-verde .classic-form input[type=week]:focus, .sfondo-verde .classic-form input[type=time]:focus, .sfondo-verde .classic-form input[type=datetime]:focus, .sfondo-verde .classic-form input[type=datetime-local]:focus, .sfondo-verde .classic-form input[type=color]:focus, .sfondo-verde .classic-form input[type=file]:focus, .sfondo-verde .classic-form textarea:focus {
  background-color: #fff;
  color: #5C6B7A;
  border: 1px solid #00C0F0;
}

.sfondo-giallo {
  background-color: #00C0F0;
  color: #00225A;
  background-color: #FBB03B;
}
.sfondo-giallo h1, .sfondo-giallo h2, .sfondo-giallo h3, .sfondo-giallo h4, .sfondo-giallo h5, .sfondo-giallo h6, .sfondo-giallo .titolo, .sfondo-giallo .titolo1, .sfondo-giallo .titolo2, .sfondo-giallo .titolo3, .sfondo-giallo .titolo4 {
  color: #fff;
}
.sfondo-giallo a {
  color: #B8D1F5;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
}
.sfondo-giallo a:focus {
  outline: 0;
}
.sfondo-giallo a:visited {
  color: #B8D1F5;
}
.sfondo-giallo a:hover {
  color: #B8D1F5;
  text-decoration: underline;
}
.sfondo-giallo a.link {
  font-weight: 800;
  color: #00225A;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
  display: inline-block;
  font-size: 88%;
  text-transform: uppercase;
}
.sfondo-giallo a.link:focus {
  outline: 0;
}
.sfondo-giallo a.link:visited {
  color: #00225A;
}
.sfondo-giallo a.link:hover {
  color: #00225A;
  text-decoration: none;
}
.sfondo-giallo a.link:hover:after {
  padding-left: 20px;
}
.sfondo-giallo a.link:after {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f061";
  padding-left: 10px;
  transition: all 0.4s ease-in-out;
}
.sfondo-giallo a.pulsante, .sfondo-giallo .wp-block-button__link, .sfondo-giallo submit, .sfondo-giallo input[type=submit], .sfondo-giallo input[type=reset], .sfondo-giallo input[type=button] {
  position: relative;
  border-radius: 25px;
  background: #00225A;
  color: #fff;
  border: 2px solid #00225A;
  box-shadow: none;
  text-shadow: none;
  text-decoration: none !important;
  vertical-align: middle;
  display: inline-block;
  width: auto;
  margin: 0;
  font-size: 88%;
  font-weight: 700;
  text-transform: uppercase;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  -o-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  padding: 10px 30px;
  cursor: pointer;
  min-width: 155px;
  text-align: center;
}
.sfondo-giallo a.pulsante:visited, .sfondo-giallo .wp-block-button__link:visited, .sfondo-giallo submit:visited, .sfondo-giallo input[type=submit]:visited, .sfondo-giallo input[type=reset]:visited, .sfondo-giallo input[type=button]:visited {
  color: #fff;
}
.sfondo-giallo a.pulsante:hover, .sfondo-giallo .wp-block-button__link:hover, .sfondo-giallo submit:hover, .sfondo-giallo input[type=submit]:hover, .sfondo-giallo input[type=reset]:hover, .sfondo-giallo input[type=button]:hover {
  font-weight: 700;
  background: transparent;
  color: #00225A;
  border-color: #00225A;
  box-shadow: none;
}
.sfondo-giallo a.pulsante.negativo, .sfondo-giallo input[type=submit].negativo, .sfondo-giallo input[type=reset].negativo, .sfondo-giallo input[type=button].negativo {
  position: relative;
  border-radius: 25px;
  background: transparent;
  color: #00225A;
  border: 2px solid #00225A;
  box-shadow: none;
  text-shadow: none;
  text-decoration: none !important;
  vertical-align: middle;
  display: inline-block;
  width: auto;
  margin: 0;
  font-size: 88%;
  font-weight: 700;
  text-transform: uppercase;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  -o-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  padding: 10px 30px;
  cursor: pointer;
  min-width: 155px;
  text-align: center;
}
.sfondo-giallo a.pulsante.negativo:visited, .sfondo-giallo input[type=submit].negativo:visited, .sfondo-giallo input[type=reset].negativo:visited, .sfondo-giallo input[type=button].negativo:visited {
  color: #00225A;
}
.sfondo-giallo a.pulsante.negativo:hover, .sfondo-giallo input[type=submit].negativo:hover, .sfondo-giallo input[type=reset].negativo:hover, .sfondo-giallo input[type=button].negativo:hover {
  font-weight: 700;
  background: #00225A;
  color: #fff;
  border-color: #00225A;
  box-shadow: none;
}
.sfondo-giallo .wpcf7-form input[type=text], .sfondo-giallo .wpcf7-form input[type=email], .sfondo-giallo .wpcf7-form input[type=confirm_email], .sfondo-giallo .wpcf7-form input[type=url], .sfondo-giallo .wpcf7-form input[type=password], .sfondo-giallo .wpcf7-form input[type=search], .sfondo-giallo .wpcf7-form input[type=number], .sfondo-giallo .wpcf7-form input[type=tel], .sfondo-giallo .wpcf7-form input[type=range],
.sfondo-giallo .wpcf7-form input[type=date], .sfondo-giallo .wpcf7-form input[type=month], .sfondo-giallo .wpcf7-form input[type=week], .sfondo-giallo .wpcf7-form input[type=time], .sfondo-giallo .wpcf7-form input[type=datetime], .sfondo-giallo .wpcf7-form input[type=datetime-local], .sfondo-giallo .wpcf7-form input[type=color], .sfondo-giallo .wpcf7-form input[type=file], .sfondo-giallo .wpcf7-form textarea, .sfondo-giallo .search-form input[type=text], .sfondo-giallo .search-form input[type=email], .sfondo-giallo .search-form input[type=confirm_email], .sfondo-giallo .search-form input[type=url], .sfondo-giallo .search-form input[type=password], .sfondo-giallo .search-form input[type=search], .sfondo-giallo .search-form input[type=number], .sfondo-giallo .search-form input[type=tel], .sfondo-giallo .search-form input[type=range],
.sfondo-giallo .search-form input[type=date], .sfondo-giallo .search-form input[type=month], .sfondo-giallo .search-form input[type=week], .sfondo-giallo .search-form input[type=time], .sfondo-giallo .search-form input[type=datetime], .sfondo-giallo .search-form input[type=datetime-local], .sfondo-giallo .search-form input[type=color], .sfondo-giallo .search-form input[type=file], .sfondo-giallo .search-form textarea, .sfondo-giallo .classic-form input[type=text], .sfondo-giallo .classic-form input[type=email], .sfondo-giallo .classic-form input[type=confirm_email], .sfondo-giallo .classic-form input[type=url], .sfondo-giallo .classic-form input[type=password], .sfondo-giallo .classic-form input[type=search], .sfondo-giallo .classic-form input[type=number], .sfondo-giallo .classic-form input[type=tel], .sfondo-giallo .classic-form input[type=range],
.sfondo-giallo .classic-form input[type=date], .sfondo-giallo .classic-form input[type=month], .sfondo-giallo .classic-form input[type=week], .sfondo-giallo .classic-form input[type=time], .sfondo-giallo .classic-form input[type=datetime], .sfondo-giallo .classic-form input[type=datetime-local], .sfondo-giallo .classic-form input[type=color], .sfondo-giallo .classic-form input[type=file], .sfondo-giallo .classic-form textarea {
  background-color: #fff;
  color: #5C6B7A;
  border: 1px solid #00C0F0;
}
.sfondo-giallo .wpcf7-form input[type=text]:focus, .sfondo-giallo .wpcf7-form input[type=email]:focus, .sfondo-giallo .wpcf7-form input[type=confirm_email]:focus, .sfondo-giallo .wpcf7-form input[type=url]:focus, .sfondo-giallo .wpcf7-form input[type=password]:focus, .sfondo-giallo .wpcf7-form input[type=search]:focus, .sfondo-giallo .wpcf7-form input[type=number]:focus, .sfondo-giallo .wpcf7-form input[type=tel]:focus, .sfondo-giallo .wpcf7-form input[type=range]:focus,
.sfondo-giallo .wpcf7-form input[type=date]:focus, .sfondo-giallo .wpcf7-form input[type=month]:focus, .sfondo-giallo .wpcf7-form input[type=week]:focus, .sfondo-giallo .wpcf7-form input[type=time]:focus, .sfondo-giallo .wpcf7-form input[type=datetime]:focus, .sfondo-giallo .wpcf7-form input[type=datetime-local]:focus, .sfondo-giallo .wpcf7-form input[type=color]:focus, .sfondo-giallo .wpcf7-form input[type=file]:focus, .sfondo-giallo .wpcf7-form textarea:focus, .sfondo-giallo .search-form input[type=text]:focus, .sfondo-giallo .search-form input[type=email]:focus, .sfondo-giallo .search-form input[type=confirm_email]:focus, .sfondo-giallo .search-form input[type=url]:focus, .sfondo-giallo .search-form input[type=password]:focus, .sfondo-giallo .search-form input[type=search]:focus, .sfondo-giallo .search-form input[type=number]:focus, .sfondo-giallo .search-form input[type=tel]:focus, .sfondo-giallo .search-form input[type=range]:focus,
.sfondo-giallo .search-form input[type=date]:focus, .sfondo-giallo .search-form input[type=month]:focus, .sfondo-giallo .search-form input[type=week]:focus, .sfondo-giallo .search-form input[type=time]:focus, .sfondo-giallo .search-form input[type=datetime]:focus, .sfondo-giallo .search-form input[type=datetime-local]:focus, .sfondo-giallo .search-form input[type=color]:focus, .sfondo-giallo .search-form input[type=file]:focus, .sfondo-giallo .search-form textarea:focus, .sfondo-giallo .classic-form input[type=text]:focus, .sfondo-giallo .classic-form input[type=email]:focus, .sfondo-giallo .classic-form input[type=confirm_email]:focus, .sfondo-giallo .classic-form input[type=url]:focus, .sfondo-giallo .classic-form input[type=password]:focus, .sfondo-giallo .classic-form input[type=search]:focus, .sfondo-giallo .classic-form input[type=number]:focus, .sfondo-giallo .classic-form input[type=tel]:focus, .sfondo-giallo .classic-form input[type=range]:focus,
.sfondo-giallo .classic-form input[type=date]:focus, .sfondo-giallo .classic-form input[type=month]:focus, .sfondo-giallo .classic-form input[type=week]:focus, .sfondo-giallo .classic-form input[type=time]:focus, .sfondo-giallo .classic-form input[type=datetime]:focus, .sfondo-giallo .classic-form input[type=datetime-local]:focus, .sfondo-giallo .classic-form input[type=color]:focus, .sfondo-giallo .classic-form input[type=file]:focus, .sfondo-giallo .classic-form textarea:focus {
  background-color: #fff;
  color: #5C6B7A;
  border: 1px solid #00C0F0;
}

.sfondo-nero {
  background-color: #191919;
  color: #818181;
}
.sfondo-nero h1, .sfondo-nero h2, .sfondo-nero h3, .sfondo-nero h4, .sfondo-nero h5, .sfondo-nero h6, .sfondo-nero .titolo, .sfondo-nero .titolo1, .sfondo-nero .titolo2, .sfondo-nero .titolo3, .sfondo-nero .titolo4, .sfondo-nero .titolo5 {
  color: #ffffff;
}
.sfondo-nero a {
  color: #ffffff;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
}
.sfondo-nero a:focus {
  outline: 0;
}
.sfondo-nero a:visited {
  color: #ffffff;
}
.sfondo-nero a:hover {
  color: #078855;
  text-decoration: underline;
}
.sfondo-nero a.link {
  font-weight: 800;
  color: #ffffff;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
  display: inline-block;
  font-size: 88%;
  text-transform: uppercase;
}
.sfondo-nero a.link:focus {
  outline: 0;
}
.sfondo-nero a.link:visited {
  color: #ffffff;
}
.sfondo-nero a.link:hover {
  color: #078855;
  text-decoration: none;
}
.sfondo-nero a.link:hover:after {
  padding-left: 20px;
}
.sfondo-nero a.link:after {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f061";
  padding-left: 10px;
  transition: all 0.4s ease-in-out;
}
.sfondo-nero a.pulsante, .sfondo-nero .wp-block-button__link, .sfondo-nero submit, .sfondo-nero input[type=submit], .sfondo-nero input[type=reset], .sfondo-nero input[type=button] {
  position: relative;
  border-radius: 25px;
  background: #078855;
  color: #fff;
  border: 2px solid #078855;
  box-shadow: none;
  text-shadow: none;
  text-decoration: none !important;
  vertical-align: middle;
  display: inline-block;
  width: auto;
  margin: 0;
  font-size: 88%;
  font-weight: 700;
  text-transform: uppercase;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  -o-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  padding: 10px 30px;
  cursor: pointer;
  min-width: 155px;
  text-align: center;
}
.sfondo-nero a.pulsante:visited, .sfondo-nero .wp-block-button__link:visited, .sfondo-nero submit:visited, .sfondo-nero input[type=submit]:visited, .sfondo-nero input[type=reset]:visited, .sfondo-nero input[type=button]:visited {
  color: #fff;
}
.sfondo-nero a.pulsante:hover, .sfondo-nero .wp-block-button__link:hover, .sfondo-nero submit:hover, .sfondo-nero input[type=submit]:hover, .sfondo-nero input[type=reset]:hover, .sfondo-nero input[type=button]:hover {
  font-weight: 700;
  background: transparent;
  color: #fff;
  border-color: #fff;
  box-shadow: none;
}
.sfondo-nero a.pulsante.negativo, .sfondo-nero input[type=submit].negativo, .sfondo-nero input[type=reset].negativo, .sfondo-nero input[type=button].negativo {
  position: relative;
  border-radius: 25px;
  background: transparent;
  color: #fff;
  border: 2px solid #fff;
  box-shadow: none;
  text-shadow: none;
  text-decoration: none !important;
  vertical-align: middle;
  display: inline-block;
  width: auto;
  margin: 0;
  font-size: 88%;
  font-weight: 700;
  text-transform: uppercase;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  -o-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  padding: 10px 30px;
  cursor: pointer;
  min-width: 155px;
  text-align: center;
}
.sfondo-nero a.pulsante.negativo:visited, .sfondo-nero input[type=submit].negativo:visited, .sfondo-nero input[type=reset].negativo:visited, .sfondo-nero input[type=button].negativo:visited {
  color: #fff;
}
.sfondo-nero a.pulsante.negativo:hover, .sfondo-nero input[type=submit].negativo:hover, .sfondo-nero input[type=reset].negativo:hover, .sfondo-nero input[type=button].negativo:hover {
  font-weight: 700;
  background: #078855;
  color: #fff;
  border-color: #078855;
  box-shadow: none;
}

.sfondo-viola {
  background-color: #5D5BD4;
  color: #ffffff;
}
.sfondo-viola h1, .sfondo-viola h2, .sfondo-viola h3, .sfondo-viola h4, .sfondo-viola h5, .sfondo-viola h6, .sfondo-viola .titolo, .sfondo-viola .titolo1, .sfondo-viola .titolo2, .sfondo-viola .titolo3, .sfondo-viola .titolo4, .sfondo-viola .titolo5 {
  color: #ffffff;
}
.sfondo-viola a {
  color: #ffffff;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
}
.sfondo-viola a:focus {
  outline: 0;
}
.sfondo-viola a:visited {
  color: #ffffff;
}
.sfondo-viola a:hover {
  color: #c6cbd1;
  text-decoration: underline;
}
.sfondo-viola a.link {
  font-weight: 800;
  color: #ffffff;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
  display: inline-block;
  font-size: 88%;
  text-transform: uppercase;
}
.sfondo-viola a.link:focus {
  outline: 0;
}
.sfondo-viola a.link:visited {
  color: #ffffff;
}
.sfondo-viola a.link:hover {
  color: #c6cbd1;
  text-decoration: none;
}
.sfondo-viola a.link:hover:after {
  padding-left: 20px;
}
.sfondo-viola a.link:after {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f061";
  padding-left: 10px;
  transition: all 0.4s ease-in-out;
}
.sfondo-viola a.pulsante, .sfondo-viola .wp-block-button__link, .sfondo-viola submit, .sfondo-viola input[type=submit], .sfondo-viola input[type=reset], .sfondo-viola input[type=button] {
  position: relative;
  border-radius: 25px;
  background: #ffffff;
  color: #5D5BD4;
  border: 2px solid #fff;
  box-shadow: none;
  text-shadow: none;
  text-decoration: none !important;
  vertical-align: middle;
  display: inline-block;
  width: auto;
  margin: 0;
  font-size: 88%;
  font-weight: 700;
  text-transform: uppercase;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  -o-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  padding: 10px 30px;
  cursor: pointer;
  min-width: 155px;
  text-align: center;
}
.sfondo-viola a.pulsante:visited, .sfondo-viola .wp-block-button__link:visited, .sfondo-viola submit:visited, .sfondo-viola input[type=submit]:visited, .sfondo-viola input[type=reset]:visited, .sfondo-viola input[type=button]:visited {
  color: #5D5BD4;
}
.sfondo-viola a.pulsante:hover, .sfondo-viola .wp-block-button__link:hover, .sfondo-viola submit:hover, .sfondo-viola input[type=submit]:hover, .sfondo-viola input[type=reset]:hover, .sfondo-viola input[type=button]:hover {
  font-weight: 700;
  background: transparent;
  color: #fff;
  border-color: #fff;
  box-shadow: none;
}
.sfondo-viola a.pulsante.negativo, .sfondo-viola input[type=submit].negativo, .sfondo-viola input[type=reset].negativo, .sfondo-viola input[type=button].negativo {
  position: relative;
  border-radius: 25px;
  background: #5D5BD4;
  color: #fff;
  border: 2px solid #5D5BD4;
  box-shadow: none;
  text-shadow: none;
  text-decoration: none !important;
  vertical-align: middle;
  display: inline-block;
  width: auto;
  margin: 0;
  font-size: 88%;
  font-weight: 700;
  text-transform: uppercase;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  -o-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  padding: 10px 30px;
  cursor: pointer;
  min-width: 155px;
  text-align: center;
}
.sfondo-viola a.pulsante.negativo:visited, .sfondo-viola input[type=submit].negativo:visited, .sfondo-viola input[type=reset].negativo:visited, .sfondo-viola input[type=button].negativo:visited {
  color: #fff;
}
.sfondo-viola a.pulsante.negativo:hover, .sfondo-viola input[type=submit].negativo:hover, .sfondo-viola input[type=reset].negativo:hover, .sfondo-viola input[type=button].negativo:hover {
  font-weight: 700;
  background: #ffffff;
  color: #5D5BD4;
  border-color: #5D5BD4;
  box-shadow: none;
}

.page {
  margin: 0;
}

.audio_component {
  position: relative;
  height: 45px;
}
.audio_component .link-audio-player {
  position: absolute;
  top: 0;
  height: 45px;
  display: flex;
  align-items: center;
}
.audio_component .audio_wrapper {
  visibility: hidden;
  position: absolute;
  top: 0;
}

.page-load-status {
  display: none; /* hidden by default */
  padding-top: 20px;
  text-align: center;
  color: #777;
}

#centrami {
  width: 304px;
  margin: 0 auto;
  padding-bottom: 30px;
}

.riduco {
  max-width: 1541px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.arrotonda {
  border-radius: 25px;
}

.hidden {
  visibility: hidden;
}

.check-ok {
  color: #16DB7D;
}

.check-ko {
  color: #FC2759;
}

.modal {
  z-index: 3000;
}

.modal-content {
  border: 0;
  border-radius: 25px;
}

.modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}

.modal-footer {
  border-top: 0;
}

#myModalPeople .modal-header .close {
  color: #fff;
  text-shadow: none;
}
#myModalPeople .modal-header .close a {
  color: #fff;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
}
#myModalPeople .modal-header .close a:focus {
  outline: 0;
}
#myModalPeople .modal-header .close a:visited {
  color: #fff;
}
#myModalPeople .modal-header .close a:hover {
  color: #fff;
  text-decoration: underline;
}
#myModalPeople .modal-header .close:hover {
  color: #fff;
}

#myModalPopup .modal-header .close {
  text-shadow: none;
  color: #fff;
  opacity: 1;
  margin: -1rem -2rem -0.5rem auto;
  outline: none;
}
#myModalPopup .modal-content {
  background: transparent;
  border-radius: 0;
}
#myModalPopup .modal-body {
  padding: 0;
}
#myModalPopup .modal-body .inner {
  padding: 40px;
}

.form_required {
  border: 2px solid #F05032 !important;
}

.wrapper-activate iframe {
  width: 100%;
  border: 0;
}

/* -------------------------------------------------- */
/*	 OVERRIDE MENU
/* -------------------------------------------------- */
body #page.site.submenu {
  margin-top: 0 !important;
}
body #page.site.submenu #masthead.site-header .menu__header {
  position: relative;
}

/* -------------------------------------------------- */
/*	BLOCCHI COMUNI
/* -------------------------------------------------- */
#primary.site-main #myModalInt .modal-header, #primary.site-main #myModalInt .modal-footer, #primary.site-main #myModalInt .modal-body {
  position: relative;
}
#primary.site-main #myModalInt .cerchiotop {
  background-color: #fff;
  border-radius: 25px;
}
#primary.site-main #myModalInt .barra-dx .pulsante {
  width: 234px;
}
#primary.site-main #myModalInt .barra-dx .approfondimento ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
#primary.site-main #myModalInt .modal-footer .barra-dx {
  top: -4px;
  right: 21px;
}
#primary.site-main #myModalInt .apps .griglia .item {
  max-width: none;
  flex-grow: 0;
  flex-basis: 100%;
}
#primary.site-main #myModalInt .apps .griglia .item .cerchio {
  width: 100%;
  max-width: 100%;
  justify-content: center;
  cursor: auto;
}
@media (max-width: 992px) {
  #primary.site-main #myModalInt .modal-lg, #primary.site-main #myModalInt .modal-xl {
    max-width: 90vw !important;
  }
  #primary.site-main #myModalInt .modal-lg .apps .griglia .item, #primary.site-main #myModalInt .modal-xl .apps .griglia .item {
    flex-grow: 0;
    flex-basis: 33%;
  }
  #primary.site-main #myModalInt .modal-lg .apps .griglia .item .cerchio, #primary.site-main #myModalInt .modal-xl .apps .griglia .item .cerchio {
    width: 100%;
    max-width: 97%;
  }
}
@media (max-width: 576px) {
  #primary.site-main #myModalInt .modal-dialog {
    max-width: 90vw;
  }
}
#primary.site-main .form_newsletter_inline-form .nlinput {
  max-width: 300px;
}
#primary.site-main .intro.solotxt {
  background-image: url("../img/top-solotxt.svg");
  background-size: cover;
  background-position: center top;
}
#primary.site-main .intro.solotxt.txtleft {
  background-position: center right;
}
#primary.site-main .img-sinistra-bottom {
  position: absolute;
  left: 5%;
  bottom: 5%;
  width: auto;
  z-index: 100;
}
#primary.site-main .img-destra-top {
  position: absolute;
  right: 5%;
  top: 5%;
  width: auto;
  z-index: 100;
}
#primary.site-main .firstcontent {
  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;
}
#primary.site-main .fancybox-video {
  background: transparent;
  border: 0;
  display: block;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%;
  height: 56.25%;
}
#primary.site-main .fancybox-slide--video .fancybox-content {
  background: transparent;
}
#primary.site-main #jolly {
  position: relative;
}
#primary.site-main #jolly .testo {
  padding: 50px 0;
}
#primary.site-main #jolly .immagine {
  max-width: 674px;
  position: absolute;
  bottom: 0;
  width: 40%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
#primary.site-main #jolly.imgdx .immagine {
  right: 10%;
}
#primary.site-main #jolly.imgdx .forma {
  border-radius: 180px 0 180px 180px;
}
#primary.site-main #jolly.imgsx .immagine {
  left: 10%;
}
#primary.site-main #jolly.imgsx .forma {
  border-radius: 0 180px 180px 180px;
}
#primary.site-main #block-intro {
  position: relative;
  background-image: url("../img/fascia-integration.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
#primary.site-main #block-intro .imgxl {
  position: absolute;
  top: 12%;
  left: 52%;
  max-width: 647px;
  height: auto;
  padding-right: 10px;
}
#primary.site-main #block-intro .testo {
  padding: 30px 0;
}
#primary.site-main #block-intro .img {
  max-width: 647px;
  height: auto;
}
#primary.site-main #block-suite .item {
  border-right: 1px solid #fff;
}
#primary.site-main #block-suite .item:first-of-type {
  border-left: 1px solid #fff;
}
#primary.site-main #block-suite .item .inner {
  padding: 40px;
}
#primary.site-main #block-suite .item .inner .content {
  min-height: 440px;
  width: 100%;
  position: relative;
}
#primary.site-main #block-suite .item .inner .content .logo {
  position: absolute;
  width: 100%;
  right: 0;
  bottom: 0;
}
#primary.site-main #block-suite .item .inner .content .logo img {
  max-width: 150px;
  height: auto;
}
#primary.site-main #block-suite .item .inner .puntatore {
  display: none;
}
#primary.site-main .double-cta .item {
  border-right: 2px solid #E9EDF3;
}
#primary.site-main .double-cta .item:first-of-type {
  border-left: 2px solid #E9EDF3;
}
#primary.site-main .double-cta .item .inner {
  padding: 40px;
}
#primary.site-main .double-cta .item .inner .content {
  width: 100%;
  position: relative;
}
#primary.site-main .double-cta .item .inner .content .sottotitolo {
  padding-bottom: 40px;
}
#primary.site-main .double-cta .item .inner .content .logo {
  padding-bottom: 40px;
}
#primary.site-main .double-cta .item .inner .content .logo img {
  max-width: 150px;
  height: auto;
}
#primary.site-main #block-suite-new .item {
  border-right: 1px solid #fff;
}
#primary.site-main #block-suite-new .item:first-of-type {
  border-left: 1px solid #fff;
}
#primary.site-main #block-suite-new .item .inner .content {
  min-height: 440px;
}
#primary.site-main #block-clienti .owl-carousel {
  padding-top: 50px;
}
#primary.site-main #block-clienti .img-cli {
  /*            width: 116px;
              height: auto;
              margin: 0 auto;*/
}
#primary.site-main #block-contatori .wrapper {
  align-items: center;
  justify-content: center;
  display: flex;
}
#primary.site-main #block-contatori .wrapper .item {
  background-color: rgba(204, 212, 226, 0.6);
  text-align: center;
  padding: 50px 100px;
  border-radius: 100px;
  margin-left: -30px;
}
#primary.site-main #block-contatori .wrapper .item .titolo {
  font-size: 64px;
  font-weight: bold;
}
#primary.site-main #block-contatori .wrapper .item .sottotitolo {
  font-size: 166%;
}
#primary.site-main #block-contatori .wrapper .item:first-of-type {
  border-radius: 0 100px 100px 0;
  flex-grow: 1;
  padding-left: 0;
  padding-right: 50px;
  margin-left: 0;
}
#primary.site-main #block-contatori .wrapper .item:first-of-type .titolo, #primary.site-main #block-contatori .wrapper .item:first-of-type .sottotitolo {
  color: transparent;
}
#primary.site-main #block-contatori .wrapper .item:last-of-type {
  border-radius: 100px 0 0 100px;
  flex-grow: 1;
  padding-right: 0;
  padding-left: 50px;
}
#primary.site-main #block-contatori .wrapper .item:last-of-type .titolo, #primary.site-main #block-contatori .wrapper .item:last-of-type .sottotitolo {
  color: transparent;
}
#primary.site-main #block-contatori .wrapper .item.pieno {
  width: auto;
  max-width: 328px;
}
#primary.site-main .calltoaction {
  background-color: #00C0F0;
  color: #00225A;
  position: relative;
  background-color: #00C0F0;
}
#primary.site-main .calltoaction h1, #primary.site-main .calltoaction h2, #primary.site-main .calltoaction h3, #primary.site-main .calltoaction h4, #primary.site-main .calltoaction h5, #primary.site-main .calltoaction h6, #primary.site-main .calltoaction .titolo, #primary.site-main .calltoaction .titolo1, #primary.site-main .calltoaction .titolo2, #primary.site-main .calltoaction .titolo3, #primary.site-main .calltoaction .titolo4 {
  color: #fff;
}
#primary.site-main .calltoaction a {
  color: #B8D1F5;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
}
#primary.site-main .calltoaction a:focus {
  outline: 0;
}
#primary.site-main .calltoaction a:visited {
  color: #B8D1F5;
}
#primary.site-main .calltoaction a:hover {
  color: #B8D1F5;
  text-decoration: underline;
}
#primary.site-main .calltoaction a.link {
  font-weight: 800;
  color: #00225A;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
  display: inline-block;
  font-size: 88%;
  text-transform: uppercase;
}
#primary.site-main .calltoaction a.link:focus {
  outline: 0;
}
#primary.site-main .calltoaction a.link:visited {
  color: #00225A;
}
#primary.site-main .calltoaction a.link:hover {
  color: #00225A;
  text-decoration: none;
}
#primary.site-main .calltoaction a.link:hover:after {
  padding-left: 20px;
}
#primary.site-main .calltoaction a.link:after {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f061";
  padding-left: 10px;
  transition: all 0.4s ease-in-out;
}
#primary.site-main .calltoaction a.pulsante, #primary.site-main .calltoaction .wp-block-button__link, #primary.site-main .calltoaction submit, #primary.site-main .calltoaction input[type=submit], #primary.site-main .calltoaction input[type=reset], #primary.site-main .calltoaction input[type=button] {
  position: relative;
  border-radius: 25px;
  background: #00225A;
  color: #fff;
  border: 2px solid #00225A;
  box-shadow: none;
  text-shadow: none;
  text-decoration: none !important;
  vertical-align: middle;
  display: inline-block;
  width: auto;
  margin: 0;
  font-size: 88%;
  font-weight: 700;
  text-transform: uppercase;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  -o-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  padding: 10px 30px;
  cursor: pointer;
  min-width: 155px;
  text-align: center;
}
#primary.site-main .calltoaction a.pulsante:visited, #primary.site-main .calltoaction .wp-block-button__link:visited, #primary.site-main .calltoaction submit:visited, #primary.site-main .calltoaction input[type=submit]:visited, #primary.site-main .calltoaction input[type=reset]:visited, #primary.site-main .calltoaction input[type=button]:visited {
  color: #fff;
}
#primary.site-main .calltoaction a.pulsante:hover, #primary.site-main .calltoaction .wp-block-button__link:hover, #primary.site-main .calltoaction submit:hover, #primary.site-main .calltoaction input[type=submit]:hover, #primary.site-main .calltoaction input[type=reset]:hover, #primary.site-main .calltoaction input[type=button]:hover {
  font-weight: 700;
  background: transparent;
  color: #00225A;
  border-color: #00225A;
  box-shadow: none;
}
#primary.site-main .calltoaction a.pulsante.negativo, #primary.site-main .calltoaction input[type=submit].negativo, #primary.site-main .calltoaction input[type=reset].negativo, #primary.site-main .calltoaction input[type=button].negativo {
  position: relative;
  border-radius: 25px;
  background: transparent;
  color: #00225A;
  border: 2px solid #00225A;
  box-shadow: none;
  text-shadow: none;
  text-decoration: none !important;
  vertical-align: middle;
  display: inline-block;
  width: auto;
  margin: 0;
  font-size: 88%;
  font-weight: 700;
  text-transform: uppercase;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  -o-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  padding: 10px 30px;
  cursor: pointer;
  min-width: 155px;
  text-align: center;
}
#primary.site-main .calltoaction a.pulsante.negativo:visited, #primary.site-main .calltoaction input[type=submit].negativo:visited, #primary.site-main .calltoaction input[type=reset].negativo:visited, #primary.site-main .calltoaction input[type=button].negativo:visited {
  color: #00225A;
}
#primary.site-main .calltoaction a.pulsante.negativo:hover, #primary.site-main .calltoaction input[type=submit].negativo:hover, #primary.site-main .calltoaction input[type=reset].negativo:hover, #primary.site-main .calltoaction input[type=button].negativo:hover {
  font-weight: 700;
  background: #00225A;
  color: #fff;
  border-color: #00225A;
  box-shadow: none;
}
#primary.site-main .calltoaction .wpcf7-form input[type=text], #primary.site-main .calltoaction .wpcf7-form input[type=email], #primary.site-main .calltoaction .wpcf7-form input[type=confirm_email], #primary.site-main .calltoaction .wpcf7-form input[type=url], #primary.site-main .calltoaction .wpcf7-form input[type=password], #primary.site-main .calltoaction .wpcf7-form input[type=search], #primary.site-main .calltoaction .wpcf7-form input[type=number], #primary.site-main .calltoaction .wpcf7-form input[type=tel], #primary.site-main .calltoaction .wpcf7-form input[type=range],
#primary.site-main .calltoaction .wpcf7-form input[type=date], #primary.site-main .calltoaction .wpcf7-form input[type=month], #primary.site-main .calltoaction .wpcf7-form input[type=week], #primary.site-main .calltoaction .wpcf7-form input[type=time], #primary.site-main .calltoaction .wpcf7-form input[type=datetime], #primary.site-main .calltoaction .wpcf7-form input[type=datetime-local], #primary.site-main .calltoaction .wpcf7-form input[type=color], #primary.site-main .calltoaction .wpcf7-form input[type=file], #primary.site-main .calltoaction .wpcf7-form textarea, #primary.site-main .calltoaction .search-form input[type=text], #primary.site-main .calltoaction .search-form input[type=email], #primary.site-main .calltoaction .search-form input[type=confirm_email], #primary.site-main .calltoaction .search-form input[type=url], #primary.site-main .calltoaction .search-form input[type=password], #primary.site-main .calltoaction .search-form input[type=search], #primary.site-main .calltoaction .search-form input[type=number], #primary.site-main .calltoaction .search-form input[type=tel], #primary.site-main .calltoaction .search-form input[type=range],
#primary.site-main .calltoaction .search-form input[type=date], #primary.site-main .calltoaction .search-form input[type=month], #primary.site-main .calltoaction .search-form input[type=week], #primary.site-main .calltoaction .search-form input[type=time], #primary.site-main .calltoaction .search-form input[type=datetime], #primary.site-main .calltoaction .search-form input[type=datetime-local], #primary.site-main .calltoaction .search-form input[type=color], #primary.site-main .calltoaction .search-form input[type=file], #primary.site-main .calltoaction .search-form textarea, #primary.site-main .calltoaction .classic-form input[type=text], #primary.site-main .calltoaction .classic-form input[type=email], #primary.site-main .calltoaction .classic-form input[type=confirm_email], #primary.site-main .calltoaction .classic-form input[type=url], #primary.site-main .calltoaction .classic-form input[type=password], #primary.site-main .calltoaction .classic-form input[type=search], #primary.site-main .calltoaction .classic-form input[type=number], #primary.site-main .calltoaction .classic-form input[type=tel], #primary.site-main .calltoaction .classic-form input[type=range],
#primary.site-main .calltoaction .classic-form input[type=date], #primary.site-main .calltoaction .classic-form input[type=month], #primary.site-main .calltoaction .classic-form input[type=week], #primary.site-main .calltoaction .classic-form input[type=time], #primary.site-main .calltoaction .classic-form input[type=datetime], #primary.site-main .calltoaction .classic-form input[type=datetime-local], #primary.site-main .calltoaction .classic-form input[type=color], #primary.site-main .calltoaction .classic-form input[type=file], #primary.site-main .calltoaction .classic-form textarea {
  background-color: #fff;
  color: #5C6B7A;
  border: 1px solid #00C0F0;
}
#primary.site-main .calltoaction .wpcf7-form input[type=text]:focus, #primary.site-main .calltoaction .wpcf7-form input[type=email]:focus, #primary.site-main .calltoaction .wpcf7-form input[type=confirm_email]:focus, #primary.site-main .calltoaction .wpcf7-form input[type=url]:focus, #primary.site-main .calltoaction .wpcf7-form input[type=password]:focus, #primary.site-main .calltoaction .wpcf7-form input[type=search]:focus, #primary.site-main .calltoaction .wpcf7-form input[type=number]:focus, #primary.site-main .calltoaction .wpcf7-form input[type=tel]:focus, #primary.site-main .calltoaction .wpcf7-form input[type=range]:focus,
#primary.site-main .calltoaction .wpcf7-form input[type=date]:focus, #primary.site-main .calltoaction .wpcf7-form input[type=month]:focus, #primary.site-main .calltoaction .wpcf7-form input[type=week]:focus, #primary.site-main .calltoaction .wpcf7-form input[type=time]:focus, #primary.site-main .calltoaction .wpcf7-form input[type=datetime]:focus, #primary.site-main .calltoaction .wpcf7-form input[type=datetime-local]:focus, #primary.site-main .calltoaction .wpcf7-form input[type=color]:focus, #primary.site-main .calltoaction .wpcf7-form input[type=file]:focus, #primary.site-main .calltoaction .wpcf7-form textarea:focus, #primary.site-main .calltoaction .search-form input[type=text]:focus, #primary.site-main .calltoaction .search-form input[type=email]:focus, #primary.site-main .calltoaction .search-form input[type=confirm_email]:focus, #primary.site-main .calltoaction .search-form input[type=url]:focus, #primary.site-main .calltoaction .search-form input[type=password]:focus, #primary.site-main .calltoaction .search-form input[type=search]:focus, #primary.site-main .calltoaction .search-form input[type=number]:focus, #primary.site-main .calltoaction .search-form input[type=tel]:focus, #primary.site-main .calltoaction .search-form input[type=range]:focus,
#primary.site-main .calltoaction .search-form input[type=date]:focus, #primary.site-main .calltoaction .search-form input[type=month]:focus, #primary.site-main .calltoaction .search-form input[type=week]:focus, #primary.site-main .calltoaction .search-form input[type=time]:focus, #primary.site-main .calltoaction .search-form input[type=datetime]:focus, #primary.site-main .calltoaction .search-form input[type=datetime-local]:focus, #primary.site-main .calltoaction .search-form input[type=color]:focus, #primary.site-main .calltoaction .search-form input[type=file]:focus, #primary.site-main .calltoaction .search-form textarea:focus, #primary.site-main .calltoaction .classic-form input[type=text]:focus, #primary.site-main .calltoaction .classic-form input[type=email]:focus, #primary.site-main .calltoaction .classic-form input[type=confirm_email]:focus, #primary.site-main .calltoaction .classic-form input[type=url]:focus, #primary.site-main .calltoaction .classic-form input[type=password]:focus, #primary.site-main .calltoaction .classic-form input[type=search]:focus, #primary.site-main .calltoaction .classic-form input[type=number]:focus, #primary.site-main .calltoaction .classic-form input[type=tel]:focus, #primary.site-main .calltoaction .classic-form input[type=range]:focus,
#primary.site-main .calltoaction .classic-form input[type=date]:focus, #primary.site-main .calltoaction .classic-form input[type=month]:focus, #primary.site-main .calltoaction .classic-form input[type=week]:focus, #primary.site-main .calltoaction .classic-form input[type=time]:focus, #primary.site-main .calltoaction .classic-form input[type=datetime]:focus, #primary.site-main .calltoaction .classic-form input[type=datetime-local]:focus, #primary.site-main .calltoaction .classic-form input[type=color]:focus, #primary.site-main .calltoaction .classic-form input[type=file]:focus, #primary.site-main .calltoaction .classic-form textarea:focus {
  background-color: #fff;
  color: #5C6B7A;
  border: 1px solid #00C0F0;
}
#primary.site-main .calltoaction .cerchio {
  background-image: url("../img/pattern-2.png");
  border-radius: 50%;
  width: 170px;
  height: 170px;
  position: absolute;
  left: 40%;
  top: 20px;
  opacity: 0.3;
}
#primary.site-main .calltoaction .immagine {
  position: absolute;
  left: 0;
  top: 0;
  width: 45%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0 400px 0 0;
}
#primary.site-main .calltoaction .immagine .velina {
  background-color: #00C0F0;
  opacity: 0.7;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 0 400px 0 0;
}
#primary.site-main .calltoaction .immagine .plus {
  position: absolute;
  left: 5%;
  bottom: 0;
  width: 50%;
  height: auto;
}
#primary.site-main .calltoaction .wpcf7-form input[type=text], #primary.site-main .calltoaction .wpcf7-form input[type=email], #primary.site-main .calltoaction .wpcf7-form input[type=confirm_email], #primary.site-main .calltoaction .wpcf7-form input[type=url], #primary.site-main .calltoaction .wpcf7-form input[type=password], #primary.site-main .calltoaction .wpcf7-form input[type=search], #primary.site-main .calltoaction .wpcf7-form input[type=number], #primary.site-main .calltoaction .wpcf7-form input[type=tel], #primary.site-main .calltoaction .wpcf7-form input[type=range],
#primary.site-main .calltoaction .wpcf7-form input[type=date], #primary.site-main .calltoaction .wpcf7-form input[type=month], #primary.site-main .calltoaction .wpcf7-form input[type=week], #primary.site-main .calltoaction .wpcf7-form input[type=time], #primary.site-main .calltoaction .wpcf7-form input[type=datetime], #primary.site-main .calltoaction .wpcf7-form input[type=datetime-local], #primary.site-main .calltoaction .wpcf7-form input[type=color], #primary.site-main .calltoaction .wpcf7-form input[type=file], #primary.site-main .calltoaction .wpcf7-form textarea, #primary.site-main .calltoaction .search-form input[type=text], #primary.site-main .calltoaction .search-form input[type=email], #primary.site-main .calltoaction .search-form input[type=confirm_email], #primary.site-main .calltoaction .search-form input[type=url], #primary.site-main .calltoaction .search-form input[type=password], #primary.site-main .calltoaction .search-form input[type=search], #primary.site-main .calltoaction .search-form input[type=number], #primary.site-main .calltoaction .search-form input[type=tel], #primary.site-main .calltoaction .search-form input[type=range],
#primary.site-main .calltoaction .search-form input[type=date], #primary.site-main .calltoaction .search-form input[type=month], #primary.site-main .calltoaction .search-form input[type=week], #primary.site-main .calltoaction .search-form input[type=time], #primary.site-main .calltoaction .search-form input[type=datetime], #primary.site-main .calltoaction .search-form input[type=datetime-local], #primary.site-main .calltoaction .search-form input[type=color], #primary.site-main .calltoaction .search-form input[type=file], #primary.site-main .calltoaction .search-form textarea, #primary.site-main .calltoaction .classic-form input[type=text], #primary.site-main .calltoaction .classic-form input[type=email], #primary.site-main .calltoaction .classic-form input[type=confirm_email], #primary.site-main .calltoaction .classic-form input[type=url], #primary.site-main .calltoaction .classic-form input[type=password], #primary.site-main .calltoaction .classic-form input[type=search], #primary.site-main .calltoaction .classic-form input[type=number], #primary.site-main .calltoaction .classic-form input[type=tel], #primary.site-main .calltoaction .classic-form input[type=range],
#primary.site-main .calltoaction .classic-form input[type=date], #primary.site-main .calltoaction .classic-form input[type=month], #primary.site-main .calltoaction .classic-form input[type=week], #primary.site-main .calltoaction .classic-form input[type=time], #primary.site-main .calltoaction .classic-form input[type=datetime], #primary.site-main .calltoaction .classic-form input[type=datetime-local], #primary.site-main .calltoaction .classic-form input[type=color], #primary.site-main .calltoaction .classic-form input[type=file], #primary.site-main .calltoaction .classic-form textarea {
  background-color: #fff;
  color: #5C6B7A;
  border: 1px solid #00C0F0;
}
#primary.site-main .calltoaction .wpcf7-form input[type=text]:focus, #primary.site-main .calltoaction .wpcf7-form input[type=email]:focus, #primary.site-main .calltoaction .wpcf7-form input[type=confirm_email]:focus, #primary.site-main .calltoaction .wpcf7-form input[type=url]:focus, #primary.site-main .calltoaction .wpcf7-form input[type=password]:focus, #primary.site-main .calltoaction .wpcf7-form input[type=search]:focus, #primary.site-main .calltoaction .wpcf7-form input[type=number]:focus, #primary.site-main .calltoaction .wpcf7-form input[type=tel]:focus, #primary.site-main .calltoaction .wpcf7-form input[type=range]:focus,
#primary.site-main .calltoaction .wpcf7-form input[type=date]:focus, #primary.site-main .calltoaction .wpcf7-form input[type=month]:focus, #primary.site-main .calltoaction .wpcf7-form input[type=week]:focus, #primary.site-main .calltoaction .wpcf7-form input[type=time]:focus, #primary.site-main .calltoaction .wpcf7-form input[type=datetime]:focus, #primary.site-main .calltoaction .wpcf7-form input[type=datetime-local]:focus, #primary.site-main .calltoaction .wpcf7-form input[type=color]:focus, #primary.site-main .calltoaction .wpcf7-form input[type=file]:focus, #primary.site-main .calltoaction .wpcf7-form textarea:focus, #primary.site-main .calltoaction .search-form input[type=text]:focus, #primary.site-main .calltoaction .search-form input[type=email]:focus, #primary.site-main .calltoaction .search-form input[type=confirm_email]:focus, #primary.site-main .calltoaction .search-form input[type=url]:focus, #primary.site-main .calltoaction .search-form input[type=password]:focus, #primary.site-main .calltoaction .search-form input[type=search]:focus, #primary.site-main .calltoaction .search-form input[type=number]:focus, #primary.site-main .calltoaction .search-form input[type=tel]:focus, #primary.site-main .calltoaction .search-form input[type=range]:focus,
#primary.site-main .calltoaction .search-form input[type=date]:focus, #primary.site-main .calltoaction .search-form input[type=month]:focus, #primary.site-main .calltoaction .search-form input[type=week]:focus, #primary.site-main .calltoaction .search-form input[type=time]:focus, #primary.site-main .calltoaction .search-form input[type=datetime]:focus, #primary.site-main .calltoaction .search-form input[type=datetime-local]:focus, #primary.site-main .calltoaction .search-form input[type=color]:focus, #primary.site-main .calltoaction .search-form input[type=file]:focus, #primary.site-main .calltoaction .search-form textarea:focus, #primary.site-main .calltoaction .classic-form input[type=text]:focus, #primary.site-main .calltoaction .classic-form input[type=email]:focus, #primary.site-main .calltoaction .classic-form input[type=confirm_email]:focus, #primary.site-main .calltoaction .classic-form input[type=url]:focus, #primary.site-main .calltoaction .classic-form input[type=password]:focus, #primary.site-main .calltoaction .classic-form input[type=search]:focus, #primary.site-main .calltoaction .classic-form input[type=number]:focus, #primary.site-main .calltoaction .classic-form input[type=tel]:focus, #primary.site-main .calltoaction .classic-form input[type=range]:focus,
#primary.site-main .calltoaction .classic-form input[type=date]:focus, #primary.site-main .calltoaction .classic-form input[type=month]:focus, #primary.site-main .calltoaction .classic-form input[type=week]:focus, #primary.site-main .calltoaction .classic-form input[type=time]:focus, #primary.site-main .calltoaction .classic-form input[type=datetime]:focus, #primary.site-main .calltoaction .classic-form input[type=datetime-local]:focus, #primary.site-main .calltoaction .classic-form input[type=color]:focus, #primary.site-main .calltoaction .classic-form input[type=file]:focus, #primary.site-main .calltoaction .classic-form textarea:focus {
  background-color: #fff;
  color: #5C6B7A;
  border: 1px solid #00C0F0;
}
#primary.site-main .calltoaction .titolo2 {
  color: #fff;
}
#primary.site-main .calltoaction .titolo5 {
  padding-bottom: 50px;
}
#primary.site-main .help-block .mySwiperThumbs .swiper-slide {
  cursor: pointer;
  margin-bottom: 30px;
}
#primary.site-main .help-block .mySwiperThumbs .swiper-slide .titolotab {
  display: inline-block;
  padding-bottom: 20px;
  display: inline-block;
  position: relative;
  border-bottom: 4px solid transparent;
  transition-property: border-bottom-color;
  transition-duration: 1s;
}
#primary.site-main .help-block .mySwiperThumbs .swiper-slide.swiper-slide-thumb-active .titolotab {
  font-weight: bold;
}
#primary.site-main .help-block .lista-blocchi-help .item {
  -webkit-box-shadow: 0px 20px 42px -45px rgba(0, 34, 90, 0.77);
  -moz-box-shadow: 0px 20px 42px -45px rgba(0, 34, 90, 0.77);
  box-shadow: 0px 20px 42px -45px rgba(0, 34, 90, 0.77);
  background-color: #fff;
  color: #5C6B7A;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 25px;
  padding: 50px;
  margin-bottom: 10px;
  margin-top: 8px;
}
#primary.site-main .help-block .lista-blocchi-help .item h1, #primary.site-main .help-block .lista-blocchi-help .item h2, #primary.site-main .help-block .lista-blocchi-help .item h3, #primary.site-main .help-block .lista-blocchi-help .item h4, #primary.site-main .help-block .lista-blocchi-help .item h5, #primary.site-main .help-block .lista-blocchi-help .item h6, #primary.site-main .help-block .lista-blocchi-help .item .titolo, #primary.site-main .help-block .lista-blocchi-help .item .titolo1, #primary.site-main .help-block .lista-blocchi-help .item .titolo2, #primary.site-main .help-block .lista-blocchi-help .item .titolo3, #primary.site-main .help-block .lista-blocchi-help .item .titolo4 {
  color: #00225A;
}
#primary.site-main .help-block .lista-blocchi-help .item a {
  color: #429AFF;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
}
#primary.site-main .help-block .lista-blocchi-help .item a:focus {
  outline: 0;
}
#primary.site-main .help-block .lista-blocchi-help .item a:visited {
  color: #429AFF;
}
#primary.site-main .help-block .lista-blocchi-help .item a:hover {
  color: #429AFF;
  text-decoration: underline;
}
#primary.site-main .help-block .lista-blocchi-help .item a.link {
  font-weight: 800;
  color: #00225A;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
  display: inline-block;
  font-size: 88%;
  text-transform: uppercase;
}
#primary.site-main .help-block .lista-blocchi-help .item a.link:focus {
  outline: 0;
}
#primary.site-main .help-block .lista-blocchi-help .item a.link:visited {
  color: #00225A;
}
#primary.site-main .help-block .lista-blocchi-help .item a.link:hover {
  color: #00225A;
  text-decoration: none;
}
#primary.site-main .help-block .lista-blocchi-help .item a.link:hover:after {
  padding-left: 20px;
}
#primary.site-main .help-block .lista-blocchi-help .item a.link:after {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f061";
  padding-left: 10px;
  transition: all 0.4s ease-in-out;
}
#primary.site-main .help-block .lista-blocchi-help .item a.pulsante, #primary.site-main .help-block .lista-blocchi-help .item .wp-block-button__link, #primary.site-main .help-block .lista-blocchi-help .item submit, #primary.site-main .help-block .lista-blocchi-help .item input[type=submit], #primary.site-main .help-block .lista-blocchi-help .item input[type=reset], #primary.site-main .help-block .lista-blocchi-help .item input[type=button] {
  position: relative;
  border-radius: 25px;
  background: #00225A;
  color: #fff;
  border: 2px solid #00225A;
  box-shadow: none;
  text-shadow: none;
  text-decoration: none !important;
  vertical-align: middle;
  display: inline-block;
  width: auto;
  margin: 0;
  font-size: 88%;
  font-weight: 700;
  text-transform: uppercase;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  -o-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  padding: 10px 30px;
  cursor: pointer;
  min-width: 155px;
  text-align: center;
}
#primary.site-main .help-block .lista-blocchi-help .item a.pulsante:visited, #primary.site-main .help-block .lista-blocchi-help .item .wp-block-button__link:visited, #primary.site-main .help-block .lista-blocchi-help .item submit:visited, #primary.site-main .help-block .lista-blocchi-help .item input[type=submit]:visited, #primary.site-main .help-block .lista-blocchi-help .item input[type=reset]:visited, #primary.site-main .help-block .lista-blocchi-help .item input[type=button]:visited {
  color: #fff;
}
#primary.site-main .help-block .lista-blocchi-help .item a.pulsante:hover, #primary.site-main .help-block .lista-blocchi-help .item .wp-block-button__link:hover, #primary.site-main .help-block .lista-blocchi-help .item submit:hover, #primary.site-main .help-block .lista-blocchi-help .item input[type=submit]:hover, #primary.site-main .help-block .lista-blocchi-help .item input[type=reset]:hover, #primary.site-main .help-block .lista-blocchi-help .item input[type=button]:hover {
  font-weight: 700;
  background: transparent;
  color: #00225A;
  border-color: #00225A;
  box-shadow: none;
}
#primary.site-main .help-block .lista-blocchi-help .item a.pulsante.negativo, #primary.site-main .help-block .lista-blocchi-help .item input[type=submit].negativo, #primary.site-main .help-block .lista-blocchi-help .item input[type=reset].negativo, #primary.site-main .help-block .lista-blocchi-help .item input[type=button].negativo {
  position: relative;
  border-radius: 25px;
  background: transparent;
  color: #00225A;
  border: 2px solid #00225A;
  box-shadow: none;
  text-shadow: none;
  text-decoration: none !important;
  vertical-align: middle;
  display: inline-block;
  width: auto;
  margin: 0;
  font-size: 88%;
  font-weight: 700;
  text-transform: uppercase;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  -o-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  padding: 10px 30px;
  cursor: pointer;
  min-width: 155px;
  text-align: center;
}
#primary.site-main .help-block .lista-blocchi-help .item a.pulsante.negativo:visited, #primary.site-main .help-block .lista-blocchi-help .item input[type=submit].negativo:visited, #primary.site-main .help-block .lista-blocchi-help .item input[type=reset].negativo:visited, #primary.site-main .help-block .lista-blocchi-help .item input[type=button].negativo:visited {
  color: #00225A;
}
#primary.site-main .help-block .lista-blocchi-help .item a.pulsante.negativo:hover, #primary.site-main .help-block .lista-blocchi-help .item input[type=submit].negativo:hover, #primary.site-main .help-block .lista-blocchi-help .item input[type=reset].negativo:hover, #primary.site-main .help-block .lista-blocchi-help .item input[type=button].negativo:hover {
  font-weight: 700;
  background: #00225A;
  color: #fff;
  border-color: #00225A;
  box-shadow: none;
}
#primary.site-main .help-block .lista-blocchi-help .item .gruppo-pulsanti a.pulsante, #primary.site-main .help-block .lista-blocchi-help .item .gruppo-pulsanti .wp-block-button__link, #primary.site-main .help-block .lista-blocchi-help .item .gruppo-pulsanti submit, #primary.site-main .help-block .lista-blocchi-help .item .gruppo-pulsanti input[type=submit], #primary.site-main .help-block .lista-blocchi-help .item .gruppo-pulsanti input[type=reset], #primary.site-main .help-block .lista-blocchi-help .item .gruppo-pulsanti input[type=button] {
  position: relative;
  border-radius: 25px;
  background: transparent;
  color: #00225A;
  border: 2px solid #00225A;
  box-shadow: none;
  text-shadow: none;
  text-decoration: none !important;
  vertical-align: middle;
  display: inline-block;
  width: auto;
  margin: 0;
  font-size: 88%;
  font-weight: 700;
  text-transform: uppercase;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  -o-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  padding: 10px 30px;
  cursor: pointer;
  min-width: 155px;
  text-align: center;
}
#primary.site-main .help-block .lista-blocchi-help .item .gruppo-pulsanti a.pulsante:visited, #primary.site-main .help-block .lista-blocchi-help .item .gruppo-pulsanti .wp-block-button__link:visited, #primary.site-main .help-block .lista-blocchi-help .item .gruppo-pulsanti submit:visited, #primary.site-main .help-block .lista-blocchi-help .item .gruppo-pulsanti input[type=submit]:visited, #primary.site-main .help-block .lista-blocchi-help .item .gruppo-pulsanti input[type=reset]:visited, #primary.site-main .help-block .lista-blocchi-help .item .gruppo-pulsanti input[type=button]:visited {
  color: #00225A;
}
#primary.site-main .help-block .lista-blocchi-help .item .gruppo-pulsanti a.pulsante:hover, #primary.site-main .help-block .lista-blocchi-help .item .gruppo-pulsanti .wp-block-button__link:hover, #primary.site-main .help-block .lista-blocchi-help .item .gruppo-pulsanti submit:hover, #primary.site-main .help-block .lista-blocchi-help .item .gruppo-pulsanti input[type=submit]:hover, #primary.site-main .help-block .lista-blocchi-help .item .gruppo-pulsanti input[type=reset]:hover, #primary.site-main .help-block .lista-blocchi-help .item .gruppo-pulsanti input[type=button]:hover {
  font-weight: 700;
  background: #00225A;
  color: #fff;
  border-color: #00225A;
  box-shadow: none;
}
#primary.site-main .help-block .lista-blocchi-help .item .testoinner {
  padding-bottom: 1rem;
}
#primary.site-main .help-block.sfondo-blu .mySwiperThumbs .swiper-slide .titolotab {
  color: #fff;
}
#primary.site-main .help-block.sfondo-blu .mySwiperThumbs .swiper-slide.swiper-slide-thumb-active .titolotab {
  border-bottom-color: #fff;
}
#primary.site-main .help-block.sfondo-grigio .mySwiperThumbs .swiper-slide .titolotab {
  color: #00225A;
}
#primary.site-main .help-block.sfondo-grigio .mySwiperThumbs .swiper-slide.swiper-slide-thumb-active .titolotab {
  border-bottom-color: #00225A;
}
#primary.site-main .help-block.sfondo-cta .titolo1 {
  color: #00225A;
}
#primary.site-main .help-block.sfondo-cta .mySwiperThumbs .swiper-slide .titolotab {
  color: #00225A;
}
#primary.site-main .help-block.sfondo-cta .mySwiperThumbs .swiper-slide.swiper-slide-thumb-active .titolotab {
  border-bottom-color: #00225A;
}
@media (min-width: 992px) {
  #primary.site-main .help-block .mySwiperThumbs .swiper-slide {
    width: max-content !important;
  }
}
#primary.site-main .lista-blocchi {
  background-color: #E9EDF3;
}
#primary.site-main .lista-blocchi .item {
  background: #fff;
  border-radius: 25px;
  padding: 40px;
  margin-bottom: 10px;
  margin-top: 8px;
  transition: transform 0.5s;
}
#primary.site-main .lista-blocchi .item img {
  width: auto;
  height: 74px;
  margin-bottom: 20px;
}
#primary.site-main .lista-blocchi .item .testoinner {
  padding-bottom: 1rem;
}
#primary.site-main .lista-blocchi .item:hover {
  -webkit-transform: translateY(-8px);
  -ms-transform: translateY(-8px);
  transform: translateY(-8px);
  -webkit-box-shadow: 0px 20px 85px -45px rgba(0, 34, 90, 0.77);
  -moz-box-shadow: 0px 20px 85px -45px rgba(0, 34, 90, 0.77);
  box-shadow: 0px 20px 85px -45px rgba(0, 34, 90, 0.77);
  transition: all 0.2s ease-in-out;
}
#primary.site-main .lista-blocchi .item.sfondo-blu {
  background-color: #004182;
}
#primary.site-main .lista-blocchi .item.sfondo-cta {
  background-color: #00C0F0;
}
#primary.site-main .lista-blocchi .item.sfondo-grigio {
  background-color: #E9EDF3;
}
#primary.site-main .lista-blocchi .item.sfondo-nero {
  background-color: #191919;
}
#primary.site-main .lista-blocchi .item.sfondo-viola {
  background-color: #5D5BD4;
}
#primary.site-main .lista-blocchi .owl-carousel .owl-stage {
  padding-left: 0 !important;
}
#primary.site-main .lista-blocchi .owl-carousel .owl-stage .owl-item.active .item {
  -webkit-box-shadow: 11px 15px 15px -15px rgba(0, 34, 90, 0.77);
  -moz-box-shadow: 11px 15px 15px -15px rgba(0, 34, 90, 0.77);
  box-shadow: 11px 15px 15px -15px rgba(0, 34, 90, 0.77);
  transition: all 0.2s ease-in-out;
}
#primary.site-main .lista-blocchi.col2 .item img {
  height: 172px;
  margin-bottom: 40px;
}
#primary.site-main #blocco-silos {
  background-color: #E9EDF3;
}
#primary.site-main #blocco-silos .item h3 {
  padding: 20px 0;
  border-bottom: 1px solid #c6cbd1;
}
#primary.site-main #blocco-silos .item h4 {
  padding: 20px 0;
  border-bottom: 1px solid #c6cbd1;
}
#primary.site-main #blocco-silos .item .testoinner {
  padding: 20px 0;
  font-size: 22px;
  color: #00225A;
}
#primary.site-main #blocco-silos .item .points {
  margin: 0 -40px -40px -40px;
  border-top: 4px solid #E9EDF3;
  padding: 40px;
}
#primary.site-main #blocco-silos .item .points ul {
  /*                    margin: 0;
                      padding: 0;*/
}
#primary.site-main #blocco-silos .item .points ul li {
  font-size: 22px;
  padding: 10px 0;
  color: #00225A;
  border-bottom: 1px solid #c6cbd1;
}
#primary.site-main #blocco-silos .item .points ul li:last-of-type {
  border-bottom: 0;
}
#primary.site-main #blocco-silos .item.bestseller {
  -webkit-box-shadow: 0px 20px 85px -45px rgba(0, 34, 90, 0.77);
  -moz-box-shadow: 0px 20px 85px -45px rgba(0, 34, 90, 0.77);
  box-shadow: 0px 20px 85px -45px rgba(0, 34, 90, 0.77);
  transition: all 0.2s ease-in-out;
}
#primary.site-main #blocco-silos .item.bestseller {
  position: relative;
  padding-top: 140px;
  padding-bottom: 140px;
}
#primary.site-main #blocco-silos .item.bestseller .best {
  color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  width: 40%;
  min-width: 130px;
  min-height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-top-right-radius: 25px;
  border-bottom-left-radius: 25px;
  text-align: center;
}
#primary.site-main #blocco-silos.call {
  background-color: #E9EDF3;
  background-image: url("../img/help/4-elementi-help-center-chiamaci.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
#primary.site-main #blocco-silos.call .item {
  padding: 40px 20px;
}
#primary.site-main #blocco-silos.call .item img {
  width: 100%;
  height: auto;
}
#primary.site-main #blocco-silos.call .item .days {
  padding: 20px 0;
  border-bottom: 1px solid #c6cbd1;
}
#primary.site-main #blocco-silos.call .item .schedule {
  padding: 20px 0;
  border-bottom: 1px solid #c6cbd1;
}
#primary.site-main #blocco-silos.call .item .points {
  margin: 0 -20px -20px;
  padding: 20px;
}
#primary.site-main #blocco-silos.call .item .points .el {
  padding: 10px 0;
}
#primary.site-main #assistenza {
  background-image: url("../img/prodotto/8-imagiclecare.svg");
  background-size: cover;
  background-position: center;
}
#primary.site-main #assistenza .blocco {
  border-bottom: 1px solid #ccc;
}
#primary.site-main #assistenza .blocco:last-of-type {
  border-bottom: 0;
}
#primary.site-main .prod-cta {
  background-color: #00C0F0;
  color: #00225A;
}
#primary.site-main .prod-cta h1, #primary.site-main .prod-cta h2, #primary.site-main .prod-cta h3, #primary.site-main .prod-cta h4, #primary.site-main .prod-cta h5, #primary.site-main .prod-cta h6, #primary.site-main .prod-cta .titolo, #primary.site-main .prod-cta .titolo1, #primary.site-main .prod-cta .titolo2, #primary.site-main .prod-cta .titolo3, #primary.site-main .prod-cta .titolo4 {
  color: #fff;
}
#primary.site-main .prod-cta a {
  color: #B8D1F5;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
}
#primary.site-main .prod-cta a:focus {
  outline: 0;
}
#primary.site-main .prod-cta a:visited {
  color: #B8D1F5;
}
#primary.site-main .prod-cta a:hover {
  color: #B8D1F5;
  text-decoration: underline;
}
#primary.site-main .prod-cta a.link {
  font-weight: 800;
  color: #00225A;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
  display: inline-block;
  font-size: 88%;
  text-transform: uppercase;
}
#primary.site-main .prod-cta a.link:focus {
  outline: 0;
}
#primary.site-main .prod-cta a.link:visited {
  color: #00225A;
}
#primary.site-main .prod-cta a.link:hover {
  color: #00225A;
  text-decoration: none;
}
#primary.site-main .prod-cta a.link:hover:after {
  padding-left: 20px;
}
#primary.site-main .prod-cta a.link:after {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f061";
  padding-left: 10px;
  transition: all 0.4s ease-in-out;
}
#primary.site-main .prod-cta a.pulsante, #primary.site-main .prod-cta .wp-block-button__link, #primary.site-main .prod-cta submit, #primary.site-main .prod-cta input[type=submit], #primary.site-main .prod-cta input[type=reset], #primary.site-main .prod-cta input[type=button] {
  position: relative;
  border-radius: 25px;
  background: #00225A;
  color: #fff;
  border: 2px solid #00225A;
  box-shadow: none;
  text-shadow: none;
  text-decoration: none !important;
  vertical-align: middle;
  display: inline-block;
  width: auto;
  margin: 0;
  font-size: 88%;
  font-weight: 700;
  text-transform: uppercase;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  -o-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  padding: 10px 30px;
  cursor: pointer;
  min-width: 155px;
  text-align: center;
}
#primary.site-main .prod-cta a.pulsante:visited, #primary.site-main .prod-cta .wp-block-button__link:visited, #primary.site-main .prod-cta submit:visited, #primary.site-main .prod-cta input[type=submit]:visited, #primary.site-main .prod-cta input[type=reset]:visited, #primary.site-main .prod-cta input[type=button]:visited {
  color: #fff;
}
#primary.site-main .prod-cta a.pulsante:hover, #primary.site-main .prod-cta .wp-block-button__link:hover, #primary.site-main .prod-cta submit:hover, #primary.site-main .prod-cta input[type=submit]:hover, #primary.site-main .prod-cta input[type=reset]:hover, #primary.site-main .prod-cta input[type=button]:hover {
  font-weight: 700;
  background: transparent;
  color: #00225A;
  border-color: #00225A;
  box-shadow: none;
}
#primary.site-main .prod-cta a.pulsante.negativo, #primary.site-main .prod-cta input[type=submit].negativo, #primary.site-main .prod-cta input[type=reset].negativo, #primary.site-main .prod-cta input[type=button].negativo {
  position: relative;
  border-radius: 25px;
  background: transparent;
  color: #00225A;
  border: 2px solid #00225A;
  box-shadow: none;
  text-shadow: none;
  text-decoration: none !important;
  vertical-align: middle;
  display: inline-block;
  width: auto;
  margin: 0;
  font-size: 88%;
  font-weight: 700;
  text-transform: uppercase;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  -o-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  padding: 10px 30px;
  cursor: pointer;
  min-width: 155px;
  text-align: center;
}
#primary.site-main .prod-cta a.pulsante.negativo:visited, #primary.site-main .prod-cta input[type=submit].negativo:visited, #primary.site-main .prod-cta input[type=reset].negativo:visited, #primary.site-main .prod-cta input[type=button].negativo:visited {
  color: #00225A;
}
#primary.site-main .prod-cta a.pulsante.negativo:hover, #primary.site-main .prod-cta input[type=submit].negativo:hover, #primary.site-main .prod-cta input[type=reset].negativo:hover, #primary.site-main .prod-cta input[type=button].negativo:hover {
  font-weight: 700;
  background: #00225A;
  color: #fff;
  border-color: #00225A;
  box-shadow: none;
}
#primary.site-main .prod-cta .wpcf7-form input[type=text], #primary.site-main .prod-cta .wpcf7-form input[type=email], #primary.site-main .prod-cta .wpcf7-form input[type=confirm_email], #primary.site-main .prod-cta .wpcf7-form input[type=url], #primary.site-main .prod-cta .wpcf7-form input[type=password], #primary.site-main .prod-cta .wpcf7-form input[type=search], #primary.site-main .prod-cta .wpcf7-form input[type=number], #primary.site-main .prod-cta .wpcf7-form input[type=tel], #primary.site-main .prod-cta .wpcf7-form input[type=range],
#primary.site-main .prod-cta .wpcf7-form input[type=date], #primary.site-main .prod-cta .wpcf7-form input[type=month], #primary.site-main .prod-cta .wpcf7-form input[type=week], #primary.site-main .prod-cta .wpcf7-form input[type=time], #primary.site-main .prod-cta .wpcf7-form input[type=datetime], #primary.site-main .prod-cta .wpcf7-form input[type=datetime-local], #primary.site-main .prod-cta .wpcf7-form input[type=color], #primary.site-main .prod-cta .wpcf7-form input[type=file], #primary.site-main .prod-cta .wpcf7-form textarea, #primary.site-main .prod-cta .search-form input[type=text], #primary.site-main .prod-cta .search-form input[type=email], #primary.site-main .prod-cta .search-form input[type=confirm_email], #primary.site-main .prod-cta .search-form input[type=url], #primary.site-main .prod-cta .search-form input[type=password], #primary.site-main .prod-cta .search-form input[type=search], #primary.site-main .prod-cta .search-form input[type=number], #primary.site-main .prod-cta .search-form input[type=tel], #primary.site-main .prod-cta .search-form input[type=range],
#primary.site-main .prod-cta .search-form input[type=date], #primary.site-main .prod-cta .search-form input[type=month], #primary.site-main .prod-cta .search-form input[type=week], #primary.site-main .prod-cta .search-form input[type=time], #primary.site-main .prod-cta .search-form input[type=datetime], #primary.site-main .prod-cta .search-form input[type=datetime-local], #primary.site-main .prod-cta .search-form input[type=color], #primary.site-main .prod-cta .search-form input[type=file], #primary.site-main .prod-cta .search-form textarea, #primary.site-main .prod-cta .classic-form input[type=text], #primary.site-main .prod-cta .classic-form input[type=email], #primary.site-main .prod-cta .classic-form input[type=confirm_email], #primary.site-main .prod-cta .classic-form input[type=url], #primary.site-main .prod-cta .classic-form input[type=password], #primary.site-main .prod-cta .classic-form input[type=search], #primary.site-main .prod-cta .classic-form input[type=number], #primary.site-main .prod-cta .classic-form input[type=tel], #primary.site-main .prod-cta .classic-form input[type=range],
#primary.site-main .prod-cta .classic-form input[type=date], #primary.site-main .prod-cta .classic-form input[type=month], #primary.site-main .prod-cta .classic-form input[type=week], #primary.site-main .prod-cta .classic-form input[type=time], #primary.site-main .prod-cta .classic-form input[type=datetime], #primary.site-main .prod-cta .classic-form input[type=datetime-local], #primary.site-main .prod-cta .classic-form input[type=color], #primary.site-main .prod-cta .classic-form input[type=file], #primary.site-main .prod-cta .classic-form textarea {
  background-color: #fff;
  color: #5C6B7A;
  border: 1px solid #00C0F0;
}
#primary.site-main .prod-cta .wpcf7-form input[type=text]:focus, #primary.site-main .prod-cta .wpcf7-form input[type=email]:focus, #primary.site-main .prod-cta .wpcf7-form input[type=confirm_email]:focus, #primary.site-main .prod-cta .wpcf7-form input[type=url]:focus, #primary.site-main .prod-cta .wpcf7-form input[type=password]:focus, #primary.site-main .prod-cta .wpcf7-form input[type=search]:focus, #primary.site-main .prod-cta .wpcf7-form input[type=number]:focus, #primary.site-main .prod-cta .wpcf7-form input[type=tel]:focus, #primary.site-main .prod-cta .wpcf7-form input[type=range]:focus,
#primary.site-main .prod-cta .wpcf7-form input[type=date]:focus, #primary.site-main .prod-cta .wpcf7-form input[type=month]:focus, #primary.site-main .prod-cta .wpcf7-form input[type=week]:focus, #primary.site-main .prod-cta .wpcf7-form input[type=time]:focus, #primary.site-main .prod-cta .wpcf7-form input[type=datetime]:focus, #primary.site-main .prod-cta .wpcf7-form input[type=datetime-local]:focus, #primary.site-main .prod-cta .wpcf7-form input[type=color]:focus, #primary.site-main .prod-cta .wpcf7-form input[type=file]:focus, #primary.site-main .prod-cta .wpcf7-form textarea:focus, #primary.site-main .prod-cta .search-form input[type=text]:focus, #primary.site-main .prod-cta .search-form input[type=email]:focus, #primary.site-main .prod-cta .search-form input[type=confirm_email]:focus, #primary.site-main .prod-cta .search-form input[type=url]:focus, #primary.site-main .prod-cta .search-form input[type=password]:focus, #primary.site-main .prod-cta .search-form input[type=search]:focus, #primary.site-main .prod-cta .search-form input[type=number]:focus, #primary.site-main .prod-cta .search-form input[type=tel]:focus, #primary.site-main .prod-cta .search-form input[type=range]:focus,
#primary.site-main .prod-cta .search-form input[type=date]:focus, #primary.site-main .prod-cta .search-form input[type=month]:focus, #primary.site-main .prod-cta .search-form input[type=week]:focus, #primary.site-main .prod-cta .search-form input[type=time]:focus, #primary.site-main .prod-cta .search-form input[type=datetime]:focus, #primary.site-main .prod-cta .search-form input[type=datetime-local]:focus, #primary.site-main .prod-cta .search-form input[type=color]:focus, #primary.site-main .prod-cta .search-form input[type=file]:focus, #primary.site-main .prod-cta .search-form textarea:focus, #primary.site-main .prod-cta .classic-form input[type=text]:focus, #primary.site-main .prod-cta .classic-form input[type=email]:focus, #primary.site-main .prod-cta .classic-form input[type=confirm_email]:focus, #primary.site-main .prod-cta .classic-form input[type=url]:focus, #primary.site-main .prod-cta .classic-form input[type=password]:focus, #primary.site-main .prod-cta .classic-form input[type=search]:focus, #primary.site-main .prod-cta .classic-form input[type=number]:focus, #primary.site-main .prod-cta .classic-form input[type=tel]:focus, #primary.site-main .prod-cta .classic-form input[type=range]:focus,
#primary.site-main .prod-cta .classic-form input[type=date]:focus, #primary.site-main .prod-cta .classic-form input[type=month]:focus, #primary.site-main .prod-cta .classic-form input[type=week]:focus, #primary.site-main .prod-cta .classic-form input[type=time]:focus, #primary.site-main .prod-cta .classic-form input[type=datetime]:focus, #primary.site-main .prod-cta .classic-form input[type=datetime-local]:focus, #primary.site-main .prod-cta .classic-form input[type=color]:focus, #primary.site-main .prod-cta .classic-form input[type=file]:focus, #primary.site-main .prod-cta .classic-form textarea:focus {
  background-color: #fff;
  color: #5C6B7A;
  border: 1px solid #00C0F0;
}
#primary.site-main .prod-cta .item {
  border-left: 1px solid #fff;
  margin-top: 50px;
  margin-bottom: 50px;
}
#primary.site-main .prod-cta .item:last-of-type {
  border-right: 1px solid #fff;
}
#primary.site-main .top-header.titolosx {
  background-position: bottom right;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: flex-end;
}
#primary.site-main .top-header.solotxt {
  background-image: url("../img/top-solotxt.svg");
  background-size: cover;
  background-position: center top;
}
#primary.site-main .top-header.solotxt.txtleft {
  background-position: center right;
}
#primary.site-main .top-header.bigimg {
  position: relative;
  background-color: #E9EDF3;
}
#primary.site-main .top-header.bigimg h1 {
  color: #fff;
}
#primary.site-main .top-header.bigimg .ombra {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 17, 57, 0.2);
  border-bottom-left-radius: 10vh;
  border-bottom-right-radius: 10vh;
}
#primary.site-main .top-header.bigimg .immagine {
  position: relative;
}
#primary.site-main .top-header.bigimg .immagine img {
  border-bottom-left-radius: 10vh;
  border-bottom-right-radius: 10vh;
}
#primary.site-main .top-header.bigimg .contenuto {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}
#primary.site-main .intro-titolosx {
  background-color: #00C0F0;
  background-image: url("../img/help/0-elementi-help-center-banner-HC.svg");
  background-position: bottom right;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 80px;
}
#primary.site-main .intro-titolosx .contenuto {
  padding-top: 20px;
  padding-bottom: 170px;
}
#primary.site-main .intro-titolo-center {
  position: relative;
  background-color: #E9EDF3;
}
#primary.site-main .intro-titolo-center h1 {
  color: #fff;
}
#primary.site-main .intro-titolo-center .ombra {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 17, 57, 0.2);
  border-bottom-left-radius: 10vh;
  border-bottom-right-radius: 10vh;
}
#primary.site-main .intro-titolo-center .immagine {
  position: relative;
}
#primary.site-main .intro-titolo-center .immagine img {
  border-bottom-left-radius: 10vh;
  border-bottom-right-radius: 10vh;
}
#primary.site-main .intro-titolo-center .contenuto {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}
#primary.site-main #blog {
  background-image: url("../img/prodotto/11-dal-blog.svg");
  background-size: cover;
  background-position: center;
}
#primary.site-main #blog .pattern-news {
  padding-top: 60px;
  padding-bottom: 50px;
}
#primary.site-main #blog .pattern-news .griglia.classico {
  height: 310px;
}
#primary.site-main #block-languages {
  background-color: #E9EDF3;
  background-image: url("../img/sette-lingue.svg");
  background-size: contain;
  background-position: bottom right;
  background-repeat: no-repeat;
}
#primary.site-main #block-languages .container-fluid, #primary.site-main #block-languages .container-sm, #primary.site-main #block-languages .container-md, #primary.site-main #block-languages .container-lg, #primary.site-main #block-languages .container-xl {
  max-width: 2300px !important;
}
#primary.site-main #block-languages .lista {
  display: flex;
  flex-wrap: wrap;
}
#primary.site-main #block-languages .lista .el {
  flex-basis: 176px;
}
#primary.site-main #block-languages .lista .el img {
  height: 176px;
  width: 176px;
}
#primary.site-main .roadmap {
  position: relative;
}
#primary.site-main .roadmap .grafica {
  text-align: right;
}
#primary.site-main .roadmap .contenuto {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  align-items: center;
}
#primary.site-main #review .sfondo {
  background-color: #004182;
  height: 100%;
  width: 66%;
  position: absolute;
  right: 0;
  top: 0;
  border-top-left-radius: 50vh;
  border-bottom-left-radius: 50vh;
}
#primary.site-main #review .sfondo-rtl {
  background-color: #004182;
  height: 100%;
  width: 66%;
  position: absolute;
  left: 0;
  top: 0;
  border-top-right-radius: 50vh;
  border-bottom-right-radius: 50vh;
}
#primary.site-main #review .top-review {
  background-image: url("../img/customers/5-elementi-customers-sempre.svg");
  background-size: contain;
  background-position: bottom left;
  background-repeat: no-repeat;
}
#primary.site-main #review .top-review .img {
  max-height: 400px;
}
#primary.site-main #review .lista-review {
  position: relative;
  color: #fff;
}
#primary.site-main #review .lista-review .container {
  z-index: 100;
  position: relative;
}
#primary.site-main #review .lista-review .citazione {
  font-family: "Gotham Rounded";
  font-size: 32px;
}
#primary.site-main #review .lista-review .name {
  font-size: 18;
  font-weight: bold;
  padding-top: 30px;
}
#primary.site-main #review .lista-review .role {
  font-size: 18;
}
#primary.site-main .people-img {
  max-width: 282px;
}
#primary.site-main .lista-people .inner {
  padding-top: 20px;
}
#primary.site-main .lista-people .inner .name {
  font-weight: 600;
  font-size: 24px;
  letter-spacing: -1px;
  padding-bottom: 7px;
}
#primary.site-main .lista-people .inner .role {
  font-size: 18px;
  padding-bottom: 10px;
}
#primary.site-main .lista-people .inner .area {
  font-size: 22px;
}
#primary.site-main .people-item, #primary.site-main .owl-carousel .owl-item .people-item {
  text-align: center;
}
#primary.site-main .people-item .people-img, #primary.site-main .owl-carousel .owl-item .people-item .people-img {
  max-width: 282px;
  height: auto;
  display: inline-block;
}
#primary.site-main .block-cta-round {
  background-color: #fff;
  background-image: url("../img/help/3-elementi-help-center-ticket.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
#primary.site-main .block-cta-round .immagine {
  padding-top: 20px;
  position: relative;
}
#primary.site-main .block-cta-round .immagine .img-grafico {
  background-color: #00C0F0;
  width: 90%;
  height: 100%;
  border-radius: 0 100vh 100vh 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
#primary.site-main .block-cta-round .immagine img {
  z-index: 100;
  max-height: 470px;
  position: relative;
  width: auto;
}
#primary.site-main .block-cta-round.dx .immagine .img-grafico {
  border-radius: 100vh 0 0 100vh;
  left: auto;
  right: 0;
}
#primary.site-main #myModalPeople .modal-content {
  background-color: #004182;
  color: #B8D1F5;
  background-color: #003F85;
  color: #fff;
  border-radius: 0;
}
#primary.site-main #myModalPeople .modal-content h1, #primary.site-main #myModalPeople .modal-content h2, #primary.site-main #myModalPeople .modal-content h3, #primary.site-main #myModalPeople .modal-content h4, #primary.site-main #myModalPeople .modal-content h5, #primary.site-main #myModalPeople .modal-content h6, #primary.site-main #myModalPeople .modal-content .titolo, #primary.site-main #myModalPeople .modal-content .titolo1, #primary.site-main #myModalPeople .modal-content .titolo2, #primary.site-main #myModalPeople .modal-content .titolo3, #primary.site-main #myModalPeople .modal-content .titolo4 {
  color: #fff;
}
#primary.site-main #myModalPeople .modal-content a {
  color: #429AFF;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
}
#primary.site-main #myModalPeople .modal-content a:focus {
  outline: 0;
}
#primary.site-main #myModalPeople .modal-content a:visited {
  color: #429AFF;
}
#primary.site-main #myModalPeople .modal-content a:hover {
  color: #ffffff;
  text-decoration: underline;
}
#primary.site-main #myModalPeople .modal-content a.link {
  font-weight: 800;
  color: #ffffff;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
  display: inline-block;
  font-size: 88%;
  text-transform: uppercase;
}
#primary.site-main #myModalPeople .modal-content a.link:focus {
  outline: 0;
}
#primary.site-main #myModalPeople .modal-content a.link:visited {
  color: #ffffff;
}
#primary.site-main #myModalPeople .modal-content a.link:hover {
  color: #ffffff;
  text-decoration: none;
}
#primary.site-main #myModalPeople .modal-content a.link:hover:after {
  padding-left: 20px;
}
#primary.site-main #myModalPeople .modal-content a.link:after {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f061";
  padding-left: 10px;
  transition: all 0.4s ease-in-out;
}
#primary.site-main #myModalPeople .modal-content a.pulsante, #primary.site-main #myModalPeople .modal-content .wp-block-button__link, #primary.site-main #myModalPeople .modal-content submit, #primary.site-main #myModalPeople .modal-content input[type=submit], #primary.site-main #myModalPeople .modal-content input[type=reset], #primary.site-main #myModalPeople .modal-content input[type=button] {
  position: relative;
  border-radius: 25px;
  background: transparent;
  color: #fff;
  border: 2px solid #fff;
  box-shadow: none;
  text-shadow: none;
  text-decoration: none !important;
  vertical-align: middle;
  display: inline-block;
  width: auto;
  margin: 0;
  font-size: 88%;
  font-weight: 700;
  text-transform: uppercase;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  -o-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  padding: 10px 30px;
  cursor: pointer;
  min-width: 155px;
  text-align: center;
}
#primary.site-main #myModalPeople .modal-content a.pulsante:visited, #primary.site-main #myModalPeople .modal-content .wp-block-button__link:visited, #primary.site-main #myModalPeople .modal-content submit:visited, #primary.site-main #myModalPeople .modal-content input[type=submit]:visited, #primary.site-main #myModalPeople .modal-content input[type=reset]:visited, #primary.site-main #myModalPeople .modal-content input[type=button]:visited {
  color: #fff;
}
#primary.site-main #myModalPeople .modal-content a.pulsante:hover, #primary.site-main #myModalPeople .modal-content .wp-block-button__link:hover, #primary.site-main #myModalPeople .modal-content submit:hover, #primary.site-main #myModalPeople .modal-content input[type=submit]:hover, #primary.site-main #myModalPeople .modal-content input[type=reset]:hover, #primary.site-main #myModalPeople .modal-content input[type=button]:hover {
  font-weight: 700;
  background: #fff;
  color: #00225A;
  border-color: #fff;
  box-shadow: none;
}
#primary.site-main #myModalPeople .modal-content a.pulsante.negativo, #primary.site-main #myModalPeople .modal-content input[type=submit].negativo, #primary.site-main #myModalPeople .modal-content input[type=reset].negativo, #primary.site-main #myModalPeople .modal-content input[type=button].negativo {
  position: relative;
  border-radius: 25px;
  background: #fff;
  color: #00225A;
  border: 2px solid #fff;
  box-shadow: none;
  text-shadow: none;
  text-decoration: none !important;
  vertical-align: middle;
  display: inline-block;
  width: auto;
  margin: 0;
  font-size: 88%;
  font-weight: 700;
  text-transform: uppercase;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  -o-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  padding: 10px 30px;
  cursor: pointer;
  min-width: 155px;
  text-align: center;
}
#primary.site-main #myModalPeople .modal-content a.pulsante.negativo:visited, #primary.site-main #myModalPeople .modal-content input[type=submit].negativo:visited, #primary.site-main #myModalPeople .modal-content input[type=reset].negativo:visited, #primary.site-main #myModalPeople .modal-content input[type=button].negativo:visited {
  color: #00225A;
}
#primary.site-main #myModalPeople .modal-content a.pulsante.negativo:hover, #primary.site-main #myModalPeople .modal-content input[type=submit].negativo:hover, #primary.site-main #myModalPeople .modal-content input[type=reset].negativo:hover, #primary.site-main #myModalPeople .modal-content input[type=button].negativo:hover {
  font-weight: 700;
  background: transparent;
  color: #fff;
  border-color: #fff;
  box-shadow: none;
}
#primary.site-main #myModalPeople .modal-content .modal-header {
  border-bottom: 0;
}
#primary.site-main #myModalPeople .modal-content .modal-footer {
  border-top: 0;
}
#primary.site-main .modal-backdrop {
  background-color: #00225A;
}
#primary.site-main #storie {
  background-image: url("../img/prodotto/6-storie-di-aperto.svg");
  background-size: cover;
  background-position: center;
  background-color: #E9EDF3;
}
#primary.site-main #storie .lista .item {
  background-color: #fff;
  cursor: pointer;
}
#primary.site-main #storie .lista .item img {
  border-bottom-left-radius: 200px;
  width: 90%;
  float: right;
  border-top-right-radius: 25px;
}
#primary.site-main #storie .lista .item h3, #primary.site-main #storie .lista .item h4 {
  padding: 12px 21px 10px 10%;
}
#primary.site-main #storie .approfondimento, #primary.site-main #myModalStorie {
  padding-top: 40px;
  display: none;
}
#primary.site-main #storie .approfondimento .item, #primary.site-main #myModalStorie .item {
  padding: 50px;
  background-color: #fff;
}
#primary.site-main #storie .approfondimento .item .sinistra, #primary.site-main #myModalStorie .item .sinistra {
  height: 100%;
  color: #00225A;
  border-right: 1px solid #ccc;
  font-size: 22px;
  font-weight: 400;
}
#primary.site-main #storie .approfondimento .item .sinistra .introimg, #primary.site-main #myModalStorie .item .sinistra .introimg {
  display: none;
  border-bottom-left-radius: 200px;
  width: 90%;
  float: right;
  border-top-right-radius: 25px;
  width: 45%;
}
#primary.site-main #storie .approfondimento .item .sinistra a, #primary.site-main #myModalStorie .item .sinistra a {
  color: #00225A;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
}
#primary.site-main #storie .approfondimento .item .sinistra a:focus, #primary.site-main #myModalStorie .item .sinistra a:focus {
  outline: 0;
}
#primary.site-main #storie .approfondimento .item .sinistra a:visited, #primary.site-main #myModalStorie .item .sinistra a:visited {
  color: #00225A;
}
#primary.site-main #storie .approfondimento .item .sinistra a:hover, #primary.site-main #myModalStorie .item .sinistra a:hover {
  color: #00225A;
  text-decoration: underline;
}
#primary.site-main #storie .approfondimento .item .sinistra ul, #primary.site-main #myModalStorie .item .sinistra ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
#primary.site-main #storie .approfondimento .item .sinistra ul li, #primary.site-main #myModalStorie .item .sinistra ul li {
  padding-bottom: 0;
  line-height: 1;
}
#primary.site-main #storie .approfondimento .item .sinistra .logo, #primary.site-main #myModalStorie .item .sinistra .logo {
  max-width: 125px;
  height: auto;
  padding-bottom: 20px;
}
#primary.site-main #storie .approfondimento .item .sinistra .settore, #primary.site-main #storie .approfondimento .item .sinistra .website, #primary.site-main #storie .approfondimento .item .sinistra .piattaforma, #primary.site-main #myModalStorie .item .sinistra .settore, #primary.site-main #myModalStorie .item .sinistra .website, #primary.site-main #myModalStorie .item .sinistra .piattaforma {
  font-size: 18px;
}
#primary.site-main #storie .approfondimento .item .contenuto, #primary.site-main #myModalStorie .item .contenuto {
  height: 100%;
}
#primary.site-main #storie .approfondimento .item .contenuto .testo, #primary.site-main #myModalStorie .item .contenuto .testo {
  font-size: 18px;
  padding-left: 20px;
}
#primary.site-main #storie .approfondimento .item .contenuto .imgbottom, #primary.site-main #myModalStorie .item .contenuto .imgbottom {
  display: none;
  padding-top: 20px;
}
#primary.site-main .apps .griglia {
  display: flex;
  flex-wrap: wrap;
}
#primary.site-main .apps .griglia .item {
  display: flex;
  flex-grow: 1;
  flex-basis: 212px;
  margin-bottom: 18px;
  max-width: 234px;
  justify-content: center;
}
#primary.site-main .apps .griglia .item .cerchio {
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 212px;
  height: 80px;
  max-width: 212px;
  max-height: 80px;
  border-radius: 25px;
  background-color: #fff;
  padding: 10px;
  border: 1px solid #E9EDF3;
  transform: translateY(0);
  transition: transform 0.5s, box-shadow 0.2s ease-in-out;
}
#primary.site-main .apps .griglia .item .cerchio .icona {
  width: 60px;
  height: 60px;
  margin-right: 20px;
}
#primary.site-main .apps .griglia .item .cerchio .nomeapp {
  font-size: 18px;
  font-weight: 300;
  padding: 0;
  margin: 0;
}
#primary.site-main .apps .griglia .item .cerchio:hover {
  -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  transform: translateY(-5px);
  -webkit-box-shadow: 0 15px 35px -15px rgba(0, 34, 90, 0.77);
  -moz-box-shadow: 0 15px 35px -15px rgba(0, 34, 90, 0.77);
  box-shadow: 0 15px 35px -15px rgba(0, 34, 90, 0.77);
}
#primary.site-main .apps .griglia .item .cerchio.dis {
  opacity: 0.65;
}
#primary.site-main #points.rowpoints {
  background-image: url("../img/elementi-points-3500.png");
  background-size: contain;
  background-position: top;
  background-repeat: repeat-y;
}
#primary.site-main #points.rowpoints .container {
  border-bottom: 1px solid #c6cbd1;
}
#primary.site-main #points.rowpoints .container .immagine {
  max-width: 316px;
  height: auto;
}
#primary.site-main #points.rowpoints .container:last-of-type {
  border-bottom: none;
}
#primary.site-main #points.rowpoints .container.no-border {
  border-bottom: 0;
}
#primary.site-main .integrazioni {
  background-color: #D5DCE8;
  background-image: url("../img/prodotto/4-integrata-con.svg");
  background-size: cover;
  background-position: center;
}
#primary.site-main .integrazioni .griglia {
  display: grid;
  grid-gap: 45px;
  grid-template-columns: repeat(5, 1fr);
}
#primary.site-main .integrazioni .griglia .item {
  justify-self: center;
  display: flex;
}
#primary.site-main .integrazioni .griglia .item .cerchio {
  align-items: center;
  width: 176px;
  height: 176px;
  max-width: 176px;
  max-height: 176px;
  border-radius: 25px;
  background-color: #fff;
}
#primary.site-main .integrazioni .griglia .item .cerchio img.open-integration, #primary.site-main .integrazioni .griglia .item .cerchio img.open-integration-modal {
  cursor: pointer;
}
#primary.site-main .integrazioni .griglia .item .cerchio:hover {
  -webkit-box-shadow: 0px 20px 85px -45px rgba(0, 34, 90, 0.77);
  -moz-box-shadow: 0px 20px 85px -45px rgba(0, 34, 90, 0.77);
  box-shadow: 0px 20px 85px -45px rgba(0, 34, 90, 0.77);
  transition: all 0.2s ease-in-out;
}
#primary.site-main .integrazioni .griglia .item .cerchio .discover {
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  margin-top: -27px;
  display: block;
}
#primary.site-main .integrazioni .griglia .item .cerchio.dis {
  background-color: #E9EDF3;
}
#primary.site-main #block-cloud {
  background-color: #fff;
}
#primary.site-main #block-cloud .immagine {
  position: relative;
}
#primary.site-main #block-cloud .immagine .img-grafico {
  background-color: #00C0F0;
  width: 90%;
  height: 100%;
  border-radius: 0 100vh 100vh 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
#primary.site-main #block-cloud .immagine img {
  z-index: 100;
  width: 630px;
  max-width: 80%;
  position: relative;
  padding: 100px 0;
}
#primary.site-main #block-cloud .inner .content {
  max-width: 580px;
}
#primary.site-main #blocco-ricerca-kb {
  background-color: #E9EDF3;
  background-image: url("../img/help/2-elementi-help-center-cosa-stai.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
#primary.site-main #blocco-ricerca-kb .search-field {
  background: #fff;
}

/***** Use Case *******/
#shr_canvas2 {
  display: none;
}

#usecase {
  background: #e9edf3;
}

.item.arrotonda ul {
  padding: 0;
  margin-bottom: 10px;
}

.nascondiblock_usecase {
  display: none !important;
}

.nascondiblock_usecaseSol {
  display: none;
}

.nascondiblock_usecaseSearch {
  display: none;
}

.arrotonda.usecase {
  min-height: auto !important;
  height: fit-content;
}
.arrotonda.usecase h4 {
  padding-bottom: 0px !important;
}
.arrotonda.usecase img {
  max-width: 120px;
  margin-bottom: 20px;
}
.arrotonda.usecase li {
  color: #5c6b7a !important;
}

.arrotonda.usecase ul, .cpt_usecase-template-default .content-landing .blocco-s ul {
  margin-left: 0 !important;
  padding-left: 20px !important;
  margin-bottom: 15px;
}

.arrotonda.usecase li::marker, #tab-usecase .arrotonda li::marker, .cpt_usecase-template-default .content-landing .blocco-s ul li::marker {
  color: #5c6b7a !important;
}

.content_box_use_case .pattern-news {
  padding-bottom: 0px !important;
}

.content_box_use_case .pattern-news .griglia {
  height: auto !important;
  padding-bottom: 80px !important;
}

#tab-usecase .arrotonda {
  padding: 28px !important;
}

@media (max-width: 767px) {
  .arrotonda.usecase {
    padding: 20px;
  }
  .usecaseform .col-12 {
    margin-bottom: 5px;
  }
}
/** AREE COMUNI **/
#primary.site-main #faq {
  background-color: #E9EDF3;
  background-image: url("../img/prodotto/14-faq.svg");
  background-size: 100%;
  background-position: top;
  background-repeat: no-repeat;
}
#primary.site-main #faq .lista .item {
  background: #fff;
  padding: 20px 30px;
  margin-bottom: 10px;
  border-radius: 35px;
}
#primary.site-main #faq .lista .item .domanda {
  padding-bottom: 0;
  font-weight: bold;
  color: #00225A;
  display: grid;
  grid-template-columns: 90% 10%;
  align-items: center;
}
#primary.site-main #faq .lista .item .domanda:after {
  display: flex;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  align-self: flex-start;
  justify-content: end;
}
#primary.site-main #faq .lista .item .domanda[aria-expanded=false]::after {
  content: "\f067";
}
#primary.site-main #faq .lista .item .domanda[aria-expanded=true]::after {
  content: "\f068";
}
#primary.site-main #faq .lista .item .risposta {
  padding-top: 15px;
}
#primary.site-main .approfondimento-inc {
  background-image: url("../img/packages/02-sfondo@2x.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
#primary.site-main .approfondimento-inc .item {
  padding: 40px;
}
#primary.site-main .approfondimento-inc .lista .lista-item {
  border-right: 3px solid #D5DCE8;
  padding-left: 40px;
  padding-right: 40px;
}
#primary.site-main .approfondimento-inc .lista .lista-item:first-child {
  padding-left: 20px;
}
#primary.site-main .approfondimento-inc .lista .lista-item:last-child {
  border-right: 0;
  padding-right: 20px;
}
#primary.site-main .approfondimento-inc .lista .lista-item img {
  height: 74px;
  margin-bottom: 20px;
  width: auto;
}

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 7-mag-2021, 10.34.43
    Author     : veronica
*/
.tabella {
  padding: 70px 50px;
  background: #fff;
}
.tabella .row {
  border-bottom: 1px solid #c6cbd1;
}
.tabella .grouptitle {
  color: #00225A;
  padding: 30px 0 10px 0;
  font-size: 22px;
  font-weight: 500;
}
.tabella .cellath {
  padding: 10px 0;
}
.tabella .td {
  padding: 10px 10px 10px 0;
  color: #00225A;
}
.tabella .th {
  padding: 10px 10px 10px 0;
  color: #00225A;
  font-family: "Gotham Rounded";
  font-size: 24px;
}
.tabella .evidenza {
  background-color: rgba(233, 237, 243, 0.2);
}

.sfondo-bianco .tabella {
  background-color: #E9EDF3;
}
.sfondo-bianco .tabella .row {
  border-bottom: 1px solid #fff;
}
.sfondo-bianco .tabella .evidenza {
  background-color: #D5DCE8;
}

.tabella.img {
  padding-top: 0;
}
.tabella.img .row-img {
  border-bottom: 0 !important;
}
.tabella.img .row-img .th-img img {
  max-height: 52px;
}

#page.site table, .tablepress {
  border: 0;
  border-top: 1px solid #c6cbd1 !important;
  width: 100%;
}
#page.site table tr, .tablepress tr {
  border-bottom: 1px solid #c6cbd1 !important;
}
#page.site table tr.no-border, .tablepress tr.no-border {
  border-bottom: 0;
}
#page.site table th, .tablepress th {
  padding: 10px 10px 10px 0;
  color: #00225A;
  font-family: "Gotham Rounded";
  font-size: 24px;
}
#page.site table td, .tablepress td {
  padding: 20px 10px 20px 0 !important;
}
#page.site table td .titolo, .tablepress td .titolo {
  padding-bottom: 0;
}
#page.site table .sfondocta, .tablepress .sfondocta {
  background-color: #00C0F0;
}
#page.site table .evidenza, .tablepress .evidenza {
  background-color: rgba(233, 237, 243, 0.2);
}

#page.site table.no-border {
  border-top: 0;
}

.tablepress > :where(thead, tfoot) > * > th {
  padding: 10px 10px 10px 0 !important;
  color: #00225A !important;
  font-family: "Gotham Rounded" !important;
  font-size: 24px !important;
  background: none !important;
}

#page.site table.highlightc1 td:nth-child(1) {
  background-color: rgba(233, 237, 243, 0.2);
}

#page.site table.highlightc2 td:nth-child(2) {
  background-color: rgba(233, 237, 243, 0.2);
}

#page.site table.highlightc3 td:nth-child(3) {
  background-color: rgba(233, 237, 243, 0.2);
}

#page.site table.highlightc4 td:nth-child(4) {
  background-color: rgba(233, 237, 243, 0.2);
}

#page.site table.highlightc5 td:nth-child(5) {
  background-color: rgba(233, 237, 243, 0.2);
}

#page.site table.highlightc6 td:nth-child(6) {
  background-color: rgba(233, 237, 243, 0.2);
}

#page.site table.centerc1 td:nth-child(1) {
  text-align: center;
}

#page.site table.centerc2 td:nth-child(2) {
  text-align: center;
}

#page.site table.centerc3 td:nth-child(3) {
  text-align: center;
}

#page.site table.centerc4 td:nth-child(4) {
  text-align: center;
}

#page.site table.centerc5 td:nth-child(5) {
  text-align: center;
}

#page.site table.centerc6 td:nth-child(6) {
  text-align: center;
}

#page.site table.compare th {
  text-align: center;
}
#page.site table.compare th:nth-child(1) {
  text-align: left;
}
#page.site table.compare td {
  text-align: center;
}
#page.site table.compare td:nth-child(1) {
  font-weight: bold;
  text-align: left;
}

/*.row-img-wrapper {
    background: #fff;
    border-top-left-radius:25px;
    border-top-right-radius:25px;
    .th-img {
        img {
            max-height: 52px;
        }
        &:last-of-type {
            border-top-right-radius:25px;
        }
    }

}*/
/*
    MOBILE
*/
@media (max-width: 991px) {
  .tabella .evidenza {
    background-color: none;
  }
  .tabella .cellath {
    color: #00225A;
  }
  .tabella .td {
    color: #5C6B7A;
  }
  .tabella .td .intestazione img {
    width: 30px;
    height: auto;
  }
}
@media (max-width: 575px) {
  .tabella .grouptitle:after {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: "Font Awesome 5 Pro";
    font-weight: 300;
    content: "\f107";
    float: right;
    font-size: 28px;
  }
  .tabella .grouptitle.open:after {
    content: "\f106";
  }
  .tabella .groupcontent {
    display: none;
  }
  .tabella .groupcontent.open {
    display: flex;
  }
}
/*
    Created on : 31-ago-2020, 10.04.40
    Author     : veronica
*/
/* ==========================================================================
   #Global Owl Slider - begin
   ========================================================================== */
.owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev, .owl-carousel button.owl-dot {
  outline: none;
}

.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.owl-theme .owl-nav [class*=owl-] {
  color: #FFF;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #008CFF;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}

.owl-theme .owl-nav [class*=owl-]:hover {
  background: #00225A;
  color: #FFF;
  text-decoration: none;
}

.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 60px;
}

.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  *display: inline;
}

.owl-theme .owl-dots .owl-dot span {
  width: 12px;
  height: 12px;
  margin: 5px 7px;
  background: #008CFF;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px;
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #00225A;
}

.sfondo-blu .owl-theme .owl-dots .owl-dot span {
  background: #5b6a7a;
}
.sfondo-blu .owl-theme .owl-dots .owl-dot.active span, .sfondo-blu .owl-theme .owl-dots .owl-dot:hover span {
  background: #00C0F0;
}

.sfondo-grigio .owl-theme .owl-dots .owl-dot span {
  background: #fff;
}
.sfondo-grigio .owl-theme .owl-dots .owl-dot.active span, .sfondo-grigio .owl-theme .owl-dots .owl-dot:hover span {
  background: #00225A;
}

.sfondo-bianco .owl-theme .owl-dots .owl-dot span {
  background: rgb(204, 212, 226);
}
.sfondo-bianco .owl-theme .owl-dots .owl-dot.active span, .sfondo-bianco .owl-theme .owl-dots .owl-dot:hover span {
  background: #00225A;
}

.owl-carousel.owl-theme-navfloat .owl-nav {
  position: absolute;
  top: 50%;
  width: 100%;
  font-size: 30px;
  -webkit-tap-highlight-color: transparent;
}

.owl-carousel.owl-theme-navfloat .owl-nav [class*=owl-] {
  margin: 0;
  position: absolute;
  padding: 0;
}

.owl-carousel.owl-theme-navfloat .owl-nav [class*=owl-]:hover {
  color: #00225A;
  text-decoration: none;
}

.owl-carousel.owl-theme-navfloat .owl-nav .owl-prev {
  left: 0;
  margin-left: 15px;
}

.owl-carousel.owl-theme-navfloat .owl-nav .owl-next {
  right: 0;
  margin-right: 15px;
}

.owl-carousel.owl-theme-navfloat .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}

.owl-theme-neg .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.owl-theme-neg .owl-nav [class*=owl-] {
  color: #FFF;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #B8D1F5;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}

.owl-theme-neg .owl-nav [class*=owl-]:hover {
  background: #fff;
  color: #FFF;
  text-decoration: none;
}

.owl-theme-neg .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}

.owl-theme-neg .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}

.owl-theme-neg .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.owl-theme-neg .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  *display: inline;
}

.owl-theme-neg .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #fff;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px;
}

.owl-theme-neg .owl-dots .owl-dot.active span, .owl-theme-neg .owl-dots .owl-dot:hover span {
  background: #00225A;
}

.owl-theme.owlsingleboth .owl-nav {
  width: 100%;
  margin: 0;
  position: absolute;
  top: calc((100% - 90px) / 2);
}
.owl-theme.owlsingleboth .owl-nav button.owl-prev, .owl-theme.owlsingleboth .owl-nav button.owl-next {
  position: absolute;
  color: #00225A;
  font-size: 50px;
}
.owl-theme.owlsingleboth .owl-nav button.owl-prev:hover, .owl-theme.owlsingleboth .owl-nav button.owl-next:hover {
  background-color: transparent;
  color: #008CFF;
}
.owl-theme.owlsingleboth .owl-nav button.owl-prev {
  left: -60px;
}
.owl-theme.owlsingleboth .owl-nav button.owl-next {
  right: -60px;
}
.owl-theme.owlsingleboth .owl-dots {
  margin-top: 20px;
}

/* ==========================================================================
   #Global Owl Slider - end
   ========================================================================== */
/*+++++++++++++++++++++
CUSTOMIZZAZIONE JCF
+++++++++++++++++++++++*/
.jcf-select {
  border: 0;
  border-bottom: 1px solid #ccc;
  margin: 0;
  min-width: 100%;
  height: 35px;
  padding: 5px 10px 5px 0px;
}
.jcf-select:before {
  content: "";
  position: absolute;
  top: 15px;
  right: 11px;
  border-style: solid;
  border-width: 6px 5px;
  border-color: #ccc transparent transparent;
}
.jcf-select .jcf-select-opener {
  width: 35px;
  background: none;
}

.jcf-select .jcf-select-text {
  line-height: 1;
  margin: 5px 35px 5px 0;
}

.jcf-focus {
  border-color: #ccc !important;
}

.jcf-file {
  width: 100%;
  margin: 0;
  margin-bottom: 20px;
  margin-top: 5px;
}
.jcf-file .jcf-fake-input {
  width: 80%;
  padding: 6px 8px;
  border: 1px solid #ccc;
  height: 46px;
}
.jcf-file .jcf-upload-button {
  padding: 6px 0;
  width: 20%;
  border: 1px solid #ccc;
  height: 46px;
  text-align: center;
  font-size: 13px;
}

.jcf-list, .jcf-button, .jcf-file {
  background: transparent;
}

.jcf-list-box {
  border: 1px solid #ccc;
  margin-left: 0;
  min-width: 100%;
}

#content-marker {
  text-align: left;
}
#content-marker img {
  padding-bottom: 10px;
}

/* -------------------------------------------------- */
/*	WPML
/* -------------------------------------------------- */
.wpml-ls-legacy-list-vertical a {
  padding-left: 0;
}

.wpml-ls-native {
  display: inline-block;
  position: relative;
}

.wpml-ls-native:before {
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  left: 50%;
  -webkit-transition: left 0.2s cubic-bezier(0.4, 0, 0.2, 1), width 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transition: left 0.2s cubic-bezier(0.4, 0, 0.2, 1), width 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  width: 0;
  height: 2px;
  margin-top: 2px;
  background: #00225A;
}

.wpml-ls-link:hover .wpml-ls-native:before, .wpml-ls-link:focus .wpml-ls-native:before {
  left: 0;
  width: 100%;
}

/* -------------------------------------------------- */
/*	INPUT
/* -------------------------------------------------- */
.wpcf7-form, .search-form, .classic-form, .inline-form, .comment-form {
  /*    input[type="submit"] {
          @include button_style(transparent, $txt_blu, $txt_blu, #fff, $txt_blu, $txt_blu);
          &:disabled {
              @include button_style(transparent, $txt_blu, $txt_blu, #fff, $txt_blu, $txt_blu);
          }
      }
      button[type="submit"] {
          @include button_style(transparent, $txt_blu, $txt_blu, #fff, $txt_blu, $txt_blu);
          &:disabled {
              @include button_style(transparent, $txt_blu, $txt_blu, #fff, $txt_blu, $txt_blu);
          }
      }*/
}
.wpcf7-form input[type=text], .wpcf7-form input[type=email], .wpcf7-form input[type=confirm_email], .wpcf7-form input[type=url], .wpcf7-form input[type=password], .wpcf7-form input[type=search], .wpcf7-form input[type=number], .wpcf7-form input[type=tel], .wpcf7-form input[type=range],
.wpcf7-form input[type=date], .wpcf7-form input[type=month], .wpcf7-form input[type=week], .wpcf7-form input[type=time], .wpcf7-form input[type=datetime], .wpcf7-form input[type=datetime-local], .wpcf7-form input[type=color], .wpcf7-form input[type=file], .wpcf7-form textarea, .search-form input[type=text], .search-form input[type=email], .search-form input[type=confirm_email], .search-form input[type=url], .search-form input[type=password], .search-form input[type=search], .search-form input[type=number], .search-form input[type=tel], .search-form input[type=range],
.search-form input[type=date], .search-form input[type=month], .search-form input[type=week], .search-form input[type=time], .search-form input[type=datetime], .search-form input[type=datetime-local], .search-form input[type=color], .search-form input[type=file], .search-form textarea, .classic-form input[type=text], .classic-form input[type=email], .classic-form input[type=confirm_email], .classic-form input[type=url], .classic-form input[type=password], .classic-form input[type=search], .classic-form input[type=number], .classic-form input[type=tel], .classic-form input[type=range],
.classic-form input[type=date], .classic-form input[type=month], .classic-form input[type=week], .classic-form input[type=time], .classic-form input[type=datetime], .classic-form input[type=datetime-local], .classic-form input[type=color], .classic-form input[type=file], .classic-form textarea, .inline-form input[type=text], .inline-form input[type=email], .inline-form input[type=confirm_email], .inline-form input[type=url], .inline-form input[type=password], .inline-form input[type=search], .inline-form input[type=number], .inline-form input[type=tel], .inline-form input[type=range],
.inline-form input[type=date], .inline-form input[type=month], .inline-form input[type=week], .inline-form input[type=time], .inline-form input[type=datetime], .inline-form input[type=datetime-local], .inline-form input[type=color], .inline-form input[type=file], .inline-form textarea, .comment-form input[type=text], .comment-form input[type=email], .comment-form input[type=confirm_email], .comment-form input[type=url], .comment-form input[type=password], .comment-form input[type=search], .comment-form input[type=number], .comment-form input[type=tel], .comment-form input[type=range],
.comment-form input[type=date], .comment-form input[type=month], .comment-form input[type=week], .comment-form input[type=time], .comment-form input[type=datetime], .comment-form input[type=datetime-local], .comment-form input[type=color], .comment-form input[type=file], .comment-form textarea {
  background-color: transparent;
  border-radius: 25px;
  color: #5C6B7A;
  padding: 8px 15px;
  width: 100%;
  font-size: 88%;
  line-height: 1;
  border: 2px solid #00225A;
  outline: none;
  margin: 0;
}
.wpcf7-form input[type=date], .search-form input[type=date], .classic-form input[type=date], .inline-form input[type=date], .comment-form input[type=date] {
  font-size: 82%;
}
.wpcf7-form select, .search-form select, .classic-form select, .inline-form select, .comment-form select {
  width: 100%;
  border-radius: 25px;
  color: #5C6B7A;
  padding: 9px 15px;
  line-height: 1;
  border: 2px solid #00225A;
  outline: none;
  margin: 0;
}
.wpcf7-form label, .search-form label, .classic-form label, .inline-form label, .comment-form label {
  width: 100%;
  font-weight: 400;
  margin-top: 15px;
  margin-bottom: 3px;
  padding: 0;
  font-size: 18px;
  letter-spacing: 0;
  color: #00225A;
  line-height: 1.5em;
}
.wpcf7-form .select2-container--default .select2-selection--single, .search-form .select2-container--default .select2-selection--single, .classic-form .select2-container--default .select2-selection--single, .inline-form .select2-container--default .select2-selection--single, .comment-form .select2-container--default .select2-selection--single {
  border-radius: 0;
  border: 1px solid #ccc;
}
.wpcf7-form .select2-container .select2-selection--single, .search-form .select2-container .select2-selection--single, .classic-form .select2-container .select2-selection--single, .inline-form .select2-container .select2-selection--single, .comment-form .select2-container .select2-selection--single {
  height: 42px;
}
.wpcf7-form .select2-container--default .select2-selection--single .select2-selection__rendered, .search-form .select2-container--default .select2-selection--single .select2-selection__rendered, .classic-form .select2-container--default .select2-selection--single .select2-selection__rendered, .inline-form .select2-container--default .select2-selection--single .select2-selection__rendered, .comment-form .select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 37px;
}
.wpcf7-form .select2-container--default .select2-selection--single .select2-selection__arrow, .search-form .select2-container--default .select2-selection--single .select2-selection__arrow, .classic-form .select2-container--default .select2-selection--single .select2-selection__arrow, .inline-form .select2-container--default .select2-selection--single .select2-selection__arrow, .comment-form .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 40px;
}
.wpcf7-form .select2-results, .search-form .select2-results, .classic-form .select2-results, .inline-form .select2-results, .comment-form .select2-results {
  font-size: 18px;
}
.wpcf7-form .privacy, .search-form .privacy, .classic-form .privacy, .inline-form .privacy, .comment-form .privacy {
  font-size: 60%;
  text-transform: inherit;
}
.wpcf7-form .privacy span.wpcf7-list-item, .search-form .privacy span.wpcf7-list-item, .classic-form .privacy span.wpcf7-list-item, .inline-form .privacy span.wpcf7-list-item, .comment-form .privacy span.wpcf7-list-item {
  margin: 0;
}
.wpcf7-form .obb, .search-form .obb, .classic-form .obb, .inline-form .obb, .comment-form .obb {
  font-weight: 500;
  font-size: 65%;
}
.wpcf7-form div.wpcf7-validation-errors, .search-form div.wpcf7-validation-errors, .classic-form div.wpcf7-validation-errors, .inline-form div.wpcf7-validation-errors, .comment-form div.wpcf7-validation-errors {
  display: block;
  border: 0;
  color: #F05032;
}
.wpcf7-form div.wpcf7-mail-sent-ok, .search-form div.wpcf7-mail-sent-ok, .classic-form div.wpcf7-mail-sent-ok, .inline-form div.wpcf7-mail-sent-ok, .comment-form div.wpcf7-mail-sent-ok {
  border: 0;
  color: #16DB7D;
  text-align: center;
}
.wpcf7-form div.wpcf7-response-output, .search-form div.wpcf7-response-output, .classic-form div.wpcf7-response-output, .inline-form div.wpcf7-response-output, .comment-form div.wpcf7-response-output {
  margin: 1em 0.5em 1em;
}
.wpcf7-form .ajax-loader, .search-form .ajax-loader, .classic-form .ajax-loader, .inline-form .ajax-loader, .comment-form .ajax-loader {
  float: right;
}
.wpcf7-form span.wpcf7-not-valid-tip, .search-form span.wpcf7-not-valid-tip, .classic-form span.wpcf7-not-valid-tip, .inline-form span.wpcf7-not-valid-tip, .comment-form span.wpcf7-not-valid-tip {
  font-size: 90%;
  padding-top: 5px;
}
.wpcf7-form .wpcf7-list-item-label, .search-form .wpcf7-list-item-label, .classic-form .wpcf7-list-item-label, .inline-form .wpcf7-list-item-label, .comment-form .wpcf7-list-item-label {
  font-size: 76%;
}
.wpcf7-form span.wpcf7-list-item, .search-form span.wpcf7-list-item, .classic-form span.wpcf7-list-item, .inline-form span.wpcf7-list-item, .comment-form span.wpcf7-list-item {
  margin: 0;
}
.wpcf7-form .select2-container--default .select2-selection--single, .search-form .select2-container--default .select2-selection--single, .classic-form .select2-container--default .select2-selection--single, .inline-form .select2-container--default .select2-selection--single, .comment-form .select2-container--default .select2-selection--single {
  border-radius: 0;
}
.wpcf7-form .select2-container .select2-selection--single, .search-form .select2-container .select2-selection--single, .classic-form .select2-container .select2-selection--single, .inline-form .select2-container .select2-selection--single, .comment-form .select2-container .select2-selection--single {
  height: 35px;
}
.wpcf7-form .select2-container--default .select2-selection--single .select2-selection__rendered, .search-form .select2-container--default .select2-selection--single .select2-selection__rendered, .classic-form .select2-container--default .select2-selection--single .select2-selection__rendered, .inline-form .select2-container--default .select2-selection--single .select2-selection__rendered, .comment-form .select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #000;
  line-height: 35px;
}
.wpcf7-form .select2-container--default .select2-selection--single .select2-selection__arrow, .search-form .select2-container--default .select2-selection--single .select2-selection__arrow, .classic-form .select2-container--default .select2-selection--single .select2-selection__arrow, .inline-form .select2-container--default .select2-selection--single .select2-selection__arrow, .comment-form .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 35px;
}
.wpcf7-form .select2-container--default .select2-selection--single, .search-form .select2-container--default .select2-selection--single, .classic-form .select2-container--default .select2-selection--single, .inline-form .select2-container--default .select2-selection--single, .comment-form .select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 0;
  border-bottom: 1px solid #ccc;
  border-radius: 0;
  outline: none;
}

.inline-form input[type=text], .inline-form input[type=email], .inline-form input[type=confirm_email], .inline-form input[type=url], .inline-form input[type=password], .inline-form input[type=search], .inline-form input[type=number], .inline-form input[type=tel], .inline-form input[type=range],
.inline-form input[type=date], .inline-form input[type=month], .inline-form input[type=week], .inline-form input[type=time], .inline-form input[type=datetime], .inline-form input[type=datetime-local], .inline-form input[type=color], .inline-form input[type=file], .inline-form textarea, .inline-form select {
  width: auto;
}

/* -------------------------------------------------- */
/*	SLICK
/* -------------------------------------------------- */
.slick-dots {
  bottom: -40px;
}

.slick-dots li button:before {
  color: #004182;
  content: "\f111";
  font-family: "Font Awesome 5 Pro";
  font-size: 12px;
  opacity: 1;
  font-style: normal;
  font-weight: 400;
}

.slick-dots li.slick-active button:before {
  opacity: 1;
  color: #004182;
  content: "\f111";
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 900;
}

.slick-next {
  right: -60px;
}

.slick-prev {
  left: -60px;
}

.slick-next, .slick-prev {
  width: 50px;
  height: 50px;
}

.slick-next:before, .slick-prev:before {
  color: #004182;
  content: "\f111";
  font-family: "Font Awesome 5 Pro";
  opacity: 1;
  font-size: 50px;
}

.slick-prev:before {
  content: "\f053";
}

.slick-next:before {
  content: "\f054";
}

.sfondo-bianco .slick-dots li button:before {
  color: rgb(204, 212, 226);
}
.sfondo-bianco .slick-dots li.slick-active button:before {
  color: #00225A;
}

/* -------------------------------------------------- */
/*	PAGE NAVI
/* -------------------------------------------------- */
.wp-pagenavi {
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  font-size: 120%;
}
.wp-pagenavi a, .wp-pagenavi span {
  border: 0;
}
.wp-pagenavi .pages {
  display: none;
}
.wp-pagenavi .current {
  color: #004182;
}

/* -------------------------------------------------- */
/*	Cookiebot
/* -------------------------------------------------- */
.CookieDeclarationType {
  border: 0 !important;
  padding: 0 !important;
}

.page-id-1562 #primary.site-main.legal .mod {
  display: none;
}

/* -------------------------------------------------- */
/*	SWIPER SLIDER
/* -------------------------------------------------- */
.swiper-pagination-bullet {
  width: 12px;
  height: 12px;
}

.sfondo-blu .swiper-pagination-bullet {
  opacity: 1;
  background: #5b6a7a;
}
.sfondo-blu .swiper-pagination-bullet-active {
  background: #00C0F0;
}

.sfondo-grigio .swiper-pagination-bullet {
  opacity: 1;
  background: #fff;
}
.sfondo-grigio .swiper-pagination-bullet-active {
  background: #00225A;
}

/*
    Created on : 31-ago-2020, 10.04.40
    Author     : veronica
*/
#page.site {
  margin-top: 79px;
}

/* -------------------------------------------------- */
/*	FOOTER
/* -------------------------------------------------- */
#colophon.site-footer {
  color: #fff;
  padding: 50px 0;
  font-size: 15px;
}
#colophon.site-footer .logoimagicle {
  font-family: "Gotham Rounded";
  font-size: 20px;
  font-weight: bold;
  padding-top: 10px;
  display: block;
  border-bottom: 1px solid #fff;
  padding-bottom: 30px;
}
#colophon.site-footer .logoimagicle small {
  font-family: "Source Sans Variable", sans-serif;
  font-size: 15px;
  font-weight: normal;
  padding-left: 10px;
}
#colophon.site-footer .logozucchetti {
  width: 180px;
}
#colophon.site-footer .titoletto {
  font-weight: bold;
  font-size: 16px;
  padding-bottom: 10px;
  padding-top: 30px;
}
#colophon.site-footer ul {
  list-style: none;
  margin: 10px 0 0 0;
  padding: 0;
}
#colophon.site-footer ul li {
  padding-bottom: 5px;
}
#colophon.site-footer .newsletter {
  padding-top: 30px;
}
#colophon.site-footer .newsletter .col-email, #colophon.site-footer .newsletter .col-submit {
  width: 100% !important;
}
#colophon.site-footer .newsletter .col-email {
  padding-bottom: 10px;
}
#colophon.site-footer .newsletter input[type=email] {
  background-color: #fff;
  color: #5C6B7A;
  border: 1px solid #fff;
}
#colophon.site-footer .social {
  padding-top: 10px;
  font-size: 150%;
}
#colophon.site-footer .social a {
  padding-right: 5px;
}
#colophon.site-footer .social a:last-of-type {
  padding-right: 0;
}
#colophon.site-footer .contatti .titoletto {
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid #fff;
  padding-bottom: 20px;
}
#colophon.site-footer .contatti .zona {
  font-weight: bold;
  font-size: 16px;
  padding-bottom: 10px;
}
#colophon.site-footer .contatti .telefono {
  font-weight: 400;
  font-size: 15px;
  padding-bottom: 10px;
}
#colophon.site-footer .contatti .orario {
  font-weight: 400;
  font-size: 15px;
}
#colophon.site-footer .copy {
  border-top: 1px solid #fff;
  margin-top: 30px;
  padding-top: 30px;
}
#colophon.site-footer a {
  color: #fff;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
}
#colophon.site-footer a:focus {
  outline: 0;
}
#colophon.site-footer a:visited {
  color: #fff;
}
#colophon.site-footer a:hover {
  color: #429AFF;
  text-decoration: underline;
}

/* -------------------------------------------------- */
/*	PAGE HOME
/* -------------------------------------------------- */
#primary.site-main.home {
  /*    #top-home {
          .scorri {
              padding-top: 10px;
              color: $txt_blu;
          }
      }*/
}
#primary.site-main.home .supertop {
  background-image: url("../img/sfondo-home-head.svg");
  background-position: center 120px;
  background-repeat: no-repeat;
  background-size: cover;
}
#primary.site-main.home .supertop-text {
  background-image: url("../img/home-top-titolo-desk.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
#primary.site-main.home .supertop-app {
  background-image: url("../img/sfondo-home-c-4.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
#primary.site-main.home #top-video {
  position: relative;
  padding-top: 60px;
  display: none;
  opacity: 0;
  transition: opacity 2s;
}
#primary.site-main.home #top-video .close-video {
  position: absolute;
  right: -20px;
  top: 0;
  width: auto;
}
#primary.site-main.home #top-video .close-video .close-video-tag {
  color: #004182;
  cursor: pointer;
}
#primary.site-main.home #top-video .close-video .close-video-tag:hover {
  color: #00225A;
}
#primary.site-main.home #top-video.mostra {
  display: block;
}
#primary.site-main.home .top {
  background-position: center;
  background-color: #E9EDF3;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;
  overflow: hidden;
  /* Style the video: 100% width and height to cover the entire window */
}
#primary.site-main.home .top .myVideo {
  object-fit: contain;
  width: 100vw;
  /*height: 100vh;*/
  position: relative;
  right: 0;
  bottom: 0;
  /*            min-width: 100%;
              min-height: 100%;*/
}
#primary.site-main.home #top-home {
  display: block;
}
#primary.site-main.home #top-home .subhead {
  padding-bottom: 50px;
}
#primary.site-main.home #top-home .play-video {
  cursor: pointer;
  position: absolute;
  width: 100%;
  left: 0;
  top: 45%;
  text-align: center;
}
#primary.site-main.home #top-home .play-video a {
  color: #004182;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
}
#primary.site-main.home #top-home .play-video a:focus {
  outline: 0;
}
#primary.site-main.home #top-home .play-video a:visited {
  color: #004182;
}
#primary.site-main.home #top-home .play-video a:hover {
  color: #ef5a26;
  text-decoration: underline;
}
#primary.site-main.home #top-home .play-video .play-video-tag {
  color: #004182;
  cursor: pointer;
}
#primary.site-main.home #top-home .play-video .play-video-tag:hover {
  color: #ef5a26;
}
#primary.site-main.home #top-home .fancybox-video {
  background: transparent;
  border: 0;
  display: block;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%;
  height: 56.25%;
}
#primary.site-main.home #top-home .fancybox-slide--video .fancybox-content {
  background: transparent;
}
#primary.site-main.home #top-home.nascondi {
  display: none;
  transition: all 2s linear;
}
#primary.site-main.home #prodotti {
  position: relative;
  background-color: transparent;
  /*        background-image: url("../img/linguetta-dx.svg");
          background-repeat: no-repeat;
          background-position: right bottom;
          background-size: 300px;*/
}
#primary.site-main.home #prodotti.lista-blocchi .item .testoinner {
  padding-bottom: 3rem;
  min-height: 123px;
}
#primary.site-main.home #prodotti.pacchetti.lista-blocchi .item {
  border-radius: 50px;
  padding: 0;
}
#primary.site-main.home #prodotti.pacchetti.lista-blocchi .item .inner {
  padding: 40px;
  font-size: 20px;
}
#primary.site-main.home #prodotti.pacchetti.lista-blocchi .item .inner .apps a:visited {
  color: #00225A;
}
#primary.site-main.home #prodotti.pacchetti.lista-blocchi .item .inner .apps a:hover {
  color: #429AFF;
}
#primary.site-main.home #prodotti.pacchetti.lista-blocchi .item img {
  height: 117px;
}
#primary.site-main.home #prodotti.pacchetti.lista-blocchi .banda {
  height: 2.5em;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
}
#primary.site-main.home #news-home {
  position: relative;
  padding-top: 90px;
  padding-bottom: 0;
  background-repeat: no-repeat;
  background-position: top;
  background-image: url("../img/sfondo-news.svg");
  /*        .tondo {
              position: absolute;
              background: $color_giallo;
              width: 85px;
              height: 85px;
              border-radius: 50%;
              right: -30px;
              top: -30px;
          }*/
}
#primary.site-main.home #news-home .curva {
  position: absolute;
  left: 0;
  top: 0;
  width: 300px;
}
#primary.site-main.home #news-home .cerchio {
  position: absolute;
  bottom: -85px;
  left: 10%;
  width: 170px;
  height: auto;
}
#primary.site-main.home #news-home .cerchio .over {
  position: absolute;
  width: 100%;
  height: calc(50% - 1px);
  top: 0;
  left: 0;
  background: #004182;
  opacity: 0.8;
}
#primary.site-main.home #chi-siamo {
  position: relative;
  padding: 8% 0;
  background-repeat: no-repeat;
  background-position: top right;
  background-image: url("../img/fascia-we-are-imagicle-all.svg");
}
#primary.site-main.home #chi-siamo .testo {
  padding: 50px 0;
}
#primary.site-main.home #chi-siamo .immagine {
  max-width: 674px;
  position: absolute;
  right: 10%;
  bottom: 0;
  border-radius: 20%;
  width: 40%;
}
#primary.site-main.home #chi-siamo .img-round {
  border-radius: 90px;
}

/* -------------------------------------------------- */
/*	PAGE WEBINAR
/* -------------------------------------------------- */
#primary.site-main.webinar .top-header.titolosx {
  background-image: url("../img/webinar/1-banner-webinar.svg");
}
#primary.site-main.webinar .pattern-news {
  background-image: none;
}
#primary.site-main.webinar .input-search-wrapper {
  position: relative;
}
#primary.site-main.webinar .input-search-wrapper input {
  background: #fff;
  padding-right: 30px;
}
#primary.site-main.webinar .input-search-wrapper:before {
  content: "\f002";
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  color: #00225A;
  font-size: 18px;
  padding-right: 15px;
  position: absolute;
  top: 12px;
  right: 0;
}
#primary.site-main.webinar .ico-webinar {
  width: 50px;
  height: auto;
}
#primary.site-main.webinar #blocco-ricerca {
  background-image: url("../img/webinar/2-search-webinar.svg");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}
#primary.site-main.webinar #blocco-ricerca .search-field {
  background: #fff;
}
#primary.site-main.webinar #blocco-risultato-ricerca .pattern-news {
  padding-bottom: 30px;
}
#primary.site-main.webinar #blocco-silos {
  background-color: #E9EDF3;
  background-image: url("../img/webinar/3-diventa-esperto-webinar.svg");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}
#primary.site-main.webinar #blocco-evidenza {
  background-image: url("../img/webinar/4-da-non-perdere-webinar.svg");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}
#primary.site-main.webinar #blocco-live-demand {
  background-image: url("../img/webinar/5-live-on-demand-webinar.svg");
  background-size: contain;
  background-position: top center;
}

/* -------------------------------------------------- */
/*	PAGE PRICING
/* -------------------------------------------------- */
#primary.site-main.pricing .sezione-inner {
  background-color: #E9EDF3;
  background-image: url("../img/pricing/scegli-cio-pricing.svg");
  background-size: cover;
  background-position: center top;
}
#primary.site-main.pricing #partner {
  background-image: url("../img/pricing/partner-pricing.svg");
  background-size: cover;
  background-position: center top;
}
#primary.site-main.pricing #cisco {
  background-image: url("../img/pricing/Cisco-GPL.svg");
  background-size: cover;
  background-position: center top;
}
#primary.site-main.pricing #cisco img.spazio {
  max-width: 360px;
  max-height: 360px;
}
#primary.site-main.pricing #blocco-silos {
  background-color: transparent;
}
#primary.site-main.pricing #blocco-silos .item.bestseller {
  position: relative;
  padding-top: 140px;
  padding-bottom: 140px;
}
#primary.site-main.pricing #blocco-silos .item.bestseller .best {
  color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  width: 40%;
  min-width: 130px;
  min-height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-top-right-radius: 25px;
  border-bottom-left-radius: 25px;
  text-align: center;
}

/* -------------------------------------------------- */
/*	PAGE HELP CENTER
/* -------------------------------------------------- */
#primary.site-main.help-center .top-header.titolosx {
  background-image: url("../img/help/0-elementi-help-center-banner-HC.svg");
}
#primary.site-main.help-center #points {
  background-image: url("../img/help/1-elementi-help-center-per-cosa.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
#primary.site-main.help-center .lista-people {
  background-image: url("../img/help/6-elementi-help-center-siamo-qua.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
#primary.site-main.help-center #block-intro .imgxl {
  top: auto;
  bottom: 0;
}

/* -------------------------------------------------- */
/*	PAGE ADV SERVICES
/* -------------------------------------------------- */
#primary.site-main.advanced-services .top-header.titolosx {
  background-image: url("../img/advanced/0-elementi-advanced-services-banner.svg");
}
#primary.site-main.advanced-services #highlights {
  background-image: url("../img/advanced/1-elementi-advanced-services-points.svg");
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
}
#primary.site-main.advanced-services .block-cta-file {
  background-color: #E9EDF3;
  background-image: url("../img/advanced/5-elementi-advanced-services-engagement.svg");
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
}
#primary.site-main.advanced-services .lista-people {
  background-image: url("../img/help/6-elementi-help-center-siamo-qua.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

/* -------------------------------------------------- */
/*	PAGE PEOPLE
/* -------------------------------------------------- */
#primary.site-main.people .top-header .el-grafico {
  background-image: url("../img/people/0-elementi-people-banner.svg");
  background-size: cover;
  background-position: top right;
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: -10%;
}
#primary.site-main.people #colonnasx {
  background-image: url("../img/people/1-elementi-people-our-values.svg");
  background-size: cover;
  background-position: top center;
}
#primary.site-main.people #colonnasx #points {
  background: none;
}
#primary.site-main.people #colonnasx .lista-blocchi .item img {
  height: auto;
  padding-bottom: 20px;
  max-height: 245px;
}
#primary.site-main.people .block-list {
  background-image: url("../img/people/3-elementi-people-togheter.svg");
  background-size: cover;
  background-position: left;
}
#primary.site-main.people .block-list .lista-blocchi {
  background-color: transparent;
}
#primary.site-main.people .block-list .lista-blocchi .item {
  padding: 20px;
  cursor: pointer;
}
#primary.site-main.people .block-list .lista-blocchi .item .team-name {
  font-weight: bold;
  font-size: 18px;
}
#primary.site-main.people .block-list .lista-blocchi .item .team-people {
  font-family: "Gotham Rounded";
  font-weight: 600;
  font-size: 24px;
}
#primary.site-main.people .block-list .lista-blocchi .item .team-people span {
  font-size: 120px;
}
#primary.site-main.people .block-list .lista-blocchi .item .team-position {
  font-family: "Gotham Rounded";
  font-size: 24px;
  font-weight: 600;
  color: #00C0F0;
  padding-bottom: 10px;
}
#primary.site-main.people .block-list .lista-blocchi .item .team-position span {
  font-size: 40px;
}
#primary.site-main.people .block-list .lista-blocchi .item.freccia {
  background-image: url("../img/freccia-people.svg");
  background-position: calc(100% - 20px) 20px;
  background-size: 48px;
  background-repeat: no-repeat;
}
#primary.site-main.people .block-list .lista-blocchi .item.total {
  background-color: #00C0F0;
  cursor: auto;
}
#primary.site-main.people .block-list .lista-blocchi .item.total .team-position {
  color: #fff;
}
#primary.site-main.people .people-item, #primary.site-main.people .owl-carousel .owl-item .people-item {
  cursor: pointer;
}
#primary.site-main.people .extendex-list {
  background-image: url("../img/people/4-elementi-people-advanced.svg");
  background-size: contain;
  background-position: top center;
}
#primary.site-main.people #support {
  background-image: url("../img/people/5-elementi-people-imagicle-support.svg");
  background-size: contain;
  background-position: top center;
}
#primary.site-main.people .blocco-cta-round {
  background-image: url("../img/people/6-elementi-people-growing.svg");
}

/* -------------------------------------------------- */
/*	PAGE SUPPORT
/* -------------------------------------------------- */
#primary.site-main.support .top-header.titolosx {
  background-image: url("../img/support/0-elementi-support-banner-support.svg");
}
#primary.site-main.support #highlights {
  background-image: url("../img/support/1-elementi-support-banner-cura.svg");
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
}
#primary.site-main.support #blocco-silos {
  background-color: transparent;
}
#primary.site-main.support .details {
  background-image: url("../img/support/4-elementi-support-banner-imagiclecare.svg");
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
}
#primary.site-main.support .details #table-details {
  display: none;
}
#primary.site-main.support .roadmap {
  background-image: url("../img/support/5-elementi-support-banner-stagione.svg");
  background-size: cover;
  background-position: left top;
  background-repeat: no-repeat;
}

/* -------------------------------------------------- */
/*	PAGE DOWNLOAD
/* -------------------------------------------------- */
#primary.site-main.download .top-header.titolosx {
  background-image: url("../img/download/01-download-header-sfondo.svg");
  background-position: left;
}
#primary.site-main.download #highlights {
  background-image: url("../img/download/02-download-points.svg");
  background-size: contain;
  background-position: center top;
  background-repeat: no-repeat;
  background-color: #E9EDF3;
}
#primary.site-main.download .double-cta {
  background-color: #D5DCE8;
}

/* -------------------------------------------------- */
/*	PAGE ROADMAP
/* -------------------------------------------------- */
#primary.site-main.roadmap .top-header.titolosx {
  background-image: url("../img/roadmap/01-roadmap-header-sfondo.svg");
  background-position: left;
}
#primary.site-main.roadmap .firstcontent {
  background-image: url("../img/roadmap/02-intro.svg");
}
#primary.site-main.roadmap .block-cta-round.sx {
  background-color: #E9EDF3;
  background-image: none;
}
#primary.site-main.roadmap .block-cta-round.sx .immagine {
  padding: 0;
}
#primary.site-main.roadmap .block-cta-round.sx .immagine .img-grafico {
  display: none;
}
#primary.site-main.roadmap #story {
  background-color: #E9EDF3;
  background-image: url("../img/roadmap/04-roadmap.svg");
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
}
#primary.site-main.roadmap #story .apps {
  padding-top: 40px;
}
#primary.site-main.roadmap #story .lista-needs .item-need {
  background: #fff;
  padding: 20px 30px;
  margin-bottom: 10px;
  border-radius: 35px;
  -webkit-box-shadow: 0px 20px 85px -45px rgba(0, 34, 90, 0.77);
  -moz-box-shadow: 0px 20px 85px -45px rgba(0, 34, 90, 0.77);
  box-shadow: 0px 20px 85px -45px rgba(0, 34, 90, 0.77);
  transition: all 0.2s ease-in-out;
}
#primary.site-main.roadmap #story .lista-needs .item-need .domanda {
  padding-bottom: 0;
  font-weight: bold;
  color: #00225A;
}
#primary.site-main.roadmap #story .lista-needs .item-need .domanda:after {
  display: inline-block;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  float: right;
}
#primary.site-main.roadmap #story .lista-needs .item-need .domanda[aria-expanded=false]::after {
  content: "\f107";
}
#primary.site-main.roadmap #story .lista-needs .item-need .domanda[aria-expanded=true]::after {
  content: "\f106";
}
#primary.site-main.roadmap #story .lista-needs .item-need .risposta {
  padding-top: 40px;
}
#primary.site-main.roadmap #story .lista-needs .item-need .risposta .riga {
  border-bottom: 1px solid #c6cbd1;
}
#primary.site-main.roadmap #story .lista-needs .item-need .risposta .el {
  padding: 10px 10px 10px 0;
}
#primary.site-main.roadmap #story .lista-needs .item-need .risposta .product {
  font-size: 22px;
}
#primary.site-main.roadmap #story .lista-needs .item-need .risposta .feature {
  font-size: 18;
  padding: 10px 10px 10px 0;
}
#primary.site-main.roadmap #story .lista-needs .item-need .risposta .feature strong, #primary.site-main.roadmap #story .lista-needs .item-need .risposta .feature b, #primary.site-main.roadmap #story .lista-needs .item-need .risposta .feature span {
  color: #00225A;
  font-size: 22px;
  font-weight: 400;
}
#primary.site-main.roadmap #story .lista-needs .item-need .risposta .for {
  font-size: 18;
  font-weight: 500;
}
#primary.site-main.roadmap #story .lista-needs .item-need .risposta .for ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
#primary.site-main.roadmap #story .lista-needs .item-need .risposta .for .cisco {
  color: #00225A;
}
#primary.site-main.roadmap #story .lista-needs .item-need .risposta .for .teams {
  color: #008CFF;
}
#primary.site-main.roadmap #story .lista-needs .item-need .risposta .for .cross {
  color: #F05032;
}

/* -------------------------------------------------- */
/*	PAGE SECURITY
/* -------------------------------------------------- */
#primary.site-main.security .top-header.titolosx {
  background-image: url("../img/security/01-header-sfondo.svg");
  background-position: left;
}
#primary.site-main.security .firstcontent {
  background-image: url("../img/roadmap/02-intro.svg");
}

/* -------------------------------------------------- */
/*	PAGE SMART WORKING
/* -------------------------------------------------- */
#primary.site-main.smartworking .top-header.titolosx {
  background-image: url("../img/smartworking/01-working-header.svg");
  background-position: left;
}
#primary.site-main.smartworking .firstcontent {
  background-image: url("../img/smartworking/02-working-thenew.svg");
}
#primary.site-main.smartworking #prodotti {
  background-image: url("../img/smartworking/03-working-working.svg");
  background-position: center bottom;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #D5DCE8;
}
#primary.site-main.smartworking .lista-blocchi {
  background-color: none;
}
#primary.site-main.smartworking #environment {
  background-image: url("../img/smartworking/05-working-build.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #E9EDF3;
}
#primary.site-main.smartworking #points.rowpoints {
  background-image: url("../img/smartworking/06-working-why.svg");
  background-color: #D5DCE8 !important;
  background-repeat: no-repeat;
}

/* -------------------------------------------------- */
/*	PAGE LEGAL COMPLIANCE
/* -------------------------------------------------- */
#primary.site-main.legal-compliance .top-header.titolosx {
  background-image: url("../img/legalcompliance/01-compliance-header.svg");
  background-position: left;
}
#primary.site-main.legal-compliance #needs .parte-top {
  background-image: url("../img/legalcompliance/02-compliance-certifications.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #E9EDF3;
}
#primary.site-main.legal-compliance #needs .apps {
  padding-top: 40px;
  clear: both;
}
#primary.site-main.legal-compliance #needs .item-need {
  background: #fff;
  padding: 40px;
  margin-bottom: 10px;
  border-radius: 35px;
  -webkit-box-shadow: 0px 20px 85px -45px rgba(0, 34, 90, 0.77);
  -moz-box-shadow: 0px 20px 85px -45px rgba(0, 34, 90, 0.77);
  box-shadow: 0px 20px 85px -45px rgba(0, 34, 90, 0.77);
  transition: all 0.2s ease-in-out;
}
#primary.site-main.legal-compliance #points {
  background-image: url("../img/legalcompliance/03-compliance-thesolution.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #E9EDF3;
}

/* -------------------------------------------------- */
/*	PAGE CUSTOMER
/* -------------------------------------------------- */
#primary.site-main.customer .header_review {
  background-color: #00C0F0;
  background-image: url("../img/customers/0-elementi-customers-banner.svg");
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
}
#primary.site-main.customer .header_review .owl-dots {
  padding-top: 20px;
  text-align: left;
}
#primary.site-main.customer .wrapper-contatori {
  background-image: url("../img/customers/1-elementi-customers-almost.svg");
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  background-color: #E9EDF3;
}
#primary.site-main.customer #block-contatori {
  margin-top: 0;
}
#primary.site-main.customer #block-contatori .wrapper .item.pieno {
  width: auto;
  max-width: 450px;
}
#primary.site-main.customer #block-clienti h2 {
  display: none;
}
#primary.site-main.customer #block-clienti .owl-carousel {
  padding-top: 0;
}
#primary.site-main.customer #teatri {
  padding-top: 0;
}
#primary.site-main.customer #teatri .apps {
  padding-top: 40px;
}
#primary.site-main.customer #teatri .lista-needs .item-need {
  background: #fff;
  padding: 20px 30px;
  margin-bottom: 10px;
  border-radius: 35px;
  -webkit-box-shadow: 0px 20px 85px -45px rgba(0, 34, 90, 0.77);
  -moz-box-shadow: 0px 20px 85px -45px rgba(0, 34, 90, 0.77);
  box-shadow: 0px 20px 85px -45px rgba(0, 34, 90, 0.77);
  transition: all 0.2s ease-in-out;
}
#primary.site-main.customer #teatri .lista-needs .item-need .domanda {
  padding-bottom: 0;
  font-weight: bold;
  color: #00225A;
}
#primary.site-main.customer #teatri .lista-needs .item-need .domanda:after {
  display: inline-block;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  float: right;
}
#primary.site-main.customer #teatri .lista-needs .item-need .domanda[aria-expanded=false]::after {
  content: "\f107";
}
#primary.site-main.customer #teatri .lista-needs .item-need .domanda[aria-expanded=true]::after {
  content: "\f106";
}
#primary.site-main.customer #teatri .lista-needs .item-need .risposta {
  padding-top: 40px;
  background-repeat: no-repeat;
  background-position: left;
  background-size: contain;
  min-height: 600px;
}
#primary.site-main.customer #teatri .lista-needs .item-need .risposta ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
#primary.site-main.customer #teatri .lista-needs .item-need .risposta ul li {
  font-size: 21px;
}
#primary.site-main.customer #teatri .lista-needs .item-need .risposta ul.dividi {
  columns: 2;
}
#primary.site-main.customer #teatri .lista-needs .item-need .risposta .storia-item {
  cursor: pointer;
}
#primary.site-main.customer #teatri .lista-needs .item-need .risposta .storia-item:hover {
  color: #00225A;
}
#primary.site-main.customer #storie {
  background-image: url("../img/customers/3-elementi-customers-storie.svg");
  background-size: cover;
  background-position: center;
  background-color: #E9EDF3;
}
#primary.site-main.customer #storie .lista .item {
  background-color: #fff;
  margin-bottom: 18px;
  cursor: pointer;
}
#primary.site-main.customer #storie .lista .item img {
  border-bottom-left-radius: 200px;
  width: 90%;
  float: right;
  border-top-right-radius: 25px;
}
#primary.site-main.customer #storie .lista .item h3, #primary.site-main.customer #storie .lista .item h4 {
  padding: 12px 21px 10px 10%;
}
#primary.site-main.customer #storie .approfondimento {
  padding-top: 40px;
  display: none;
}
#primary.site-main.customer #storie .approfondimento .item {
  padding: 0 20px;
  background-color: #fff;
}
#primary.site-main.customer #storie .approfondimento .item .sinistra {
  height: auto;
  color: #00225A;
  font-size: 22px;
  font-weight: 400;
}
#primary.site-main.customer #storie .approfondimento .item .sinistra .introimg {
  display: none;
  border-bottom-left-radius: 200px;
  width: 90%;
  float: right;
  border-top-right-radius: 25px;
  width: 45%;
}
#primary.site-main.customer #storie .approfondimento .item .sinistra a {
  color: #00225A;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
}
#primary.site-main.customer #storie .approfondimento .item .sinistra a:focus {
  outline: 0;
}
#primary.site-main.customer #storie .approfondimento .item .sinistra a:visited {
  color: #00225A;
}
#primary.site-main.customer #storie .approfondimento .item .sinistra a:hover {
  color: #00225A;
  text-decoration: underline;
}
#primary.site-main.customer #storie .approfondimento .item .sinistra ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
#primary.site-main.customer #storie .approfondimento .item .sinistra ul li {
  padding-bottom: 0;
  line-height: 1;
}
#primary.site-main.customer #storie .approfondimento .item .sinistra .logo {
  max-width: 125px;
  height: auto;
  padding-bottom: 20px;
}
#primary.site-main.customer #storie .approfondimento .item .sinistra .settore, #primary.site-main.customer #storie .approfondimento .item .sinistra .website, #primary.site-main.customer #storie .approfondimento .item .sinistra .piattaforma {
  font-size: 18px;
}
#primary.site-main.customer #storie .approfondimento .item .contenuto {
  height: auto;
}
#primary.site-main.customer #storie .approfondimento .item .contenuto .testo {
  font-size: 18px;
  padding-left: 20px;
}
#primary.site-main.customer #storie .approfondimento .item .contenuto .imgbottom {
  display: none;
  padding-top: 20px;
}
#primary.site-main.customer #points {
  background-image: url("../img/customers/7-elementi-customers-the-solution.svg");
  background-size: cover;
  background-position: left;
}

/* -------------------------------------------------- */
/*	PAGE ABOUT
/* -------------------------------------------------- */
#primary.site-main.about .top-header .el-grafico {
  background-image: url("../img/about/0-elementi-about-banner.svg");
  background-size: cover;
  background-position: top right;
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
#primary.site-main.about .silos-wrapper {
  background-color: #D5DCE8;
}
#primary.site-main.about #mission {
  background-image: url("../img/about/2-elementi-about-mission.svg");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}
#primary.site-main.about #blocco-silos .item {
  padding: 40px 20px;
}
#primary.site-main.about #blocco-silos .item img {
  width: 100%;
  height: auto;
}
#primary.site-main.about #blocco-silos .item .points {
  margin: 0 -20px -20px;
  padding: 20px;
}
#primary.site-main.about #blocco-silos .item .days {
  font-size: 86px;
  padding-top: 20px;
  line-height: 1;
  font-family: "Gotham Rounded";
}
#primary.site-main.about #blocco-silos .item .testoinner {
  padding-top: 0;
  font-family: "Gotham Rounded";
}
#primary.site-main.about #blocco-silos .item .points .el {
  padding: 10px 0;
  color: #00C0F0;
}
#primary.site-main.about #blocco-silos .parte-top {
  padding-bottom: 30px;
  background-color: #E9EDF3;
}
#primary.site-main.about #blocco-silos .parte-bottom {
  padding-top: 30px;
  background-color: #D5DCE8;
  background-image: url("../img/help/4-elementi-help-center-chiamaci.svg");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}
#primary.site-main.about .prod-cta {
  background-color: #E9EDF3;
  color: #5C6B7A;
  background-color: transparent !important;
}
#primary.site-main.about .prod-cta .subhead {
  color: #00225A;
}
#primary.site-main.about .prod-cta h3 {
  color: #00225A;
}
#primary.site-main.about #timeline {
  background-image: url("../img/about/3-elementi-about-work-togheter.svg");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}
#primary.site-main.about #timeline .owl-carousel .owl-stage {
  display: flex;
  align-items: flex-end;
}
#primary.site-main.about #timeline .owl-item .item .image img {
  height: 100%;
  max-height: 200px;
  width: auto;
  padding: 20px 40px;
  display: inline;
  max-width: inherit;
}
#primary.site-main.about #timeline .owl-item .item .testo {
  padding: 0 40px 20px 40px;
  font-family: "Gotham Rounded";
  font-size: 26px;
  color: #00225A;
  direction: initial;
}
#primary.site-main.about #timeline .owl-item .item .anno {
  height: 100px;
  align-items: center;
  display: flex;
  background: #004182;
  color: #00C0F0;
  font-size: 50px;
  font-family: "Gotham Rounded";
  padding: 0 40px;
  line-height: 1;
  justify-content: flex-end;
}
#primary.site-main.about #timeline .owl-item.center .anno {
  background: #00C0F0;
  color: #00225A;
  font-size: 64px;
}
#primary.site-main.about #timeline .past .owl-item:first-of-type .item .anno {
  border-radius: 0 50vh 50vh 0;
}
#primary.site-main.about #timeline .past .owl-item .item.ultimo .anno {
  border-radius: 50vh 0 0 50vh;
}
#primary.site-main.about #timeline .future .owl-item .item .anno {
  justify-content: flex-start;
}
#primary.site-main.about #timeline .future .owl-item:first-of-type .item .anno {
  border-radius: 50vh 0 0 50vh;
}
#primary.site-main.about #timeline .future .owl-item:last-of-type .item .anno {
  border-radius: 0 50vh 50vh 0;
}
#primary.site-main.about #block-intro {
  background-image: url("../img/help/3-elementi-help-center-ticket.svg");
}
#primary.site-main.about #block-intro .imgxl {
  top: auto;
  bottom: 0;
  max-width: 50%;
}

/* -------------------------------------------------- */
/*	PAGE EMBED
/* -------------------------------------------------- */
body.licenseCloud {
  background-color: transparent;
}

#primary.site-main.embed .form-wrapper {
  background-color: #fff;
  -webkit-box-shadow: 0px 20px 85px -45px rgba(0, 34, 90, 0.77);
  -moz-box-shadow: 0px 20px 85px -45px rgba(0, 34, 90, 0.77);
  box-shadow: 0px 20px 85px -45px rgba(0, 34, 90, 0.77);
  transition: all 0.2s ease-in-out;
}
#primary.site-main.embed .form-wrapper h3.title, #primary.site-main.embed .form-wrapper h4.title {
  border-bottom: 3px solid #e9edf3;
  padding: 30px;
}
#primary.site-main.embed .form-wrapper .cloudRegister {
  padding: 30px;
}
#primary.site-main.embed .form-wrapper .cloudLicenseInput {
  padding-bottom: 30px;
}
#primary.site-main.embed .form-wrapper .cloudLicenseInput input {
  width: 100%;
}
#primary.site-main.embed .form-wrapper #idCaptchaCheck {
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
}
#primary.site-main.embed .form-wrapper .cloudButton {
  padding-bottom: 30px;
}
#primary.site-main.embed .form-wrapper .cloudButton input {
  width: 100%;
}

/* -------------------------------------------------- */
/*	PAGE LANDING
/* -------------------------------------------------- */
#primary.site-main.landing {
  position: relative;
}
#primary.site-main.landing .top-landing {
  background-color: #D5DCE8;
  background-image: url("../img/0-elementi-grafici-form-banner.svg");
  background-position: left;
  background-size: cover;
  background-repeat: no-repeat;
}
#primary.site-main.landing .content-landing {
  background-color: #E9EDF3;
  background-image: url("../img/1-elementi-grafici-form.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
#primary.site-main.landing .form-wrapper {
  height: 990px;
  background-color: #fff;
  transform: translateY(-300px);
  min-height: 900px;
  -webkit-box-shadow: 0px 20px 85px -45px rgba(0, 34, 90, 0.77);
  -moz-box-shadow: 0px 20px 85px -45px rgba(0, 34, 90, 0.77);
  box-shadow: 0px 20px 85px -45px rgba(0, 34, 90, 0.77);
  transition: all 0.2s ease-in-out;
}
#primary.site-main.landing .form-wrapper iframe {
  height: 100%;
  width: 100%;
  border-radius: 25px;
}
#primary.site-main.landing .form-wrapper-gated {
  min-height: auto;
  padding: 40px;
  height: auto;
  background-color: #fff;
}

/* -------------------------------------------------- */
/*	PAGE LANDING WEBINAR
/* -------------------------------------------------- */
#primary.site-main.landing.webinar-single .shareaholic-share-buttons-container {
  display: none;
}
#primary.site-main.landing.webinar-single .lista-people .item {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: -1px;
  border-top: 1px solid #c6cbd1;
  border-bottom: 1px solid #c6cbd1;
}
#primary.site-main.landing.webinar-single .lista-people .item img {
  max-width: 100px;
  margin-right: 10px;
}
#primary.site-main.landing.webinar-single .lista-people .inner .name {
  font-size: 20px;
  padding-bottom: 5px;
}
#primary.site-main.landing.webinar-single .lista-people .inner .role {
  font-size: 16px;
  padding-bottom: 10px;
}

/* -------------------------------------------------- */
/*	PAGE CONTATTI
/* -------------------------------------------------- */
#primary.site-main.contacts #blocco-silos {
  padding-top: 0;
}
#primary.site-main.contacts #blocco-silos .intestazione {
  text-align: left;
}

/* -------------------------------------------------- */
/*	PAGE ACTIVATE
/* -------------------------------------------------- */
#primary.site-main.activate .top-header.titolosx {
  background-image: url("../img/activate/0-elementi-grafici-activate-banner.svg");
}
#primary.site-main.activate #highlights {
  background-color: #E9EDF3;
  background-image: url("../img/activate/1-elementi-grafici-activate-sed-ut.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

/* -------------------------------------------------- */
/*	PAGE KB
/* -------------------------------------------------- */
#primary.site-main.kb #prodotti ul {
  list-style: none;
  margin: 0;
  padding: 0;
  padding-top: 30px;
}
#primary.site-main.kb #prodotti ul a {
  padding-bottom: 5px;
}
#primary.site-main.kb #featured ul {
  list-style: none;
  margin: 0;
  padding: 0;
  columns: 3;
}
#primary.site-main.kb #featured ul li {
  padding-bottom: 10px;
}

/* -------------------------------------------------- */
/*	PAGE KB Result
/* -------------------------------------------------- */
#primary.site-main.kb-result iframe {
  width: 100%;
  border: 0;
  background: #fff;
  padding: 0;
  height: calc(100vh - 106px);
}

.otgs-development-site-front-end {
  display: none;
}

/* -------------------------------------------------- */
/*	PAGE LEGAL
/* -------------------------------------------------- */
#primary.site-main.legal {
  background-color: #E9EDF3;
  background-image: url("../img/sfondo-legal.svg");
  background-size: contain;
  background-position: top left;
  background-repeat: repeat-y;
}
#primary.site-main.legal .mod {
  font-size: 15px;
  color: #00225A;
  padding-bottom: 10px;
}
#primary.site-main.legal .sottomenu {
  padding-top: 50px;
}
#primary.site-main.legal .sottomenu .active {
  font-weight: bold;
  color: #00225A;
}
#primary.site-main.legal .sottomenu a {
  color: #00225A;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
}
#primary.site-main.legal .sottomenu a:focus {
  outline: 0;
}
#primary.site-main.legal .sottomenu a:visited {
  color: #00225A;
}
#primary.site-main.legal .sottomenu a:hover {
  color: #00225A;
  text-decoration: underline;
}
#primary.site-main.legal .sottomenu li {
  padding-top: 10px;
  padding-bottom: 10px;
}
#primary.site-main.legal .sottomenu .navbar-nav {
  margin-left: 0;
}
#primary.site-main.legal .sottomenu .navbar-toggler {
  border: 0;
}
#primary.site-main.legal .sottomenu button.navbar-toggler:focus  {
  outline: none;
}
#primary.site-main.legal .sottomenu .navbar-brand {
  font-weight: bold;
}
#primary.site-main.legal .inner {
  padding: 40px;
}

/* -------------------------------------------------- */
/*	PAGE Self-Service Demo
/* -------------------------------------------------- */
#primary.site-main.legal.self-service {
  background-image: url("../img/0-elementi-grafici-demo-banner.svg");
  background-repeat: no-repeat;
}

/* -------------------------------------------------- */
/*	PAGE SINGLE SOLUION
/* -------------------------------------------------- */
#primary.site-main.solution .top-header.titolosx {
  background-image: url("../img/vertical/01-Sfondi-VerticalGovernement-Banner.svg");
  background-position: left;
}
#primary.site-main.solution .firstcontent {
  background-image: url("../img/vertical/02-Sfondi-VerticalGovernement-intro.svg");
}
#primary.site-main.solution #vertical .icona {
  text-align: center;
  padding-right: 18px;
}
#primary.site-main.solution #vertical .icona img {
  max-width: 212px;
}
#primary.site-main.solution #vertical .lista-blocchi .titolo1 {
  font-size: 90px;
}
#primary.site-main.solution #vertical .lista-blocchi {
  background: transparent;
}
#primary.site-main.solution #vertical .info-grafica {
  background-image: url("../img/vertical/Sfondo-VerticalGovernment-Infografica.svg");
  background-position: left top;
  background-size: contain;
  background-repeat: no-repeat;
}
#primary.site-main.solution #highlights {
  background-image: url("../img/vertical/06-Sfondi-VerticalGovernement-Storie.svg");
  background-position: center top;
  background-size: contain;
  background-repeat: no-repeat;
}
#primary.site-main.solution .apps .griglia {
  justify-content: center;
}
#primary.site-main.solution .silos-expand h3 {
  border-bottom: 0;
}
#primary.site-main.solution #points.rowpoints .container:last-of-type {
  border-bottom: 1px solid #c6cbd1;
}

/* -------------------------------------------------- */
/*	PAGE ALL INTEGRATIONS
/* -------------------------------------------------- */
#primary.site-main.integrations .top-header.titolosx {
  background-image: url("../img/integrations/00-MaterialiGrafici-PaginaIntegrations-Banner.svg");
  background-position: left;
}
#primary.site-main.integrations .firstcontent {
  background-image: url("../img/integrations/01-MaterialiGrafici-PaginaIntegrations-intro.svg");
}
#primary.site-main.integrations .integrazioni {
  background-color: transparent;
  background-image: none;
}
#primary.site-main.integrations .integrations-wrapper {
  background-color: #E9EDF3;
  background-image: url("../img/integrations/02-MaterialiGrafici-PaginaIntegrations-Calling.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
#primary.site-main.integrations #points {
  background-image: url("../img/integrations/03-MaterialiGrafici-PaginaIntegrations-API.svg");
  background-position: center top;
  background-size: contain;
  background-repeat: no-repeat;
}

/* -------------------------------------------------- */
/*	PAGE SINGLE INTEGRATION
/* -------------------------------------------------- */
#primary.site-main.integration .top-header.titolosx {
  background-image: url("../img/integrations/00-MaterialiGrafici-PaginaIntegrations-Banner.svg");
  background-position: left;
}
#primary.site-main.integration .wrapper-intro {
  background-color: #E9EDF3;
  background-image: url("../img/integrations/1-single-integrations-intro.svg");
  background-position: center top;
  background-size: contain;
  background-repeat: no-repeat;
}
#primary.site-main.integration .integrazioni {
  background-image: url("../img/integrations/2-single-integrations-integration.svg");
  background-position: bottom;
}
#primary.site-main.integration .integrazioni.n2 {
  background-image: url("../img/integrations/4-single-integrations-integ2.svg");
  background-position: top;
}
#primary.site-main.integration #blocco-silos {
  background-image: url("../img/integrations/5-single-integrations-software.svg");
  background-position: center top;
  background-size: contain;
  background-repeat: no-repeat;
}
#primary.site-main.integration .software {
  background-color: #D5DCE8;
  background-image: url("../img/integrations/6-single-integrations-activate.svg");
  background-position: center top;
  background-size: contain;
  background-repeat: no-repeat;
}
#primary.site-main.integration .software .block-cta-round.dx {
  background-image: none;
  background-color: transparent;
}
#primary.site-main.integration .software .block-cta-round.dx .immagine .img-grafico {
  background-color: #fff;
}
#primary.site-main.integration .tabella-wrapper .blocco {
  padding: 30px 0 0 0;
}
#primary.site-main.integration .tabella-wrapper .tabella {
  padding: 0;
}

/* -------------------------------------------------- */
/*	PAGE AR: SALES TOOLS
/* -------------------------------------------------- */
#primary.site-main.sales-tools .primaria img, #primary.site-main.reserved-download .primaria img {
  padding-right: 20px;
}
#primary.site-main.sales-tools .files, #primary.site-main.reserved-download .files {
  font-size: 160%;
}
#primary.site-main.sales-tools .files i, #primary.site-main.reserved-download .files i {
  padding: 0 5px;
}
#primary.site-main.sales-tools .card .intestazione, #primary.site-main.reserved-download .card .intestazione {
  display: inline-flex;
}
#primary.site-main.sales-tools .card .intestazione a:after, #primary.site-main.reserved-download .card .intestazione a:after {
  padding-left: 30px;
  display: inline-block;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  float: right;
}
#primary.site-main.sales-tools .card .intestazione a[aria-expanded=false]::after, #primary.site-main.reserved-download .card .intestazione a[aria-expanded=false]::after {
  content: "\f107";
}
#primary.site-main.sales-tools .card .intestazione a[aria-expanded=true]::after, #primary.site-main.reserved-download .card .intestazione a[aria-expanded=true]::after {
  content: "\f106";
}

#primary.site-main.reserved-download .accordion > .card {
  border: 0;
  border: 0;
  margin-bottom: 40px;
  border-radius: 25px !important;
  padding: 40px;
}
#primary.site-main.reserved-download .card-body {
  padding: 1.25rem 0;
}

/* -------------------------------------------------- */
/*	PAGE AR: VULNERABILITY FIX
/* -------------------------------------------------- */
#primary.site-main.vulnerability-fix .wrapper-fix {
  padding: 40px;
}
#primary.site-main.vulnerability-fix select {
  color: #00225A;
}

/* -------------------------------------------------- */
/*	PAGE SEARCH
/* -------------------------------------------------- */
#primary.site-main.search-result #blocco-ricerca {
  background-color: #E9EDF3;
  background-image: url("../img/top-solotxt.svg");
  background-size: cover;
  background-position: center top;
}
#primary.site-main.search-result #blocco-ricerca .search-submit {
  display: none;
}
#primary.site-main.search-result .results {
  background-image: url("../img/blog/single-blog-sfondo.svg");
  background-size: cover;
  background-position: left top;
  background-repeat: no-repeat;
}
#primary.site-main.search-result .results .result-item {
  padding-top: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #c6cbd1;
}
#primary.site-main.search-result .results .result-item h6 {
  font-size: 100%;
  color: #00225A;
  padding-bottom: 0;
  margin-bottom: 0;
  margin-top: 20px;
}
#primary.site-main.search-result .results .result-item img {
  max-height: 300px;
  width: auto;
}
#primary.site-main.search-result .results .wp-pagenavi {
  padding-top: 50px;
  padding-bottom: 0;
}

/* -------------------------------------------------- */
/*	PAGE WEBEX
/* -------------------------------------------------- */
#primary.site-main.page-webex .subhead {
  color: #191919;
}
#primary.site-main.page-webex .rowpoints .testo {
  font-size: 22px;
}
#primary.site-main.page-webex .apps .griglia {
  display: flex;
  flex-wrap: wrap;
}
#primary.site-main.page-webex .apps .griglia .item {
  display: flex;
  flex-grow: 1;
  flex-basis: 212px;
  margin-bottom: 18px;
  max-width: 234px;
}
#primary.site-main.page-webex .apps .griglia .item .cerchio {
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 212px;
  height: 80px;
  max-width: 212px;
  max-height: 80px;
  border-radius: 25px;
  background-color: #E9EDF3;
  padding: 10px;
  border: 1px solid #E9EDF3;
  transform: translateY(0);
  transition: transform 0.5s, box-shadow 0.2s ease-in-out;
}
#primary.site-main.page-webex .apps .griglia .item .cerchio .icona {
  width: 60px;
  height: 60px;
  margin-right: 20px;
}
#primary.site-main.page-webex .apps .griglia .item .cerchio .nomeapp {
  font-size: 18px;
  font-weight: 300;
  padding: 0;
  margin: 0;
}
#primary.site-main.page-webex .apps .griglia .item .cerchio:hover {
  -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  transform: translateY(-5px);
  -webkit-box-shadow: 0 15px 35px -15px rgba(0, 34, 90, 0.77);
  -moz-box-shadow: 0 15px 35px -15px rgba(0, 34, 90, 0.77);
  box-shadow: 0 15px 35px -15px rgba(0, 34, 90, 0.77);
}
#primary.site-main.page-webex .apps .griglia .item .cerchio.dis {
  opacity: 0.65;
}
#primary.site-main.page-webex .cta-webex-inner {
  border-radius: 25px;
  border: 2px solid #818181;
  padding: 0 5%;
}
#primary.site-main.page-webex .cta-webex-inner .inner {
  padding: 40px;
}
#primary.site-main.page-webex .rowpoints .rowpoints-element {
  border-bottom: 2px solid #818181;
}
#primary.site-main.page-webex .rowpoints .rowpoints-element:last-child {
  border-bottom: 0;
}
#primary.site-main.page-webex .calltoaction {
  background: #191919;
  background-image: url("../img/sfondo_cta_nero.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
#primary.site-main.page-webex .calltoaction .cerchio {
  display: none;
}
#primary.site-main.page-webex .calltoaction .titolo2 {
  color: #fff;
}
#primary.site-main.page-webex .calltoaction .titolo5 {
  color: #818181;
}
#primary.site-main.page-webex .calltoaction a.pulsante {
  position: relative;
  border-radius: 25px;
  background: transparent;
  color: #fff;
  border: 2px solid #fff;
  box-shadow: none;
  text-shadow: none;
  text-decoration: none !important;
  vertical-align: middle;
  display: inline-block;
  width: auto;
  margin: 0;
  font-size: 88%;
  font-weight: 700;
  text-transform: uppercase;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  -o-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  padding: 10px 30px;
  cursor: pointer;
  min-width: 155px;
  text-align: center;
}
#primary.site-main.page-webex .calltoaction a.pulsante:visited {
  color: #fff;
}
#primary.site-main.page-webex .calltoaction a.pulsante:hover {
  font-weight: 700;
  background: #fff;
  color: #191919;
  border-color: #fff;
  box-shadow: none;
}
#primary.site-main.page-webex .calltoaction .immagine {
  background-size: contain;
  border-radius: 0;
}

.page-template-page-reserved-internal .navbar-reserved {
  display: none;
}

/* -------------------------------------------------- */
/*	PAGE MS
/* -------------------------------------------------- */
#primary.site-main.page-ms .subhead {
  color: #191919;
}
#primary.site-main.page-ms .firstcontent {
  background-color: rgb(204, 212, 226);
}
#primary.site-main.page-ms .rowpoints .testo {
  font-size: 22px;
}
#primary.site-main.page-ms .apps .griglia {
  display: flex;
  flex-wrap: wrap;
}
#primary.site-main.page-ms .apps .griglia .item {
  display: flex;
  flex-grow: 1;
  flex-basis: 212px;
  margin-bottom: 18px;
  max-width: 234px;
}
#primary.site-main.page-ms .apps .griglia .item .cerchio {
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 212px;
  height: 80px;
  max-width: 212px;
  max-height: 80px;
  border-radius: 25px;
  background-color: #E9EDF3;
  padding: 10px;
  border: 1px solid #E9EDF3;
  transform: translateY(0);
  transition: transform 0.5s, box-shadow 0.2s ease-in-out;
}
#primary.site-main.page-ms .apps .griglia .item .cerchio .icona {
  width: 60px;
  height: 60px;
  margin-right: 20px;
}
#primary.site-main.page-ms .apps .griglia .item .cerchio .nomeapp {
  font-size: 18px;
  font-weight: 300;
  padding: 0;
  margin: 0;
}
#primary.site-main.page-ms .apps .griglia .item .cerchio:hover {
  -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  transform: translateY(-5px);
  -webkit-box-shadow: 0 15px 35px -15px rgba(0, 34, 90, 0.77);
  -moz-box-shadow: 0 15px 35px -15px rgba(0, 34, 90, 0.77);
  box-shadow: 0 15px 35px -15px rgba(0, 34, 90, 0.77);
}
#primary.site-main.page-ms .apps .griglia .item .cerchio.dis {
  opacity: 0.65;
}
#primary.site-main.page-ms .cta-webex-inner {
  border-radius: 25px;
  background-color: #5D5BD4;
  color: #ffffff;
  border: 2px solid #5D5BD4;
  padding: 0 5%;
}
#primary.site-main.page-ms .cta-webex-inner h1, #primary.site-main.page-ms .cta-webex-inner h2, #primary.site-main.page-ms .cta-webex-inner h3, #primary.site-main.page-ms .cta-webex-inner h4, #primary.site-main.page-ms .cta-webex-inner h5, #primary.site-main.page-ms .cta-webex-inner h6, #primary.site-main.page-ms .cta-webex-inner .titolo, #primary.site-main.page-ms .cta-webex-inner .titolo1, #primary.site-main.page-ms .cta-webex-inner .titolo2, #primary.site-main.page-ms .cta-webex-inner .titolo3, #primary.site-main.page-ms .cta-webex-inner .titolo4, #primary.site-main.page-ms .cta-webex-inner .titolo5 {
  color: #ffffff;
}
#primary.site-main.page-ms .cta-webex-inner a {
  color: #ffffff;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
}
#primary.site-main.page-ms .cta-webex-inner a:focus {
  outline: 0;
}
#primary.site-main.page-ms .cta-webex-inner a:visited {
  color: #ffffff;
}
#primary.site-main.page-ms .cta-webex-inner a:hover {
  color: #c6cbd1;
  text-decoration: underline;
}
#primary.site-main.page-ms .cta-webex-inner a.link {
  font-weight: 800;
  color: #ffffff;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
  display: inline-block;
  font-size: 88%;
  text-transform: uppercase;
}
#primary.site-main.page-ms .cta-webex-inner a.link:focus {
  outline: 0;
}
#primary.site-main.page-ms .cta-webex-inner a.link:visited {
  color: #ffffff;
}
#primary.site-main.page-ms .cta-webex-inner a.link:hover {
  color: #c6cbd1;
  text-decoration: none;
}
#primary.site-main.page-ms .cta-webex-inner a.link:hover:after {
  padding-left: 20px;
}
#primary.site-main.page-ms .cta-webex-inner a.link:after {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f061";
  padding-left: 10px;
  transition: all 0.4s ease-in-out;
}
#primary.site-main.page-ms .cta-webex-inner a.pulsante, #primary.site-main.page-ms .cta-webex-inner .wp-block-button__link, #primary.site-main.page-ms .cta-webex-inner submit, #primary.site-main.page-ms .cta-webex-inner input[type=submit], #primary.site-main.page-ms .cta-webex-inner input[type=reset], #primary.site-main.page-ms .cta-webex-inner input[type=button] {
  position: relative;
  border-radius: 25px;
  background: #ffffff;
  color: #5D5BD4;
  border: 2px solid #fff;
  box-shadow: none;
  text-shadow: none;
  text-decoration: none !important;
  vertical-align: middle;
  display: inline-block;
  width: auto;
  margin: 0;
  font-size: 88%;
  font-weight: 700;
  text-transform: uppercase;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  -o-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  padding: 10px 30px;
  cursor: pointer;
  min-width: 155px;
  text-align: center;
}
#primary.site-main.page-ms .cta-webex-inner a.pulsante:visited, #primary.site-main.page-ms .cta-webex-inner .wp-block-button__link:visited, #primary.site-main.page-ms .cta-webex-inner submit:visited, #primary.site-main.page-ms .cta-webex-inner input[type=submit]:visited, #primary.site-main.page-ms .cta-webex-inner input[type=reset]:visited, #primary.site-main.page-ms .cta-webex-inner input[type=button]:visited {
  color: #5D5BD4;
}
#primary.site-main.page-ms .cta-webex-inner a.pulsante:hover, #primary.site-main.page-ms .cta-webex-inner .wp-block-button__link:hover, #primary.site-main.page-ms .cta-webex-inner submit:hover, #primary.site-main.page-ms .cta-webex-inner input[type=submit]:hover, #primary.site-main.page-ms .cta-webex-inner input[type=reset]:hover, #primary.site-main.page-ms .cta-webex-inner input[type=button]:hover {
  font-weight: 700;
  background: transparent;
  color: #fff;
  border-color: #fff;
  box-shadow: none;
}
#primary.site-main.page-ms .cta-webex-inner a.pulsante.negativo, #primary.site-main.page-ms .cta-webex-inner input[type=submit].negativo, #primary.site-main.page-ms .cta-webex-inner input[type=reset].negativo, #primary.site-main.page-ms .cta-webex-inner input[type=button].negativo {
  position: relative;
  border-radius: 25px;
  background: #5D5BD4;
  color: #fff;
  border: 2px solid #5D5BD4;
  box-shadow: none;
  text-shadow: none;
  text-decoration: none !important;
  vertical-align: middle;
  display: inline-block;
  width: auto;
  margin: 0;
  font-size: 88%;
  font-weight: 700;
  text-transform: uppercase;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  -o-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  padding: 10px 30px;
  cursor: pointer;
  min-width: 155px;
  text-align: center;
}
#primary.site-main.page-ms .cta-webex-inner a.pulsante.negativo:visited, #primary.site-main.page-ms .cta-webex-inner input[type=submit].negativo:visited, #primary.site-main.page-ms .cta-webex-inner input[type=reset].negativo:visited, #primary.site-main.page-ms .cta-webex-inner input[type=button].negativo:visited {
  color: #fff;
}
#primary.site-main.page-ms .cta-webex-inner a.pulsante.negativo:hover, #primary.site-main.page-ms .cta-webex-inner input[type=submit].negativo:hover, #primary.site-main.page-ms .cta-webex-inner input[type=reset].negativo:hover, #primary.site-main.page-ms .cta-webex-inner input[type=button].negativo:hover {
  font-weight: 700;
  background: #ffffff;
  color: #5D5BD4;
  border-color: #5D5BD4;
  box-shadow: none;
}
#primary.site-main.page-ms .cta-webex-inner .inner {
  padding: 40px;
}
#primary.site-main.page-ms .rowpoints .rowpoints-element {
  border-bottom: 2px solid #ffffff;
}
#primary.site-main.page-ms .rowpoints .rowpoints-element:last-child {
  border-bottom: 0;
}
#primary.site-main.page-ms .calltoaction {
  background-color: #5D5BD4;
  color: #ffffff;
  background-color: #ffffff;
  background-image: url("../img/sfondo_cta_viola.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
#primary.site-main.page-ms .calltoaction h1, #primary.site-main.page-ms .calltoaction h2, #primary.site-main.page-ms .calltoaction h3, #primary.site-main.page-ms .calltoaction h4, #primary.site-main.page-ms .calltoaction h5, #primary.site-main.page-ms .calltoaction h6, #primary.site-main.page-ms .calltoaction .titolo, #primary.site-main.page-ms .calltoaction .titolo1, #primary.site-main.page-ms .calltoaction .titolo2, #primary.site-main.page-ms .calltoaction .titolo3, #primary.site-main.page-ms .calltoaction .titolo4, #primary.site-main.page-ms .calltoaction .titolo5 {
  color: #ffffff;
}
#primary.site-main.page-ms .calltoaction a {
  color: #ffffff;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
}
#primary.site-main.page-ms .calltoaction a:focus {
  outline: 0;
}
#primary.site-main.page-ms .calltoaction a:visited {
  color: #ffffff;
}
#primary.site-main.page-ms .calltoaction a:hover {
  color: #c6cbd1;
  text-decoration: underline;
}
#primary.site-main.page-ms .calltoaction a.link {
  font-weight: 800;
  color: #ffffff;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
  display: inline-block;
  font-size: 88%;
  text-transform: uppercase;
}
#primary.site-main.page-ms .calltoaction a.link:focus {
  outline: 0;
}
#primary.site-main.page-ms .calltoaction a.link:visited {
  color: #ffffff;
}
#primary.site-main.page-ms .calltoaction a.link:hover {
  color: #c6cbd1;
  text-decoration: none;
}
#primary.site-main.page-ms .calltoaction a.link:hover:after {
  padding-left: 20px;
}
#primary.site-main.page-ms .calltoaction a.link:after {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f061";
  padding-left: 10px;
  transition: all 0.4s ease-in-out;
}
#primary.site-main.page-ms .calltoaction a.pulsante, #primary.site-main.page-ms .calltoaction .wp-block-button__link, #primary.site-main.page-ms .calltoaction submit, #primary.site-main.page-ms .calltoaction input[type=submit], #primary.site-main.page-ms .calltoaction input[type=reset], #primary.site-main.page-ms .calltoaction input[type=button] {
  position: relative;
  border-radius: 25px;
  background: #ffffff;
  color: #5D5BD4;
  border: 2px solid #fff;
  box-shadow: none;
  text-shadow: none;
  text-decoration: none !important;
  vertical-align: middle;
  display: inline-block;
  width: auto;
  margin: 0;
  font-size: 88%;
  font-weight: 700;
  text-transform: uppercase;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  -o-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  padding: 10px 30px;
  cursor: pointer;
  min-width: 155px;
  text-align: center;
}
#primary.site-main.page-ms .calltoaction a.pulsante:visited, #primary.site-main.page-ms .calltoaction .wp-block-button__link:visited, #primary.site-main.page-ms .calltoaction submit:visited, #primary.site-main.page-ms .calltoaction input[type=submit]:visited, #primary.site-main.page-ms .calltoaction input[type=reset]:visited, #primary.site-main.page-ms .calltoaction input[type=button]:visited {
  color: #5D5BD4;
}
#primary.site-main.page-ms .calltoaction a.pulsante:hover, #primary.site-main.page-ms .calltoaction .wp-block-button__link:hover, #primary.site-main.page-ms .calltoaction submit:hover, #primary.site-main.page-ms .calltoaction input[type=submit]:hover, #primary.site-main.page-ms .calltoaction input[type=reset]:hover, #primary.site-main.page-ms .calltoaction input[type=button]:hover {
  font-weight: 700;
  background: transparent;
  color: #fff;
  border-color: #fff;
  box-shadow: none;
}
#primary.site-main.page-ms .calltoaction a.pulsante.negativo, #primary.site-main.page-ms .calltoaction input[type=submit].negativo, #primary.site-main.page-ms .calltoaction input[type=reset].negativo, #primary.site-main.page-ms .calltoaction input[type=button].negativo {
  position: relative;
  border-radius: 25px;
  background: #5D5BD4;
  color: #fff;
  border: 2px solid #5D5BD4;
  box-shadow: none;
  text-shadow: none;
  text-decoration: none !important;
  vertical-align: middle;
  display: inline-block;
  width: auto;
  margin: 0;
  font-size: 88%;
  font-weight: 700;
  text-transform: uppercase;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  -o-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  padding: 10px 30px;
  cursor: pointer;
  min-width: 155px;
  text-align: center;
}
#primary.site-main.page-ms .calltoaction a.pulsante.negativo:visited, #primary.site-main.page-ms .calltoaction input[type=submit].negativo:visited, #primary.site-main.page-ms .calltoaction input[type=reset].negativo:visited, #primary.site-main.page-ms .calltoaction input[type=button].negativo:visited {
  color: #fff;
}
#primary.site-main.page-ms .calltoaction a.pulsante.negativo:hover, #primary.site-main.page-ms .calltoaction input[type=submit].negativo:hover, #primary.site-main.page-ms .calltoaction input[type=reset].negativo:hover, #primary.site-main.page-ms .calltoaction input[type=button].negativo:hover {
  font-weight: 700;
  background: #ffffff;
  color: #5D5BD4;
  border-color: #5D5BD4;
  box-shadow: none;
}
#primary.site-main.page-ms .calltoaction .cerchio {
  display: none;
}
#primary.site-main.page-ms .calltoaction .immagine {
  background-size: contain;
  border-radius: 0;
}

/* -------------------------------------------------- */
/*	PAGE EVENTO
/* -------------------------------------------------- */
#primary.site-main.prodotto.suite.page-evento .apps .griglia {
  display: flex;
  flex-wrap: wrap;
}
#primary.site-main.prodotto.suite.page-evento .apps .griglia .item {
  display: flex;
  flex-grow: 1;
  flex-basis: 212px;
  margin-bottom: 18px;
  max-width: 234px;
}
#primary.site-main.prodotto.suite.page-evento .apps .griglia .item .cerchio {
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 212px;
  height: 80px;
  max-width: 212px;
  max-height: 80px;
  border-radius: 25px;
  background-color: #E9EDF3;
  padding: 10px;
  border: 1px solid #E9EDF3;
  transform: translateY(0);
  transition: transform 0.5s, box-shadow 0.2s ease-in-out;
}
#primary.site-main.prodotto.suite.page-evento .apps .griglia .item .cerchio .icona {
  width: 60px;
  height: 60px;
  margin-right: 20px;
}
#primary.site-main.prodotto.suite.page-evento .apps .griglia .item .cerchio .nomeapp {
  font-size: 18px;
  font-weight: 300;
  padding: 0;
  margin: 0;
}
#primary.site-main.prodotto.suite.page-evento .apps .griglia .item .cerchio:hover {
  -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  transform: translateY(-5px);
  -webkit-box-shadow: 0 15px 35px -15px rgba(0, 34, 90, 0.77);
  -moz-box-shadow: 0 15px 35px -15px rgba(0, 34, 90, 0.77);
  box-shadow: 0 15px 35px -15px rgba(0, 34, 90, 0.77);
}
#primary.site-main.prodotto.suite.page-evento .apps .griglia .item .cerchio.dis {
  opacity: 0.65;
}

/* -------------------------------------------------- */
/*	PAGE 404
/* -------------------------------------------------- */
#primary.site-main.pagina404 {
  background-color: #E9EDF3;
  background-image: url("../img/404-sfondo.svg");
  background-size: cover;
  background-position: center;
}
#primary.site-main.pagina404 .search-submit {
  display: none;
}
#primary.site-main.pagina404 .search-field {
  background-color: #fff;
}

/**** Release ****/
#primary.site-main.blog-single .header-release .blocco-middle {
  position: relative;
  margin-top: calc(25vh - 32px);
}

.cpt_release-template-default .pattern-news {
  padding-bottom: 15px;
}

@media (max-width: 991px) {
  #primary.site-main.blog-single .header-release {
    background-position: 100% 100%;
    background-size: 200%;
    padding-top: 50px;
    min-height: 60vh;
  }
  #primary.site-main.blog-single .header-release .contenuto {
    text-align: center;
  }
  #primary.site-main.blog-single .header-release .dett-cta-gray {
    padding-bottom: 0px !important;
  }
  #primary.site-main.blog-single .header-release .dett-cta-gray .row.justify-content-center {
    margin-bottom: 0px !important;
  }
}
/** LANDING **/
#primary.site-main.landing-2023 .cross-landing {
  position: fixed;
  left: 0;
  top: 140px;
  height: calc(100vh - 200px);
  width: 100%;
  z-index: 1;
}
#primary.site-main.landing-2023 .top-landing {
  background-image: url("https://www.imagicle.com/wp-content/themes/imagicle/assets/img/landing/landing_header.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
}
#primary.site-main.landing-2023 .content-landing {
  background-image: url("https://www.imagicle.com/wp-content/themes/imagicle/assets/img/landing/landing_blu.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
#primary.site-main.landing-2023 #review {
  background-image: url("https://www.imagicle.com/wp-content/themes/imagicle/assets/img/landing/landing_bianco.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
#primary.site-main.landing-2023 #review .top-review {
  background: none;
}
#primary.site-main.landing-2023 .lista-blocchi {
  background-image: url("https://www.imagicle.com/wp-content/themes/imagicle/assets/img/landing/landing_grigio.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

#primary.site-main.landing-2023-sticky {
  background-image: url("https://www.imagicle.com/wp-content/themes/imagicle/assets/img/landing/sfondo-sticky.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}
#primary.site-main.landing-2023-sticky .landing-header {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 900px;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
#primary.site-main.landing-2023-sticky .landing-header.form-open {
  height: 990px;
}
#primary.site-main.landing-2023-sticky .landing-header-img {
  height: 100%;
  object-fit: cover;
  position: static;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: auto;
  width: 100%;
}
#primary.site-main.landing-2023-sticky .certificazioni img {
  height: fit-content;
  max-height: 150px;
  width: auto;
}
#primary.site-main.landing-2023-sticky .review {
  color: #00225A;
}
#primary.site-main.landing-2023-sticky .review .citazione {
  font-size: 32px;
  font-family: Gotham Rounded;
}
#primary.site-main.landing-2023-sticky .review .name {
  font-size: 18px;
  font-weight: 700;
  padding-top: 30px;
}
#primary.site-main.landing-2023-sticky .review .role {
  font-size: 18px;
}

.landing-form {
  width: 100%;
  max-width: 450px;
  background-color: #fff;
  border-radius: 30px;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  transition: 1s;
  -webkit-box-shadow: 0px 10px 13px -7px #000000, -32px 27px 15px -1px rgba(0, 0, 0, 0);
  box-shadow: 0px 10px 13px -7px #000000, -32px 27px 15px -1px rgba(0, 0, 0, 0);
  background-color: #fff;
  color: #5C6B7A;
}
.landing-form h1, .landing-form h2, .landing-form h3, .landing-form h4, .landing-form h5, .landing-form h6, .landing-form .titolo, .landing-form .titolo1, .landing-form .titolo2, .landing-form .titolo3, .landing-form .titolo4 {
  color: #00225A;
}
.landing-form a {
  color: #429AFF;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
}
.landing-form a:focus {
  outline: 0;
}
.landing-form a:visited {
  color: #429AFF;
}
.landing-form a:hover {
  color: #429AFF;
  text-decoration: underline;
}
.landing-form a.link {
  font-weight: 800;
  color: #00225A;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
  display: inline-block;
  font-size: 88%;
  text-transform: uppercase;
}
.landing-form a.link:focus {
  outline: 0;
}
.landing-form a.link:visited {
  color: #00225A;
}
.landing-form a.link:hover {
  color: #00225A;
  text-decoration: none;
}
.landing-form a.link:hover:after {
  padding-left: 20px;
}
.landing-form a.link:after {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f061";
  padding-left: 10px;
  transition: all 0.4s ease-in-out;
}
.landing-form a.pulsante, .landing-form .wp-block-button__link, .landing-form submit, .landing-form input[type=submit], .landing-form input[type=reset], .landing-form input[type=button] {
  position: relative;
  border-radius: 25px;
  background: #00225A;
  color: #fff;
  border: 2px solid #00225A;
  box-shadow: none;
  text-shadow: none;
  text-decoration: none !important;
  vertical-align: middle;
  display: inline-block;
  width: auto;
  margin: 0;
  font-size: 88%;
  font-weight: 700;
  text-transform: uppercase;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  -o-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  padding: 10px 30px;
  cursor: pointer;
  min-width: 155px;
  text-align: center;
}
.landing-form a.pulsante:visited, .landing-form .wp-block-button__link:visited, .landing-form submit:visited, .landing-form input[type=submit]:visited, .landing-form input[type=reset]:visited, .landing-form input[type=button]:visited {
  color: #fff;
}
.landing-form a.pulsante:hover, .landing-form .wp-block-button__link:hover, .landing-form submit:hover, .landing-form input[type=submit]:hover, .landing-form input[type=reset]:hover, .landing-form input[type=button]:hover {
  font-weight: 700;
  background: transparent;
  color: #00225A;
  border-color: #00225A;
  box-shadow: none;
}
.landing-form a.pulsante.negativo, .landing-form input[type=submit].negativo, .landing-form input[type=reset].negativo, .landing-form input[type=button].negativo {
  position: relative;
  border-radius: 25px;
  background: transparent;
  color: #00225A;
  border: 2px solid #00225A;
  box-shadow: none;
  text-shadow: none;
  text-decoration: none !important;
  vertical-align: middle;
  display: inline-block;
  width: auto;
  margin: 0;
  font-size: 88%;
  font-weight: 700;
  text-transform: uppercase;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  -o-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  padding: 10px 30px;
  cursor: pointer;
  min-width: 155px;
  text-align: center;
}
.landing-form a.pulsante.negativo:visited, .landing-form input[type=submit].negativo:visited, .landing-form input[type=reset].negativo:visited, .landing-form input[type=button].negativo:visited {
  color: #00225A;
}
.landing-form a.pulsante.negativo:hover, .landing-form input[type=submit].negativo:hover, .landing-form input[type=reset].negativo:hover, .landing-form input[type=button].negativo:hover {
  font-weight: 700;
  background: #00225A;
  color: #fff;
  border-color: #00225A;
  box-shadow: none;
}
.landing-form .gruppo-pulsanti a.pulsante, .landing-form .gruppo-pulsanti .wp-block-button__link, .landing-form .gruppo-pulsanti submit, .landing-form .gruppo-pulsanti input[type=submit], .landing-form .gruppo-pulsanti input[type=reset], .landing-form .gruppo-pulsanti input[type=button] {
  position: relative;
  border-radius: 25px;
  background: transparent;
  color: #00225A;
  border: 2px solid #00225A;
  box-shadow: none;
  text-shadow: none;
  text-decoration: none !important;
  vertical-align: middle;
  display: inline-block;
  width: auto;
  margin: 0;
  font-size: 88%;
  font-weight: 700;
  text-transform: uppercase;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  -o-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  padding: 10px 30px;
  cursor: pointer;
  min-width: 155px;
  text-align: center;
}
.landing-form .gruppo-pulsanti a.pulsante:visited, .landing-form .gruppo-pulsanti .wp-block-button__link:visited, .landing-form .gruppo-pulsanti submit:visited, .landing-form .gruppo-pulsanti input[type=submit]:visited, .landing-form .gruppo-pulsanti input[type=reset]:visited, .landing-form .gruppo-pulsanti input[type=button]:visited {
  color: #00225A;
}
.landing-form .gruppo-pulsanti a.pulsante:hover, .landing-form .gruppo-pulsanti .wp-block-button__link:hover, .landing-form .gruppo-pulsanti submit:hover, .landing-form .gruppo-pulsanti input[type=submit]:hover, .landing-form .gruppo-pulsanti input[type=reset]:hover, .landing-form .gruppo-pulsanti input[type=button]:hover {
  font-weight: 700;
  background: #00225A;
  color: #fff;
  border-color: #00225A;
  box-shadow: none;
}
.landing-form .titolo-form {
  border-bottom: 4px solid #E9EDF3;
  padding: 30px 35px 20px 35px;
}
.landing-form .notes {
  font-size: 80%;
  color: #999;
  padding-top: 20px;
}
.landing-form #demo_form {
  padding: 30px 35px;
}
.landing-form .hidden-input {
  display: none;
}
.landing-form .input, .landing-form .text, .landing-form .select, .landing-form input[type=text], .landing-form input[type=email], .landing-form select, .landing-form texarea {
  display: block;
  width: 100%;
  height: 100%;
  outline-offset: 0px;
  background-color: rgba(255, 255, 255, 0);
  border: 2px #000;
  border-radius: 27px;
  outline: 3px #333;
  padding: 13px 20px 0;
  font-size: 17px;
  z-index: 4;
}
.landing-form .input.select, .landing-form .select, .landing-form select {
  width: 90%;
  padding: 5px 20px 0;
}
.landing-form .form-field {
  z-index: 1000;
  color: #000;
  padding-bottom: 12px;
  position: relative;
}
.landing-form .form-field:hover {
  z-index: 15;
  position: relative;
}
.landing-form .input-wrapper {
  height: 56px;
  border: 2px solid #00225A;
  border-radius: 27px;
  justify-content: start;
  align-items: center;
  display: flex;
  position: relative;
  width: 100%;
}
.landing-form .input-wrapper.textarea {
  height: 100px;
}
.landing-form .checkbox-wrapper {
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 5px;
  grid-row-gap: 0px;
  align-items: start;
  color: #00225A;
  font-size: 80%;
}
.landing-form .checkbox-label {
  margin-bottom: 0;
}
.landing-form .input-wrapper.textarea {
  height: auto;
  padding-top: 0;
}
.landing-form .input-label {
  z-index: 3;
  color: #737b99;
  margin-bottom: 0;
  font-size: 17px;
  font-weight: 400;
  line-height: 1;
  transition: all 0.2s;
  position: absolute;
  top: 18px;
  bottom: auto;
  left: 20px;
  right: auto;
  display: block;
}
.landing-form .input-wrapper:focus-within > .input-label, .landing-form .input-wrapper.has-data-entered > .input-label {
  color: #474f70;
  font-size: 13px;
  top: 9px;
}
.landing-form .input:focus, .landing-form .select:focus {
  border-color: #3898ec;
  outline: 0;
}
.landing-form textarea.input, .landing-form textarea.select {
  height: auto;
}
.landing-form .input::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0);
}
.landing-form .input::placeholder {
  color: rgba(255, 255, 255, 0);
}
.landing-form .input.textarea {
  max-height: 400px;
  min-height: 100px;
  margin-bottom: 0;
  padding-top: 23px;
}
.landing-form .high-zindex {
  z-index: 2000 !important;
}

.landing-form.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 110px;
  bottom: 30px;
}

/* -------------------------------------------------- */
/*	PAGE ENGAGE-OFFERTE
/* -------------------------------------------------- */
#primary.site-main.prodotto.engage .tab-content {
  margin-top: 0px;
}

#primary.site-main.prodotto.engage .lista-blocchi {
  background-color: #fff;
}

#primary.site-main.prodotto.engage .firstcontent {
  padding-bottom: 0;
}

#primary.site-main.prodotto.engage .top-header.titolosx.sfondo-grigio {
  background-image: url("../img/engage_banner_sfondo.svg");
}

#primary.site-main.prodotto.engage-child .top-header.titolosx.sfondo-celeste {
  background-image: url("../img/engage_generica_banner_sfondo.svg");
}

#primary.site-main.prodotto.engage .dettagli_cta .row {
  border-bottom: 2px solid #fff;
}

#primary.site-main.prodotto.engage .dettagli-accordion {
  border-bottom: 2px solid #fff;
}

/* -------------------------------------------------- */
/*	PAGE PACKAGE
/* -------------------------------------------------- */
#primary.site-main.sigle-packages .wrapper-intro {
  background-color: #D5DCE8;
  background-image: url("../img/packages/00-sfondo header@2x.png");
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
}
#primary.site-main.sigle-packages .wrapper-intro .firstcontent {
  background-color: #fff;
  /*.col {
    text-align: left !important;
  }*/
}
#primary.site-main.sigle-packages .apps .griglia {
  justify-content: center;
}
#primary.site-main.sigle-packages .apps .griglia .item .cerchio {
  background-color: #E9EDF3;
}
#primary.site-main.sigle-packages .area-prodotti {
  background-image: url("../img/packages/01-sfondo@2x.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
#primary.site-main.sigle-packages #points {
  background-image: url("../img/packages/03-sfondo@2x.png");
  background-position: center left;
  background-repeat: no-repeat;
  background-size: contain;
}

/* -------------------------------------------------- */
/*	PAGE HIGHLIGHTS
/* -------------------------------------------------- */
#primary.site-main.highlights .apps .griglia .item .cerchio {
  background-color: #E9EDF3;
}
#primary.site-main.highlights .pattern-news {
  padding-bottom: 0;
}
#primary.site-main.highlights .pattern-news .griglia {
  max-width: 100%;
}
#primary.site-main.highlights .rowpoints .rowpoints-element {
  border-bottom: 2px solid #fff;
}
#primary.site-main.highlights .rowpoints .rowpoints-element:last-of-type {
  border-bottom: 0;
}

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 8-lug-2021, 15.30.18
    Author     : veronica
*/
.supertop-blog {
  transition: all 0.35s ease-in-out;
  background-color: transparent;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding: 5px 0;
}
.supertop-blog h4 {
  display: none;
  padding-bottom: 0;
}
.supertop-blog .immagine {
  width: 205px;
  height: 29px;
  background-image: url("../img/blog/imagicle-beat-logo-grey.svg");
  background-position: center left;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 15px 0;
}
.supertop-blog .form_newsletter_menutop {
  display: none;
}
.supertop-blog .form_newsletter_menutop .nlinput {
  max-width: 300px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.supertop-blog.mostra {
  background-color: #fff;
}
.supertop-blog.mostra h4, .supertop-blog.mostra .form_newsletter_menutop {
  display: block;
}
.supertop-blog.mostra .immagine {
  background-image: url("../img/blog/imagicle-beat-logo-grey.svg");
}

/* -------------------------------------------------- */
/*	PAGE ARCHIVE
/* -------------------------------------------------- */
/** OVERRIDE MENU **/
body.archive.category #page.site {
  margin-top: 0 !important;
}
body.archive.category #page.site #masthead.site-header .menu__header {
  position: relative;
}

/* -------------------------------------------------- */
/*	PAGE BLOG
/* -------------------------------------------------- */
/** OVERRIDE MENU **/
body.page-template-page-blog #page.site {
  margin-top: 0 !important;
}
body.page-template-page-blog #page.site #masthead.site-header .menu__header {
  position: relative;
}

#primary.site-main.blog .pattern-news .item .tipo .label {
  display: block;
}
#primary.site-main.blog .pattern-news .item .tipo .subtipo {
  display: none;
}
#primary.site-main.blog .wrapper-blog {
  background-image: url("../img/blog/blog-beat-header.svg");
  background-position: left top;
  background-color: #E9EDF3;
  background-size: contain;
  background-repeat: no-repeat;
}
#primary.site-main.blog .top-header.titolosx {
  background-image: none;
  background-color: transparent;
}
#primary.site-main.blog #blog-field {
  background-color: #fff;
}
#primary.site-main.blog .pattern-news {
  padding-top: 0;
  padding-bottom: 18px;
}
#primary.site-main.blog #blocco-risultato-ricerca .pattern-news {
  padding-top: 0;
  padding-bottom: 18px;
}
#primary.site-main.blog .widget-area input, #primary.site-main.blog .widget-area select {
  max-width: 90%;
}
#primary.site-main.blog .widget-area label {
  margin-top: 0;
  color: #00225A;
  display: block;
  margin-bottom: 0.5rem;
}
#primary.site-main.blog .widget-area .wrapper, #primary.site-main.blog .widget-area .widget {
  padding-bottom: 25px;
}
#primary.site-main.blog .widget-area .widget_search .widget-title, #primary.site-main.blog .widget-area .widget_search .search-submit {
  display: none;
}
#primary.site-main.blog .widget-area .widget_search .search-field {
  background: #fff;
}
#primary.site-main.blog .widget-area .wrapper-category .widget-title, #primary.site-main.blog .widget-area .widget_categories .widget-title {
  display: none;
}
#primary.site-main.blog .widget-area .wrapper-category ul, #primary.site-main.blog .widget-area .widget_categories ul {
  list-style: none;
  margin: 0;
  padding-left: 0;
}
#primary.site-main.blog .widget-area .wrapper-category ul li, #primary.site-main.blog .widget-area .widget_categories ul li {
  font-size: 120%;
  padding-bottom: 10px;
}
#primary.site-main.blog .widget-area .wrapper-category ul li a, #primary.site-main.blog .widget-area .widget_categories ul li a {
  color: #00225A;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
}
#primary.site-main.blog .widget-area .wrapper-category ul li a:focus, #primary.site-main.blog .widget-area .widget_categories ul li a:focus {
  outline: 0;
}
#primary.site-main.blog .widget-area .wrapper-category ul li a:visited, #primary.site-main.blog .widget-area .widget_categories ul li a:visited {
  color: #00225A;
}
#primary.site-main.blog .widget-area .wrapper-category ul li a:hover, #primary.site-main.blog .widget-area .widget_categories ul li a:hover {
  color: #429AFF;
  text-decoration: underline;
}
#primary.site-main.blog .widget-area .wrapper-category ul li a:hover, #primary.site-main.blog .widget-area .widget_categories ul li a:hover {
  text-decoration: none;
}
#primary.site-main.blog .widget-area .widget-title {
  font-size: 32px;
}
#primary.site-main.blog .widget-area .wrapper-newsletter {
  padding-top: 20px;
}
#primary.site-main.blog .widget-area .wrapper-newsletter .col-email, #primary.site-main.blog .widget-area .wrapper-newsletter .col-submit {
  width: 100% !important;
}
#primary.site-main.blog .widget-area .wrapper-newsletter .col-email {
  padding-bottom: 10px;
}
#primary.site-main.blog .widget-area .wrapper-newsletter input[type=email] {
  background-color: #fff;
  color: #5C6B7A;
}
#primary.site-main.blog .widget-area .wrapper-social {
  padding-top: 30px;
}
#primary.site-main.blog .widget-area .wrapper-social a {
  font-size: 150%;
  color: #00225A;
  padding-right: 5px;
}
#primary.site-main.blog .widget-area .wrapper-social a:last-of-type {
  padding-right: 0;
}

/* -------------------------------------------------- */
/*	PAGE NEWSROOM
/* -------------------------------------------------- */
#primary.site-main.newsroom {
  background-color: #E9EDF3;
  background-image: url("../img/pricing/un-offerta-pricing.svg");
  background-size: 120vw;
  background-position: center top;
  background-repeat: no-repeat;
}
#primary.site-main.newsroom #newsroom-field {
  background-color: #fff;
}
#primary.site-main.newsroom .pattern-news {
  padding-top: 0;
  padding-bottom: 18px;
}
#primary.site-main.newsroom #blocco-risultato-ricerca .pattern-news {
  padding-top: 50px;
  padding-bottom: 50px;
}
#primary.site-main.newsroom .pattern-news .item .tipo .label {
  display: block;
}
#primary.site-main.newsroom .pattern-news .item .tipo .subtipo {
  display: none;
}

/* -------------------------------------------------- */
/*	SINGLE BLOG
/* -------------------------------------------------- */
/** OVERRIDE MENU **/
body.single-post #page.site {
  margin-top: 0 !important;
}
body.single-post #page.site #masthead.site-header .menu__header {
  position: relative;
}

#primary.site-main.blog-single .supertop-blog .immagine {
  background-image: url("../img/blog/imagicle-beat-logo-white.svg");
}
#primary.site-main.blog-single .supertop-blog.mostra .immagine {
  background-image: url("../img/blog/imagicle-beat-logo-grey.svg");
}
#primary.site-main.blog-single .header-blog {
  position: relative;
  color: #fff;
  background-image: url("../img/blog/single-top-blog.png");
  background-size: cover;
  background-position: left top;
  background-repeat: no-repeat;
  margin-top: -70px;
  padding-top: 70px;
}
#primary.site-main.blog-single .header-blog .ombra {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 17, 57, 0.4);
}
#primary.site-main.blog-single .header-blog .titolo5 {
  padding-bottom: 0;
  color: #fff;
}
#primary.site-main.blog-single .header-blog h1 {
  border-bottom: 1px solid #fff;
  color: #fff;
  padding: 40px 0;
}
#primary.site-main.blog-single .header-blog .tags {
  text-transform: uppercase;
  font-size: 18px;
  line-height: 26px;
  padding-top: 10px;
  padding-bottom: 10px;
}
#primary.site-main.blog-single .header-blog .social {
  font-size: 26px;
}
#primary.site-main.blog-single .header-blog .social a {
  padding-right: 5px;
  border-radius: 3px;
}
#primary.site-main.blog-single .header-blog .author {
  cursor: pointer;
}
#primary.site-main.blog-single .header-blog .author img {
  width: 50px;
  height: auto;
}
#primary.site-main.blog-single .header-blog a {
  color: #fff;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
}
#primary.site-main.blog-single .header-blog a:focus {
  outline: 0;
}
#primary.site-main.blog-single .header-blog a:visited {
  color: #fff;
}
#primary.site-main.blog-single .header-blog a:hover {
  color: #fff;
  text-decoration: underline;
}
#primary.site-main.blog-single .body-blog {
  background-image: url("../img/blog/single-blog-sfondo.svg");
  background-size: cover;
  background-position: left top;
  background-repeat: repeat-y;
}
#primary.site-main.blog-single .content-blog h2 {
  font-size: 50px;
  font-weight: 500;
  line-height: 120%;
  padding: 20px 0 20px 0;
  color: #00225A;
}
#primary.site-main.blog-single .content-blog h3 {
  font-size: 32px;
  font-weight: 500;
  line-height: 42px;
  padding: 15px 0 15px 0;
  color: #00225A;
}
#primary.site-main.blog-single .content-blog ul, #primary.site-main.blog-single .content-blog ol {
  margin-left: 0;
  padding-left: 20px;
}
#primary.site-main.blog-single .content-blog ul li, #primary.site-main.blog-single .content-blog ol li {
  color: #4b5563;
  font-size: 20px;
  margin-bottom: 10px;
  line-height: 30px;
}
#primary.site-main.blog-single .content-blog p  {
  color: #4b5563;
  padding: 0;
  margin: 0;
  padding-bottom: 20px;
  font-size: 20px;
  line-height: 30px;
}
#primary.site-main.blog-single .content-blog p.subhead {
  font-size: 32px;
  line-height: 42px;
  color: #00225A;
}
#primary.site-main.blog-single .content-blog blockquote {
  color: #429AFF;
  font-family: "Gotham Rounded";
  font-size: 32px;
  padding: 60px 0;
  margin: 0 100px 0 100px;
  position: relative;
}
#primary.site-main.blog-single .content-blog blockquote p {
  padding-bottom: 0;
  color: #429AFF;
  font-family: "Gotham Rounded";
  font-size: 32px;
  line-height: 42px;
}
#primary.site-main.blog-single .content-blog blockquote cite {
  font-size: 28px;
}
#primary.site-main.blog-single .content-blog blockquote:before {
  display: block;
  /*                content: "\f10d";
                  text-rendering: auto;
                  -webkit-font-smoothing: antialiased;
                  font-family: "Font Awesome 5 Pro";
                  font-weight: 900;*/
  content: " ";
  background-image: url(../img/virgolette-cit.svg);
  width: 58px;
  height: 41px;
  float: left;
  position: absolute;
  left: -70px;
  top: 50px;
  background-color: white;
  background-position: center;
  background-repeat: no-repeat;
}
#primary.site-main.blog-single .content-blog figure.w100 img {
  width: 100%;
}
#primary.site-main.blog-single .content-blog .sfondo-grigio, #primary.site-main.blog-single .content-blog .sfondo-celeste {
  padding: 20px;
  margin: 20px 0;
  color: #4b5563;
}
#primary.site-main.blog-single .content-blog .sfondo-grigio ul, #primary.site-main.blog-single .content-blog .sfondo-grigio ol, #primary.site-main.blog-single .content-blog .sfondo-celeste ul, #primary.site-main.blog-single .content-blog .sfondo-celeste ol {
  color: #4b5563;
}
#primary.site-main.blog-single .content-blog .sfondo-blu {
  padding: 20px;
}
#primary.site-main.blog-single .content-blog .sfondo-blu h1, #primary.site-main.blog-single .content-blog .sfondo-blu h2, #primary.site-main.blog-single .content-blog .sfondo-blu h3, #primary.site-main.blog-single .content-blog .sfondo-blu h4, #primary.site-main.blog-single .content-blog .sfondo-blu h5, #primary.site-main.blog-single .content-blog .sfondo-blu h6, #primary.site-main.blog-single .content-blog .sfondo-blu p {
  color: #fff;
}
#primary.site-main.blog-single .content-blog .sfondo-blu a {
  color: #fff;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
}
#primary.site-main.blog-single .content-blog .sfondo-blu a:focus {
  outline: 0;
}
#primary.site-main.blog-single .content-blog .sfondo-blu a:visited {
  color: #fff;
}
#primary.site-main.blog-single .content-blog .sfondo-blu a:hover {
  color: #fff;
  text-decoration: underline;
}
#primary.site-main.blog-single .content-blog .negativo h1, #primary.site-main.blog-single .content-blog .negativo h2, #primary.site-main.blog-single .content-blog .negativo h3, #primary.site-main.blog-single .content-blog .negativo h4, #primary.site-main.blog-single .content-blog .negativo h5, #primary.site-main.blog-single .content-blog .negativo h6, #primary.site-main.blog-single .content-blog .negativo p {
  color: #fff;
}
#primary.site-main.blog-single .content-blog .negativo a {
  color: #fff;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
}
#primary.site-main.blog-single .content-blog .negativo a:focus {
  outline: 0;
}
#primary.site-main.blog-single .content-blog .negativo a:visited {
  color: #fff;
}
#primary.site-main.blog-single .content-blog .negativo a:hover {
  color: #fff;
  text-decoration: underline;
}
#primary.site-main.blog-single .other-blog .tags {
  text-transform: uppercase;
  font-size: 18px;
  line-height: 26px;
  padding-bottom: 30px;
}
#primary.site-main.blog-single .other-blog .tags a {
  color: #4b5563;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
}
#primary.site-main.blog-single .other-blog .tags a:focus {
  outline: 0;
}
#primary.site-main.blog-single .other-blog .tags a:visited {
  color: #4b5563;
}
#primary.site-main.blog-single .other-blog .tags a:hover {
  color: #00225A;
  text-decoration: underline;
}
#primary.site-main.blog-single .other-blog .tags a {
  background-color: #E9EDF3;
  border-radius: 20vh;
  margin-right: 5px;
  padding: 0px 10px;
}
#primary.site-main.blog-single .other-blog .social {
  font-size: 28px;
  padding-bottom: 30px;
}
#primary.site-main.blog-single .other-blog .social a {
  padding-right: 5px;
}
#primary.site-main.blog-single .other-blog .social a {
  color: #00225A;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
}
#primary.site-main.blog-single .other-blog .social a:focus {
  outline: 0;
}
#primary.site-main.blog-single .other-blog .social a:visited {
  color: #00225A;
}
#primary.site-main.blog-single .other-blog .social a:hover {
  color: #429AFF;
  text-decoration: underline;
}
#primary.site-main.blog-single .comment-blog .comment-form-cookies-consent label {
  width: auto;
}
#primary.site-main.blog-single .comment-blog .comments-title {
  display: none;
}
#primary.site-main.blog-single .comment-blog .comment-list {
  margin-left: 0;
  list-style: none;
  padding-left: 0;
  border-top: 1px solid #c6cbd1;
}
#primary.site-main.blog-single .comment-blog .children {
  border-top: 1px solid #c6cbd1;
  margin-left: 0;
  list-style: none;
}
#primary.site-main.blog-single .comment-blog .comment-body {
  padding-top: 15px;
  padding-bottom: 15px;
}
#primary.site-main.blog-single .comment-blog .comment-body .comment-author .fn {
  text-transform: uppercase;
}
#primary.site-main.blog-single .comment-blog .comment-body .comment-author .avatar, #primary.site-main.blog-single .comment-blog .comment-body .comment-author .says {
  display: none;
}
#primary.site-main.blog-single .comment-blog .comment-body .comment-metadata {
  font-size: 80%;
}
#primary.site-main.blog-single .comment-blog .comment-body .comment-metadata a {
  color: #00225A;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
}
#primary.site-main.blog-single .comment-blog .comment-body .comment-metadata a:focus {
  outline: 0;
}
#primary.site-main.blog-single .comment-blog .comment-body .comment-metadata a:visited {
  color: #00225A;
}
#primary.site-main.blog-single .comment-blog .comment-body .comment-metadata a:hover {
  color: #F05032;
  text-decoration: underline;
}
#primary.site-main.blog-single .comment-blog .comment-body .comment-content p, #primary.site-main.blog-single .comment-blog .comment-body .comment-awaiting-moderation p {
  padding-bottom: 0;
  margin-bottom: 0;
}
#primary.site-main.blog-single .comment-blog .comment-respond {
  padding-top: 40px;
  padding-bottom: 40px;
}
#primary.site-main.blog-single .comment-blog .comment-respond .comment-notes {
  font-weight: 300;
}
#primary.site-main.blog-single .comment-blog .comment-respond .comment-form-comment {
  font-size: 80%;
}
#primary.site-main.blog-single .comment-blog .comment-respond #cancel-comment-reply-link {
  float: right;
}

/* -------------------------------------------------- */
/*	SINGLE BLOG OLD
/* -------------------------------------------------- */
/** OVERRIDE MENU **/
body.single-cpt_blog #page.site {
  margin-top: 0 !important;
}
body.single-cpt_blog #page.site #masthead.site-header .menu__header {
  position: relative;
}

#primary.site-main.blog-oldsite-single .content-blog {
  font-size: 20px;
  color: #4b5563;
  line-height: 30px;
  /*        h6, p.subhead, .subhead {
              font-size: 32px!important;
              line-height: 42px!important;
              color: $txt_blu!important;
              font-family: $font_titoli!important;
          }*/
  /*    #imaAuthorAvatar {
          display: none;
      }*/
  /*        .et_pb_section_0 {
              padding: 4% 0 !important;
              h1.entry-title, h1, h1 span  {
                  font-size: $size_h1 !important;
              }
          }*/
  /*    .et_pb_section_1 {
          display: none;
      }*/
}
#primary.site-main.blog-oldsite-single .content-blog .aligncenter {
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
#primary.site-main.blog-oldsite-single .content-blog .et_pb_text_inner div span, #primary.site-main.blog-oldsite-single .content-blog .et_pb_text_inner p span {
  font-size: 20px !important;
}
#primary.site-main.blog-oldsite-single .content-blog .et-l .et_pb_row_1.et_pb_row {
  margin-left: auto !important;
}
#primary.site-main.blog-oldsite-single .content-blog h1, #primary.site-main.blog-oldsite-single .content-blog h1 span, #primary.site-main.blog-oldsite-single .content-blog h2, #primary.site-main.blog-oldsite-single .content-blog h2 span {
  font-size: 50px !important;
  font-weight: 500 !important;
  line-height: 120% !important;
  padding: 20px 0 20px 0 !important;
  color: #00225A !important;
}
#primary.site-main.blog-oldsite-single .content-blog h3, #primary.site-main.blog-oldsite-single .content-blog h3 span {
  font-size: 32px !important;
  font-weight: 500 !important;
  line-height: 42px !important;
  padding: 15px 0 15px 0 !important;
  color: #00225A !important;
}
#primary.site-main.blog-oldsite-single .content-blog .shareaholic-canvas {
  max-width: 1080px;
  margin: 0 auto;
  padding-bottom: 60px;
  width: 80%;
}
#primary.site-main.blog-oldsite-single .content-blog .et-l .et_pb_text_1 h1 {
  font-size: 44px;
  color: #00225A;
}
#primary.site-main.blog-oldsite-single .content-blog #et-boc .et-l .et_pb_with_background .et_pb_row, #primary.site-main.blog-oldsite-single .content-blog #et-boc .et-l .single.et_full_width_page .et_pb_row, #primary.site-main.blog-oldsite-single .content-blog #et-boc .et-l .single.et_full_width_portfolio_page .et_pb_row {
  width: 100%;
  max-width: 1140px;
  padding-right: 9px;
  padding-left: 9px;
}
#primary.site-main.blog-oldsite-single .content-blog #et-boc strong a, #primary.site-main.blog-oldsite-single .content-blog #et-boc strong a {
  font-weight: bold !important;
}
#primary.site-main.blog-oldsite-single .content-blog #et-boc .et-l .et_pb_module blockquote {
  margin: 0 100px;
  padding-left: inherit;
  border-left: 0;
  border-color: #fff;
  font-style: normal;
}
#primary.site-main.blog-oldsite-single .content-blog #et-boc .et-l .et_pb_section_0.et_pb_section {
  background-color: transparent !important;
}

@media (max-width: 1199px) {
  #primary.site-main.blog-oldsite-single .content-blog #et-boc .et-l .et_pb_with_background .et_pb_row,
  #primary.site-main.blog-oldsite-single .content-blog #et-boc .et-l .single.et_full_width_page .et_pb_row,
  #primary.site-main.blog-oldsite-single .content-blog #et-boc .et-l .single.et_full_width_portfolio_page .et_pb_row {
    width: 100%;
    max-width: 960px;
  }
}
@media (max-width: 991px) {
  #primary.site-main.blog-oldsite-single .content-blog #et-boc .et-l .et_pb_with_background .et_pb_row,
  #primary.site-main.blog-oldsite-single .content-blog #et-boc .et-l .single.et_full_width_page .et_pb_row,
  #primary.site-main.blog-oldsite-single .content-blog #et-boc .et-l .single.et_full_width_portfolio_page .et_pb_row {
    width: 100%;
    max-width: 720px;
  }
}
@media (max-width: 767px) {
  #primary.site-main.blog-oldsite-single .content-blog #et-boc .et-l .et_pb_with_background .et_pb_row,
  #primary.site-main.blog-oldsite-single .content-blog #et-boc .et-l .single.et_full_width_page .et_pb_row,
  #primary.site-main.blog-oldsite-single .content-blog #et-boc .et-l .single.et_full_width_portfolio_page .et_pb_row {
    width: 100%;
    max-width: 540px;
  }
}
@media (max-width: 575px) {
  #primary.site-main.blog-oldsite-single .content-blog #et-boc .et-l .et_pb_with_background .et_pb_row,
  #primary.site-main.blog-oldsite-single .content-blog #et-boc .et-l .single.et_full_width_page .et_pb_row,
  #primary.site-main.blog-oldsite-single .content-blog #et-boc .et-l .single.et_full_width_portfolio_page .et_pb_row {
    width: 100%;
    max-width: 100%;
  }
}
/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 3-feb-2021, 10.42.11
    Author     : veronica
*/
.pattern-news {
  position: relative;
  padding-top: 50px;
  padding-bottom: 180px;
}
.pattern-news .item a {
  color: #00225A;
}
.pattern-news .item a:hover {
  text-decoration: none;
}
.pattern-news .item {
  padding: 28px;
  height: 100%;
  min-height: 266px;
  background-color: #fff;
  color: #00225A;
  overflow: hidden;
  list-style: none;
  cursor: pointer;
}
.pattern-news .item .tipo {
  font-weight: bold;
  z-index: 300;
  position: relative;
  /*            &:after {
                  content: " ";
                  display: block;
                  width: 50px;
                  height: 2px;
                  background-color: $txt_blu;
                  margin: 8px 0;
              }*/
}
.pattern-news .item .tipo .label {
  display: none;
}
.pattern-news .item .tipo .subtipo {
  display: block;
}
.pattern-news .item .data {
  position: relative;
  z-index: 300;
}
.pattern-news .item .testi {
  transition: all 0.1s ease-in-out;
  position: absolute;
  width: calc(100% - 56px);
  bottom: 20px;
  z-index: 300;
}
.pattern-news .item .testi .titolo5 {
  padding-bottom: 0;
  transition: all 0.4s ease-in-out;
}
.pattern-news .item .testi .testo {
  padding-top: 10px;
  max-height: 0;
  height: 100%;
  margin: 0;
  transition: all 0.4s ease-in-out;
  transform: translateX(-100%);
  opacity: 0;
}
.pattern-news .item .image {
  background-image: url("../img/pattern-news.png");
  position: absolute;
  z-index: 100;
  background-size: 120%;
}
.pattern-news .item .image {
  width: 63%;
  height: 46%;
  right: 0;
  top: 0;
  border-radius: 0 25px 0px 104px;
}
.pattern-news .item .image.custom {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.pattern-news .item .image-velina, .pattern-news .item .image-sfondo {
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 200;
}
.pattern-news .item .image-sfondo {
  z-index: 250;
}
.pattern-news .item:hover {
  -webkit-box-shadow: 0px 10px 77px 20px rgba(0, 17, 57, 0.5);
  -moz-box-shadow: 0px 10px 77px 20px rgba(0, 17, 57, 0.5);
  box-shadow: 0px 10px 77px 20px rgba(0, 17, 57, 0.5);
  transition: all 0.4s ease-in-out;
  background-color: #85E2FF;
}
.pattern-news .item:hover .data {
  opacity: 0;
}
.pattern-news .item:hover .testi .titolo5 {
  font-size: 100%;
  display: none;
}
.pattern-news .item:hover .testi .testo {
  max-height: 300px;
  opacity: 1;
  transform: translateX(0);
}
.pattern-news .item:hover .image-velina {
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
}
.pattern-news .item:hover .image-sfondo {
  background: #85E2FF;
  opacity: 0.4;
}

.pattern-news .item.webinar .data {
  font-weight: bold;
  font-size: 26px;
}
.pattern-news .item.webinar .image {
  background-image: url("../img/pattern-webinar.svg");
  display: grid;
  text-align: right;
  padding-right: 20px;
  font-weight: 300;
  background-size: 1000px;
  grid-template-rows: 45% 55%;
  grid-gap: 5px;
}
.pattern-news .item.webinar .image .date {
  font-family: "Gotham Rounded";
  display: flex;
  font-weight: bold;
  font-size: 26px;
  justify-content: end;
  align-self: flex-end;
}
.pattern-news .item.webinar .image .time {
  font-family: "Gotham Rounded Book";
  display: flex;
  font-size: 32px;
  text-transform: uppercase;
  letter-spacing: -2px;
  justify-content: flex-end;
  align-self: flex-start;
}
.pattern-news .item.webinar .image .lang {
  display: block;
  position: absolute;
  right: 25px;
  top: -15px;
  background: #E9EDF3;
  border: 4px solid #fff;
  border-radius: 20px;
  width: 70px;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  height: 30px;
}
.pattern-news .item.webinar .image .action {
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #00225A;
  color: #fff;
  border-radius: 50px;
  width: 230px;
  height: 50px;
  font-family: "Gotham Rounded";
  font-weight: bold;
  font-size: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 33px;
}
.pattern-news .item.webinar .image .action .ico-webinar {
  width: 50px;
  height: auto;
  position: absolute;
  left: -4px;
  font-size: 58px;
  top: 0;
}
.pattern-news .item.webinar .image.custom {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.pattern-news .item.webinar.live .image {
  background-image: none;
}
.pattern-news .item.nasconditype {
  display: none;
}
.pattern-news .item.nascondilang {
  display: none;
}
.pattern-news .item.nascondiprod {
  display: none;
}

.pattern-news .item.download .image .action {
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #00225A;
  color: #fff;
  border-radius: 50px;
  width: 230px;
  height: 50px;
  font-family: "Gotham Rounded";
  font-weight: bold;
  font-size: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 33px;
}
.pattern-news .item.download .image .action .ico-webinar {
  width: 50px;
  height: auto;
  position: absolute;
  left: -4px;
  font-size: 58px;
  top: 0;
}

.pattern-news .griglia, .pattern-news .et_builder_outer_content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 18px;
  max-width: 1140px;
  height: 638px;
  margin: 0;
  padding: 0;
}
.pattern-news .griglia.colonne .item:nth-child(1) {
  grid-column: 1;
  grid-row: 1/3;
}
.pattern-news .griglia.colonne .item:nth-child(1) .image {
  width: 90%;
  height: 44%;
  right: 0;
  top: 20%;
  border-radius: 140px 0 0 140px;
}
.pattern-news .griglia.colonne .item:nth-child(2) {
  grid-column: 2;
  grid-row: 1/3;
}
.pattern-news .griglia.colonne .item:nth-child(2) .image {
  width: 90%;
  height: 44%;
  right: 0;
  top: 20%;
  border-radius: 140px 0 0 140px;
}
.pattern-news .griglia.colonne .item:nth-child(3) {
  grid-column: 3;
  grid-row: 1/3;
}
.pattern-news .griglia.colonne .item:nth-child(3) .image {
  width: 90%;
  height: 44%;
  right: 0;
  top: 20%;
  border-radius: 140px 0 0 140px;
}
.pattern-news .griglia.colonne .item:nth-child(4), .pattern-news .griglia.colonne .item:nth-child(5), .pattern-news .griglia.colonne .item:nth-child(6) {
  display: none;
}
.pattern-news .griglia.last {
  height: auto;
  grid-template-columns: repeat(1, 1fr);
}
.pattern-news .griglia.last .item {
  height: 560px;
}
.pattern-news .griglia.last .item .image {
  width: 75%;
  height: 64%;
  right: 0;
  top: 0;
  border-radius: 0 25px 0 255px;
}
.pattern-news .griglia.blog, .pattern-news .et_builder_outer_content {
  height: auto;
}
.pattern-news .griglia.blog .item, .pattern-news .et_builder_outer_content .item {
  height: 560px;
}
.pattern-news .griglia.blog .item .image, .pattern-news .et_builder_outer_content .item .image {
  width: 90%;
  height: 44%;
  right: 0;
  top: 20%;
  border-radius: 140px 0 0 140px;
}
.pattern-news .griglia.libero {
  height: auto;
}
.pattern-news .griglia.libero .item {
  height: 560px;
}
.pattern-news .griglia.libero .item .image {
  width: 90%;
  height: 44%;
  right: 0;
  top: 20%;
  border-radius: 140px 0 0 140px;
}
.pattern-news .griglia.infobox {
  height: auto;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 12px;
  max-width: 400px;
}
.pattern-news .griglia.infobox .item {
  padding: 0;
  min-height: auto !important;
  margin-bottom: 0;
  min-height: auto !important;
  margin-bottom: 0;
}
.pattern-news .griglia.infobox .item .image-velina {
  display: none;
}
.pattern-news .griglia.infobox .item .image {
  width: 100%;
  height: auto;
  min-height: 100px;
  position: relative;
  right: auto;
  top: auto;
  border-radius: 0 25px 0px 104px;
}
.pattern-news .griglia.infobox .item .tipo {
  padding: 20px 20px 0 20px;
}
.pattern-news .griglia.infobox .item .data {
  padding: 0 20px;
}
.pattern-news .griglia.infobox .item .testi {
  position: relative;
  width: 100%;
  bottom: auto;
  padding: 20px;
}
.pattern-news .griglia.infobox .item .testi .testo {
  opacity: 1;
  transform: none;
  max-height: initial;
}
.pattern-news .griglia.infobox .item:hover .image-sfondo {
  background: #00225A;
  opacity: 7%;
}
.pattern-news .griglia.infobox .item:active {
  transition: all 0.4s ease-in-out;
}
.pattern-news .griglia.infobox .item:active .image-velina {
  display: none;
}
.pattern-news .griglia.infobox .item:active .image-sfondo {
  background: #00225A;
  opacity: 20%;
}
.pattern-news .griglia.infobox .item .image {
  min-height: 100px;
}
.pattern-news .griglia.misto .item:nth-child(1) {
  grid-column: 1/3;
  grid-row: 1;
}
.pattern-news .griglia.misto .item:nth-child(1) .testi {
  width: 40%;
}
.pattern-news .griglia.misto .item:nth-child(1) .image {
  width: 50%;
  height: 100%;
  right: 0;
  top: 0;
  border-radius: 175px 25px 25px 0;
}
.pattern-news .griglia.misto .item:nth-child(1):hover .testi {
  width: 56%;
}
.pattern-news .griglia.misto .item:nth-child(1) .image .lang {
  top: 20px;
}
.pattern-news .griglia.misto .item:nth-child(1) .image .action {
  border-top-right-radius: 0;
  left: auto;
  right: 0;
  width: 80%;
}
.pattern-news .griglia.misto .item:nth-child(2) {
  grid-column: 3;
  grid-row: 1/3;
}
.pattern-news .griglia.misto .item:nth-child(2) .image {
  width: 90%;
  height: 44%;
  right: 0;
  top: 20%;
  border-radius: 140px 0 0 140px;
}
.pattern-news .griglia.misto .item:nth-child(5), .pattern-news .griglia.misto .item:nth-child(6) {
  display: none;
}
.pattern-news .griglia.evidenza .item:nth-child(1) {
  grid-column: 1/3;
  grid-row: 1/3;
}
.pattern-news .griglia.evidenza .item:nth-child(1) .image {
  width: 75%;
  height: 64%;
  right: 0;
  top: 0;
  border-radius: 0 25px 0 255px;
}
.pattern-news .griglia.evidenza .item:nth-child(2) {
  grid-column: 3;
  grid-row: 1/3;
}
.pattern-news .griglia.evidenza .item:nth-child(2) .image {
  width: 90%;
  height: 44%;
  right: 0;
  top: 20%;
  border-radius: 140px 0 0 140px;
}
.pattern-news .griglia.evidenza .item:nth-child(3), .pattern-news .griglia.evidenza .item:nth-child(4), .pattern-news .griglia.evidenza .item:nth-child(5), .pattern-news .griglia.evidenza .item:nth-child(6) {
  display: none;
}
.pattern-news .griglia.one .item:nth-child(1) {
  grid-column: 1/4;
  grid-row: 1/3;
}
.pattern-news .griglia.one .item:nth-child(1) .image {
  width: 75%;
  height: 64%;
  right: 0;
  top: 0;
  border-radius: 0 25px 0 255px;
}
.pattern-news .griglia.one .item:nth-child(2), .pattern-news .griglia.one .item:nth-child(3), .pattern-news .griglia.one .item:nth-child(4), .pattern-news .griglia.one .item:nth-child(5), .pattern-news .griglia.one .item:nth-child(6) {
  display: none;
}

.sfondo-grigio .pattern-news .item:hover {
  -webkit-box-shadow: 0px 15px 25px 10px rgba(0, 17, 57, 0.25);
  -moz-box-shadow: 0px 15px 25px 10px rgba(0, 17, 57, 0.25);
  box-shadow: 0px 15px 25px 10px rgba(0, 17, 57, 0.25);
}

/* -------------------------------------------------- */
/*	PAGE WEBEX
/* -------------------------------------------------- */
.news-sfondo-nero .titolo2 {
  color: #191919;
}
.news-sfondo-nero .pattern-news .item {
  background-color: #191919;
  color: #818181;
  background: #191919;
  color: #818181;
}
.news-sfondo-nero .pattern-news .item h1, .news-sfondo-nero .pattern-news .item h2, .news-sfondo-nero .pattern-news .item h3, .news-sfondo-nero .pattern-news .item h4, .news-sfondo-nero .pattern-news .item h5, .news-sfondo-nero .pattern-news .item h6, .news-sfondo-nero .pattern-news .item .titolo, .news-sfondo-nero .pattern-news .item .titolo1, .news-sfondo-nero .pattern-news .item .titolo2, .news-sfondo-nero .pattern-news .item .titolo3, .news-sfondo-nero .pattern-news .item .titolo4, .news-sfondo-nero .pattern-news .item .titolo5 {
  color: #ffffff;
}
.news-sfondo-nero .pattern-news .item a {
  color: #ffffff;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
}
.news-sfondo-nero .pattern-news .item a:focus {
  outline: 0;
}
.news-sfondo-nero .pattern-news .item a:visited {
  color: #ffffff;
}
.news-sfondo-nero .pattern-news .item a:hover {
  color: #078855;
  text-decoration: underline;
}
.news-sfondo-nero .pattern-news .item a.link {
  font-weight: 800;
  color: #ffffff;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
  display: inline-block;
  font-size: 88%;
  text-transform: uppercase;
}
.news-sfondo-nero .pattern-news .item a.link:focus {
  outline: 0;
}
.news-sfondo-nero .pattern-news .item a.link:visited {
  color: #ffffff;
}
.news-sfondo-nero .pattern-news .item a.link:hover {
  color: #078855;
  text-decoration: none;
}
.news-sfondo-nero .pattern-news .item a.link:hover:after {
  padding-left: 20px;
}
.news-sfondo-nero .pattern-news .item a.link:after {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f061";
  padding-left: 10px;
  transition: all 0.4s ease-in-out;
}
.news-sfondo-nero .pattern-news .item a.pulsante, .news-sfondo-nero .pattern-news .item .wp-block-button__link, .news-sfondo-nero .pattern-news .item submit, .news-sfondo-nero .pattern-news .item input[type=submit], .news-sfondo-nero .pattern-news .item input[type=reset], .news-sfondo-nero .pattern-news .item input[type=button] {
  position: relative;
  border-radius: 25px;
  background: #078855;
  color: #fff;
  border: 2px solid #078855;
  box-shadow: none;
  text-shadow: none;
  text-decoration: none !important;
  vertical-align: middle;
  display: inline-block;
  width: auto;
  margin: 0;
  font-size: 88%;
  font-weight: 700;
  text-transform: uppercase;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  -o-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  padding: 10px 30px;
  cursor: pointer;
  min-width: 155px;
  text-align: center;
}
.news-sfondo-nero .pattern-news .item a.pulsante:visited, .news-sfondo-nero .pattern-news .item .wp-block-button__link:visited, .news-sfondo-nero .pattern-news .item submit:visited, .news-sfondo-nero .pattern-news .item input[type=submit]:visited, .news-sfondo-nero .pattern-news .item input[type=reset]:visited, .news-sfondo-nero .pattern-news .item input[type=button]:visited {
  color: #fff;
}
.news-sfondo-nero .pattern-news .item a.pulsante:hover, .news-sfondo-nero .pattern-news .item .wp-block-button__link:hover, .news-sfondo-nero .pattern-news .item submit:hover, .news-sfondo-nero .pattern-news .item input[type=submit]:hover, .news-sfondo-nero .pattern-news .item input[type=reset]:hover, .news-sfondo-nero .pattern-news .item input[type=button]:hover {
  font-weight: 700;
  background: transparent;
  color: #fff;
  border-color: #fff;
  box-shadow: none;
}
.news-sfondo-nero .pattern-news .item a.pulsante.negativo, .news-sfondo-nero .pattern-news .item input[type=submit].negativo, .news-sfondo-nero .pattern-news .item input[type=reset].negativo, .news-sfondo-nero .pattern-news .item input[type=button].negativo {
  position: relative;
  border-radius: 25px;
  background: transparent;
  color: #fff;
  border: 2px solid #fff;
  box-shadow: none;
  text-shadow: none;
  text-decoration: none !important;
  vertical-align: middle;
  display: inline-block;
  width: auto;
  margin: 0;
  font-size: 88%;
  font-weight: 700;
  text-transform: uppercase;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  -o-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  padding: 10px 30px;
  cursor: pointer;
  min-width: 155px;
  text-align: center;
}
.news-sfondo-nero .pattern-news .item a.pulsante.negativo:visited, .news-sfondo-nero .pattern-news .item input[type=submit].negativo:visited, .news-sfondo-nero .pattern-news .item input[type=reset].negativo:visited, .news-sfondo-nero .pattern-news .item input[type=button].negativo:visited {
  color: #fff;
}
.news-sfondo-nero .pattern-news .item a.pulsante.negativo:hover, .news-sfondo-nero .pattern-news .item input[type=submit].negativo:hover, .news-sfondo-nero .pattern-news .item input[type=reset].negativo:hover, .news-sfondo-nero .pattern-news .item input[type=button].negativo:hover {
  font-weight: 700;
  background: #078855;
  color: #fff;
  border-color: #078855;
  box-shadow: none;
}
.news-sfondo-nero .pattern-news .item h1, .news-sfondo-nero .pattern-news .item h2, .news-sfondo-nero .pattern-news .item h3, .news-sfondo-nero .pattern-news .item h4, .news-sfondo-nero .pattern-news .item h5, .news-sfondo-nero .pattern-news .item h6, .news-sfondo-nero .pattern-news .item .titolo, .news-sfondo-nero .pattern-news .item .titolo1, .news-sfondo-nero .pattern-news .item .titolo2, .news-sfondo-nero .pattern-news .item .titolo3, .news-sfondo-nero .pattern-news .item .titolo4, .news-sfondo-nero .pattern-news .item .titolo5 {
  color: #ffffff;
}
.news-sfondo-nero .pattern-news .item a {
  color: #ffffff;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
}
.news-sfondo-nero .pattern-news .item a:focus {
  outline: 0;
}
.news-sfondo-nero .pattern-news .item a:visited {
  color: #ffffff;
}
.news-sfondo-nero .pattern-news .item a:hover {
  color: #818181;
  text-decoration: underline;
}
.news-sfondo-nero .pattern-news .item:hover {
  -webkit-box-shadow: 0px 10px 77px 20px rgba(0, 17, 57, 0.5);
  -moz-box-shadow: 0px 10px 77px 20px rgba(0, 17, 57, 0.5);
  box-shadow: 0px 10px 77px 20px rgba(0, 17, 57, 0.5);
  transition: all 0.4s ease-in-out;
  background-color: #191919;
}
.news-sfondo-nero .pattern-news .item:hover a {
  color: #818181;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
}
.news-sfondo-nero .pattern-news .item:hover a:focus {
  outline: 0;
}
.news-sfondo-nero .pattern-news .item:hover a:visited {
  color: #818181;
}
.news-sfondo-nero .pattern-news .item:hover a:hover {
  color: #fff;
  text-decoration: underline;
}
.news-sfondo-nero .pattern-news .item:hover .testi .testo {
  max-height: 100px;
  opacity: 1;
  transform: translateX(0);
  color: #fff;
}
.news-sfondo-nero .pattern-news .item:hover .image-velina {
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
}
.news-sfondo-nero .pattern-news .item:hover .image-sfondo {
  background: #191919;
  opacity: 0.4;
}

/* -------------------------------------------------- */
/*	PAGE MS
/* -------------------------------------------------- */
.news-sfondo-viola .titolo2 {
  color: #000;
}
.news-sfondo-viola .pattern-news .item {
  background-color: #5D5BD4;
  color: #ffffff;
  background: #5D5BD4;
  color: #ffffff;
}
.news-sfondo-viola .pattern-news .item h1, .news-sfondo-viola .pattern-news .item h2, .news-sfondo-viola .pattern-news .item h3, .news-sfondo-viola .pattern-news .item h4, .news-sfondo-viola .pattern-news .item h5, .news-sfondo-viola .pattern-news .item h6, .news-sfondo-viola .pattern-news .item .titolo, .news-sfondo-viola .pattern-news .item .titolo1, .news-sfondo-viola .pattern-news .item .titolo2, .news-sfondo-viola .pattern-news .item .titolo3, .news-sfondo-viola .pattern-news .item .titolo4, .news-sfondo-viola .pattern-news .item .titolo5 {
  color: #ffffff;
}
.news-sfondo-viola .pattern-news .item a {
  color: #ffffff;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
}
.news-sfondo-viola .pattern-news .item a:focus {
  outline: 0;
}
.news-sfondo-viola .pattern-news .item a:visited {
  color: #ffffff;
}
.news-sfondo-viola .pattern-news .item a:hover {
  color: #c6cbd1;
  text-decoration: underline;
}
.news-sfondo-viola .pattern-news .item a.link {
  font-weight: 800;
  color: #ffffff;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
  display: inline-block;
  font-size: 88%;
  text-transform: uppercase;
}
.news-sfondo-viola .pattern-news .item a.link:focus {
  outline: 0;
}
.news-sfondo-viola .pattern-news .item a.link:visited {
  color: #ffffff;
}
.news-sfondo-viola .pattern-news .item a.link:hover {
  color: #c6cbd1;
  text-decoration: none;
}
.news-sfondo-viola .pattern-news .item a.link:hover:after {
  padding-left: 20px;
}
.news-sfondo-viola .pattern-news .item a.link:after {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f061";
  padding-left: 10px;
  transition: all 0.4s ease-in-out;
}
.news-sfondo-viola .pattern-news .item a.pulsante, .news-sfondo-viola .pattern-news .item .wp-block-button__link, .news-sfondo-viola .pattern-news .item submit, .news-sfondo-viola .pattern-news .item input[type=submit], .news-sfondo-viola .pattern-news .item input[type=reset], .news-sfondo-viola .pattern-news .item input[type=button] {
  position: relative;
  border-radius: 25px;
  background: #ffffff;
  color: #5D5BD4;
  border: 2px solid #fff;
  box-shadow: none;
  text-shadow: none;
  text-decoration: none !important;
  vertical-align: middle;
  display: inline-block;
  width: auto;
  margin: 0;
  font-size: 88%;
  font-weight: 700;
  text-transform: uppercase;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  -o-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  padding: 10px 30px;
  cursor: pointer;
  min-width: 155px;
  text-align: center;
}
.news-sfondo-viola .pattern-news .item a.pulsante:visited, .news-sfondo-viola .pattern-news .item .wp-block-button__link:visited, .news-sfondo-viola .pattern-news .item submit:visited, .news-sfondo-viola .pattern-news .item input[type=submit]:visited, .news-sfondo-viola .pattern-news .item input[type=reset]:visited, .news-sfondo-viola .pattern-news .item input[type=button]:visited {
  color: #5D5BD4;
}
.news-sfondo-viola .pattern-news .item a.pulsante:hover, .news-sfondo-viola .pattern-news .item .wp-block-button__link:hover, .news-sfondo-viola .pattern-news .item submit:hover, .news-sfondo-viola .pattern-news .item input[type=submit]:hover, .news-sfondo-viola .pattern-news .item input[type=reset]:hover, .news-sfondo-viola .pattern-news .item input[type=button]:hover {
  font-weight: 700;
  background: transparent;
  color: #fff;
  border-color: #fff;
  box-shadow: none;
}
.news-sfondo-viola .pattern-news .item a.pulsante.negativo, .news-sfondo-viola .pattern-news .item input[type=submit].negativo, .news-sfondo-viola .pattern-news .item input[type=reset].negativo, .news-sfondo-viola .pattern-news .item input[type=button].negativo {
  position: relative;
  border-radius: 25px;
  background: #5D5BD4;
  color: #fff;
  border: 2px solid #5D5BD4;
  box-shadow: none;
  text-shadow: none;
  text-decoration: none !important;
  vertical-align: middle;
  display: inline-block;
  width: auto;
  margin: 0;
  font-size: 88%;
  font-weight: 700;
  text-transform: uppercase;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  -o-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  padding: 10px 30px;
  cursor: pointer;
  min-width: 155px;
  text-align: center;
}
.news-sfondo-viola .pattern-news .item a.pulsante.negativo:visited, .news-sfondo-viola .pattern-news .item input[type=submit].negativo:visited, .news-sfondo-viola .pattern-news .item input[type=reset].negativo:visited, .news-sfondo-viola .pattern-news .item input[type=button].negativo:visited {
  color: #fff;
}
.news-sfondo-viola .pattern-news .item a.pulsante.negativo:hover, .news-sfondo-viola .pattern-news .item input[type=submit].negativo:hover, .news-sfondo-viola .pattern-news .item input[type=reset].negativo:hover, .news-sfondo-viola .pattern-news .item input[type=button].negativo:hover {
  font-weight: 700;
  background: #ffffff;
  color: #5D5BD4;
  border-color: #5D5BD4;
  box-shadow: none;
}
.news-sfondo-viola .pattern-news .item h1, .news-sfondo-viola .pattern-news .item h2, .news-sfondo-viola .pattern-news .item h3, .news-sfondo-viola .pattern-news .item h4, .news-sfondo-viola .pattern-news .item h5, .news-sfondo-viola .pattern-news .item h6, .news-sfondo-viola .pattern-news .item .titolo, .news-sfondo-viola .pattern-news .item .titolo1, .news-sfondo-viola .pattern-news .item .titolo2, .news-sfondo-viola .pattern-news .item .titolo3, .news-sfondo-viola .pattern-news .item .titolo4, .news-sfondo-viola .pattern-news .item .titolo5 {
  color: #ffffff;
}
.news-sfondo-viola .pattern-news .item a {
  color: #ffffff;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
}
.news-sfondo-viola .pattern-news .item a:focus {
  outline: 0;
}
.news-sfondo-viola .pattern-news .item a:visited {
  color: #ffffff;
}
.news-sfondo-viola .pattern-news .item a:hover {
  color: #ffffff;
  text-decoration: underline;
}
.news-sfondo-viola .pattern-news .item:hover {
  -webkit-box-shadow: 0px 10px 77px 20px rgba(0, 17, 57, 0.5);
  -moz-box-shadow: 0px 10px 77px 20px rgba(0, 17, 57, 0.5);
  box-shadow: 0px 10px 77px 20px rgba(0, 17, 57, 0.5);
  transition: all 0.4s ease-in-out;
  background-color: #5D5BD4;
}
.news-sfondo-viola .pattern-news .item:hover a {
  color: #fff;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
}
.news-sfondo-viola .pattern-news .item:hover a:focus {
  outline: 0;
}
.news-sfondo-viola .pattern-news .item:hover a:visited {
  color: #fff;
}
.news-sfondo-viola .pattern-news .item:hover a:hover {
  color: #fff;
  text-decoration: underline;
}
.news-sfondo-viola .pattern-news .item:hover .testi .testo {
  max-height: 100px;
  opacity: 1;
  transform: translateX(0);
  color: #fff;
}
.news-sfondo-viola .pattern-news .item:hover .image-velina {
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
}
.news-sfondo-viola .pattern-news .item:hover .image-sfondo {
  background: #5D5BD4;
  opacity: 0.4;
}

@media (max-width: 991px) {
  .pattern-news {
    padding-bottom: 90px;
  }
  .pattern-news .griglia, .pattern-news .griglia.libero, .pattern-news .griglia.blog, .pattern-news .et_builder_outer_content {
    height: auto !important;
    grid-template-columns: repeat(1, 1fr) !important;
  }
  .pattern-news .griglia .item, .pattern-news .griglia.libero .item, .pattern-news .griglia.blog .item, .pattern-news .et_builder_outer_content .item {
    height: auto !important;
    min-height: 330px !important;
  }
  .pattern-news .griglia .item .testi, .pattern-news .griglia.libero .item .testi, .pattern-news .griglia.blog .item .testi, .pattern-news .et_builder_outer_content .item .testi {
    width: 40%;
  }
  .pattern-news .griglia .item .image, .pattern-news .griglia.libero .item .image, .pattern-news .griglia.blog .item .image, .pattern-news .et_builder_outer_content .item .image {
    width: 50%;
    height: 100%;
    right: 0;
    top: 0;
    border-radius: 175px 25px 25px 0;
  }
  .pattern-news .griglia .item:hover .testi, .pattern-news .griglia.libero .item:hover .testi, .pattern-news .griglia.blog .item:hover .testi, .pattern-news .et_builder_outer_content .item:hover .testi {
    width: 56%;
  }
  .pattern-news .griglia .item .data, .pattern-news .griglia.libero .item .data, .pattern-news .griglia.blog .item .data, .pattern-news .et_builder_outer_content .item .data {
    padding-bottom: 20px;
  }
  .pattern-news .griglia .item .tipo, .pattern-news .griglia.libero .item .tipo, .pattern-news .griglia.blog .item .tipo, .pattern-news .et_builder_outer_content .item .tipo {
    padding-bottom: 20px;
  }
  .pattern-news .griglia .item .testi, .pattern-news .griglia.libero .item .testi, .pattern-news .griglia.blog .item .testi, .pattern-news .et_builder_outer_content .item .testi {
    position: relative;
    bottom: auto;
  }
  .pattern-news .griglia .item .testi .testo, .pattern-news .griglia.libero .item .testi .testo, .pattern-news .griglia.blog .item .testi .testo, .pattern-news .et_builder_outer_content .item .testi .testo {
    opacity: 1;
    transform: none;
    max-height: initial;
  }
  .pattern-news .griglia.evidenza .item:nth-child(1), .pattern-news .griglia.misto .item:nth-child(1), .pattern-news .griglia.colonne .item:nth-child(1) {
    grid-column: 1;
    grid-row: 1;
  }
  .pattern-news .griglia.evidenza .item:nth-child(1) .testi, .pattern-news .griglia.misto .item:nth-child(1) .testi, .pattern-news .griglia.colonne .item:nth-child(1) .testi {
    width: 40%;
  }
  .pattern-news .griglia.evidenza .item:nth-child(1) .image, .pattern-news .griglia.misto .item:nth-child(1) .image, .pattern-news .griglia.colonne .item:nth-child(1) .image {
    width: 50%;
    height: 100%;
    right: 0;
    top: 0;
    border-radius: 175px 25px 25px 0;
  }
  .pattern-news .griglia.evidenza .item:nth-child(1):hover .testi, .pattern-news .griglia.misto .item:nth-child(1):hover .testi, .pattern-news .griglia.colonne .item:nth-child(1):hover .testi {
    width: 56%;
  }
  .pattern-news .griglia.evidenza .item:nth-child(2), .pattern-news .griglia.misto .item:nth-child(2), .pattern-news .griglia.colonne .item:nth-child(2) {
    grid-column: 1;
    grid-row: 2;
  }
  .pattern-news .griglia.evidenza .item:nth-child(2) .testi, .pattern-news .griglia.misto .item:nth-child(2) .testi, .pattern-news .griglia.colonne .item:nth-child(2) .testi {
    width: 40%;
  }
  .pattern-news .griglia.evidenza .item:nth-child(2) .image, .pattern-news .griglia.misto .item:nth-child(2) .image, .pattern-news .griglia.colonne .item:nth-child(2) .image {
    width: 50%;
    height: 100%;
    right: 0;
    top: 0;
    border-radius: 175px 25px 25px 0;
  }
  .pattern-news .griglia.evidenza .item:nth-child(2):hover .testi, .pattern-news .griglia.misto .item:nth-child(2):hover .testi, .pattern-news .griglia.colonne .item:nth-child(2):hover .testi {
    width: 56%;
  }
  .pattern-news .griglia.evidenza .item:nth-child(3), .pattern-news .griglia.misto .item:nth-child(3), .pattern-news .griglia.colonne .item:nth-child(3) {
    grid-column: 1;
    grid-row: 3;
  }
  .pattern-news .griglia.evidenza .item:nth-child(3) .testi, .pattern-news .griglia.misto .item:nth-child(3) .testi, .pattern-news .griglia.colonne .item:nth-child(3) .testi {
    width: 40%;
  }
  .pattern-news .griglia.evidenza .item:nth-child(3) .image, .pattern-news .griglia.misto .item:nth-child(3) .image, .pattern-news .griglia.colonne .item:nth-child(3) .image {
    width: 50%;
    height: 100%;
    right: 0;
    top: 0;
    border-radius: 175px 25px 25px 0;
  }
  .pattern-news .griglia.evidenza .item:nth-child(3):hover .testi, .pattern-news .griglia.misto .item:nth-child(3):hover .testi, .pattern-news .griglia.colonne .item:nth-child(3):hover .testi {
    width: 56%;
  }
  .pattern-news .griglia.evidenza .item:nth-child(4), .pattern-news .griglia.evidenza .item:nth-child(5), .pattern-news .griglia.evidenza .item:nth-child(6), .pattern-news .griglia.misto .item:nth-child(4), .pattern-news .griglia.misto .item:nth-child(5), .pattern-news .griglia.misto .item:nth-child(6), .pattern-news .griglia.colonne .item:nth-child(4), .pattern-news .griglia.colonne .item:nth-child(5), .pattern-news .griglia.colonne .item:nth-child(6) {
    display: none;
  }
  .pattern-news .item.webinar .image .lang {
    right: 30px;
    top: 20px;
  }
  .pattern-news .item.webinar .image .action, .pattern-news .item.download .image .action {
    border-top-right-radius: 0;
    left: auto;
    right: 0;
    width: 80%;
  }
  .pattern-news .item.webinar .image .action .ico-webinar, .pattern-news .item.download .image .action .ico-webinar {
    font-size: 60px;
    top: auto;
    bottom: 0;
  }
  .pattern-news .griglia.infobox .item .image {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .pattern-news {
    padding-top: 60px;
    padding-bottom: 40px;
  }
  .pattern-news .griglia {
    display: block;
  }
  .pattern-news .item {
    min-height: 400px;
    padding: 0;
    margin-bottom: 20px;
  }
  .pattern-news .item .image-sfondo, .pattern-news .item .image-velina {
    display: none;
  }
  .pattern-news .item .image {
    width: 100%;
    height: auto;
    min-height: 250px;
    position: relative;
    right: auto;
    top: auto;
    border-radius: 0 25px 0px 104px;
  }
  .pattern-news .item .tipo {
    padding: 20px 20px 0 20px;
  }
  .pattern-news .item .data {
    padding: 0 20px;
  }
  .pattern-news .item .testi {
    position: relative;
    width: 100%;
    bottom: auto;
    padding: 20px;
  }
  .pattern-news .item .testi .testo {
    opacity: 1;
    transform: none;
    max-height: initial;
  }
  .pattern-news .item:hover {
    box-shadow: none;
    transition: none;
    background-color: #fff;
  }
  .pattern-news .item:hover .testi .testo {
    max-height: none;
  }
  .pattern-news .griglia .item, .pattern-news .griglia.libero .item, .pattern-news .griglia.blog .item, .pattern-news .et_builder_outer_content .item {
    min-height: 400px;
    padding: 0;
    margin-bottom: 20px;
  }
  .pattern-news .griglia .item .image-sfondo, .pattern-news .griglia .item .image-velina, .pattern-news .griglia.libero .item .image-sfondo, .pattern-news .griglia.libero .item .image-velina, .pattern-news .griglia.blog .item .image-sfondo, .pattern-news .griglia.blog .item .image-velina, .pattern-news .et_builder_outer_content .item .image-sfondo, .pattern-news .et_builder_outer_content .item .image-velina {
    display: none;
  }
  .pattern-news .griglia .item .image, .pattern-news .griglia.libero .item .image, .pattern-news .griglia.blog .item .image, .pattern-news .et_builder_outer_content .item .image {
    width: 100%;
    height: auto;
    min-height: 250px;
    position: relative;
    right: auto;
    top: auto;
    border-radius: 0 25px 0px 104px;
  }
  .pattern-news .griglia .item .tipo, .pattern-news .griglia.libero .item .tipo, .pattern-news .griglia.blog .item .tipo, .pattern-news .et_builder_outer_content .item .tipo {
    padding: 20px 20px 0 20px;
  }
  .pattern-news .griglia .item .data, .pattern-news .griglia.libero .item .data, .pattern-news .griglia.blog .item .data, .pattern-news .et_builder_outer_content .item .data {
    padding: 0 20px;
  }
  .pattern-news .griglia .item .testi, .pattern-news .griglia.libero .item .testi, .pattern-news .griglia.blog .item .testi, .pattern-news .et_builder_outer_content .item .testi {
    position: relative;
    width: 100%;
    bottom: auto;
    padding: 20px;
  }
  .pattern-news .griglia .item .testi .testo, .pattern-news .griglia.libero .item .testi .testo, .pattern-news .griglia.blog .item .testi .testo, .pattern-news .et_builder_outer_content .item .testi .testo {
    opacity: 1;
    transform: none;
    max-height: initial;
  }
  .pattern-news .griglia .item:hover, .pattern-news .griglia.libero .item:hover, .pattern-news .griglia.blog .item:hover, .pattern-news .et_builder_outer_content .item:hover {
    box-shadow: none;
    transition: none;
    background-color: #fff;
  }
  .pattern-news .griglia .item:hover .testi .testo, .pattern-news .griglia.libero .item:hover .testi .testo, .pattern-news .griglia.blog .item:hover .testi .testo, .pattern-news .et_builder_outer_content .item:hover .testi .testo {
    max-height: none;
  }
  .pattern-news .griglia.infobox .item {
    margin-bottom: 12px;
  }
  .pattern-news .item.webinar .image .action, .pattern-news .item.download .image .action {
    border-top-right-radius: 50px;
    width: 50%;
    left: 30px;
    right: auto;
  }
  .sfondo-grigio .pattern-news .item:hover {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
}
@media (max-width: 480px) {
  .pattern-news .item {
    min-height: 310px;
  }
}
/*
    Created on : 25-mar-2021, 9.47.15
    Author     : veronica
*/
/** OVERRIDE MENU **/
body.single-cpt_prodotti #page.site,
body.page-template-page-offerte #page.site,
body.page-template-page-offerte-child #page.site,
#page.site:has(main#primary.no-top) {
  margin-top: 0 !important;
}
body.single-cpt_prodotti #page.site #masthead.site-header .menu__header,
body.page-template-page-offerte #page.site #masthead.site-header .menu__header,
body.page-template-page-offerte-child #page.site #masthead.site-header .menu__header,
#page.site:has(main#primary.no-top) #masthead.site-header .menu__header {
  position: relative;
}

/* -------------------------------------------------- */
/*	PAGE PRODOTTO
/* -------------------------------------------------- */
#primary.site-main.prodotto #menu-prod {
  transition: all 0.35s ease-in-out;
  background: transparent;
  padding: 10px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
#primary.site-main.prodotto #menu-prod .icona {
  width: 52px;
  height: 52px;
}
#primary.site-main.prodotto #menu-prod .goToOverview {
  display: inline-flex;
  margin-bottom: 0;
  padding-bottom: 0;
}
#primary.site-main.prodotto #menu-prod .logoprodhide, #primary.site-main.prodotto #menu-prod .menumobileprod, #primary.site-main.prodotto #menu-prod #prodList {
  opacity: 0;
}
#primary.site-main.prodotto #menu-prod .list-group {
  border: 0;
}
#primary.site-main.prodotto #menu-prod .list-group .list-group-item {
  border: 0;
  background: none;
  color: #00225A;
  font-size: 18px;
  font-weight: 400;
  padding-right: 20px;
  white-space: nowrap;
}
#primary.site-main.prodotto #menu-prod .list-group .list-group-item:hover {
  color: #00225A;
  font-weight: bold;
  text-decoration: none;
}
#primary.site-main.prodotto #menu-prod .list-group .list-group-item.active {
  background: none;
  font-weight: bold;
}
#primary.site-main.prodotto #menu-prod .list-group a.pulsante {
  white-space: nowrap;
  padding: 6px 15px;
  font-size: 16px;
}
#primary.site-main.prodotto #menu-prod.compresso .goToOverview {
  opacity: 0;
}
#primary.site-main.prodotto #menu-prod.mostra {
  background: #fff;
}
#primary.site-main.prodotto #menu-prod.mostra #prodList, #primary.site-main.prodotto #menu-prod.mostra .goToOverview {
  opacity: 1;
}
#primary.site-main.prodotto .tab-content {
  margin-top: -83px;
}
#primary.site-main.prodotto #intro {
  position: relative;
  /*        background-image: url('../img/prodotto/prod_bkg_lg.svg');
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center top;*/
}
#primary.site-main.prodotto #intro .el-intro {
  padding-top: 190px;
  padding-bottom: 160px;
}
#primary.site-main.prodotto #intro .el-grafico img {
  /*            -webkit-box-shadow: 0px 20px 85px -45px rgba(0,34,90,0.77);
              -moz-box-shadow: 0px 20px 85px -45px rgba(0,34,90,0.77);
              box-shadow: 0px 20px 85px -45px rgba(0,34,90,0.77);
              transition: all .2s ease-in-out;*/
}
#primary.site-main.prodotto #intro .logo-grafico {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center top;
}
#primary.site-main.prodotto #intro .subhead {
  color: #00225A;
  padding-bottom: 50px;
}
#primary.site-main.prodotto #intro .embed-video {
  border: 30px solid #00225A;
  border-radius: 30px;
}
#primary.site-main.prodotto #intro .play-video {
  cursor: pointer;
  position: absolute;
  width: 100%;
  left: 0;
  top: 45%;
  text-align: center;
  font-size: 90px;
}
#primary.site-main.prodotto #intro .play-video a {
  color: #004182;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
}
#primary.site-main.prodotto #intro .play-video a:focus {
  outline: 0;
}
#primary.site-main.prodotto #intro .play-video a:visited {
  color: #004182;
}
#primary.site-main.prodotto #intro .play-video a:hover {
  color: #ef5a26;
  text-decoration: underline;
}
#primary.site-main.prodotto #intro .play-video .play-video-tag {
  color: #004182;
  cursor: pointer;
}
#primary.site-main.prodotto #intro .play-video .play-video-tag:hover {
  color: #ef5a26;
}
#primary.site-main.prodotto #punti {
  background-image: url("../img/prodotto/3-la-migliore-acc.svg");
  background-size: cover;
  background-position: center;
}
#primary.site-main.prodotto #integrata {
  background-color: #D5DCE8;
  background-image: url("../img/prodotto/4-integrata-con.svg");
  background-size: cover;
  background-position: center;
}
#primary.site-main.prodotto #integrata .griglia {
  display: grid;
  grid-gap: 45px;
  grid-template-columns: repeat(5, 1fr);
}
#primary.site-main.prodotto #integrata .griglia .item {
  justify-self: center;
  display: flex;
}
#primary.site-main.prodotto #integrata .griglia .item .cerchio {
  display: flex;
  align-items: center;
  width: 176px;
  height: 176px;
  max-width: 176px;
  max-height: 176px;
  border-radius: 50%;
  background-color: #fff;
}
#primary.site-main.prodotto #integrata .griglia .item .cerchio.dis {
  background-color: #E9EDF3;
}
#primary.site-main.prodotto #dettagli {
  background-image: url("../img/prodotto/5-dettagli-che-fanno.svg");
  background-size: cover;
  background-position: center;
  background-color: #004182;
}
#primary.site-main.prodotto #dettagli .main-lite {
  /*            .more {
                  display: none;
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  background: #fff;
                  align-items: center;
                  border-radius: 30px;
                  .item {
                      max-height: 100%;
                      justify-content: center;
                      display: flex;
                      img {
                          max-height: 510px;
                          width: auto;
                      }
                  }

                  .chiudi {
                      position: absolute;
                      right: 30px;
                      top: 30px;
                      font-size: 24px;
                      z-index: 100;
                  }
              }
              .more.mostra {
                  display: flex;
              }*/
}
#primary.site-main.prodotto #dettagli .main-lite .row {
  margin-bottom: 50px;
  position: relative;
}
#primary.site-main.prodotto #dettagli .main-lite .contenuto {
  min-height: 600px;
  background: #fff;
  border-radius: 0 30px 30px 0;
  padding: 50px 40px;
  display: flex;
  align-items: center;
}
#primary.site-main.prodotto #dettagli .main-lite .contenuto h2, #primary.site-main.prodotto #dettagli .main-lite .contenuto h3 {
  color: #00225A;
}
#primary.site-main.prodotto #dettagli .main-lite .contenuto .testo {
  padding-top: 10px;
  font-size: 26px;
}
#primary.site-main.prodotto #dettagli .main-lite .contenuto .scopri {
  padding-top: 30px;
}
#primary.site-main.prodotto #dettagli .main-lite .contenuto .scopri .scopri-link {
  color: #00225A;
  cursor: pointer;
  font-weight: 800;
}
#primary.site-main.prodotto #dettagli .main-lite .contenuto.revert {
  border-radius: 30px 0 0 30px;
}
#primary.site-main.prodotto #dettagli .main-lite .grafica {
  background-position: center;
  background-size: cover;
  min-height: 600px;
  border-radius: 30px 0 0 30px;
}
#primary.site-main.prodotto #dettagli .main-lite .grafica .video-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  opacity: 1;
  transition: opacity 0.2s;
}
#primary.site-main.prodotto #dettagli .main-lite .grafica .video-wrapper video {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 30px 0 0 30px;
}
#primary.site-main.prodotto #dettagli .main-lite .grafica .video-wrapper .playback-control-container {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  text-align: center;
  z-index: 3;
  bottom: 50px;
}
#primary.site-main.prodotto #dettagli .main-lite .grafica .video-wrapper .playback-control-container .playback-control {
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
#primary.site-main.prodotto #dettagli .main-lite .grafica .video-wrapper .playback-control-container .playback-control:focus {
  opacity: 1;
}
#primary.site-main.prodotto #dettagli .main-lite .grafica .video-wrapper .playback-control-container .playback-control {
  display: block;
  position: relative;
  z-index: 2;
  background-repeat: no-repeat;
  margin: auto;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  background: transparent;
  border: 0;
  outline: none;
}
#primary.site-main.prodotto #dettagli .main-lite .grafica .video-wrapper .playback-control-container .playback-control.is-paused {
  width: 36px;
  height: 36px;
  background-size: 36px 36px;
  background-repeat: no-repeat;
  background-image: url("../img/feature/play.png");
}
#primary.site-main.prodotto #dettagli .main-lite .grafica .video-wrapper .playback-control-container .playback-control.is-playing {
  width: 36px;
  height: 36px;
  background-size: 36px 36px;
  background-repeat: no-repeat;
  background-image: url("../img/feature/stop.png");
}
#primary.site-main.prodotto #dettagli .main-lite .grafica .video-wrapper:hover .playback-control {
  opacity: 1;
}
#primary.site-main.prodotto #dettagli .main-lite .grafica .video-wrapper.nascondi {
  opacity: 0;
}
#primary.site-main.prodotto #dettagli .main-lite .grafica.revert {
  border-radius: 0 30px 30px 0;
}
#primary.site-main.prodotto #dettagli .main-lite .grafica.revert .video-wrapper video {
  border-radius: 0 30px 30px 0;
}
#primary.site-main.prodotto #dettagli .main-lite .grafica.revert .more {
  border-radius: 0 30px 30px 0;
}
#primary.site-main.prodotto #dettagli .main-lite .modal-content {
  border: 0;
}
#primary.site-main.prodotto #dettagli .main-lite .modal-content .modal-header {
  border: 0;
  padding-left: 30px;
  padding-right: 30px;
}
#primary.site-main.prodotto #dettagli .main-lite .modal-content .modal-body {
  border: 0;
  padding-left: 30px;
  padding-right: 30px;
}
#primary.site-main.prodotto #storie {
  background-image: url("../img/prodotto/6-storie-di-aperto.svg");
  background-size: cover;
  background-position: center;
  background-color: #E9EDF3;
}
#primary.site-main.prodotto #storie .lista .item {
  background-color: #fff;
  cursor: pointer;
}
#primary.site-main.prodotto #storie .lista .item img {
  border-bottom-left-radius: 200px;
  width: 90%;
  float: right;
  border-top-right-radius: 25px;
}
#primary.site-main.prodotto #storie .lista .item h3, #primary.site-main.prodotto #storie .lista .item h4 {
  padding: 12px 21px 10px 10%;
}
#primary.site-main.prodotto #storie .approfondimento {
  padding-top: 40px;
  display: none;
}
#primary.site-main.prodotto #storie .approfondimento .item {
  padding: 50px;
  background-color: #fff;
}
#primary.site-main.prodotto #storie .approfondimento .item .sinistra {
  height: 100%;
  color: #00225A;
  border-right: 1px solid #ccc;
  font-size: 22px;
  font-weight: 400;
}
#primary.site-main.prodotto #storie .approfondimento .item .sinistra .introimg {
  display: none;
  border-bottom-left-radius: 200px;
  width: 90%;
  float: right;
  border-top-right-radius: 25px;
  width: 45%;
}
#primary.site-main.prodotto #storie .approfondimento .item .sinistra a {
  color: #00225A;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
}
#primary.site-main.prodotto #storie .approfondimento .item .sinistra a:focus {
  outline: 0;
}
#primary.site-main.prodotto #storie .approfondimento .item .sinistra a:visited {
  color: #00225A;
}
#primary.site-main.prodotto #storie .approfondimento .item .sinistra a:hover {
  color: #00225A;
  text-decoration: underline;
}
#primary.site-main.prodotto #storie .approfondimento .item .sinistra ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
#primary.site-main.prodotto #storie .approfondimento .item .sinistra ul li {
  padding-bottom: 0;
  line-height: 1;
}
#primary.site-main.prodotto #storie .approfondimento .item .sinistra .logo {
  max-width: 125px;
  height: auto;
  padding-bottom: 20px;
}
#primary.site-main.prodotto #storie .approfondimento .item .sinistra .settore, #primary.site-main.prodotto #storie .approfondimento .item .sinistra .website, #primary.site-main.prodotto #storie .approfondimento .item .sinistra .piattaforma {
  font-size: 18px;
}
#primary.site-main.prodotto #storie .approfondimento .item .contenuto {
  height: 100%;
}
#primary.site-main.prodotto #storie .approfondimento .item .contenuto .testo {
  font-size: 18px;
  padding-left: 20px;
}
#primary.site-main.prodotto #storie .approfondimento .item .contenuto .imgbottom {
  display: none;
  padding-top: 20px;
}
#primary.site-main.prodotto #prodotti {
  background-image: url("../img/prodotto/7-ottieni-il-massimo.svg");
  background-size: cover;
  background-position: center;
}
#primary.site-main.prodotto #assistenza {
  background-image: url("../img/prodotto/8-imagiclecare.svg");
  background-size: cover;
  background-position: center;
}
#primary.site-main.prodotto #risorse {
  background-image: url("../img/prodotto/9-risorse.svg");
  background-size: cover;
  background-position: center;
}
#primary.site-main.prodotto #risorse .pattern-news {
  padding-bottom: 50px;
}
#primary.site-main.prodotto #tech {
  background-color: #E9EDF3;
  background-image: url("../img/prodotto/10-caratteristiche-architettura.svg");
  background-size: cover;
  padding-top: 180px;
  background-position: center top;
}
#primary.site-main.prodotto #tech .card .intestazione a:before {
  padding-right: 30px;
  display: inline-block;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: Font Awesome\ 5 Pro;
  font-weight: 400;
}
#primary.site-main.prodotto #tech .card .intestazione a[aria-expanded=false]:before {
  content: "\f067";
}
#primary.site-main.prodotto #tech .card .intestazione a[aria-expanded=true]:before {
  content: "\f068";
}
#primary.site-main.prodotto #tech table th, #primary.site-main.prodotto #tech table td {
  padding-right: 30px;
}
#primary.site-main.prodotto #tech table th:last-of-type, #primary.site-main.prodotto #tech table td:last-of-type {
  padding-right: 0;
}
#primary.site-main.prodotto #tab-resources {
  padding-top: 83px;
}
#primary.site-main.prodotto #blog {
  background-image: url("../img/prodotto/11-dal-blog.svg");
  background-size: cover;
  background-position: center;
}
#primary.site-main.prodotto #blog .pattern-news {
  padding-top: 60px;
  padding-bottom: 50px;
}
#primary.site-main.prodotto #blog .pattern-news .griglia.classico {
  height: 310px;
}
#primary.site-main.prodotto #webinar {
  background-color: #E9EDF3;
  background-image: url("../img/prodotto/12-webinar.svg");
  background-size: cover;
  background-position: center;
}
#primary.site-main.prodotto #webinar .pattern-news {
  background-image: none;
  padding-bottom: 50px;
}
#primary.site-main.prodotto #whitepaper {
  background-color: #E9EDF3;
  background-image: url("../img/prodotto/13-white-paper-rev1.svg");
  background-size: cover;
  background-position: center;
}
#primary.site-main.prodotto #whitepaper .pattern-news {
  background-image: none;
  padding-bottom: 50px;
}
#primary.site-main.prodotto #faq {
  background-color: #E9EDF3;
  background-image: url("../img/prodotto/14-faq.svg");
  background-size: 100%;
  background-position: top;
  background-repeat: no-repeat;
}
#primary.site-main.prodotto #faq .lista .item {
  background: #fff;
  padding: 20px 30px;
  margin-bottom: 10px;
  border-radius: 35px;
}
#primary.site-main.prodotto #faq .lista .item .domanda {
  padding-bottom: 0;
  font-weight: bold;
  color: #00225A;
  display: grid;
  grid-template-columns: 90% 10%;
  align-items: center;
}
#primary.site-main.prodotto #faq .lista .item .domanda:after {
  display: flex;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  align-self: flex-start;
  justify-content: end;
}
#primary.site-main.prodotto #faq .lista .item .domanda[aria-expanded=false]::after {
  content: "\f067";
}
#primary.site-main.prodotto #faq .lista .item .domanda[aria-expanded=true]::after {
  content: "\f068";
}
#primary.site-main.prodotto #faq .lista .item .risposta {
  padding-top: 15px;
}
#primary.site-main.prodotto .punti-elenco .inner {
  background: #fff;
  padding: 50px;
}
#primary.site-main.prodotto .punti-elenco .inner .testo h3 {
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 30px;
}
#primary.site-main.prodotto .punti-elenco .inner .lista {
  padding-top: 40px;
}
#primary.site-main.prodotto .punti-elenco .inner .lista .item {
  border-top: 1px solid #ccc;
  padding: 15px 0;
}
#primary.site-main.prodotto .punti-elenco .inner .lista .item .testo-lista p {
  padding-bottom: 0;
}
#primary.site-main.prodotto .punti-elenco .inner .galleria {
  padding-top: 40px;
}

/*
    MOBILE
*/
@media (max-width: 1800px) {
  #primary.site-main.prodotto #intro .logo-grafico {
    background-size: 110%;
  }
  #primary.site-main.prodotto #punti {
    background-position: left;
  }
}
@media (max-width: 1650px) {
  #primary.site-main.prodotto #intro .logo-grafico {
    background-size: 120%;
  }
}
@media (max-width: 1500px) {
  #primary.site-main.prodotto #intro .logo-grafico {
    background-size: 130%;
  }
}
@media (max-width: 1350px) {
  #primary.site-main.prodotto #intro .logo-grafico {
    background-size: 140%;
  }
}
@media (max-width: 1199px) {
  #primary.site-main.prodotto #intro .el-intro {
    padding-top: 140px;
    padding-bottom: 70px;
  }
  #primary.site-main.prodotto #integrata .griglia .item .cerchio {
    width: 170px;
    height: 170px;
  }
  #primary.site-main.prodotto #dettagli .main-lite .contenuto, #primary.site-main.prodotto #dettagli .main-lite .grafica {
    min-height: 504px;
  }
  #primary.site-main.prodotto #integrata .griglia {
    grid-gap: 30px 0;
  }
}
@media (max-width: 1150px) {
  #primary.site-main.prodotto #intro .logo-grafico {
    background-size: 170%;
  }
}
@media (max-width: 991px) {
  #primary.site-main.prodotto #menu-prod {
    background-color: #fff;
  }
  #primary.site-main.prodotto #menu-prod .submenuprod {
    display: none;
  }
  #primary.site-main.prodotto #menu-prod .submenuprod .list-group-horizontal {
    flex-direction: column;
  }
  #primary.site-main.prodotto #menu-prod .submenuprod .list-group {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  #primary.site-main.prodotto #menu-prod .submenuprod .list-group .list-group-item {
    padding: 10px 0 10px 0;
    border-bottom: 1px solid #ededed;
  }
  #primary.site-main.prodotto #menu-prod .submenuprod .list-group .list-group-item:nth-of-type(3) {
    border-bottom: 0;
  }
  #primary.site-main.prodotto #menu-prod .submenuprod .list-group a.pulsante {
    margin-top: 30px;
  }
  #primary.site-main.prodotto #menu-prod .menumobileprod {
    opacity: 1;
    cursor: pointer;
    color: #00225A;
    transform: none;
    transition: transform 0.5s ease-in-out;
  }
  #primary.site-main.prodotto #menu-prod.open .menumobileprod {
    transform: rotate(180deg);
  }
  #primary.site-main.prodotto #menu-prod.open .submenuprod #prodList {
    opacity: 1;
  }
  #primary.site-main.prodotto #menu-prod.mostra {
    background: #fff;
  }
  #primary.site-main.prodotto #menu-prod.mostra .menumobileprod {
    display: block;
  }
  #primary.site-main.prodotto #menu-prod.mostra .menumobileprod, #primary.site-main.prodotto #menu-prod.mostra #prodList {
    opacity: 1;
  }
  #primary.site-main.prodotto .tab-content {
    margin-top: 0;
  }
  #primary.site-main.prodotto #intro .logo-grafico {
    background-size: contain;
  }
  #primary.site-main.prodotto #intro .play-video {
    top: 40%;
    font-size: 70px;
  }
  #primary.site-main.prodotto #integrata .griglia {
    grid-gap: 20px 0;
  }
  #primary.site-main.prodotto #integrata .griglia .item .cerchio {
    width: 130px;
    height: 130px;
  }
  #primary.site-main.prodotto #tech {
    padding-top: 100px;
  }
  #primary.site-main.prodotto #tab-resources {
    padding-top: 0;
  }
  #primary.site-main.prodotto #dettagli .main-lite .contenuto, #primary.site-main.prodotto #dettagli .main-lite .contenuto.revert {
    border-radius: 0 0 30px 30px;
    min-height: 480px;
  }
  #primary.site-main.prodotto #dettagli .main-lite .grafica, #primary.site-main.prodotto #dettagli .main-lite .grafica.revert {
    border-radius: 30px 30px 0 0;
    min-height: 480px;
  }
  #primary.site-main.prodotto #dettagli .main-lite .grafica .video-wrapper video, #primary.site-main.prodotto #dettagli .main-lite .grafica.revert .video-wrapper video {
    border-radius: 30px 30px 0 0;
  }
}
@media (max-width: 768px) {
  #primary.site-main.prodotto #menu-prod .goToOverview {
    font-size: 20px;
  }
  #primary.site-main.prodotto #menu-prod .icona {
    width: 42px;
    height: 42px;
  }
  #primary.site-main.prodotto #menu-prod .list-group .list-group-item {
    padding-left: 0;
    padding-right: 15px;
  }
  #primary.site-main.prodotto #menu-prod .list-group a.pulsante {
    min-width: auto;
  }
  #primary.site-main.prodotto #intro .el-intro {
    padding-top: 120px;
    padding-bottom: 50px;
  }
}
@media (max-width: 767px) {
  #primary.site-main.prodotto #intro .el-intro {
    padding-top: 290px;
    padding-bottom: 50px;
  }
  #primary.site-main.prodotto #intro .logo-grafico {
    /*            background-image: url('../img/prodotto/prod_bkg_sm.svg'); */
    background-size: cover;
  }
  #primary.site-main.prodotto #menu-prod {
    padding: 10px 0;
  }
  #primary.site-main.prodotto #integrata .griglia {
    grid-gap: 18px;
    grid-template-columns: repeat(4, 1fr);
  }
  #primary.site-main.prodotto #integrata .griglia .item .cerchio {
    width: 116px;
    height: 100%;
  }
  #primary.site-main.prodotto #dettagli .main-lite .contenuto, #primary.site-main.prodotto #dettagli .main-lite .contenuto.revert {
    min-height: 428px;
  }
  #primary.site-main.prodotto #dettagli .main-lite .grafica, #primary.site-main.prodotto #dettagli .main-lite .grafica.revert {
    min-height: 428px;
  }
  #primary.site-main.prodotto #risorse .pattern-news, #primary.site-main.prodotto #blog .pattern-news, #primary.site-main.prodotto #webinar .pattern-news, #primary.site-main.prodotto #whitepaper .pattern-news {
    padding-top: 30px;
    padding-bottom: 20px;
  }
}
@media (max-width: 575px) {
  #primary.site-main.prodotto .tab-content {
    margin-top: 0;
  }
  #primary.site-main.prodotto #tab-resources {
    padding-top: 0;
  }
  #primary.site-main.prodotto #tech {
    padding-top: 60px;
  }
  #primary.site-main.prodotto #dettagli .main-lite .contenuto, #primary.site-main.prodotto #dettagli .main-lite .contenuto.revert {
    min-height: 442px;
  }
  #primary.site-main.prodotto #dettagli .main-lite .grafica, #primary.site-main.prodotto #dettagli .main-lite .grafica.revert {
    min-height: 442px;
  }
  #primary.site-main.prodotto #integrata .griglia {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 480px) {
  #primary.site-main.prodotto #dettagli .main-lite .contenuto, #primary.site-main.prodotto #dettagli .main-lite .contenuto.revert {
    min-height: 362px;
  }
  #primary.site-main.prodotto #dettagli .main-lite .grafica, #primary.site-main.prodotto #dettagli .main-lite .grafica.revert {
    min-height: 362px;
  }
  #primary.site-main.prodotto #intro .play-video {
    top: 32%;
    font-size: 60px;
  }
  #primary.site-main.prodotto #intro .logo-grafico {
    background-position: 0 -15px;
  }
  #primary.site-main.prodotto #intro .el-intro {
    padding-top: 200px;
    padding-bottom: 10px;
  }
}
@media (max-width: 420px) {
  #primary.site-main.prodotto #dettagli .main-lite .grafica, #primary.site-main.prodotto #dettagli .main-lite .grafica.revert {
    min-height: 322px;
  }
}
@media (max-width: 375px) {
  #primary.site-main.prodotto #dettagli .main-lite .grafica, #primary.site-main.prodotto #dettagli .main-lite .grafica.revert {
    min-height: 285px;
  }
  #primary.site-main.prodotto #integrata .griglia {
    grid-gap: 10px;
    grid-template-columns: repeat(2, 1fr);
  }
}
/*
    Created on : 25-mar-2021, 9.47.15
    Author     : veronica
*/
/** OVERRIDE MENU **/
body.single-cpt_suite #page.site, body.page-template-page-platform #page.site {
  margin-top: 0 !important;
}
body.single-cpt_suite #page.site #masthead.site-header .menu__header, body.page-template-page-platform #page.site #masthead.site-header .menu__header {
  position: relative;
}

/* -------------------------------------------------- */
/*	PAGE PRODOTTO
/* -------------------------------------------------- */
#primary.site-main.prodotto.suite {
  /*
      display: flex;
  flex-grow: 1;
  flex-basis: 280px;
  margin-bottom: 18px;
  max-width: 280px;
  */
}
#primary.site-main.prodotto.suite .top {
  background-position: center;
  background-color: #E9EDF3;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: calc(100vh - 68px);
  position: relative;
  overflow: hidden;
  /* Style the video: 100% width and height to cover the entire window */
  /* Add some content at the bottom of the video/page */
}
#primary.site-main.prodotto.suite .top .myVideo {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: relative;
  right: 0;
  bottom: 0;
  /*            min-width: 100%;
              min-height: 100%;*/
}
#primary.site-main.prodotto.suite .top .content {
  position: absolute;
  top: 120px;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  /*            img {
                  opacity: 0.5;
              }*/
}
#primary.site-main.prodotto.suite .top .content h1 {
  -webkit-animation-delay: 10s;
  animation-delay: 10s;
  -webkit-animation-delay: calc(var(--animate-delay) * 10);
  animation-delay: calc(var(--animate-delay) * 10);
}
#primary.site-main.prodotto.suite .top .content .subhead {
  padding-bottom: 50px;
  -webkit-animation-delay: 13s;
  animation-delay: 13s;
  -webkit-animation-delay: calc(var(--animate-delay) * 13);
  animation-delay: calc(var(--animate-delay) * 13);
}
#primary.site-main.prodotto.suite .top .content .azioni {
  -webkit-animation-delay: 13.5s;
  animation-delay: 13.5s;
  -webkit-animation-delay: calc(var(--animate-delay) * 13.5);
  animation-delay: calc(var(--animate-delay) * 13.5);
}
#primary.site-main.prodotto.suite .top .content .play-video {
  cursor: pointer;
  position: absolute;
  width: 100%;
  left: 0;
  top: 45%;
  text-align: center;
}
#primary.site-main.prodotto.suite .top .content .play-video a {
  color: #004182;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
}
#primary.site-main.prodotto.suite .top .content .play-video a:focus {
  outline: 0;
}
#primary.site-main.prodotto.suite .top .content .play-video a:visited {
  color: #004182;
}
#primary.site-main.prodotto.suite .top .content .play-video a:hover {
  color: #ef5a26;
  text-decoration: underline;
}
#primary.site-main.prodotto.suite .top .content .play-video .play-video-tag {
  color: #004182;
  cursor: pointer;
}
#primary.site-main.prodotto.suite .top .content .play-video .play-video-tag:hover {
  color: #ef5a26;
}
#primary.site-main.prodotto.suite .top .content .fancybox-video {
  background: transparent;
  border: 0;
  display: block;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%;
  height: 56.25%;
}
#primary.site-main.prodotto.suite .top .content .fancybox-slide--video .fancybox-content {
  background: transparent;
}
#primary.site-main.prodotto.suite .top .hero__scroll {
  position: absolute;
  bottom: 30px;
  width: 200px;
  margin: auto;
  display: block;
  cursor: pointer;
  padding-bottom: 40px;
  left: 0;
  right: 0;
  text-transform: uppercase;
  -webkit-animation-delay: 5s;
  animation-delay: 5s;
  font-size: 60px;
  text-align: center;
  -webkit-animation-delay: calc(var(--animate-delay) * 5);
  animation-delay: calc(var(--animate-delay) * 5);
}
#primary.site-main.prodotto.suite .top .hero__scroll .chevron {
  margin-top: 20px;
  display: block;
  /*                -webkit-animation: pulse 2s infinite;
                  animation: pulse 2s infinite;*/
  color: #00225A;
}
#primary.site-main.prodotto.suite #intro {
  position: relative;
  /*        background-image: url('../img/prodotto/prod_bkg_lg.svg');
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center top;*/
}
#primary.site-main.prodotto.suite #intro .el-intro {
  padding-top: 190px;
  padding-bottom: 160px;
}
#primary.site-main.prodotto.suite #intro .logo-grafico {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center top;
}
#primary.site-main.prodotto.suite #intro .subhead {
  color: #00225A;
  padding-bottom: 50px;
}
#primary.site-main.prodotto.suite #intro .embed-video {
  border: 30px solid #00225A;
  border-radius: 30px;
}
#primary.site-main.prodotto.suite #intro .play-video {
  cursor: pointer;
  position: absolute;
  width: 100%;
  left: 0;
  top: 45%;
  text-align: center;
  font-size: 90px;
}
#primary.site-main.prodotto.suite #intro .play-video a {
  color: #004182;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
}
#primary.site-main.prodotto.suite #intro .play-video a:focus {
  outline: 0;
}
#primary.site-main.prodotto.suite #intro .play-video a:visited {
  color: #004182;
}
#primary.site-main.prodotto.suite #intro .play-video a:hover {
  color: #ef5a26;
  text-decoration: underline;
}
#primary.site-main.prodotto.suite #intro .play-video .play-video-tag {
  color: #004182;
  cursor: pointer;
}
#primary.site-main.prodotto.suite #intro .play-video .play-video-tag:hover {
  color: #ef5a26;
}
#primary.site-main.prodotto.suite .apps .griglia {
  display: flex;
  flex-wrap: wrap;
}
#primary.site-main.prodotto.suite .apps .griglia .item {
  display: flex;
  flex-grow: 1;
  flex-basis: 212px;
  margin-bottom: 18px;
  max-width: 234px;
}
#primary.site-main.prodotto.suite .apps .griglia .item .cerchio {
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 220px;
  height: 80px;
  max-width: 220px;
  max-height: 80px;
  border-radius: 25px;
  background-color: #fff;
  padding: 10px;
  border: 1px solid #E9EDF3;
  transform: translateY(0);
  transition: transform 0.5s, box-shadow 0.2s ease-in-out;
}
#primary.site-main.prodotto.suite .apps .griglia .item .cerchio .icona {
  width: 60px;
  height: 60px;
  margin-right: 12px;
}
#primary.site-main.prodotto.suite .apps .griglia .item .cerchio .nomeapp {
  font-size: 18px;
  font-weight: 300;
  padding: 0;
  margin: 0;
}
#primary.site-main.prodotto.suite .apps .griglia .item .cerchio:hover {
  -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  transform: translateY(-5px);
  -webkit-box-shadow: 0 15px 35px -15px rgba(0, 34, 90, 0.77);
  -moz-box-shadow: 0 15px 35px -15px rgba(0, 34, 90, 0.77);
  box-shadow: 0 15px 35px -15px rgba(0, 34, 90, 0.77);
}
#primary.site-main.prodotto.suite .apps .griglia .item .cerchio.dis {
  opacity: 0.65;
}
#primary.site-main.prodotto.suite #needs {
  background-color: #E9EDF3;
  background-image: url("../img/prodotto/14-faq.svg");
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
}
#primary.site-main.prodotto.suite #needs .apps {
  padding-top: 40px;
}
#primary.site-main.prodotto.suite #needs .lista-needs .item-need {
  background: #fff;
  padding: 20px 30px;
  margin-bottom: 10px;
  border-radius: 35px;
  -webkit-box-shadow: 0px 20px 85px -45px rgba(0, 34, 90, 0.77);
  -moz-box-shadow: 0px 20px 85px -45px rgba(0, 34, 90, 0.77);
  box-shadow: 0px 20px 85px -45px rgba(0, 34, 90, 0.77);
  transition: all 0.2s ease-in-out;
}
#primary.site-main.prodotto.suite #needs .lista-needs .item-need .domanda {
  padding-bottom: 0;
  font-weight: bold;
  color: #00225A;
}
#primary.site-main.prodotto.suite #needs .lista-needs .item-need .domanda:after {
  display: inline-block;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  float: right;
}
#primary.site-main.prodotto.suite #needs .lista-needs .item-need .domanda[aria-expanded=false]::after {
  content: "\f107";
}
#primary.site-main.prodotto.suite #needs .lista-needs .item-need .domanda[aria-expanded=true]::after {
  content: "\f106";
}
#primary.site-main.prodotto.suite #needs .lista-needs .item-need .risposta {
  padding-top: 40px;
}
#primary.site-main.prodotto.suite #infofaq {
  background-image: url("../img/suite/faq-elementi-grafici.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
#primary.site-main.prodotto.suite #infofaq .item {
  padding: 40px;
  background: #E9EDF3;
  color: #00225A;
}
#primary.site-main.prodotto.suite #infofaq .item .numero {
  font-family: "Gotham Rounded";
}
#primary.site-main.prodotto.suite #infofaq .item .risposta {
  font-size: 22px;
}
#primary.site-main.prodotto.suite #infofaq .item .info {
  color: #00C0F0;
  font-size: 40px;
}
#primary.site-main.prodotto.suite #infofaq .el-grafico {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
}
#primary.site-main.prodotto.suite #differenze .tabella {
  padding: 70px 50px;
  background: #fff;
}
#primary.site-main.prodotto.suite #differenze .tabella .row {
  border-bottom: 1px solid #c6cbd1;
}
#primary.site-main.prodotto.suite #differenze .tabella .intestazione, #primary.site-main.prodotto.suite #differenze .tabella .issue, #primary.site-main.prodotto.suite #differenze .tabella .cloud, #primary.site-main.prodotto.suite #differenze .tabella .other {
  padding: 10px 0;
}
#primary.site-main.prodotto.suite #differenze .tabella .intestazione {
  color: #00225A;
  font-family: "Gotham Rounded";
  font-size: 24px;
}
#primary.site-main.prodotto.suite #differenze .tabella .cloud {
  background-color: rgba(233, 237, 243, 0.2);
  border-left: 1px solid #E9EDF3;
  border-right: 1px solid #E9EDF3;
}
#primary.site-main.prodotto.suite #differenze .tabella .ok {
  color: #16DB7D;
}
#primary.site-main.prodotto.suite #differenze .tabella .ko {
  color: #FC2759;
}

/*
    MOBILE
*/
@media (max-width: 1199px) {
  #primary.site-main.prodotto.suite .apps .griglia .item .cerchio {
    width: 95%;
    max-width: unset;
  }
}
@media (max-width: 991px) {
  #primary.site-main.prodotto.suite .top {
    height: calc(100vh - 142px);
  }
  #primary.site-main.prodotto.suite .top .content {
    top: 60px;
  }
  #primary.site-main.prodotto.suite #differenze .tabella {
    padding: 50px;
  }
  #primary.site-main.prodotto.suite #differenze .tabella .cloud {
    background: none;
    border: none;
    padding-left: 10px;
  }
  #primary.site-main.prodotto.suite #differenze .tabella .issue {
    padding-bottom: 0;
    padding-left: 10px;
  }
  #primary.site-main.prodotto.suite #differenze .tabella .cloud .subtitle, #primary.site-main.prodotto.suite #differenze .tabella .other .subtitle {
    font-weight: bold;
  }
  #primary.site-main.prodotto.suite #differenze .tabella .row:nth-child(odd) {
    background-color: rgba(233, 237, 243, 0.2);
  }
}
@media (max-width: 768px) {
  #primary.site-main.prodotto.suite .top .hero__scroll {
    bottom: 0;
    padding-bottom: 40px;
  }
}
@media (max-width: 767px) {
  #primary.site-main.prodotto.suite .apps .griglia .item {
    flex-basis: 50%;
    max-width: 50%;
    justify-content: center;
  }
  #primary.site-main.prodotto.suite #differenze .tabella {
    padding: 30px;
  }
}
@media (max-width: 575px) {
  #primary.site-main.prodotto.suite .top {
    height: calc(100vh - 135px);
  }
  #primary.site-main.prodotto.suite .top .hero__scroll {
    bottom: 0;
    padding-bottom: 20px;
  }
  #primary.site-main.prodotto.suite #differenze .tabella {
    padding: 20px;
  }
  #primary.site-main.prodotto.suite #differenze .tabella .other {
    padding-left: 10px;
    padding-top: 0;
  }
}
@media (max-width: 480px) {
  #primary.site-main.prodotto.suite .top {
    height: calc(100vh - 113px);
  }
  #primary.site-main.prodotto.suite .apps .griglia .item {
    flex-basis: 100%;
    max-width: 100%;
  }
}
/*
    Created on : 31-ago-2020, 10.04.40
    Author     : veronica
*/
.navbar-reserved {
  padding: 24px 1rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 1000;
}
.navbar-reserved .navbar-toggler {
  padding: 0;
  border: 0;
  color: #00225A;
  outline: none;
}
.navbar-reserved submit {
  position: relative;
  border-radius: 25px;
  background: #00225A;
  color: #fff;
  border: 2px solid #00225A;
  box-shadow: none;
  text-shadow: none;
  text-decoration: none !important;
  vertical-align: middle;
  display: inline-block;
  width: auto;
  margin: 0;
  font-size: 88%;
  font-weight: 700;
  text-transform: uppercase;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  -o-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  padding: 10px 30px;
  cursor: pointer;
  min-width: 155px;
  text-align: center;
  padding: 6px 15px;
  font-size: 16px;
  font-weight: 600;
}
.navbar-reserved submit:visited {
  color: #fff;
}
.navbar-reserved submit:hover {
  font-weight: 700;
  background: transparent;
  color: #00225A;
  border-color: #00225A;
  box-shadow: none;
}
.navbar-reserved a {
  font-size: 16px;
  color: #00225A;
  text-decoration: none;
  font-weight: 600;
}
.navbar-reserved a:focus {
  outline: 0;
}
.navbar-reserved a:visited {
  color: #00225A;
}
.navbar-reserved a:hover {
  color: #00225A;
  text-decoration: none;
  font-weight: 600;
}
.navbar-reserved a {
  display: inline-block;
  position: relative;
}
.navbar-reserved a:before {
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  left: 50%;
  -webkit-transition: left 0.2s cubic-bezier(0.4, 0, 0.2, 1), width 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transition: left 0.2s cubic-bezier(0.4, 0, 0.2, 1), width 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  width: 0;
  height: 2px;
  margin-top: 2px;
  background: #00225A;
}
.navbar-reserved a:hover:before {
  left: 0;
  width: 100%;
}
.navbar-reserved ul {
  margin: 0;
}
.navbar-reserved li {
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 0;
}

#masthead.site-header .logo {
  width: 142px;
}
#masthead.site-header .iniziali {
  border-radius: 50%;
  background-color: #fff;
  color: #00225A;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  align-content: center;
  text-transform: uppercase;
  justify-content: center;
}
#masthead.site-header .menu__dimmed {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #00173c;
  opacity: 0;
  z-index: 2000;
  -webkit-transition: opacity 0.6s cubic-bezier(0.4, 0, 0.2, 1) 0.2s;
  transition: opacity 0.6s cubic-bezier(0.4, 0, 0.2, 1) 0.2s;
}
#masthead.site-header .menu__dimmed.open {
  display: block;
  opacity: 0.68;
  -webkit-transition: opacity 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  transition: opacity 0.6s cubic-bezier(0.4, 0, 0.2, 1);
}
#masthead.site-header .menu__header {
  z-index: 2100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
#masthead.site-header .menu__header .svg-blu {
  display: inline-block;
  width: auto;
  height: 18px;
}
#masthead.site-header .menu__header .svg path {
  fill: #fff;
}
#masthead.site-header .menu__header .svg-blu path {
  fill: #00225A;
}
#masthead.site-header .menu__header .menu__top {
  z-index: 2100;
  background: #00C0F0;
  color: #fff;
  position: relative;
  padding: 0 40px;
  /*            ul li.active {
                  border-bottom: 3px solid $txt_blu;
                  font-weight: bold;
                  transition: border 2s;
              }*/
}
#masthead.site-header .menu__header .menu__top .nav-link.mgc__depth1-link {
  font-weight: 600;
  font-size: 16px;
  color: #00225A;
}
#masthead.site-header .menu__header .menu__top .nav-link.mgc__depth1-link:hover {
  font-weight: 700;
}
#masthead.site-header .menu__header .menu__top a {
  font-weight: 600;
  font-size: 16px;
  color: #00225A;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  -webkit-transition-duration: 200ms;
  -moz-transition-duration: 200ms;
  -ms-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  text-decoration: none;
}
#masthead.site-header .menu__header .menu__top a:focus {
  outline: 0;
}
#masthead.site-header .menu__header .menu__top a:visited {
  color: #00225A;
}
#masthead.site-header .menu__header .menu__top a:hover {
  color: #00225A;
  text-decoration: none;
  font-weight: 700;
}
#masthead.site-header .menu__header .menu__top a.pulsante {
  position: relative;
  border-radius: 25px;
  background: #00225A;
  color: #fff;
  border: 2px solid #00225A;
  box-shadow: none;
  text-shadow: none;
  text-decoration: none !important;
  vertical-align: middle;
  display: inline-block;
  width: auto;
  margin: 0;
  font-size: 88%;
  font-weight: 700;
  text-transform: uppercase;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  -o-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  padding: 10px 30px;
  cursor: pointer;
  min-width: 155px;
  text-align: center;
  padding: 6px 15px;
  font-size: 16px;
}
#masthead.site-header .menu__header .menu__top a.pulsante:visited {
  color: #fff;
}
#masthead.site-header .menu__header .menu__top a.pulsante:hover {
  font-weight: 700;
  background: transparent;
  color: #00225A;
  border-color: #00225A;
  box-shadow: none;
}
#masthead.site-header .menu__header .menu__top ul {
  margin-left: 0;
}
#masthead.site-header .menu__header .menu__top ul li {
  padding: 20px 0;
  margin: 0;
  border-bottom: 3px solid transparent;
}
#masthead.site-header .menu__header .menu__top ul li.menu-pulsante {
  padding: 0;
  margin-left: 20px;
}
#masthead.site-header .menu__header .menu__top .mgc__depth1-link-text {
  display: inline-block;
  position: relative;
}
#masthead.site-header .menu__header .menu__top .mgc__depth1-link-text:before {
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  left: 50%;
  -webkit-transition: left 0.2s cubic-bezier(0.4, 0, 0.2, 1), width 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transition: left 0.2s cubic-bezier(0.4, 0, 0.2, 1), width 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  width: 0;
  height: 3px;
  margin-top: 28px;
  background: #00225A;
}
#masthead.site-header .menu__header .menu__top .mgc__depth1-link:hover .mgc__depth1-link-text, #masthead.site-header .menu__header .menu__top .mgc__depth1-link:focus .mgc__depth1-link-text {
  font-weight: bold;
}
#masthead.site-header .menu__header .menu__top .mgc__depth1-link:hover .mgc__depth1-link-text:before, #masthead.site-header .menu__header .menu__top .mgc__depth1-link:focus .mgc__depth1-link-text:before, #masthead.site-header .menu__header .menu__top ul li.active .mgc__depth1-link .mgc__depth1-link-text:before {
  left: 0;
  width: 100%;
}
#masthead.site-header .menu__header .menu__top ul.nav.dx .nav-item .nav-link {
  padding: 0;
  padding-left: 20px;
  font-size: 20px;
}
#masthead.site-header .menu__header .menu__top .header-nav-mobile__menu-button-wrapper {
  width: 20px;
  height: 15px;
  padding: 0;
  position: relative;
  cursor: pointer;
  border: 0;
  background: none;
  outline: none;
}
#masthead.site-header .menu__header .menu__top .header-nav-mobile__menu-button-wrapper .header-nav-mobile__menu-line {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  width: 100%;
  height: 2px;
  position: absolute;
  left: 0;
  top: 7.5px;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  -webkit-transition: -webkit-transform 0.3s, background 0.3s;
  -webkit-transition: transform 0.3s, background 0.3s;
  transition: transform 0.3s, background 0.3s;
  background-color: #00225A;
}
#masthead.site-header .menu__header .menu__top .header-nav-mobile__menu-button-wrapper .header-nav-mobile__menu-line--top {
  -webkit-transform: translateY(-7.5px);
  -ms-transform: translateY(-7.5px);
  transform: translateY(-7.5px);
}
#masthead.site-header .menu__header .menu__top .header-nav-mobile__menu-button-wrapper .header-nav-mobile__menu-line--middle {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY();
}
#masthead.site-header .menu__header .menu__top .header-nav-mobile__menu-button-wrapper .header-nav-mobile__menu-line--bottom {
  -webkit-transform: translateY(7.5px);
  -ms-transform: translateY(7.5px);
  transform: translateY(7.5px);
}
#masthead.site-header .menu__header .menu__top #menu-mobile.active .header-nav-mobile__menu-button-wrapper .header-nav-mobile__menu-line--top {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
#masthead.site-header .menu__header .menu__top #menu-mobile.active .header-nav-mobile__menu-button-wrapper .header-nav-mobile__menu-line--middle {
  display: none;
}
#masthead.site-header .menu__header .menu__top #menu-mobile.active .header-nav-mobile__menu-button-wrapper .header-nav-mobile__menu-line--bottom {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
#masthead.site-header .menu__header #menul1imagicle.menuclose {
  margin-right: -5000px;
  transition: 1s;
}
#masthead.site-header .menu__header #menul1imagicle {
  transition: 1s;
  margin-right: 0px;
}
#masthead.site-header .menu__header .menu__bottom {
  position: absolute;
  top: 79px;
  width: 100%;
  overflow: hidden;
  z-index: 2100;
  background: #fff;
  color: #00225A;
  height: 0;
  -webkit-transition-property: height;
  transition-property: height;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}
#masthead.site-header .menu__header .menu__bottom h2, #masthead.site-header .menu__header .menu__bottom .menu-title {
  font-weight: bold;
  font-size: 121%;
  padding-top: 0;
}
#masthead.site-header .menu__header .menu__bottom .submenu {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  padding: 40px 0;
  overflow: hidden;
  transition: visibility 0s, opacity 2s linear;
  -webkit-transform: translateY(-30px);
  transform: translateY(-30px);
  -webkit-transition: opacity 0.4s cubic-bezier(0.4, 0, 0.2, 1) 0.1s, -webkit-transform 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0.1s;
  transition: opacity 0.4s cubic-bezier(0.4, 0, 0.2, 1) 0.1s, -webkit-transform 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0.1s;
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0.1s, opacity 0.4s cubic-bezier(0.4, 0, 0.2, 1) 0.1s;
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0.1s, opacity 0.4s cubic-bezier(0.4, 0, 0.2, 1) 0.1s, -webkit-transform 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0.1s;
}
#masthead.site-header .menu__header .menu__bottom .submenu .menuinner {
  padding-left: 15px;
  border-right: 1px solid #E9EDF3;
}
#masthead.site-header .menu__header .menu__bottom .submenu ul, #masthead.site-header .menu__header .menu__bottom .submenu ul.nav {
  margin-left: 0;
  list-style: none;
}
#masthead.site-header .menu__header .menu__bottom .submenu ul li, #masthead.site-header .menu__header .menu__bottom .submenu ul.nav li {
  padding-bottom: 13px;
  margin-bottom: 0px;
}
#masthead.site-header .menu__header .menu__bottom .submenu ul li a, #masthead.site-header .menu__header .menu__bottom .submenu ul.nav li a {
  color: #00225A;
  text-decoration: none;
  font-weight: 400;
}
#masthead.site-header .menu__header .menu__bottom .submenu ul li a:focus, #masthead.site-header .menu__header .menu__bottom .submenu ul.nav li a:focus {
  outline: 0;
}
#masthead.site-header .menu__header .menu__bottom .submenu ul li a:visited, #masthead.site-header .menu__header .menu__bottom .submenu ul.nav li a:visited {
  color: #00225A;
}
#masthead.site-header .menu__header .menu__bottom .submenu ul li a:hover, #masthead.site-header .menu__header .menu__bottom .submenu ul.nav li a:hover {
  color: #00225A;
  text-decoration: none;
  font-weight: 400;
}
#masthead.site-header .menu__header .menu__bottom .submenu ul li .mgc__depth3-link-text, #masthead.site-header .menu__header .menu__bottom .submenu ul.nav li .mgc__depth3-link-text {
  display: inline-block;
  position: relative;
}
#masthead.site-header .menu__header .menu__bottom .submenu ul li .mgc__depth3-link-text:before, #masthead.site-header .menu__header .menu__bottom .submenu ul.nav li .mgc__depth3-link-text:before {
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  left: 50%;
  -webkit-transition: left 0.2s cubic-bezier(0.4, 0, 0.2, 1), width 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transition: left 0.2s cubic-bezier(0.4, 0, 0.2, 1), width 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  width: 0;
  height: 2px;
  margin-top: 2px;
  background: #00225A;
}
#masthead.site-header .menu__header .menu__bottom .submenu ul li .mgc__depth3-link:hover .mgc__depth3-link-text:before, #masthead.site-header .menu__header .menu__bottom .submenu ul li .mgc__depth3-link:focus .mgc__depth3-link-text:before, #masthead.site-header .menu__header .menu__bottom .submenu ul.nav li .mgc__depth3-link:hover .mgc__depth3-link-text:before, #masthead.site-header .menu__header .menu__bottom .submenu ul.nav li .mgc__depth3-link:focus .mgc__depth3-link-text:before {
  left: 0;
  width: 100%;
}
#masthead.site-header .menu__header .menu__bottom .submenu ul li.spazio-top, #masthead.site-header .menu__header .menu__bottom .submenu ul.nav li.spazio-top {
  padding-top: 10px;
}
#masthead.site-header .menu__header .menu__bottom .submenu .article {
  cursor: pointer;
}
#masthead.site-header .menu__header .menu__bottom .submenu .article .tipo {
  padding-top: 15px;
  font-weight: bold;
  z-index: 300;
  position: relative;
}
#masthead.site-header .menu__header .menu__bottom .submenu .article .titolo {
  font-size: 122%;
  padding-top: 5px;
}
#masthead.site-header .menu__header .menu__bottom .submenu.menusx .col-titolo {
  text-align: right;
  padding-right: 30px;
}
#masthead.site-header .menu__header .menu__bottom .submenu.menusx .col-menu {
  padding-left: 60px;
}
#masthead.site-header .menu__header .menu__bottom .submenu.menusx .col-article {
  padding-left: 60px;
}
#masthead.site-header .menu__header .menu__bottom .submenu.menudx .col-titolo {
  text-align: right;
  padding-right: 40px;
}
#masthead.site-header .menu__header .menu__bottom .submenu.menudx .col-menu {
  padding-left: 40px;
}
#masthead.site-header .menu__header .menu__bottom .submenu.menudx .col-article {
  padding-right: 60px;
}
#masthead.site-header .menu__header .menu__bottom .submenu.menudx .wpml-ls-statics-shortcode_actions {
  margin-left: 0;
}
#masthead.site-header .menu__header .menu__bottom .submenu-search .search-field {
  border-radius: 0;
  border-width: 0 0 2px 0;
  padding-left: 0;
  font-size: 26px;
  color: #00225A;
  width: 100%;
}
#masthead.site-header .menu__header .menu__bottom .submenu-search .search-submit {
  display: none;
}
#masthead.site-header .menu__header .menu__bottom .submenu-search ::-webkit-input-placeholder {
  /* WebKit browsers */
  color: transparent;
}
#masthead.site-header .menu__header .menu__bottom .submenu-search :-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: transparent;
}
#masthead.site-header .menu__header .menu__bottom .submenu-search ::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: transparent;
}
#masthead.site-header .menu__header .menu__bottom .submenu-search :-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: transparent;
}
#masthead.site-header .menu__header .menu__bottom .submenu-search input::placeholder {
  color: transparent;
}
#masthead.site-header .menu__header .menu__bottom .submenu-login h2, #masthead.site-header .menu__header .menu__bottom .submenu-login .menu-title {
  padding-bottom: 10px;
}
#masthead.site-header .menu__header .menu__bottom .submenu-login .fa-ul {
  padding-left: 30px;
  margin-bottom: 5px;
}
#masthead.site-header .menu__header .menu__bottom .submenu-login .fa-ul li {
  padding-bottom: 5px !important;
}
#masthead.site-header .menu__header .menu__bottom .submenu-login submit {
  margin-top: 20px;
}
#masthead.site-header .menu__header .menu__bottom .submenu-login a.forgot {
  font-size: 85%;
}
#masthead.site-header .menu__header .menu__bottom .submenu-login a.pulsante, #masthead.site-header .menu__header .menu__bottom .submenu-login submit {
  position: relative;
  border-radius: 25px;
  background: #00225A;
  color: #fff;
  border: 2px solid #00225A;
  box-shadow: none;
  text-shadow: none;
  text-decoration: none !important;
  vertical-align: middle;
  display: inline-block;
  width: auto;
  margin: 0;
  font-size: 88%;
  font-weight: 700;
  text-transform: uppercase;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  -o-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  padding: 10px 30px;
  cursor: pointer;
  min-width: 155px;
  text-align: center;
  padding: 6px 15px;
  font-size: 16px;
}
#masthead.site-header .menu__header .menu__bottom .submenu-login a.pulsante:visited, #masthead.site-header .menu__header .menu__bottom .submenu-login submit:visited {
  color: #fff;
}
#masthead.site-header .menu__header .menu__bottom .submenu-login a.pulsante:hover, #masthead.site-header .menu__header .menu__bottom .submenu-login submit:hover {
  font-weight: 700;
  background: transparent;
  color: #00225A;
  border-color: #00225A;
  box-shadow: none;
}
#masthead.site-header .menu__header .menu__bottom .submenu-login a.pulsante.negativo {
  position: relative;
  border-radius: 25px;
  background: transparent;
  color: #00225A;
  border: 2px solid #00225A;
  box-shadow: none;
  text-shadow: none;
  text-decoration: none !important;
  vertical-align: middle;
  display: inline-block;
  width: auto;
  margin: 0;
  font-size: 88%;
  font-weight: 700;
  text-transform: uppercase;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  -o-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  padding: 10px 30px;
  cursor: pointer;
  min-width: 155px;
  text-align: center;
  padding: 6px 15px;
  font-size: 16px;
}
#masthead.site-header .menu__header .menu__bottom .submenu-login a.pulsante.negativo:visited {
  color: #00225A;
}
#masthead.site-header .menu__header .menu__bottom .submenu-login a.pulsante.negativo:hover {
  font-weight: 700;
  background: #00225A;
  color: #fff;
  border-color: #00225A;
  box-shadow: none;
}
#masthead.site-header .menu__header .menu__bottom .bordo {
  border-right: 2px solid #ededed;
}
#masthead.site-header .menu__header .menu__bottom .bordo-sx {
  border-left: 2px solid #ededed;
}
#masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile {
  height: calc(100vh - 80px);
  overflow: scroll;
}
#masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile ul.mm-depth1 {
  margin-left: 60px;
}
#masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile ul.mm-depth1 li {
  padding-bottom: 0;
}
#masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile .article {
  padding: 40px 0;
}
#masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile .mm-depth1 {
  width: 100%;
  -webkit-transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  display: table;
  line-height: 1.33;
  float: left;
}
#masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile .mm-depth1 .mm-depth1-menu {
  display: table;
  width: 80%;
  height: auto;
  vertical-align: middle;
  margin-bottom: 0;
  max-width: 300px;
}
#masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile .mm-depth1 .mm-depth1-link {
  display: table-cell;
  /*                    left: 0;
                      width: 100%;*/
  padding: 0;
  vertical-align: middle;
  opacity: 1;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0.2s, -webkit-transform 0.5s cubic-bezier(0.6, 0, 0.4, 1);
  transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0.2s, -webkit-transform 0.5s cubic-bezier(0.6, 0, 0.4, 1);
  transition: transform 0.5s cubic-bezier(0.6, 0, 0.4, 1), opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0.2s;
  transition: transform 0.5s cubic-bezier(0.6, 0, 0.4, 1), opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0.2s, -webkit-transform 0.5s cubic-bezier(0.6, 0, 0.4, 1);
  overflow: hidden;
  position: relative;
  padding-right: 40px;
  padding-bottom: 15px;
  font-weight: 600;
}
#masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile .mm-depth1 .mm-depth1-link .icon--next {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px 0;
}
#masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile .mm-depth1 .mm-depth1-link-text {
  display: inline-block;
  position: relative;
}
#masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile .mm-depth1 .mm-depth1-link-text:before {
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  left: 50%;
  -webkit-transition: left 0.2s cubic-bezier(0.4, 0, 0.2, 1), width 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transition: left 0.2s cubic-bezier(0.4, 0, 0.2, 1), width 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  width: 0;
  height: 2px;
  margin-top: 2px;
  background: #00225A;
}
#masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile .mm-depth1 .mm-depth1-link:hover .mm-depth1-link-text, #masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile .mm-depth1 .mm-depth1-link:focus .mm-depth1-link-text {
  font-weight: bold;
}
#masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile .mm-depth1 .mm-depth1-link:hover .mm-depth1-link-text:before, #masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile .mm-depth1 .mm-depth1-link:focus .mm-depth1-link-text:before {
  left: 0;
  width: 100%;
}
#masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile .mm-depth1 a.pulsante {
  position: relative;
  border-radius: 25px;
  background: #00225A;
  color: #fff;
  border: 2px solid #00225A;
  box-shadow: none;
  text-shadow: none;
  text-decoration: none !important;
  vertical-align: middle;
  display: inline-block;
  width: auto;
  margin: 0;
  font-size: 88%;
  font-weight: 700;
  text-transform: uppercase;
  outline: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  -o-transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
  padding: 10px 30px;
  cursor: pointer;
  min-width: 155px;
  text-align: center;
  padding: 6px 15px;
  font-size: 16px;
  margin-top: 30px;
  width: 100%;
}
#masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile .mm-depth1 a.pulsante:visited {
  color: #fff;
}
#masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile .mm-depth1 a.pulsante:hover {
  font-weight: 700;
  background: transparent;
  color: #00225A;
  border-color: #00225A;
  box-shadow: none;
}
#masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile .mm-depth2-wrap {
  display: none;
  overflow: hidden;
  position: fixed;
  left: auto;
  right: 0;
  top: 79px;
  bottom: 0;
  width: 100%;
  background: #fff;
  z-index: 2200;
  -webkit-transform: translateX(360px);
  transform: translateX(360px);
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.6, 0, 0.4, 1) 0.05s;
  transition: -webkit-transform 0.3s cubic-bezier(0.6, 0, 0.4, 1) 0.05s;
  transition: transform 0.3s cubic-bezier(0.6, 0, 0.4, 1) 0.05s;
  transition: transform 0.3s cubic-bezier(0.6, 0, 0.4, 1) 0.05s, -webkit-transform 0.3s cubic-bezier(0.6, 0, 0.4, 1) 0.05s;
}
#masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile .mm-depth2-wrap .mm-menu-close {
  position: absolute;
  top: 20px;
  right: 60px;
  width: 40px;
  height: 40px;
  opacity: 0;
  z-index: 2300;
  -webkit-transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}
#masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile .mm-depth2-wrap .mm-depth2-inner {
  visibility: visible;
  overflow: hidden;
  overflow-y: auto;
  padding-top: 56px;
  height: calc(100vh - 150px);
  /*                        position: absolute;
                          left: 0;
                          top: 56px;
                          bottom: 0;
                          width: 100%;
                          height: auto;*/
  -webkit-transition-property: scrollTop;
  transition-property: scrollTop;
  padding: 0 69px;
}
#masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile .mm-depth2-wrap .mm-depth2-inner ul.mm-depth2 li {
  padding-bottom: 10px;
  margin-bottom: 0;
}
#masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile .mm-depth2-wrap .mm-depth2-inner .mm-depth2-inner-sub {
  width: 25%;
  float: left;
  padding-left: 1em;
  padding-right: 1em;
}
#masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile .mm-depth2-wrap .mm-depth2-inner .mm-depth2-inner-sub:nth-child(1) {
  padding-left: 0;
  border-left-width: 0;
}
#masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile .mm-depth2-wrap .mm-depth2-inner .article {
  width: 40%;
  clear: both;
  padding-right: 1em;
}
#masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile .mm-depth2-wrap .mm-depth2-back-wrap {
  padding-top: 40px;
  padding-bottom: 20px;
  padding-left: 42px;
  width: 25%;
  /*                        -webkit-transform: translateX(60px);
                          transform: translateX(60px);
                          height: 57px;
                          display: block;
                          position: absolute;
                          top: 0;
                          left: 0;
                          height: 56px;
                          width: 100%;*/
  background: #fff;
  z-index: 2100;
  /*                        .mm-depth2-back {
                              display: block;
                              position: absolute;
                              left: 60px;
                              top: 20px;
                              width: 40px;
                              height: 40px;
                          }*/
}
#masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile .mm-depth2-wrap .mm-depth2-back-wrap .mm-depth2-back {
  font-weight: bold;
}
#masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile .mm-depth2-wrap.open {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.6, 0, 0.4, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.6, 0, 0.4, 1);
  transition: transform 0.3s cubic-bezier(0.6, 0, 0.4, 1);
  transition: transform 0.3s cubic-bezier(0.6, 0, 0.4, 1), -webkit-transform 0.3s cubic-bezier(0.6, 0, 0.4, 1);
}
#masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile .mm-depth2-wrap.open .mm-depth2-back-wrap {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: opacity 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0.2s, -webkit-transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  transition: opacity 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0.2s, -webkit-transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0.2s;
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0.2s, -webkit-transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}
#masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile .mm-depth2-wrap.open .mm-depth2-wrap.open .mm-depth2-inner {
  visibility: visible;
}
#masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile .mm-depth2-wrap.open .mm-menu-close {
  visibility: visible;
  opacity: 1;
}
#masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile .submenu-search h2, #masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile .submenu-search .menu-title {
  padding-bottom: 0;
}
#masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile .submenu-search .search-field {
  font-size: 20px;
}
#masthead.site-header .menu__header .menu__bottom.active {
  transition-duration: 0.5s;
  height: 550px;
}
#masthead.site-header .menu__header .menu__bottom.active .submenu.open {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.article.download .data, .article.webinar .data {
  font-weight: bold;
  font-size: 26px;
}
.article.download .image, .article.webinar .image {
  width: 314px;
  height: 210px;
  background-image: url("../img/pattern-webinar.svg");
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  padding-right: 20px;
  font-weight: 300;
  background-size: 1000px;
  position: relative;
}
.article.download .image .date, .article.webinar .image .date {
  font-family: "Gotham Rounded";
  display: contents;
  font-weight: bold;
  font-size: 26px;
}
.article.download .image .time, .article.webinar .image .time {
  font-family: "Gotham Rounded Book";
  display: contents;
  font-size: 32px;
  text-transform: uppercase;
  letter-spacing: -2px;
}
.article.download .image .lang, .article.webinar .image .lang {
  display: block;
  position: absolute;
  right: 30px;
  top: 20px;
  background: #E9EDF3;
  border: 4px solid #fff;
  border-radius: 20px;
  width: 70px;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  height: 30px;
}
.article.download .image .action, .article.webinar .image .action {
  display: block;
  position: absolute;
  bottom: 0;
  background: #00225A;
  color: #fff;
  border-radius: 50px;
  height: 50px;
  font-family: "Gotham Rounded";
  font-weight: bold;
  font-size: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 33px;
  border-top-right-radius: 0;
  left: auto;
  right: 0;
  width: 80%;
}
.article.download .image .action .ico-webinar, .article.webinar .image .action .ico-webinar {
  width: 50px;
  height: auto;
  position: absolute;
  left: -4px;
  bottom: 0;
  font-size: 60px;
}

.article.download .image {
  background-position: center;
  background-size: contain;
}

.item.webinar.live .image {
  background-image: none;
}

#masthead.site-header .menu__header .menu__bottom .submenu .sub-menu-inner {
  padding-left: 10px;
}
#masthead.site-header .menu__header .menu__bottom .submenu .sub-menu-inner .titolo2 {
  padding-bottom: 15px;
}
#masthead.site-header .menu__header .menu__bottom .submenu .sub-menu-inner li.menu-item {
  padding-bottom: 8px;
}
#masthead.site-header .menu__header .menu__bottom .submenu .sub-menu-inner a.mgc__depth3-link {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  position: relative;
  display: block;
}
#masthead.site-header .menu__header .menu__bottom .submenu .sub-menu-inner a.mgc__depth3-link:after {
  display: inline-flex;
  justify-content: end;
  align-items: center;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: Font Awesome\ 5 Pro;
  font-weight: 400;
  padding-right: 10px;
  position: absolute;
  right: 0;
}
#masthead.site-header .menu__header .menu__bottom .submenu .sub-menu-inner a.mgc__depth3-link[aria-expanded=false] {
  background-color: transparent;
}
#masthead.site-header .menu__header .menu__bottom .submenu .sub-menu-inner a.mgc__depth3-link[aria-expanded=false]:after {
  content: none;
}
#masthead.site-header .menu__header .menu__bottom .submenu .sub-menu-inner a.mgc__depth3-link[aria-expanded=true] {
  background-color: #E9EDF3;
}
#masthead.site-header .menu__header .menu__bottom .submenu .sub-menu-inner a.mgc__depth3-link[aria-expanded=true]:after {
  content: "\f105";
}
#masthead.site-header .menu__header .menu__bottom .submenu .sub-sub-menu-mobile ul {
  padding-bottom: 10px;
}
#masthead.site-header .menu__header .menu__bottom .submenu .sub-sub-menu-mobile ul li {
  border-bottom: 1px solid #E9EDF3;
}

#masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile .mm-depth2-wrap .mm-depth2-inner .mm-depth2-inner-sub.sub-menu-inner {
  min-width: 50%;
}
#masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile .mm-depth2-wrap .mm-depth2-inner .mm-depth2-inner-sub.sub-menu-inner a.mgc__depth3-link[aria-expanded=true]:after {
  content: "\f107";
}

@media (max-width: 1250px) {
  #masthead.site-header .menu__header .menu__top {
    padding: 0 10px;
  }
}
@media (max-width: 1199px) {
  #masthead.site-header .menu__header .menu__top {
    padding: 22px 40px;
  }
  #masthead.site-header .menu__header .menu__top ul li {
    padding: 0;
  }
  #masthead.site-header .menu__header .menu__top a {
    font-size: 20px;
  }
  #masthead.site-header .menu__header .menu__bottom .submenu.menudx .col-menu {
    padding-right: 40px;
  }
  #masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile .mm-depth2-wrap {
    top: 0;
  }
  #masthead.site-header .menu__header .menu__bottom.active {
    height: calc(100vh - 80px);
  }
}
@media (max-width: 991px) {
  #masthead.site-header .menu__header .menu__bottom .submenu ul, #masthead.site-header .menu__header .menu__bottom .submenu ul.nav {
    margin-left: 0;
  }
  #masthead.site-header .menu__header .menu__bottom .submenu.menusx .col-article, #masthead.site-header .menu__header .menu__bottom .submenu.menusx .col-menu {
    padding-left: 0;
  }
  #masthead.site-header .menu__header .menu__bottom .submenu.menusx .article {
    margin-left: 30px;
  }
  #masthead.site-header .menu__header .menu__bottom .submenu.menudx .col-article {
    padding-right: 30px;
  }
  #masthead.site-header .menu__header .menu__bottom .submenu.menudx .col-menu {
    padding-right: 9px;
    padding-left: 30px;
  }
  #masthead.site-header .menu__header .menu__bottom .submenu.menudx.submenu-search .blocco {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  #masthead.site-header .menu__header .menu__bottom .submenu.menudx.submenu-search .blocco h2, #masthead.site-header .menu__header .menu__bottom .submenu.menudx.submenu-search .blocco .menu-title {
    padding-bottom: 0;
  }
  #masthead.site-header .menu__header .menu__bottom .submenu.menudx .wpml-ls-statics-shortcode_actions {
    margin-left: 0;
  }
  .navbar-reserved a {
    font-size: 110%;
  }
  .navbar-reserved ul li {
    padding-left: 0;
    padding-bottom: 10px;
  }
  .navbar-reserved ul li a {
    font-size: 110%;
  }
  .navbar-reserved #navbarNav {
    border-top: 2px solid #ededed;
    padding-top: 20px;
    margin-top: 10px;
  }
  .navbar-reserved form {
    border-top: 2px solid #ededed;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 10px;
  }
}
@media (max-width: 768px) {
  #masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile .mm-depth2-wrap .mm-depth2-inner .mm-depth2-inner-sub {
    width: 100%;
    height: auto !important;
    border-left-width: 0;
  }
  #masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile .mm-depth2-wrap .mm-depth2-inner .mm-depth2-inner-sub h2, #masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile .mm-depth2-wrap .mm-depth2-inner .mm-depth2-inner-sub .menu-title {
    padding-top: 20px;
    border-top: 2px solid #ededed;
    border-bottom: 2px solid #ededed;
  }
  #masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile .mm-depth2-wrap .mm-depth2-inner .mm-depth2-inner-sub:nth-child(1) {
    padding-left: 1em;
    border-left-width: 0;
  }
  #masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile .mm-depth2-wrap .mm-depth2-inner .article {
    display: none;
  }
  #masthead.site-header .menu__header .menu__bottom .submenu ul.nav {
    margin-top: 10px;
  }
  #masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile .mm-depth2-wrap .mm-depth2-back-wrap {
    width: 100%;
  }
}
@media (max-width: 767px) {
  #masthead.site-header .menu__header .menu__bottom .submenu.menudx .col-article {
    padding: 0 20px;
  }
  #masthead.site-header .menu__header .menu__bottom .submenu.menudx .col-menu {
    padding-right: 20px;
    padding-left: 20px;
  }
  #masthead.site-header .menu__header .menu__bottom .submenu.menudx.submenu-search .blocco {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  #masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile .mm-depth2-wrap .mm-depth2-back-wrap {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
@media (max-width: 575px) {
  #masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile .mm-depth2-wrap .mm-depth2-back-wrap .mm-depth2-back {
    left: 30px;
  }
  #masthead.site-header .menu__header .menu__top {
    padding: 22px 0;
  }
  #masthead.site-header .menu__header .menu__bottom .submenu {
    min-height: auto;
    padding-top: 0px;
    padding-bottom: 0;
  }
  #masthead.site-header .menu__header .menu__bottom .submenu.submenu-lang, #masthead.site-header .menu__header .menu__bottom .submenu.submenu-login {
    padding-top: 30px;
  }
  #masthead.site-header .menu__header .menu__bottom .bordo-sx {
    border-left: 0;
  }
  #masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile ul.mm-depth1 {
    margin-left: 0;
  }
  #masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile ul.mm-depth1 .mm-depth1-menu {
    margin-top: 15px;
    margin-bottom: 15px;
    border-bottom: 2px solid #ededed;
    width: 100%;
    max-width: 100%;
  }
  #masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile ul.mm-depth1 .mm-depth1-menu:last-of-type {
    border-bottom: 0;
  }
  #masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile .mm-depth2-wrap .mm-depth2-inner {
    padding: 0 15px;
  }
  #masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile .mm-depth2-wrap .mm-depth2-inner .mm-depth2-inner-sub {
    padding-left: 0;
  }
  #masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile .mm-depth2-wrap .mm-depth2-inner .mm-depth2-inner-sub:first-child {
    padding-left: 0;
  }
  #masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile .mm-depth2-wrap .mm-depth2-back-wrap {
    padding-top: 25px;
    padding-left: 10px;
  }
}
@media (max-width: 480px) {
  #masthead.site-header .logo {
    width: 140px;
  }
  #masthead.site-header .menu__header .menu__top {
    padding: 12px 0;
  }
  #masthead.site-header .menu__header .menu__bottom {
    top: 58px;
  }
  #masthead.site-header .menu__header .menu__bottom .submenu.submenu-mobile {
    height: calc(100vh - 58px);
    overflow: scroll;
  }
}
/*
    Created on : 31-ago-2020, 10.04.40
    Author     : veronica
*/
/* ++++++++++++++++  OVERRIDE ++++++++++++++++++++++*/
/***** Remove cookiebot logo *******/
/* Branding on the banner */
a#CybotCookiebotDialogPoweredbyCybot,
div#CybotCookiebotDialogPoweredByText {
  display: none;
}

/* Branding on the widget */
#CookiebotWidget .CookiebotWidget-body .CookiebotWidget-main-logo {
  display: none;
}

/* ++++++++++++++++++++++++++++++++++++++++++++++++*/
@media (max-width: 2200px) {
  #primary.site-main.home .supertop {
    background-size: contain;
  }
}
@media (max-width: 1500px) {
  #primary.site-main.home #chi-siamo .immagine {
    max-width: 674px;
    right: 80px;
    width: 43%;
  }
  #primary.site-main #review .sfondo {
    width: 70%;
  }
}
@media (max-width: 1350px) {
  #primary.site-main #block-contatori .wrapper .item {
    padding: 50px 75px;
  }
  #primary.site-main #block-contatori .wrapper .item .titolo {
    font-size: 265%;
  }
  #primary.site-main #block-contatori .wrapper .item .sottotitolo {
    font-size: 150%;
  }
  .curva-tl {
    border-top-left-radius: 200px;
  }
  .curva-tr {
    border-top-right-radius: 200px;
  }
  .curva-br {
    border-bottom-right-radius: 200px;
  }
  .curva-bl {
    border-bottom-left-radius: 200px;
  }
  #primary.site-main.home #chi-siamo .immagine {
    max-width: 674px;
    position: absolute;
    right: 36px;
    bottom: 0;
    border-radius: 20%;
    width: 46%;
  }
  #primary.site-main #block-languages .lista .el {
    flex-basis: 33%;
  }
  #primary.site-main #block-cloud .immagine img {
    padding: 140px 0;
  }
}
@media (max-width: 1250px) {
  #primary.site-main.home #chi-siamo {
    padding-bottom: 0;
  }
  #primary.site-main .roadmap .grafica .el:nth-child(1) img {
    width: 20%;
    height: auto;
  }
  #primary.site-main .roadmap .grafica .el:nth-child(2) img {
    width: 40%;
    height: auto;
  }
  #primary.site-main .roadmap .grafica .el:nth-child(3) img {
    width: 50%;
    height: auto;
  }
  #primary.site-main .intro-titolosx .contenuto {
    padding-bottom: 120px;
  }
  #primary.site-main .img-destra-top {
    right: 10px;
    top: auto;
    bottom: 5%;
  }
}
@media (max-width: 1199px) {
  .titolo2.big {
    font-size: 50px;
    line-height: 50px;
  }
  .cta-gialla img {
    width: auto;
    height: 430px;
    object-fit: cover;
    object-position: 0;
    padding-left: 0px;
  }
  #primary.site-main #block-intro .imgxl {
    top: 20%;
    left: auto;
    right: 0;
    max-width: 50vw;
  }
  #primary.site-main #block-contatori .wrapper .item {
    padding: 50px 65px;
  }
  #primary.site-main #block-contatori .wrapper .item .titolo {
    font-size: 230%;
  }
  #primary.site-main #block-contatori .wrapper .item .sottotitolo {
    font-size: 120%;
  }
  #primary.site-main .integrazioni .griglia {
    grid-gap: 30px 0;
  }
  #primary.site-main .integrazioni .griglia .item .cerchio {
    width: 170px;
    height: 170px;
  }
  #primary.site-main.home #prodotti {
    background-size: 70px;
  }
  #primary.site-main.home #chi-siamo {
    background-position: 130% 0;
  }
  #primary.site-main #jolly.imgdx .immagine {
    right: 0;
    width: 50%;
  }
  #primary.site-main #jolly.imgdx .forma {
    border-radius: 180px 0 180px 0;
  }
  #primary.site-main #block-languages .lista .el {
    flex-basis: 25%;
  }
  #primary.site-main.kb #featured ul {
    column-count: 2;
  }
  #primary.site-main #myModalInt .duecol, #primary.site-main #myModalInt .lista_due_colonne {
    column-count: 1;
  }
  #primary.site-main #myModalInt .apps .griglia {
    display: grid;
    grid-gap: 1vh;
    grid-template-columns: repeat(3, 1fr);
  }
  #primary.site-main #myModalInt .apps .griglia .item {
    flex-basis: 100%;
    margin-bottom: 0;
  }
  #primary.site-main.kb-result iframe {
    height: calc(100vh - 120px);
  }
}
@media (max-width: 1150px) {
  #primary.site-main.home #news-home .tondo {
    right: -10px;
  }
  #primary.site-main .calltoaction .immagine {
    width: 50%;
  }
}
@media (max-width: 991px) {
  .curva-tl {
    border-top-left-radius: 170px;
  }
  .curva-tr {
    border-top-right-radius: 170px;
  }
  .curva-br {
    border-bottom-right-radius: 170px;
  }
  .curva-bl {
    border-bottom-left-radius: 170px;
  }
  h1, .titolo1 {
    font-size: 54px;
    font-weight: 500;
    line-height: 1;
    padding-bottom: 15px;
  }
  h2, .titolo2 {
    font-size: 40px;
    font-weight: 700;
    line-height: 1.15;
    padding-bottom: 20px;
  }
  h3, .titolo3 {
    font-size: 30px;
    font-weight: 700;
    line-height: 1.3;
  }
  h4, .titolo4 {
    font-size: 24px;
    font-weight: 700;
    line-height: 1.3;
  }
  h5, .titolo5 {
    font-size: 22px;
    font-weight: 500;
    line-height: 1.3;
  }
  h6, .sottotitolo {
    font-size: 20px;
    font-weight: 450;
    line-height: 1.3;
  }
  .subhead {
    font-size: 30px;
    line-height: 1.3;
  }
  .titolo2.big {
    font-size: 45px;
    line-height: 45px;
  }
  .cta-gialla img {
    height: 380px;
  }
  /* INSERISCO IL PADDING COME DA SPAZIATORE */
  .blocco {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .blocco-top {
    padding-top: 100px;
  }
  .blocco-bottom {
    padding-bottom: 100px;
  }
  /* INSERISCO IL PADDING COME DA SPAZIATORE 2/3 */
  .blocco-m {
    padding-top: calc(66.6666666667px);
    padding-bottom: calc(66.6666666667px);
  }
  .blocco-m-t {
    padding-top: calc(66.6666666667px);
  }
  .blocco-m-b {
    padding-bottom: calc(66.6666666667px);
  }
  .blocco-s {
    padding-top: calc(50px);
    padding-bottom: calc(50px);
  }
  .blocco-s-t {
    padding-top: calc(50px);
  }
  .blocco-s-b {
    padding-bottom: calc(50px);
  }
  /* INSERISCO IL MARGINE COME DA SPAZIATORE */
  .margini {
    margin-top: 100px;
    margin-bottom: 100px;
  }
  .margine-top {
    margin-top: 100px;
  }
  .margine-bottom {
    margin-bottom: 100px;
  }
  .margini-m {
    margin-top: calc(66.6666666667px);
    margin-bottom: calc(66.6666666667px);
  }
  .margine-m-t {
    margin-top: calc(66.6666666667px);
  }
  .margine-m-b {
    margin-bottom: calc(66.6666666667px);
  }
  .margini-s {
    margin-top: calc(50px);
    margin-bottom: calc(50px);
  }
  .margine-s-t {
    margin-top: calc(50px);
  }
  .margine-s-b {
    margin-bottom: calc(50px);
  }
  .nav-link {
    padding: 0.5rem 0.6rem;
  }
  .tabella .td {
    border-left: 0;
    border-top: 1px solid #e9edf3;
  }
  .tabella .td .intestazione {
    width: 50%;
    float: left;
    text-align: left;
  }
  .tabella .td .contenuto {
    width: 50%;
    float: right;
    text-align: right;
  }
  #myModalInt .barra-dx {
    padding-top: 0px;
  }
  #primary.site-main .top-header.titolosx {
    background-position: bottom right;
    background-size: 200%;
    padding-top: 50px;
  }
  #primary.site-main .top-header.titolosx .contenuto, #primary.site-main .top-header.titolosx .immagine {
    text-align: center;
  }
  #primary.site-main .intro-titolosx {
    padding-top: 20px;
  }
  #primary.site-main .intro-titolosx .contenuto {
    padding-bottom: 120px;
  }
  #primary.site-main #block-intro {
    padding-top: 0;
    background-image: none;
  }
  #primary.site-main #block-intro .imgxl-container {
    padding-top: calc(60px);
    padding-bottom: calc(60px);
    background: #00C0F0;
    text-align: center;
    border-bottom-left-radius: 35%;
  }
  #primary.site-main #block-intro .imgxl-container .imgxl {
    position: relative;
    left: auto;
    top: auto;
    padding: 0;
    max-width: 70vw;
  }
  #primary.site-main #block-suite .item .inner {
    padding: 20px;
  }
  #primary.site-main .double-cta {
    padding: 0;
  }
  #primary.site-main .double-cta .item {
    border-right: 0;
    border-bottom: 2px solid #E9EDF3;
  }
  #primary.site-main .double-cta .item .inner {
    padding: 40px 0;
  }
  #primary.site-main .double-cta .item .inner .content {
    min-height: auto;
  }
  #primary.site-main .double-cta .item:first-of-type {
    border-left: 0;
  }
  #primary.site-main .double-cta .item:last-of-type {
    border-bottom: 0;
  }
  #primary.site-main .double-cta .item h3 br {
    display: none;
    padding-left: 5px;
  }
  #primary.site-main #block-suite-new .item {
    border-bottom-color: #fff;
    border-right: 0;
  }
  #primary.site-main #block-suite-new .item:first-of-type {
    border-left: 0;
  }
  #primary.site-main #block-contatori .wrapper .item:first-of-type {
    padding-right: 0;
  }
  #primary.site-main #block-contatori .wrapper .item:last-of-type {
    padding-left: 0;
  }
  #primary.site-main .calltoaction .titolo5 {
    padding-bottom: 25px;
  }
  #primary.site-main .calltoaction submit {
    margin-top: 10px;
    width: 100%;
  }
  #primary.site-main .prod-cta .item {
    margin: 0;
    border-left: 0;
    border-bottom: 1px solid #fff;
  }
  #primary.site-main .prod-cta .item:last-of-type {
    border-right: 0;
    border-bottom: 0;
  }
  #primary.site-main .integrazioni .griglia {
    grid-gap: 20px 0;
  }
  #primary.site-main .integrazioni .griglia .item .cerchio {
    width: 130px;
    height: 130px;
  }
  #primary.site-main.home .supertop-text {
    background-image: url("../img/home-top-titolo-mob.svg");
  }
  #primary.site-main.home #news-home {
    padding-bottom: 90px;
  }
  #primary.site-main.home #news-home .cerchio {
    display: none;
  }
  #primary.site-main.home #chi-siamo {
    background-image: none;
    padding-top: 0px;
    margin-top: 0;
    padding-top: 90px;
  }
  #primary.site-main.home #chi-siamo .img-round {
    border-radius: 100px;
    margin-bottom: 50px;
  }
  #primary.site-main #jolly.imgdx .immagine {
    right: 0;
    width: 100%;
  }
  #primary.site-main #jolly.imgdx .forma {
    border-radius: 180px 0 180px 0;
  }
  #primary.site-main.webinar #blocco-live-demand .live-lang, #primary.site-main.webinar #blocco-live-demand .live-type, #primary.site-main.webinar #blocco-live-demand .ondemand-lang, #primary.site-main.webinar #blocco-live-demand .ondemand-type {
    margin-bottom: 20px;
  }
  #primary.site-main #block-intro .imgxl-container {
    padding-bottom: 0;
    text-align: right;
    padding-right: 0px;
    padding-top: 5px;
  }
  #primary.site-main #block-intro .imgxl-container .imgxl {
    width: 70%;
    right: 10px;
  }
  #primary.site-main #review .sfondo {
    width: 100%;
    height: 100%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  #primary.site-main #review .lista-review .citazione {
    padding-bottom: 20px;
  }
  #primary.site-main .img-sinistra-bottom {
    left: -40px;
    bottom: 20px;
  }
  #primary.site-main .roadmap .contenuto {
    position: relative;
    padding-bottom: 100px;
  }
  #primary.site-main .roadmap .grafica {
    display: none;
  }
  #primary.site-main.landing .form-wrapper {
    transform: translateY(0);
    margin-top: 50px;
    height: 630px;
    min-height: auto;
  }
  #primary.site-main.about #block-intro .imgxl-container {
    background: none;
    text-align: center;
    padding-top: 0;
  }
  #primary.site-main #block-cloud .immagine img {
    padding: 80px 0;
  }
  #primary.site-main #myModalInt .apps .griglia {
    grid-template-columns: repeat(2, 1fr);
  }
  #primary.site-main.landing-2023-sticky .landing-header {
    height: 1530px;
    transition: 1s;
    transition-property: height;
  }
  #primary.site-main.landing-2023-sticky .landing-header.form-open {
    height: 1790px;
  }
  #primary.site-main .help-block .lista-blocchi-help .item .img-slider {
    padding: 1rem;
  }
  #primary.site-main .help-block .lista-blocchi-help .item {
    padding: 40px;
  }
  #primary.site-main .approfondimento .item {
    padding: 30px;
  }
  #primary.site-main .approfondimento .lista .lista-item {
    padding-left: 30px;
    padding-right: 30px;
  }
  #primary.site-main .approfondimento .lista .lista-item:first-child {
    padding-left: 10px;
  }
  #primary.site-main .approfondimento .lista .lista-item:last-child {
    padding-right: 10px;
  }
}
@media (max-width: 950px) {
  #primary.site-main #block-contatori .wrapper .item {
    padding: 40px 55px;
  }
  #primary.site-main #block-contatori .wrapper .item .titolo {
    font-size: 180%;
  }
  #primary.site-main #block-contatori .wrapper .item .sottotitolo {
    font-size: 110%;
  }
}
@media (max-width: 768px) {
  .curva-tl {
    border-top-left-radius: 120px;
  }
  .curva-tr {
    border-top-right-radius: 120px;
  }
  .curva-br {
    border-bottom-right-radius: 120px;
  }
  .curva-bl {
    border-bottom-left-radius: 120px;
  }
  #primary.site-main.home #chi-siamo {
    background-position: 400% 0;
    padding-top: 40px;
  }
  /*    #primary.site-main .roadmap .grafica {
          margin-right: -250px;
      }*/
  #primary.site-main.blog-single .body-blog {
    background-image: none;
  }
}
@media (max-width: 767px) {
  h1, .titolo1 {
    font-size: 58px;
    font-weight: 500;
    line-height: 1;
    padding-bottom: 15px;
  }
  h2, .titolo2 {
    font-size: 38px;
    font-weight: 700;
    line-height: 1.15;
    padding-bottom: 20px;
  }
  h3, .titolo3 {
    font-size: 28px;
    font-weight: 700;
    line-height: 1.3;
  }
  h4, .titolo4 {
    font-size: 22px;
    font-weight: 700;
    line-height: 1.3;
  }
  h5, .titolo5 {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.3;
  }
  h6, .sottotitolo {
    font-size: 18px;
    font-weight: 450;
    line-height: 1.3;
  }
  .subhead {
    font-size: 28px;
    line-height: 1.3;
  }
  .titolo2.big {
    font-size: 43px;
    line-height: 43px;
  }
  .cta-gialla img {
    height: 377px;
    object-fit: cover;
    object-position: 50px -20px;
    padding-left: 0;
    width: 100%;
  }
  .wpml-ls-legacy-list-vertical a {
    padding-left: 0;
  }
  #colophon.site-footer .newsletter {
    padding-top: 0;
  }
  #primary.site-main .lista-blocchi .item {
    border-radius: 25px;
  }
  #primary.site-main .intro-titolosx .contenuto {
    padding-bottom: 50px;
  }
  #primary.site-main #block-suite {
    padding: 0;
  }
  #primary.site-main #block-suite .item {
    border-right: 0;
    border-bottom: 2px solid #fff;
  }
  #primary.site-main #block-suite .item .inner {
    padding: 40px 0;
    position: relative;
  }
  #primary.site-main #block-suite .item .inner .content {
    width: 90%;
    min-height: auto;
  }
  #primary.site-main #block-suite .item .inner .content h3 {
    padding-bottom: 0;
  }
  #primary.site-main #block-suite .item .inner .puntatore {
    position: absolute;
    right: 0;
    top: calc(50% - 27px);
    display: block;
  }
  #primary.site-main #block-suite .item .inner .puntatore a {
    color: #00225A;
    transition-timing-function: ease-in-out;
    transition-duration: 200ms;
    -webkit-transition-duration: 200ms;
    -moz-transition-duration: 200ms;
    -ms-transition-duration: 200ms;
    -o-transition-duration: 200ms;
    text-decoration: none;
  }
  #primary.site-main #block-suite .item .inner .puntatore a:focus {
    outline: 0;
  }
  #primary.site-main #block-suite .item .inner .puntatore a:visited {
    color: #00225A;
  }
  #primary.site-main #block-suite .item .inner .puntatore a:hover {
    color: #00225A;
    text-decoration: underline;
  }
  #primary.site-main #block-suite .item:first-of-type {
    border-left: 0;
  }
  #primary.site-main #block-suite .item:last-of-type {
    border-bottom: 0;
  }
  #primary.site-main #block-suite .item h3 br {
    display: none;
    padding-left: 5px;
  }
  #primary.site-main #block-suite-new .item .inner .content {
    min-height: auto;
  }
  #primary.site-main #block-contatori .wrapper {
    flex-wrap: wrap;
  }
  #primary.site-main #block-contatori .wrapper .item:first-of-type, #primary.site-main #block-contatori .wrapper .item:last-of-type {
    display: none;
  }
  #primary.site-main #block-contatori .wrapper .item:nth-child(2), #primary.site-main #block-contatori .wrapper .item:nth-child(3), #primary.site-main #block-contatori .wrapper .item:nth-child(4), #primary.site-main #block-contatori .wrapper .item:nth-child(5) {
    width: auto;
    flex-grow: 1;
    flex-basis: 50%;
    max-width: 400px;
  }
  #primary.site-main #block-contatori .wrapper .item:nth-child(2), #primary.site-main #block-contatori .wrapper .item:nth-child(4) {
    margin-left: 0;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
  }
  #primary.site-main #block-contatori .wrapper .item:nth-child(3), #primary.site-main #block-contatori .wrapper .item:nth-child(5) {
    margin-right: 0;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
  }
  #primary.site-main #block-contatori .wrapper .item:nth-child(4), #primary.site-main #block-contatori .wrapper .item:nth-child(5) {
    margin-top: -25px;
  }
  #primary.site-main .calltoaction {
    padding-top: 300px;
  }
  #primary.site-main .calltoaction .container {
    position: relative;
    z-index: 1000;
  }
  #primary.site-main .calltoaction .immagine {
    width: 100%;
    height: 300px;
    border-radius: 0 0 0 300px;
    z-index: 200;
  }
  #primary.site-main .calltoaction .cerchio {
    left: auto;
    right: 0;
    height: 100%;
    border-radius: 0;
    top: 0;
    z-index: 1000;
    background-color: transparent;
  }
  #primary.site-main .lista-blocchi .container-fluid, #primary.site-main .lista-blocchi .container-sm, #primary.site-main .lista-blocchi .container-md, #primary.site-main .lista-blocchi .container-lg, #primary.site-main .lista-blocchi .container-xl {
    padding-right: 0;
  }
  #primary.site-main .lista-blocchi .container-fluid .owl-carousel .owl-item img, #primary.site-main .lista-blocchi .container-sm .owl-carousel .owl-item img, #primary.site-main .lista-blocchi .container-md .owl-carousel .owl-item img, #primary.site-main .lista-blocchi .container-lg .owl-carousel .owl-item img, #primary.site-main .lista-blocchi .container-xl .owl-carousel .owl-item img {
    width: 50%;
  }
  #primary.site-main .lista-blocchi .item .pulsante {
    margin-top: 20px;
  }
  #primary.site-main #assistenza .inner {
    padding-top: 20px;
  }
  #primary.site-main #assistenza .blocco {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  #primary.site-main #storie .lista .item img {
    display: none;
  }
  #primary.site-main #storie .approfondimento .item {
    padding: 40px;
  }
  #primary.site-main #storie .approfondimento .item .sinistra {
    border-right: 0;
    border-bottom: 1px solid #ccc;
    height: auto;
    padding-bottom: 20px;
  }
  #primary.site-main #storie .approfondimento .item .sinistra .introimg {
    display: block;
  }
  #primary.site-main #storie .approfondimento .item .contenuto {
    height: auto;
    padding-top: 20px;
  }
  #primary.site-main #storie .approfondimento .item .contenuto .testo {
    padding-left: 0;
  }
  #primary.site-main .integrazioni .griglia {
    grid-gap: 18px;
    grid-template-columns: repeat(4, 1fr);
  }
  #primary.site-main .integrazioni .griglia .item .cerchio {
    width: 116px;
    height: 100%;
  }
  #primary.site-main.landing .form-wrapper {
    height: 900px;
  }
  #primary.site-main.home #chi-siamo {
    background-image: none;
  }
  #primary.site-main.home #chi-siamo .testo {
    padding-top: 0;
  }
  #primary.site-main.home #chi-siamo .img-round {
    border-radius: 72px;
  }
  #primary.site-main.home #news-home {
    background-size: cover;
    background-position: right;
  }
  /*    #primary.site-main .roadmap .grafica  {
          padding-top: 150px;
      }*/
  #primary.site-main.legal .top.blocco {
    padding-bottom: 0;
  }
  #primary.site-main.legal .sottomenu {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  #primary.site-main.kb #featured ul {
    column-count: 1;
  }
  #primary.site-main.page-webex .apps .griglia .item {
    max-width: 50%;
  }
  #primary.site-main .approfondimento .item {
    padding: 30px;
  }
  #primary.site-main .approfondimento .lista .lista-item {
    padding: 30px 0;
    border-right: 0;
    border-bottom: 3px solid rgb(204, 212, 226);
  }
  #primary.site-main .approfondimento .lista .lista-item:first-child {
    padding-left: 0px;
    padding-top: 0;
  }
  #primary.site-main .approfondimento .lista .lista-item:last-child {
    padding-right: 0px;
    border-bottom: 0;
    padding-bottom: 0;
  }
}
@media (max-width: 575px) {
  h1, .titolo1 {
    font-size: 50px;
    font-weight: 500;
    line-height: 1;
    padding-bottom: 15px;
  }
  h2, .titolo2 {
    font-size: 34px;
    font-weight: 700;
    line-height: 1.15;
    padding-bottom: 20px;
  }
  h3, .titolo3 {
    font-size: 28px;
    font-weight: 700;
    line-height: 1.3;
  }
  h4, .titolo4 {
    font-size: 22px;
    font-weight: 700;
    line-height: 1.3;
  }
  h5, .titolo5 {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.3;
  }
  h6, .sottotitolo {
    font-size: 18px;
    font-weight: 450;
    line-height: 1.3;
  }
  .subhead {
    font-size: 28px;
    line-height: 1.3;
  }
  /* INSERISCO IL PADDING COME DA SPAZIATORE */
  .blocco {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .blocco-top {
    padding-top: 60px;
  }
  .blocco-bottom {
    padding-bottom: 60px;
  }
  /* INSERISCO IL PADDING COME DA SPAZIATORE 2/3 */
  .blocco-m {
    padding-top: calc(40px);
    padding-bottom: calc(40px);
  }
  .blocco-m-t {
    padding-top: calc(40px);
  }
  .blocco-m-b {
    padding-bottom: calc(40px);
  }
  .blocco-s {
    padding-top: calc(30px);
    padding-bottom: calc(30px);
  }
  .blocco-s-t {
    padding-top: calc(30px);
  }
  .blocco-s-b {
    padding-bottom: calc(30px);
  }
  /* INSERISCO IL MARGINE COME DA SPAZIATORE */
  .margini {
    margin-top: 60px;
    margin-bottom: 60px;
  }
  .margine-top {
    margin-top: 60px;
  }
  .margine-bottom {
    margin-bottom: 60px;
  }
  .margini-m {
    margin-top: calc(40px);
    margin-bottom: calc(40px);
  }
  .margine-m-t {
    margin-top: calc(40px);
  }
  .margine-m-b {
    margin-bottom: calc(40px);
  }
  .margini-s {
    margin-top: calc(30px);
    margin-bottom: calc(30px);
  }
  .margine-s-t {
    margin-top: calc(30px);
  }
  .margine-s-b {
    margin-bottom: calc(30px);
  }
  .sfondo-giallo .immagine {
    background-repeat: no-repeat;
    background-size: cover;
    height: 40vh;
    width: 100%;
    background-position: left center;
    max-height: 410px;
  }
  #colophon.site-footer .logoimagicle {
    border-bottom: 0;
    padding-bottom: 0;
  }
  .owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: 10px;
  }
  .owl-theme .owl-dots .owl-dot span {
    width: 10px;
    height: 10px;
  }
  .swiper-pagination-bullet {
    height: 10px;
    width: 10px;
  }
  .container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
    padding-right: 18px;
    padding-left: 18px;
  }
  a.pulsante {
    width: 100% !important;
    text-align: center;
  }
  #primary.site-main.prodotto #storie .lista {
    display: none;
  }
  #primary.site-main.prodotto #storie .approfondimento {
    display: block !important;
    padding-top: 0;
  }
  #primary.site-main.prodotto #storie .approfondimento .item .sinistra logo {
    float: right;
  }
  #primary.site-main.prodotto #storie .approfondimento .item .sinistra .introimg {
    display: none;
  }
  #primary.site-main.prodotto #storie .approfondimento .item .contenuto .imgbottom {
    display: block;
  }
  #primary.site-main .lista-blocchi {
    padding-bottom: 0;
  }
  #primary.site-main .lista-blocchi .lista {
    margin-left: 18px;
    padding-bottom: 30px;
  }
  #primary.site-main .lista-blocchi .lista .owl-carousel.owl-drag .owl-item {
    margin-bottom: 20px;
  }
  #primary.site-main .integrazioni .griglia {
    grid-template-columns: repeat(3, 1fr);
  }
  #primary.site-main .intro-titolo-center .immagine img {
    border-bottom-left-radius: 5vh;
    border-bottom-right-radius: 5vh;
  }
  #primary.site-main.home .supertop {
    background-image: none;
  }
  #primary.site-main.home #top-home {
    position: relative;
    padding-bottom: 10px;
  }
  #primary.site-main.home #top-home .subhead {
    padding-bottom: 0;
  }
  #primary.site-main.home #top-home .container, #primary.site-main.home #top-home .row {
    height: 100%;
  }
  #primary.site-main.home #top-home .el-grafico .blocco-s-t {
    padding-bottom: 30px;
  }
  #primary.site-main.home #top-home .play-video .play-video-tag {
    font-size: 50px;
  }
  #primary.site-main.home #prodotti.pacchetti.lista-blocchi .item img {
    height: 97px;
  }
  #primary.site-main.home #prodotti.pacchetti.lista-blocchi .item .inner {
    font-size: 18px;
    padding: 25px;
  }
  #primary.site-main.home #news-home {
    padding-top: 60px;
    padding-bottom: 40px;
  }
  #primary.site-main #block-contatori .wrapper .item .titolo {
    font-size: 211%;
  }
  #primary.site-main .block-cta-round .immagine {
    margin-left: -9px;
  }
  #primary.site-main .block-cta-round.dx .immagine {
    margin-right: -9px;
  }
  #primary.site-main .block-cta-round-sx .immagine {
    margin-left: -9px;
  }
  #primary.site-main #myModalInt .apps .griglia {
    grid-template-columns: repeat(1, 1fr);
  }
  #primary.site-main.legal .inner {
    padding: 20px;
  }
  #primary.site-main.landing-2023-sticky .landing-header {
    height: 1030px;
  }
  #primary.site-main.landing-2023-sticky .landing-header.form-open {
    height: 1280px;
  }
  #primary.site-main .help-block .lista-blocchi-help .item {
    padding: 30px;
  }
  #primary.site-main .help-block .lista-blocchi-help .item .img-slider {
    padding: 60px 20px 40px 20px;
  }
}
@media (max-width: 480px) {
  h1, .titolo1 {
    font-size: 42px;
    font-weight: 500;
    line-height: 1;
    padding-bottom: 15px;
  }
  h2, .titolo2 {
    font-size: 30px;
    font-weight: 700;
    line-height: 1.15;
    padding-bottom: 20px;
  }
  h3, .titolo3 {
    font-size: 24px;
    font-weight: 700;
    line-height: 1.3;
  }
  h4, .titolo4 {
    font-size: 22px;
    font-weight: 700;
    line-height: 1.3;
  }
  h5, .titolo5 {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.3;
  }
  h6, .sottotitolo {
    font-size: 18px;
    font-weight: 450;
    line-height: 1.3;
  }
  .subhead {
    font-size: 24px;
    line-height: 1.3;
  }
  #primary.site-main.home #top-home {
    padding: 0;
    height: auto;
  }
  #primary.site-main .calltoaction {
    padding-top: 200px;
  }
  #primary.site-main .calltoaction .cerchio {
    width: 19%;
  }
  #primary.site-main .calltoaction .immagine {
    height: 200px;
  }
  #primary.site-main.home #chi-siamo {
    padding-top: 20px;
  }
  #primary.site-main.home #chi-siamo .img-round {
    border-radius: 46px;
  }
  #primary.site-main.page-webex .apps .griglia .item {
    max-width: 100%;
  }
}
@media (max-width: 375px) {
  #primary.site-main .integrazioni .griglia {
    grid-gap: 10px;
    grid-template-columns: repeat(2, 1fr);
  }
}